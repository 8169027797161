import { sortBy, union } from 'underscore';

import { Option } from 'components/Select';
import { YoutubeLanguage } from 'redux/middleware/api/podcast-service/types';
import { NO_SELECTION_VALUE } from '../constants';

const NO_SELECTION_OPTION: Option = {
  label: 'None specified',
  value: NO_SELECTION_VALUE,
};

export const getSelectedOptionValue = (option: Option): string | undefined => {
  // "none" is an artificial value that maps to undefined from the parent.
  // If the value is picked at the selector, the output value is set to undefined.
  if (option.value === NO_SELECTION_VALUE) {
    return undefined;
  }

  return String(option.value);
};

// Maps the current options and includes the default (none) option.
export const formatLanguageOptions = (languages: YoutubeLanguage[]): Option[] =>
  union(
    [NO_SELECTION_OPTION],
    sortBy(
      languages.map(language => ({
        label: language.name,
        value: language.languageCode,
      })),
      option => option.label,
    ),
  );

export const getLanguageOption = (
  options: Option[],
  value?: string,
): Option => {
  // When no option matches the selected one, the default option is returned.
  return options.find(opt => opt.value === value) ?? NO_SELECTION_OPTION;
};
