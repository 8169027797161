import cn from 'classnames';
import React, { useCallback } from 'react';

import BemCSSTransition from 'components/BemCssTransition';
import Button from 'components/Button';
import { H2 } from 'components/Heading';
import {
  DEFAULT_CLIP_END_MILLIS,
  DEFAULT_CLIP_START_MILLIS,
} from '../constants';
import { footerBlock as block } from '../utils';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';
import { useClipSelectTracking } from './ClipSelectTrackingContext';

export interface ClipSelectFooterProps {
  className?: string;
}

const ClipSelectFooter: React.FC<ClipSelectFooterProps> = ({ className }) => {
  const animations = useClipSelectAnimation();
  const {
    onRegionChange,
    videoPlayer,
    clipsPageInfo: { mediaDurationMillis },
  } = useClipSelect();
  const { onSelectOwnClip } = useClipSelectTracking();
  const [, send] = useClipSelectNavigation();

  const handleButtonClick = useCallback(() => {
    videoPlayer?.seek(0);
    onRegionChange({
      startMillis: DEFAULT_CLIP_START_MILLIS,
      endMillis:
        mediaDurationMillis > DEFAULT_CLIP_END_MILLIS
          ? DEFAULT_CLIP_END_MILLIS
          : mediaDurationMillis,
    });
    send({ type: 'CUSTOM_CLICK' });
    onSelectOwnClip();
  }, [mediaDurationMillis, onRegionChange, onSelectOwnClip, send, videoPlayer]);

  return (
    <div className={cn(block(), className)}>
      <div className={block('contents')}>
        <H2 className={block('message')}>Don't like these suggestions?</H2>
        <BemCSSTransition {...animations.footerCta}>
          <Button
            onClick={handleButtonClick}
            theme="submit-alt"
            className={block('select-button')}
          >
            select your own clip
          </Button>
        </BemCSSTransition>
      </div>
    </div>
  );
};

export default ClipSelectFooter;
