import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalFooterButton } from 'components/Modal';
import { Step } from 'components/SteppedModal';
import Tooltip from 'components/Tooltip';
import { videoIdSelector } from 'redux/modules/download';
import { onClickInstagramPostModalCTA } from 'redux/modules/mixpanel';
import {
  instagramProviderUserIdSelector,
  instagramUserIdSelector,
  postInstagramVideo,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import SelectAccountStep from '../SelectAccountStep';
import { StepProps } from '../types';
import { MAX_INSTAGRAM_STORY_DURATION_SEC } from './constants';

export interface UseSelectAccountStepConfig extends StepProps {
  videoDurationSec: number;
}

export default function useSelectAccountStep({
  videoDurationSec,
  goToStep,
}: UseSelectAccountStepConfig): Step {
  const instagramUserId = useSelector(instagramUserIdSelector);
  const providerUserId = useSelector(instagramProviderUserIdSelector);

  const dispatch = useDispatch<Dispatch>();
  const isStoriesAllowed = videoDurationSec <= MAX_INSTAGRAM_STORY_DURATION_SEC;

  const handlePostAsStory = useCallback(() => {
    dispatch((_, getState) => {
      dispatch(onClickInstagramPostModalCTA('PostStory'));

      const videoId = videoIdSelector(getState());

      dispatch(
        postInstagramVideo(videoId, {
          instagramUserId,
          instagramMediaType: 'stories',
          providerUserId,
        }),
      );

      goToStep('loader');
    });
  }, [dispatch, goToStep, instagramUserId, providerUserId]);

  const handlePostToFeed = useCallback((): void => {
    dispatch(onClickInstagramPostModalCTA('SelectFeed'));
    goToStep('composer');
  }, [dispatch, goToStep]);

  return {
    component: (
      <SelectAccountStep
        onSwitchAccountClick={() => goToStep('switch-account')}
      />
    ),
    id: 'select-account',
    fluidFooterButtons: true,
    renderFooterButtons: ({ submit }) => [
      <Tooltip
        content={`Videos longer than ${MAX_INSTAGRAM_STORY_DURATION_SEC} seconds cannot be posted as a story`}
        placement="top"
        show={isStoriesAllowed ? false : undefined}
      >
        <ModalFooterButton
          {...submit}
          onClick={handlePostAsStory}
          key="submit"
          theme="submit"
          disabled={!isStoriesAllowed}
        >
          Post as a story
        </ModalFooterButton>
      </Tooltip>,
      <ModalFooterButton
        {...submit}
        onClick={handlePostToFeed}
        key="submit"
        theme="submit"
      >
        Post to your feed
      </ModalFooterButton>,
    ],
  };
}
