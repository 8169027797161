import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { omit } from 'underscore';
import { createRequest } from '../utils';

import * as types from './types';

const BASE_PATH = '/api/v1/podcast';
const BASE_PATH_V2 = '/api/v2/podcast';

const SEARCH_PATH = `${BASE_PATH}/search`;
const SEARCH_FEED_PATH = `${BASE_PATH}/custom-url`;
const PODCAST_PATH = `${BASE_PATH}/podcasts`;
const CLAIM_PODCAST_OWNERSHIP_PATH = `${PODCAST_PATH}/ownership`;
const REFRESH_PODCAST_FEED_PATH = `${PODCAST_PATH}/refresh-job`;
const WORKFLOW_PATH = `${BASE_PATH}/auto-create`;
const EPISODE_PATH = `${BASE_PATH}/episode`;
const WORKFLOW_EPISODE_PATH = `${WORKFLOW_PATH}/auto-create-episode`;
const SUBSCRIPTION_PATH = `${WORKFLOW_PATH}/subscription`;
const SUBSCRIPTIONS_PATH = `${WORKFLOW_PATH}/subscriptions`;
const SUBSCRIPTIONS_PREVIEW_PATH = `${WORKFLOW_PATH}/subscriptions/preview-job`;
const SUBSCRIBED_PODCAST_PATH = `${WORKFLOW_PATH}/podcast`;
const TRANSCRIPT_PATH = `${BASE_PATH}/transcript`;
const ENTIRE_EPISODE_TRANSCRIPT_PATH = `${TRANSCRIPT_PATH}/full-episode`;
const SHARE_VIDEO_PATH = `${BASE_PATH}/video-social-share`;

const TEMPLATES_PATH = `${BASE_PATH_V2}/auto-create/template`;

const podcastDetailEpisodesSchema = new schema.Entity(
  'podcastDetailEpisodes',
  {},
  {
    idAttribute: 'episodeId',
  },
);

const podcastsSchema = new schema.Entity(
  'podcasts',
  {
    episodes: [podcastDetailEpisodesSchema],
  },
  {
    idAttribute: 'podcastId',
    processStrategy: entity => omit(entity, 'nextEpisodePubAtMillis'),
  },
);

const favoritePodcastsSchema = new schema.Entity(
  'favoritePodcasts',
  {},
  {
    idAttribute: 'podcastId',
  },
);

const podcastSubscriptionSchema = new schema.Entity(
  'podcastSubscriptions',
  {},
  {
    idAttribute: 'autoCreateSubscriptionId',
  },
);

const podcastWorkflowTemplatesSchema = new schema.Entity(
  'podcastWorkflowTemplates',
  {},
  {
    idAttribute: 'templateId',
  },
);

const episodeClipSuggestionSchema = new schema.Entity(
  'episodeClipSuggestion',
  {},
  {
    idAttribute: 'suggestionId',
  },
);

const groupClipSuggestionsSchema = new schema.Entity(
  'groupClipSuggestions',
  {},
  {
    idAttribute: () => 'clipGroup',
    processStrategy: entity => ({
      clipSuggestions: entity.clipSuggestions,
      groupRequestId: entity.groupRequestId,
    }),
  },
);

const podcastFeedSchema = new schema.Entity(
  'podcastFeeds',
  {},
  {
    idAttribute: 'podcastFeedId',
    processStrategy: entity => ({
      ...entity,
      podcastFeedId: `${entity.podcastFeedId}`,
    }),
  },
);

const ownedPodcastsSchema = new schema.Entity(
  'ownedPodcasts',
  {},
  {
    idAttribute: 'podcastFeedId',
  },
);

const audioVariationsSchema = new schema.Entity('audioVariations', {});

const autoCreateEpisodesSchema = new schema.Entity('autoCreateEpisodes', {
  audioVariation: audioVariationsSchema,
});

const autoCreateEpisodeVideosSchema = new schema.Entity(
  'autoCreateEpisodeVideos',
  {
    autoCreateEpisode: autoCreateEpisodesSchema,
  },
  {
    idAttribute: 'videoId',
  },
);

const podcastFeedEpisodesSchema = new schema.Entity('podcastFeedEpisodes', {});

const podcastEpisodesSchema = new schema.Entity('podcastEpisodes', {});

const podcastRemoteEpisodesSchema = new schema.Entity(
  'podcastRemoteEpisodes',
  {},
  {
    idAttribute: 'episodeId',
  },
);

const youtubeLanguageSchema = new schema.Entity(
  'youtubeLanguages',
  {},
  { idAttribute: 'languageCode' },
);

export const claimPodcastOwnership = async (
  request: SuperAgentStatic,
  [podcastId]: types.PodcastClaimOwnershipArgs,
): Promise<types.PodcastClaimPodcastOwnershipResult> => {
  const res = await request.post(
    `${CLAIM_PODCAST_OWNERSHIP_PATH}/${podcastId}`,
  );

  return res.body;
};

export const refreshPodcastFeed = async (
  request: SuperAgentStatic,
  [podcastId]: types.RefreshPodcastFeedArgs,
): Promise<types.RefreshPodcastFeedResult> => {
  const res = await request
    .post(`${REFRESH_PODCAST_FEED_PATH}`)
    .send({ podcastId });

  return res.body;
};

export const getPodcastRefreshStatus = async (
  request: SuperAgentStatic,
  [refreshJobId]: types.PodcastGetRefreshStatusArgs,
): Promise<types.PodcastGetRefreshStatusResult> => {
  const res = await request.get(`${REFRESH_PODCAST_FEED_PATH}/${refreshJobId}`);

  return res.body;
};

export const getOwnedPodcasts = async (
  request: SuperAgentStatic,
): Promise<types.PodcastGetOwnedResult> => {
  const res = await request.get(CLAIM_PODCAST_OWNERSHIP_PATH).send();

  const normalizedOwnedPodcasts = normalize(res.body.podcasts, [
    ownedPodcastsSchema,
  ]);

  return normalizedOwnedPodcasts;
};

async function searchPodcasts(
  request: SuperAgentStatic,
  [q, offset]: types.PodcastSearchArgs,
): Promise<types.PodcastSearchResult> {
  const fieldMask =
    '{queries,nextOffset,content{podcastId,title,publisher,thumbnailUrl,imageUrl,totalEpisodes}}';

  const res = await request
    .get(SEARCH_PATH)
    .query({ q, offset })
    .set('X-Fields', fieldMask);

  const normalizedPodcasts = normalize(res.body.content, [podcastsSchema]);

  return {
    ...normalizedPodcasts,
    queries: {
      current: {
        ...res.body.queries.current,
        nextOffset: res.body.nextOffset,
      },
    },
  };
}

async function getPodcastById(
  request: SuperAgentStatic,
  [podcastId]: types.GetPodcastByIdArgs,
): Promise<types.GetPodcastByIdResult> {
  const fieldMask =
    '{podcastId,title,publisher,thumbnailUrl,totalEpisodes,episodes}';

  const res = await request
    .get(`${PODCAST_PATH}/${podcastId}`)
    .set('X-Fields', fieldMask);

  return normalize(res.body, podcastsSchema);
}

async function searchFeed(
  request: SuperAgentStatic,
  [url]: types.PodcastSearchFeedArgs,
): Promise<types.PodcastSearchFeedResult> {
  const fieldMask = '{podcastId,title,publisher,thumbnailUrl,totalEpisodes}';

  const res = await request
    .get(SEARCH_FEED_PATH)
    .query({ url })
    .set('X-Fields', fieldMask);

  const normalizedPodcasts = normalize([res.body], [podcastsSchema]);
  return {
    ...normalizedPodcasts,
  };
}

async function getEpisodes(
  request: SuperAgentStatic,
  [podcastId, nextPubMillis]: types.GetEpisodesArgs,
): Promise<types.GetEpisodesResult> {
  const fieldMask =
    '{podcastId,title,publisher,imageUrl,thumbnailUrl,totalEpisodes,nextEpisodePubAtMillis,canRefreshFeed,episodes{episodeId,audioUrl,durationSec,imageUrl,title,thumbnailUrl,publishedAtMillis,originalAudioUrl,proxyAudioUrl}}';

  const res = await request
    .get(`${PODCAST_PATH}/${podcastId}`)
    .query({
      nextEpisodePubAtMillis: nextPubMillis,
    })
    .set('X-Fields', fieldMask);

  const normalizedPodcasts = normalize(res.body, podcastsSchema);
  return {
    ...normalizedPodcasts,
    nextEpisodePubAtMillis: res.body.nextEpisodePubAtMillis,
  };
}

async function createSubscription(
  request: SuperAgentStatic,
  [
    podcastId,
    podcastLanguage,
    templateId,
    subscriptionTypes,
    templateType,
    templateAdjustment,
  ]: types.CreateSubscriptionArgs,
): Promise<types.CreateSubscriptionResult> {
  await request.post(SUBSCRIPTIONS_PATH).send({
    podcastId,
    podcastLanguage,
    subscriptionTypes,
    templateAdjustment,
    templateId,
    templateType,
  });

  return undefined;
}

async function createSubscriptionPreview(
  request: SuperAgentStatic,
  [
    podcastId,
    templateAdjustment,
    templateId,
    templateType,
  ]: types.CreateSubscriptionPreviewArgs,
): Promise<types.CreateSubscriptionPreviewResult> {
  const response = await request.post(SUBSCRIPTIONS_PREVIEW_PATH).send({
    podcastId,
    templateAdjustment,
    templateId,
    templateType,
  });

  return { subscriptionPreviewJobId: response.body.subscriptionPreviewJobId };
}

async function getSubscriptionPreview(
  request: SuperAgentStatic,
  [subscriptionPreviewJobId]: types.GetSubscriptionPreviewArgs,
): Promise<types.GetSubscriptionPreviewResult> {
  const response = await request.get(
    `${SUBSCRIPTIONS_PREVIEW_PATH}/${subscriptionPreviewJobId}`,
  );

  return response.body;
}

async function getMySubscriptions(
  request: SuperAgentStatic,
  [podcastFeedId]: types.GetMySubscriptionArgs,
): Promise<types.GetMySubscriptionResult> {
  const res = await request
    .get(`${SUBSCRIPTION_PATH}/me`)
    .query({ podcastId: podcastFeedId });
  return normalize(res.body.data, [podcastSubscriptionSchema]);
}

async function getSubscriptionById(
  request: SuperAgentStatic,
  [subscriptionId]: types.GetSubscriptionByIdArgs,
): Promise<types.GetSubscriptionByIdResult> {
  const res = await request.get(`${SUBSCRIPTION_PATH}/${subscriptionId}`);
  return normalize(res.body, podcastSubscriptionSchema);
}

async function deleteSubscription(
  request: SuperAgentStatic,
  [subscriptionId]: types.DeleteSubscriptionArgs,
): Promise<types.DeleteSubscriptionResult> {
  await request.delete(`${SUBSCRIPTION_PATH}/${subscriptionId}`);
  return { id: subscriptionId };
}

async function updateSubscription(
  request: SuperAgentStatic,
  [
    subscriptionId,
    podcastLanguage,
    templateId,
    subscriptionTypes,
    templateType,
    templateAdjustment,
    isEnabled = true,
  ]: types.UpdateSubscriptionArgs,
): Promise<types.UpdateSubscriptionResult> {
  await request.put(`${SUBSCRIPTION_PATH}/${subscriptionId}`).send({
    podcastLanguage,
    subscriptionTypes,
    templateAdjustment,
    templateId,
    templateType,
    isEnabled,
  });

  return undefined;
}

async function toggleSubscription(
  request: SuperAgentStatic,
  [subscriptionId, isEnabled = true]: types.ToggleSubscriptionArgs,
): Promise<types.ToggleSubscriptionResult> {
  await request.put(`${SUBSCRIPTION_PATH}/${subscriptionId}`).send({
    isEnabled,
  });

  return undefined;
}

async function getTemplates(
  request: SuperAgentStatic,
  [
    dimensionWidth,
    dimensionHeight,
    subjectImageUrl,
  ]: types.GetPodcastWorkflowTemplatesArgs,
): Promise<types.GetPodcastWorkflowTemplatesResult> {
  const res = await request.get(TEMPLATES_PATH).query({
    dimensionHeight,
    dimensionWidth,
    subjectImageUrl,
    useV2Configuration: true,
  });
  return normalize(res.body.data, [podcastWorkflowTemplatesSchema]);
}

async function getTemplate(
  request: SuperAgentStatic,
  [templateId, templateType]: types.GetPodcastWorkflowTemplateArgs,
): Promise<types.GetPodcastWorkflowTemplateResult> {
  const res = await request.get(
    `${TEMPLATES_PATH}/${templateType}/${templateId}`,
  );
  return normalize(res.body, podcastWorkflowTemplatesSchema);
}

async function getSeasons(
  request: SuperAgentStatic,
  [podcastId]: types.GetSeasonsArgs,
): Promise<types.GetSeasonsResult> {
  const res = await request.get(`${PODCAST_PATH}/${podcastId}/season`);
  return res.body;
}

async function createEpisodeVariation(
  request: SuperAgentStatic,
  [
    autoCreateEpisodeId,
    newTrimStartMillis,
    newTrimEndMillis,
    isCaptionEnabled,
  ]: types.CreateEpisodeVariationArgs,
): Promise<types.CreateEpisodeVariationResult> {
  const res = await request.post(`${WORKFLOW_EPISODE_PATH}/variation`).send({
    autoCreateEpisodeId,
    newTrimStartMillis,
    newTrimEndMillis,
    isCaptionEnabled,
  });

  return res.body;
}

async function enableClipSuggestion(
  request: SuperAgentStatic,
  [autoCreateEpisodeId]: types.EnableClipSuggestionArgs,
): Promise<types.EnableClipSuggestionResult> {
  const res = await request.post(
    `${WORKFLOW_EPISODE_PATH}/${autoCreateEpisodeId}/enable`,
  );

  return res.body;
}

async function createEpisodeVideo(
  request: SuperAgentStatic,
  [episodeId, autoCreateSubscriptionIds]: types.CreateEpisodeVideoArgs,
): Promise<types.CreateEpisodeVideoResult> {
  await request
    .post(WORKFLOW_EPISODE_PATH)
    .send({ episodeId, autoCreateSubscriptionIds });
}

async function getEpisodeByVideo(
  request: SuperAgentStatic,
  [embedVideoId]: types.GetEpisodeByVideoArgs,
) {
  const result = await request.get(
    `${WORKFLOW_EPISODE_PATH}/video/${embedVideoId}/episode`,
  );
  return normalize(result.body, podcastFeedEpisodesSchema);
}

async function createPreSelectVariation(
  request: SuperAgentStatic,
  [
    episodeId,
    subscriptionItemId,
    subscriptionId,
    trimStartMillis,
    trimEndMillis,
    isCaptionEnabled,
  ]: types.CreatePreSelectVariationArgs,
): Promise<types.CreatePreSelectVariationResult> {
  const res = await request
    .post(`${WORKFLOW_EPISODE_PATH}/pre-select-variation`)
    .send({
      episodeId,
      subItemId: subscriptionItemId,
      subId: subscriptionId,
      trimStartMillis,
      trimEndMillis,
      isCaptionEnabled,
    });
  return res.body;
}

async function getEpisodeTranscriptInfo(
  request: SuperAgentStatic,
  [episodeId, subscriptionItemId]: types.GetEpisodeTranscriptInfoArgs,
): Promise<types.GetEpisodeTranscriptInfoResult> {
  const res = await request
    .get(`${EPISODE_PATH}/${episodeId}/transcript`)
    .query({ subscriptionItemId });
  return normalize(res.body.clipSuggestions, [episodeClipSuggestionSchema]);
}

async function getGroupClipSuggestions(
  request: SuperAgentStatic,
  [
    episodeId,
    subscriptionItemId,
    groupRequestId,
  ]: types.GetGroupClipSuggestionsArgs,
): Promise<types.GetGroupClipSuggestionsResult> {
  const res = await request
    .get(`${EPISODE_PATH}/${episodeId}/group-clip-suggestion`)
    .query({ subscriptionItemId, groupRequestId });

  return normalize(res, [groupClipSuggestionsSchema]);
}

async function createGroupClipSuggestion(
  request: SuperAgentStatic,
  [
    episodeId,
    subItemId,
    groupRequestId,
    newTrimStartMillis,
    newTrimEndMillis,
  ]: types.CreateGroupClipSuggestionArgs,
): Promise<types.CreateGroupClipSuggestionResult> {
  const res = await request
    .post(`${EPISODE_PATH}/${episodeId}/group-clip-suggestion`)
    .send({ subItemId, groupRequestId, newTrimStartMillis, newTrimEndMillis });

  return res.body;
}

async function getSubscriptionItemInformation(
  request: SuperAgentStatic,
  [subscriptionItemId]: types.GetSubscriptionItemInformationArgs,
): Promise<types.GetSubscriptionItemInformationResult> {
  const res = await request.get(
    `${SUBSCRIPTION_PATH}/item/${subscriptionItemId}`,
  );

  return res.body;
}

async function getSubscriptionItemDetail(
  request: SuperAgentStatic,
  [subscriptionId, subscriptionItemId]: types.GetSubscriptionItemDetailArgs,
): Promise<types.GetSubscriptionItemDetailResult> {
  const res = await request.get(
    `${SUBSCRIPTION_PATH}/${subscriptionId}/item/${subscriptionItemId}`,
  );
  return res.body;
}

async function getMyPodcastFeeds(
  request: SuperAgentStatic,
): Promise<types.GetMyPodcastFeedsResult> {
  const {
    body: { data },
  } = await request.get(`${SUBSCRIBED_PODCAST_PATH}/me`);
  return normalize(data, [podcastFeedSchema]);
}

async function deletePodcastFeed(
  request: SuperAgentStatic,
  [podcastFeedId]: types.DeletePodcastFeedArgs,
): Promise<types.DeletePodcastFeedResult> {
  await request.delete(`${SUBSCRIBED_PODCAST_PATH}/${podcastFeedId}/me`);
  return undefined;
}

async function getMyPodcastFeedDetails(
  request: SuperAgentStatic,
  [podcastFeedId, pageNumber, pageSize]: types.GetMyPodcastFeedDetailsArgs,
): Promise<types.GetMyPodcastFeedDetailsResult> {
  const {
    body: { episodes, page, canRefreshFeed },
  } = await request.get(`${PODCAST_PATH}/favorites/${podcastFeedId}/me`).query({
    page: pageNumber,
    size: pageSize,
  });

  const normalized = normalize(
    episodes.map(e => ({
      ...e,
      id: `${e.id}`,
      podcastFeedId,
    })),
    [podcastFeedEpisodesSchema],
  );

  return { ...normalized, page, canRefreshFeed };
}

async function createBaseConfiguration(
  request: SuperAgentStatic,
  userId: number,
  [
    templateType,
    templateId,
    recordingId,
    durationMillis,
    podcastTitle,
    episodeTitle,
    imageUrl,
  ]: types.CreateBaseConfigArgs,
): Promise<types.CreateBaseConfigResult> {
  const { body } = await request
    .post(`${TEMPLATES_PATH}/${templateType}/${templateId}/base-config`)
    .send({
      durationMillis,
      episodeTitle,
      imageUrl,
      podcastTitle,
      recordingId,
      userId,
      useV2Configuration: true,
    });

  return body;
}

async function getFavoritePodcasts(
  request: SuperAgentStatic,
): Promise<types.GetFavoritePodcastsResult> {
  const res = await request.get(`${PODCAST_PATH}/favorites`);

  const normalizedPodcasts = normalize(res.body.podcasts, [
    favoritePodcastsSchema,
  ]);
  return {
    ...normalizedPodcasts,
  };
}

async function markPodcastFavorite(
  request: SuperAgentStatic,
  [{ podcastId, role, isDefault }]: types.MarkPodcastFavoriteArgs,
): Promise<types.MarkPodcastFavoriteResult> {
  await request.post(`${PODCAST_PATH}/favorites/${podcastId}`).send({
    role,
    isDefault,
  });
}

async function getEpisodeById(
  request: SuperAgentStatic,
  [episodeId]: types.GetEpisodeByIdArgs,
) {
  const result = await request.get(`${EPISODE_PATH}/${episodeId}`);
  return normalize(result.body, podcastEpisodesSchema);
}

const createSocialPostCaptions = (
  request: SuperAgentStatic,
  args: types.CreateSocialPostCaptionsArgs,
): Promise<types.CreateSocialPostCaptionsResult> => {
  const [episodeId] = args;

  return request
    .post(`${EPISODE_PATH}/${episodeId}/social-post-caption-job`)
    .then(res => res.body);
};

const getSocialPostCaptions = (
  request: SuperAgentStatic,
  args: types.GetSocialPostCaptionsArgs,
): Promise<types.GetSocialPostCaptionsResult> => {
  const [episodeId, jobId] = args;

  return request
    .get(`${EPISODE_PATH}/${episodeId}/social-post-caption-job/${jobId}`)
    .then(res => res.body);
};

const getGroupClipSuggestion = (
  request: SuperAgentStatic,
  args: types.GetGroupClipSuggestionArgs,
): Promise<types.GetGroupClipSuggestionResult> => {
  const [autoCreateEpisodeId] = args;

  return request
    .get(`${WORKFLOW_EPISODE_PATH}/${autoCreateEpisodeId}/clip-suggestion`)
    .then(res => res.body);
};

async function getAutoCreateEpisodeByVideo(
  request: SuperAgentStatic,
  [videoId]: types.GetAutoCreateEpisodeByVideoArgs,
) {
  const result = await request.get(
    `${WORKFLOW_EPISODE_PATH}/video/${videoId}/auto-create-episode`,
  );
  return normalize(
    { videoId, autoCreateEpisode: result.body },
    autoCreateEpisodeVideosSchema,
  );
}

async function getAutoCreateEpisode(
  request: SuperAgentStatic,
  [autoCreateEpisodeId]: types.GetAutoCreateEpisodeArgs,
) {
  const result = await request.get(
    `${WORKFLOW_EPISODE_PATH}/${autoCreateEpisodeId}`,
  );
  return normalize(result.body, autoCreateEpisodesSchema);
}

async function getPodcastRemoteEpisode(
  request: SuperAgentStatic,
  [podcastId, remoteEpisodeId]: types.GetPodcastRemoteEpisodeArgs,
) {
  const result = await request.get(
    `${PODCAST_PATH}/${podcastId}/episode/${encodeURIComponent(
      remoteEpisodeId,
    )}`,
  );

  return normalize(result.body, podcastRemoteEpisodesSchema);
}

async function createEpisodeClipSuggestionFeedback(
  request: SuperAgentStatic,
  [feedback]: types.CreateEpisodeClipSuggestionFeedbackArgs,
) {
  await request
    .post(`${BASE_PATH}/episode-clip-suggestion-feedback`)
    .send(feedback);
}

async function deleteFavoritePodcast(
  request: SuperAgentStatic,
  [podcastId]: types.DeleteFavoritePodcastArgs,
) {
  await request.delete(`${PODCAST_PATH}/favorites/${podcastId}`);
}

async function createEntireEpisodeTranscript(
  request: SuperAgentStatic,
  [episodeId]: types.CreateEntireEpisodeTranscriptArgs,
) {
  const { body } = await request.post(
    `${ENTIRE_EPISODE_TRANSCRIPT_PATH}/${episodeId}`,
  );
  return body;
}

async function getEntireEpisodeTranscriptInfo(
  request: SuperAgentStatic,
  [episodeId]: types.GetEntireEpisodeTranscriptInfoArgs,
) {
  const { body } = await request.get(
    `${ENTIRE_EPISODE_TRANSCRIPT_PATH}/${episodeId}`,
  );

  return normalize(
    {
      id: String(episodeId),
      transcriptInfo: body,
    },
    podcastFeedEpisodesSchema,
  );
}

async function getEntireEpisodeTranscriptInfos(
  request: SuperAgentStatic,
  [episodeIds]: types.GetEntireEpisodeTranscriptInfosArgs,
) {
  const {
    body: { data },
  } = await request
    .get(`${ENTIRE_EPISODE_TRANSCRIPT_PATH}`)
    .query({ episodeId: episodeIds });

  return normalize(
    data.map(({ episodeId, ...rest }) => {
      const id = String(episodeId);
      return {
        id,
        transcriptInfo: {
          episodeId: id,
          ...rest,
        },
      };
    }),
    [podcastFeedEpisodesSchema],
  );
}

export const getYoutubeLanguages = async (
  request: SuperAgentStatic,
): Promise<types.GetYoutubeLanguagesResult> => {
  const { body } = await request.get(`${SHARE_VIDEO_PATH}/youtube/languages`);

  return normalize(body.data, [youtubeLanguageSchema]);
};

export const getYoutubeCategories = (
  request: SuperAgentStatic,
): Promise<types.GetYoutubeCategoriesResult> => {
  return request
    .get(`${SHARE_VIDEO_PATH}/youtube/categories`)
    .set('Content-Type', 'application/json')
    .then(res => res.body.data);
};

async function shareVideo(
  request: SuperAgentStatic,
  args: types.ShareVideoArgs,
): Promise<types.ShareVideoResult> {
  const [
    embedVideoId,
    platform,
    accessToken,
    title,
    description,
    tags,
    privacyStatus,
    madeForKids,
    categoryId,
    playlists = [],
    visibility,
    linkedinAuthorId,
    tiktokUserId,
    twitterUserId,
    defaultLanguage,
    accessTokenSecret,
    commentEnabled,
    instagramUserId,
    instagramMediaType,
    providerUserId,
    facebookPageId,
    threadsUserId,
  ] = args;

  const { body } = await request.post(SHARE_VIDEO_PATH).send({
    accessToken,
    embedVideoId,
    platform,
    accessTokenSecret,
    providerUserId,
    options: {
      categoryId,
      defaultLanguage,
      description,
      linkedinAuthorId,
      madeForKids,
      playlists,
      privacyStatus,
      tags,
      tiktokUserId,
      twitterUserId,
      title,
      visibility,
      commentEnabled,
      instagramUserId,
      instagramMediaType,
      facebookPageId,
      threadsUserId,
    },
  });

  return body;
}

export function handle(method: any, args: any, token: string, userId: number) {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.podcast,
  });

  switch (method) {
    case types.ServiceMethod.CLAIM_PODCAST_OWNERSHIP:
      return claimPodcastOwnership(request, args);

    case types.ServiceMethod.GET_OWNED_PODCASTS:
      return getOwnedPodcasts(request);

    case types.ServiceMethod.REFRESH_PODCAST_FEED:
      return refreshPodcastFeed(request, args);

    case types.ServiceMethod.GET_PODCAST_REFRESH_STATUS:
      return getPodcastRefreshStatus(request, args);

    case types.ServiceMethod.SEARCH_FOR_PODCAST:
      return searchPodcasts(request, args);

    case types.ServiceMethod.SEARCH_FOR_FEED:
      return searchFeed(request, args);

    case types.ServiceMethod.GET_PODCAST_BY_ID:
      return getPodcastById(request, args);

    case types.ServiceMethod.GET_PODCAST_EPISODES:
      return getEpisodes(request, args);

    case types.ServiceMethod.CREATE_PODCAST_SUBSCRIPTION:
      return createSubscription(request, args);

    case types.ServiceMethod.CREATE_PODCAST_SUBSCRIPTION_PREVIEW:
      return createSubscriptionPreview(request, args);

    case types.ServiceMethod.GET_PODCAST_SUBSCRIPTION_PREVIEW:
      return getSubscriptionPreview(request, args);

    case types.ServiceMethod.GET_MY_PODCAST_SUBSCRIPTIONS:
      return getMySubscriptions(request, args);

    case types.ServiceMethod.DELETE_PODCAST_SUBSCRIPTION:
      return deleteSubscription(request, args);

    case types.ServiceMethod.UPDATE_PODCAST_SUBSCRIPTION:
      return updateSubscription(request, args);

    case types.ServiceMethod.TOGGLE_PODCAST_SUBSCRIPTION:
      return toggleSubscription(request, args);

    case types.ServiceMethod.GET_PODCAST_WORKFLOW_TEMPLATES:
      return getTemplates(request, args);

    case types.ServiceMethod.GET_PODCAST_WORKFLOW_TEMPLATE:
      return getTemplate(request, args);

    case types.ServiceMethod.GET_SEASONS:
      return getSeasons(request, args);

    case types.ServiceMethod.CREATE_EPISODE_VARIATION:
      return createEpisodeVariation(request, args);

    case types.ServiceMethod.ENABLE_CLIP_SUGGESTION:
      return enableClipSuggestion(request, args);

    case types.ServiceMethod.CREATE_PRE_SELECT_VARIATION:
      return createPreSelectVariation(request, args);

    case types.ServiceMethod.CREATE_EPISODE_VIDEO:
      return createEpisodeVideo(request, args);

    case types.ServiceMethod.GET_EPISODE_TRANSCRIPT_INFO:
      return getEpisodeTranscriptInfo(request, args);

    case types.ServiceMethod.GET_GROUP_CLIP_SUGGESTIONS:
      return getGroupClipSuggestions(request, args);

    case types.ServiceMethod.CREATE_GROUP_CLIP_SUGGESTION:
      return createGroupClipSuggestion(request, args);

    case types.ServiceMethod.GET_SUBSCRIPTION_ITEM_INFORMATION:
      return getSubscriptionItemInformation(request, args);

    case types.ServiceMethod.GET_SUBSCRIPTION_ITEM_DETAIL:
      return getSubscriptionItemDetail(request, args);

    case types.ServiceMethod.GET_MY_PODCAST_FEEDS:
      return getMyPodcastFeeds(request);

    case types.ServiceMethod.DELETE_PODCAST_FEED:
      return deletePodcastFeed(request, args);

    case types.ServiceMethod.GET_MY_PODCAST_FEED_DETAILS:
      return getMyPodcastFeedDetails(request, args);

    case types.ServiceMethod.GET_SUBSCRIPTION_BY_ID:
      return getSubscriptionById(request, args);

    case types.ServiceMethod.CREATE_BASE_CONFIGURATION:
      return createBaseConfiguration(request, userId, args);

    case types.ServiceMethod.GET_EPISODE_BY_VIDEO:
      return getEpisodeByVideo(request, args);

    case types.ServiceMethod.GET_FAVORITE_PODCASTS:
      return getFavoritePodcasts(request);

    case types.ServiceMethod.MARK_PODCAST_FAVORITE:
      return markPodcastFavorite(request, args);

    case types.ServiceMethod.GET_EPISODE_BY_ID:
      return getEpisodeById(request, args);

    case types.ServiceMethod.CREATE_SOCIAL_POST_CAPTIONS:
      return createSocialPostCaptions(request, args);

    case types.ServiceMethod.GET_SOCIAL_POST_CAPTIONS:
      return getSocialPostCaptions(request, args);

    case types.ServiceMethod.GET_GROUP_CLIP_SUGGESTION:
      return getGroupClipSuggestion(request, args);

    case types.ServiceMethod.GET_AUTO_CREATE_EPISODE_BY_VIDEO:
      return getAutoCreateEpisodeByVideo(request, args);

    case types.ServiceMethod.GET_AUTO_CREATE_EPISODE:
      return getAutoCreateEpisode(request, args);

    case types.ServiceMethod.GET_PODCAST_REMOTE_EPISODE:
      return getPodcastRemoteEpisode(request, args);

    case types.ServiceMethod.CREATE_EPISODE_CLIP_SUGGESTION_FEEDBACK:
      return createEpisodeClipSuggestionFeedback(request, args);

    case types.ServiceMethod.DELETE_FAVORITE_PODCAST:
      return deleteFavoritePodcast(request, args);

    case types.ServiceMethod.CREATE_ENTIRE_EPISODE_TRANSCRIPT:
      return createEntireEpisodeTranscript(request, args);

    case types.ServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFO:
      return getEntireEpisodeTranscriptInfo(request, args);

    case types.ServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFOS:
      return getEntireEpisodeTranscriptInfos(request, args);

    case types.ServiceMethod.SHARE_VIDEO:
      return shareVideo(request, args);

    case types.ServiceMethod.GET_YOUTUBE_CATEGORIES:
      return getYoutubeCategories(request);

    case types.ServiceMethod.GET_YOUTUBE_LANGUAGES:
      return getYoutubeLanguages(request);
  }

  return undefined;
}
