import { List, Record } from 'immutable';

import { State } from './types';

export const stateFactory = Record<State>({
  status: undefined,
  suggestionsStatus: undefined,
  groupClipSuggestionsStatus: undefined,
  dislikedGroupSuggestionIds: List(),
  creationClipSuggestionsStatus: undefined,
  dislikedCreationSuggestionIds: List(),
  suggestionIds: undefined,
  submitStatus: undefined,
  episodeId: undefined,
  entireAudioInstanceId: undefined,
  subscriptionId: undefined,
  subscriptionItemId: undefined,
  autoCreateEpisodeId: undefined,
  widgetId: undefined,
});
