import { Thumbnail } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import EpisodeListEpisodeDescription from 'blocks/AutomationCMS/components/EpisodeListEpisodeDescription';
import EpisodeListRow from 'blocks/AutomationCMS/components/EpisodeListRow';
import BemCSSTransition from 'components/BemCssTransition';
import Button from 'components/Button';
import { H4 } from 'components/Heading';
import { InfoGradient } from 'components/icons';
import LinkButton from 'components/LinkButton';

import { pushModal } from 'redux/modules/modal';
import { getAspectRatioValue } from 'utils/aspect-ratio';
import { headerBlock as block } from '../utils';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';

export interface ClipSelectHeaderProps {
  className?: string;
}

const ClipSelectHeader: React.FC<ClipSelectHeaderProps> = ({ className }) => {
  const dispatch = useDispatch();
  const {
    visibleSuggestionIds,
    clipsPageInfo: {
      title,
      subtitle,
      artwork,
      mediaDurationMillis,
      publishedAtMillis,
      artworkAspectRatioName,
    },
  } = useClipSelect();
  const [, send] = useClipSelectNavigation();
  const animations = useClipSelectAnimation();

  const handleReturnClick = useCallback(() => {
    send({ type: 'CLIPPER_CLOSE' });
  }, [send]);

  const handleLearnClick = useCallback(() => {
    dispatch(pushModal({ name: 'ClipSelectEducation' }));
  }, [dispatch]);

  return (
    <div className={cn(block(), className)}>
      <EpisodeListRow
        className={block('podcast-row')}
        active={false}
        style={{ height: 'auto' }}
      >
        <EpisodeListRow.Container
          className={block('podcast-row-container')}
          collapsible
        >
          <EpisodeListRow.Artwork>
            <div className={block('artwork-container')}>
              <Thumbnail
                src={artwork}
                alt="Clip artwork"
                ratio={getAspectRatioValue(artworkAspectRatioName)}
                basis={
                  getAspectRatioValue(artworkAspectRatioName) < 1
                    ? 'height'
                    : 'width'
                }
              />
            </div>
          </EpisodeListRow.Artwork>
          {title && (
            <EpisodeListEpisodeDescription
              title={title}
              subtitle={subtitle}
              durationMillis={mediaDurationMillis}
              publishedAtMillis={publishedAtMillis}
            />
          )}
        </EpisodeListRow.Container>
      </EpisodeListRow>
      {visibleSuggestionIds?.length > 0 ? (
        <>
          <BemCSSTransition {...animations.learnButton}>
            <div className={block('learn-container')}>
              <LinkButton onClick={handleLearnClick}>
                <InfoGradient className={block('learn-icon')} />
                <H4 className={block('learn-text')}>
                  Learn how we select clips
                </H4>
              </LinkButton>
            </div>
          </BemCSSTransition>
          <BemCSSTransition {...animations.closeClipperButton}>
            <div>
              <Button
                onClick={handleReturnClick}
                type="button"
                theme="submit-alt"
              >
                return to suggested clips
              </Button>
            </div>
          </BemCSSTransition>
        </>
      ) : null}
    </div>
  );
};

export default ClipSelectHeader;
