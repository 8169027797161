import React from 'react';
import { ShareFormField } from 'components/ShareForm';
import { block } from '../utils';

type YoutubeSelectContainerProps = {
  children: React.ReactNode;
  label: string;
  className?: string;
};

const YoutubeSelectContainer: React.FC<YoutubeSelectContainerProps> = ({
  children,
  label,
  className,
}) => (
  <ShareFormField label={label} className={className}>
    <div className={block('select-container')}>{children}</div>
  </ShareFormField>
);
export default YoutubeSelectContainer;
