import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DownloadPageDetails from 'blocks/DownloadPage/components/DownloadPageDetails';
import BemCSSTransition from 'components/BemCssTransition/BemCssTransition';
import Button from 'components/Button';
import useOnMount from 'hooks/useOnMount';
import { autoCreateEpisodeSelector } from 'redux/modules/download';
import { ProjectCreationMethod } from 'types';

import { getAutomationSuggestedClipsPath } from 'utils/routes';
import { block } from '../../utils';
import CloudUploadSection from '../CloudUploadSection';
import CopyVideoLinkForm from '../CopyVideoLinkForm';
import DownloadPageSocialSection from '../DownloadPageSocialSection';
import ShareKbLink from '../ShareKbLink';

const ButtonAsAny = Button as any;

export interface DownloadShareDetailsProps {
  canReclip: boolean;
  hidden?: boolean;
  isAutogramReviewDetailsActive: boolean;
  videoDurationMillis: number;
  videoFileName?: string;
  videoUrl?: string;
  projectCreateMethod?: ProjectCreationMethod;
  onInitialLoad?: () => void;
}

const DownloadShareDetails: React.SFC<DownloadShareDetailsProps> = ({
  canReclip,
  hidden,
  isAutogramReviewDetailsActive,
  videoDurationMillis,
  videoFileName,
  videoUrl,
  onInitialLoad,
}) => {
  const autoCreateEpisode = useSelector(autoCreateEpisodeSelector);

  useOnMount(() => {
    if (canReclip) {
      onInitialLoad();
    }
  });

  return (
    <BemCSSTransition
      className={block('share-details-wrapper')}
      in={isAutogramReviewDetailsActive}
      timeout={300}
      enter={false}
    >
      <DownloadPageDetails
        className={block('share', { hidden })}
        subtitle={
          <>
            Get tips on sharing from our <ShareKbLink /> articles.
          </>
        }
        title="Share your video"
      >
        <DownloadPageSocialSection
          videoDurationMillis={videoDurationMillis}
          videoFileName={videoFileName}
        />
        <CopyVideoLinkForm videoUrl={`${window.location.href}`} />
        <CloudUploadSection videoFileName={videoFileName} videoUrl={videoUrl} />
        {canReclip && autoCreateEpisode && (
          <ButtonAsAny
            className={block('reclip-button')}
            componentClass={Link}
            theme="submit"
            to={getAutomationSuggestedClipsPath({
              episodeId: autoCreateEpisode?.get('episodeId'),
              subscriptionItemId: autoCreateEpisode?.get('autoCreateSubItemId'),
              groupRequestId: autoCreateEpisode?.get('groupRequestId'),
              source: 'DownloadPageButton',
            })}
            fluid
          >
            choose another clip from this episode
          </ButtonAsAny>
        )}
      </DownloadPageDetails>
    </BemCSSTransition>
  );
};

export default DownloadShareDetails;
