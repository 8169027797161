import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import { isEmpty, reduce } from 'underscore';
import {
  CreationClipSuggestion,
  CreationResponse,
} from 'redux/middleware/api/creation-service/types';
import { EddySupportedProjectLanguages } from 'redux/middleware/api/headliner-user-service';
import { IVideoUpload } from 'redux/middleware/api/media-upload-service';
import {
  GroupClipSuggestion,
  PodcastDetailEpisode,
  PodcastEpisode,
  PodcastFeedEpisode,
  PodcastRemoteEpisode,
  PodcastWorkflowTemplate,
} from 'redux/middleware/api/podcast-service';

export const callEntriesSelector = state =>
  state.getIn(['entities', 'callEntries']);
export const imagesSelector = state => state.getIn(['entities', 'images']);
export const recordingsSelector = state =>
  state.getIn(['entities', 'recordings']);
export const transcriptsSelector = state =>
  state.getIn(['entities', 'transcripts']);
export const projectsSelector = state =>
  state.getIn(['entities', 'projects'], Immutable.Map());
export const waveformsSelector = state =>
  state.getIn(['entities', 'waveforms']);
export const keywordsSelector = state => state.getIn(['entities', 'keywords']);
export const manualTranscriptsSelector = state =>
  state.getIn(['entities', 'manualTranscripts']);
export const personalFontsSelector = state =>
  state.getIn(['entities', 'personalFonts']);
export const googleFontsSelector = state =>
  state.getIn(['entities', 'googleFonts']);
export const userPreferencesSelector = state =>
  state.getIn(['entities', 'userPreferences']);
export const videoSizesSelector = state =>
  state.getIn(['entities', 'videoSizes']);
export const eddySupportedProjectLanguagesSelector = state =>
  state.getIn(['entities', 'eddySupportedProjectLanguages']);
export const videosSelector = state => state.getIn(['entities', 'videos']);
export const embedExportsSelector = state =>
  state.getIn(['entities', 'embedExports']);
export const sampleAudiosSelector = state =>
  state.getIn(['entities', 'sampleAudios']);
export const announcementsSelector = state =>
  state.getIn(['entities', 'announcements']);
export const textBlobsSelector = state =>
  state.getIn(['entities', 'textBlobs']);
export const summariesSelector = state =>
  state.getIn(['entities', 'summaries']);
export const tracesSelector = state => state.getIn(['entities', 'traces']);
export const revisionHistorySelector = state =>
  state.getIn(['entities', 'revisionHistory'], Immutable.Map());
export const projectTemplatesSelector = state =>
  state.getIn(['entities', 'projectTemplates'], Immutable.Map());
export const plansSelector = state => state.getIn(['entities', 'plans']);
export const podcastsSelector = state => state.getIn(['entities', 'podcasts']);
export const podcastDetailEpisodesSelector = state =>
  state.getIn(['entities', 'podcastDetailEpisodes']);
export const podcastSubscriptionsSelector = state =>
  state.getIn(['entities', 'podcastSubscriptions']);
export const podcastWorkflowTemplatesSelector = state =>
  state.getIn(['entities', 'podcastWorkflowTemplates']);
export const entireAudioInstancesSelector = state =>
  state.getIn(['entities', 'entireAudioInstances']);
export const entireAudiosSelector = state =>
  state.getIn(['entities', 'entireAudios']);
export const entireAudioClipsSelector = state =>
  state.getIn(['entities', 'entireAudioClips']);
export const episodeClipSuggestionSelector = state =>
  state.getIn(['entities', 'episodeClipSuggestion']);
export const podcastFeedsSelector = state =>
  state.getIn(['entities', 'podcastFeeds']);
export const podcastFeedEpisodesSelector = state =>
  state.getIn(['entities', 'podcastFeedEpisodes']);
export const userProfilesSelector = state =>
  state.getIn(['entities', 'userProfiles']);
export const favoritePodcastsSelector = state =>
  state.getIn(['entities', 'favoritePodcasts']);
export const autoCreateEpisodeVideosSelector = state =>
  state.getIn(['entities', 'autoCreateEpisodeVideos']);
export const autoCreateEpisodesSelector = state =>
  state.getIn(['entities', 'autoCreateEpisodes']);
export const audioVariationsSelector = state =>
  state.getIn(['entities', 'audioVariations']);
export const displayPrefsSelector = state =>
  state.getIn(['entities', 'displayPrefs']);
export const userPrefsSelector = state =>
  state.getIn(['entities', 'userPrefs']);
export const waveformPrefsSelector = state =>
  state.getIn(['entities', 'waveformPrefs']);
export const creationsSelector = state =>
  state.getIn(['entities', 'creations']);
export const experimentsSelector = state =>
  state.getIn(['entities', 'experiments']);
export const podcastRemoteEpisodesSelector = state =>
  state.getIn(['entities', 'podcastRemoteEpisodes']);
export const podcastEpisodesSelector = state =>
  state.getIn(['entities', 'podcastEpisodes']);
export const socialSharesSelector = state =>
  state.getIn(['entities', 'socialShares']);
export const youtubeLanguagesSelector = state =>
  state.getIn(['entities', 'youtubeLanguages']);
export const textPresetsSelector = state =>
  state.getIn(['entities', 'textPresets']);
export const groupClipSuggestionsSelector = state =>
  state.getIn(['entities', 'groupClipSuggestions', 'clipGroup']);
export const creationClipSuggestionsSelector = state =>
  state.getIn(['entities', 'creationClipSuggestions', 'clipGroup']);
export const subscriptionItemInformationSelector = state =>
  state.getIn(['entities', 'subscriptionItemInformation']);
export const notificationAlertSelector = state =>
  state.getIn(['entities', 'notificationAlert', 'alerts']);
export const clipSuggestionsCreationProgressSelector = state =>
  state.getIn(['entities', 'creationSuggestionsProgress', 'progress']);

export const podcastWorkflowTemplateSelector = (id: string) => {
  return createSelector(
    podcastWorkflowTemplatesSelector,
    podcastWorkflowTemplates =>
      podcastWorkflowTemplates?.get(id)?.toJS() as PodcastWorkflowTemplate,
  );
};

export const userProfileSelector = (userId: number) => {
  return createSelector(userProfilesSelector, userProfiles => {
    return userProfiles?.get(String(userId))?.toJS();
  });
};

export const podcastDetailEpisodeSelector = (episodeId: string) => {
  return createSelector(podcastDetailEpisodesSelector, podcastEpisodes => {
    return podcastEpisodes?.get(episodeId)?.toJS() as PodcastDetailEpisode;
  });
};

export const podcastRemoteEpisodeSelector = (episodeId: string) => {
  return createSelector(podcastRemoteEpisodesSelector, episodes => {
    return episodes?.get(episodeId)?.toJS() as PodcastRemoteEpisode;
  });
};

export const makePodcastEpisodeSelector = (episodeId: number) => {
  return createSelector(podcastEpisodesSelector, podcastEpisodes => {
    return podcastEpisodes?.get(String(episodeId))?.toJS() as PodcastEpisode;
  });
};

export const makePodcastFeedEpisodeSelector = (episodeId: string) =>
  createSelector(
    podcastFeedEpisodesSelector,
    episodes => episodes?.get(episodeId)?.toJS() as PodcastFeedEpisode,
  );

export const eddySupportedLanguagesSelector = createSelector(
  eddySupportedProjectLanguagesSelector,
  (eddySupportedLanguages): EddySupportedProjectLanguages[] =>
    eddySupportedLanguages?.valueSeq()?.toJS() || [],
);

export const groupClipSuggestionsListSelector = createSelector(
  groupClipSuggestionsSelector,
  groupClipSuggestions =>
    !groupClipSuggestions
      ? []
      : (groupClipSuggestions
          ?.get('clipSuggestions')
          .filter(suggestion => suggestion.get('status') !== 'error')
          ?.toJS() as GroupClipSuggestion[]),
);

export const makeCreationSelector = (creationId: number) =>
  createSelector(
    creationsSelector,
    creations => creations?.get(String(creationId))?.toJS() as CreationResponse,
  );

export const makeVideoSelector = (videoId: number) =>
  createSelector(
    videosSelector,
    videos => videos?.get(String(videoId))?.toJS() as IVideoUpload,
  );

export const creationClipSuggestionsListSelector = createSelector(
  creationClipSuggestionsSelector,
  creationClipSuggestions =>
    !creationClipSuggestions
      ? []
      : (creationClipSuggestions
          ?.get('clipSuggestions')
          .filter(suggestion => suggestion.get('status') !== 'error')
          ?.toJS() as CreationClipSuggestion[]),
);

export const creationClipSuggestionsByIdSelector = createSelector(
  creationClipSuggestionsListSelector,
  creationClipSuggestions =>
    reduce(
      creationClipSuggestions,
      (result, item) => {
        result[item.suggestionId] = item;

        return result;
      },
      {} as Record<string, CreationClipSuggestion>,
    ),
);

export const pollCreationClipSuggestionsIdsSelector = createSelector(
  creationClipSuggestionsListSelector,
  creationClipSuggestions => {
    if (!creationClipSuggestions.length) {
      return undefined;
    }

    return creationClipSuggestions
      .filter(({ status }) => status !== 'completed')
      .map(({ suggestionId }) => suggestionId);
  },
);

export const groupRequestIdSelector = createSelector(
  groupClipSuggestionsSelector,
  groupClipSuggestions =>
    !groupClipSuggestions ? '' : groupClipSuggestions?.get('groupRequestId'),
);

export const groupClipSuggestionsByIdSelector = createSelector(
  groupClipSuggestionsListSelector,
  groupClipSuggestions =>
    reduce(
      groupClipSuggestions,
      (result, item) => {
        result[item.autoCreateEpisodeId] = item;

        return result;
      },
      {} as Record<string, GroupClipSuggestion>,
    ),
);

export const pollGroupClipSuggestionsIdsSelector = createSelector(
  groupClipSuggestionsListSelector,
  groupClipSuggestions => {
    if (!groupClipSuggestions.length) {
      return undefined;
    }

    return groupClipSuggestions
      .filter(({ status }) => status === 'processing' || status === 'queued')
      .map(({ autoCreateEpisodeId }) => autoCreateEpisodeId);
  },
);

export const groupClipSuggestionsIdsSelector = createSelector(
  groupClipSuggestionsListSelector,
  groupClipSuggestions =>
    groupClipSuggestions
      ? groupClipSuggestions?.map(suggestion => suggestion.autoCreateEpisodeId)
      : [],
);

export const hasSubscriptionItemInformationSelector = createSelector(
  subscriptionItemInformationSelector,
  subscriptionItemInformation => !isEmpty(subscriptionItemInformation),
);

export const hasNotificationAlertSelector = createSelector(
  notificationAlertSelector,
  notification =>
    notification ? notification.toJS().hasNewSocialShareData : false,
);
