import React from 'react';

import { DestinationPreset } from 'blocks/DestinationPlatforms';
import CloseIconButton from 'components/CloseIconButton';
import Error from 'components/icons/Error';
import Popover from 'components/Popover';
import { formatDurationToRoundedStr } from 'utils/time';

import { ClipperContainerMode } from './types';
import { block, isPresetBlocked, SOCIAL_PRESET_POPOVER_OPTIONS } from './utils';

interface SocialPresetPopoverProps {
  containerId?: string;
  containerMode: ClipperContainerMode;
  onClose: () => void;
  preset: DestinationPreset;
}

const EMPTY_PRESET = { durationMs: 0, icon: Error, type: '' };

const SocialPresetPopover: React.FunctionComponent<SocialPresetPopoverProps> = props => {
  const { containerId, children, containerMode, onClose, preset } = props;

  const getPresetConfig = React.useCallback(
    (nextPreset?: DestinationPreset) =>
      SOCIAL_PRESET_POPOVER_OPTIONS[nextPreset?.key] ?? EMPTY_PRESET,
    [],
  );

  const presetConfig = React.useRef(getPresetConfig(preset));

  const show = isPresetBlocked(preset);

  const [prevShow, setPrevShow] = React.useState(show);

  // As preset selection triggers the popup, when the preset is set to undefined or a non blocking one
  // the popover will hide. Given this, there would be a small blink while animating the popover fade.
  // For avoiding this the preset config is saved at a ref and only updated when show state switches
  // to visible
  if (show !== prevShow) {
    setPrevShow(show);
    if (show) {
      presetConfig.current = getPresetConfig(preset);
    }
  }

  const Content = (
    <div className={block('preset-popover-body')}>
      <presetConfig.current.icon />
      <div className={block('preset-popover-mid-container')}>
        <h6 className={block('preset-popover-title')}>MAX. DURATION</h6>
        <p className={block('preset-popover-duration')}>
          {presetConfig.current.type} (
          {formatDurationToRoundedStr(presetConfig.current.durationMs)})
        </p>
      </div>
      <CloseIconButton
        className={block('preset-popover-preset-close')}
        onClick={onClose}
      />
    </div>
  );

  return (
    <Popover
      id="preset-popover"
      className={block('preset-popover', { [containerMode]: true })}
      container={document.getElementById(containerId)}
      content={Content}
      placement="top"
      show={show}
      variant="chip"
    >
      {children}
    </Popover>
  );
};

export default SocialPresetPopover;
