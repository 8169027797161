import { Heading, Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import ClipSelectorControls from 'blocks/ClipSelect/containers/ClipSelector/ClipSelectorControls';
import FadingScrollBars from 'components/FadingScrollBars';
import ClipTimecodes, { ClipTimecodesProps } from './ClipTimecodes';
import { block } from './utils';

interface AudioClipCardTextProps extends ClipTimecodesProps {
  text: string;
  isDisabled: boolean;
  playable: boolean;
}

const AudioClipCardText: React.FC<AudioClipCardTextProps> = ({
  text,
  isDisabled,
  playable,
  clipStartMillis,
  clipEndMillis,
}) => {
  const renderTrackVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          borderRadius: 3,
          bottom: 2,
          padding: '15px 0px',
          right: 2,
          top: 2,
        }}
      />
    ),
    [],
  );

  const renderThumbVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: 'inherit',
          cursor: 'pointer',
          display: 'block',
          zIndex: 1,
        }}
      />
    ),
    [],
  );

  return (
    <Spacer
      orientation="vertical"
      space="8px"
      align="center"
      justify="center"
      className={block('text-container')}
    >
      <FadingScrollBars
        className={block('text-wrapper')}
        renderThumbVertical={renderThumbVertical}
        renderTrackVertical={renderTrackVertical}
      >
        <ClipTimecodes {...{ clipStartMillis, clipEndMillis }} />

        <Heading level={3} className={block('text')}>
          {text}
        </Heading>
      </FadingScrollBars>

      <div className={block('controls-wrapper')}>
        <ClipSelectorControls {...{ isDisabled, playable }} />
      </div>
    </Spacer>
  );
};

export default AudioClipCardText;
