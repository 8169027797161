import cn from 'classnames';
import React from 'react';
import { isArray } from 'underscore';
import { block } from './utils';

interface MediaCardVideoProps {
  /**
   * The video source(s).
   * If `src` is a string, it is treated as a single video source.
   * If `src` is an array of strings, each string represents a different video source,
   * allowing the browser to choose the most appropriate format.
   */
  src: string | string[];
  className?: string;
}

const MediaCardVideo: React.FC<MediaCardVideoProps> = ({ src, className }) => {
  return (
    <video
      autoPlay
      loop
      muted
      className={cn(block('media-card-video'), className)}
    >
      {isArray(src) ? (
        src.map(videoSrc => <source src={videoSrc} />)
      ) : (
        <source src={src} />
      )}
    </video>
  );
};

export default MediaCardVideo;
