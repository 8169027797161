import ReactGA from 'react-ga';

import { purge } from 'redux/middleware/persist-purge/utils';
import { subscriptionSelector } from 'redux/modules/pricing/selectors';
import { getCohortSurveyStatus } from 'redux/modules/survey/actions';
import { surveyDataSelector } from 'redux/modules/survey/selectors';
import { getValue } from 'utils/collections';
import { setPricingPlanGa } from 'utils/ga';
import { addLiveChatTags } from 'utils/helpdesk';
import { decode } from 'utils/jwt';
import { actions as authServiceActions } from '../../middleware/api/auth-service';
import { actions as userServiceActions } from '../../middleware/api/user-service';
import { userProfileSelector } from '../entities';
import * as mixpanelActions from '../mixpanel';
import { actions as oauthActions } from '../oauth';
import { loadPricingData } from '../pricing';
import * as routingActions from '../router';
import { sentryAction } from '../sentry';
import * as types from './action-types';
import * as selectors from './selectors';

const TOKEN_RENEW_THRESHOLD = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

const authSuccess = (userId, token) => ({
  payload: { token, userId },
  type: types.AUTHENTICATE_SUCCESS,
});

const clearUserContext = action => dispatch => {
  ReactGA.set({ userId: null });
  mixpanelActions.clearMixpanelUserInfo();
  return dispatch(
    sentryAction(action, {
      userContext: null,
    }),
  );
};

export const setUserContext = action => (dispatch, getState) => {
  const username = selectors.userNameSelector(getState());
  const id = selectors.userIdSelector(getState());
  const mySubscription = subscriptionSelector(getState());
  const surveyData = surveyDataSelector(getState());
  const userContext = { username, id };

  ReactGA.set({ userId: id });
  mixpanelActions.setMixpanelUserInfo(username, id, mySubscription, surveyData);
  setPricingPlanGa(mySubscription.plan);
  addLiveChatTags(id);
  return dispatch(
    sentryAction(action, {
      userContext,
    }),
  );
};

const getErrorMessage = (error, defaultMessage) => dispatch => {
  const code = getValue(error, ['response', 'body', 'code']);

  if (code === 204) {
    dispatch(mixpanelActions.onGoogleAuthError());
    return 'There was a problem with your Google account, please use your email instead.';
  }

  if (code === 203) {
    return 'Email already in use';
  }

  return defaultMessage;
};

const doRenew = () => dispatch => dispatch(userServiceActions.postRenew());

const login = (loginAction, provider = 'headliner') => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.LOGIN_REQUEST });

  try {
    const { token, userId } = await dispatch(loginAction);

    // store token and id in redux so that we can make authenticated api
    // requests.  note that authenticated !== logged in.  the user might still
    // have to complete onboarding or some other conditions before they can access
    // gated content

    dispatch(authSuccess(userId, token));

    await Promise.all([
      dispatch(userServiceActions.getMyProfile()),
      dispatch(getCohortSurveyStatus()),

      // don't force loading pricing data.  App is going to load the data when
      // it mounts, but the data is also needed after login to see if the user
      // should see onboarding (api / enterprise).  App and auth both issue the
      // same request if force is true
      dispatch(loadPricingData()),
    ]);

    const profile = userProfileSelector(userId)(getState());

    const { email: username, fullName, handle } = profile;

    dispatch({
      payload: {
        handle,
        token,
        userId,
        username,
        authenticationProvider: provider,
        name: fullName,
      },
      type: types.LOGIN_SUCCESS,
    });

    dispatch(setUserContext());
    dispatch(mixpanelActions.onLogin(username));

    // TODO necessary?
    if (!handle) {
      dispatch(routingActions.goToBetaSignUp());
    }
  } catch (err) {
    dispatch({
      type: types.LOGIN_FAILURE,
      payload: {
        errorMessage: 'Something went wrong',
      },
    });
    throw err;
  }
};

/**
 * Credentials auth is handled by @sparemin/auth so that by the time this is called, we
 * already have the user id and token.  This is just a convenience wrapper around
 * login so that the caller doesn't have to deal with the function argumen to `login`
 */
export const loginUserCredentials = (userId, token) => dispatch =>
  dispatch(login(() => Promise.resolve({ userId, token })));

export const logout = (redirect = true) => dispatch => {
  dispatch(clearUserContext({ type: types.USER_LOGOUT }));
  dispatch(oauthActions.clearThirdPartyAuth());
  dispatch(mixpanelActions.onLogout());
  dispatch(purge());

  if (redirect) {
    dispatch(routingActions.goToHome());
  }
};

export const tokenLogin = token => dispatch => {
  /*
   * logout current user but don't redirect to brochure site. logout first so that if something
   * fails redux is left in a logged out state
   */
  dispatch(logout(false));
  return dispatch(
    login(async () => {
      /**
       * set token in state so that even though user is not yet logged in the api middleware will
       * pickup this token and use it for the getProfile request (as opposed to the token for a
       * different user who might already be logged in).
       *
       * the getProfile request will validate the token and also ensure that the user's email is
       * is returned since the endpoint will only return an email address if the token's userId
       * matches the request's userId
       */
      const decodedToken = decode(token);
      if (!decodedToken) {
        throw new Error('Invalid authentication token');
      }

      const { sub: userId } = decodedToken;

      return { userId, token };
    }),
  );
};

const renewToken = () => dispatch => {
  dispatch(doRenew())
    .then(res =>
      dispatch({
        type: types.RENEW_SUCCESS,
        payload: {
          token: res.response.token,
        },
      }),
    )
    .catch(() => dispatch(clearUserContext({ type: types.RENEW_FAILURE })));
};

export const checkAndRenewToken = () => (dispatch, getState) => {
  if (selectors.loggedOutSelector(getState())) {
    return;
  }

  const tokenExpTime = selectors.tokenDateSelector(getState()) * 1000;
  const todayDate = new Date();

  // Renew the token if it's within the threshold of expiring
  if (todayDate.getTime() + TOKEN_RENEW_THRESHOLD > tokenExpTime) {
    dispatch(renewToken());
  }
};

const register = (provider, createUser = () => Promise.resolve()) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.REGISTER_REQUEST });

  try {
    await dispatch(createUser);

    const userId = selectors.userIdSelector(getState());
    const profile = userProfileSelector(userId)(getState());
    const email = selectors.userNameSelector(getState());

    if (!userId || !profile || !email) {
      throw new Error('User data not found');
    }

    mixpanelActions.setMixpanelAlias(email);
    dispatch(setUserContext());

    dispatch({
      payload: {
        authenticationProvider: provider,
        handle: profile.handle,
        name: profile.firstName,
      },
      type: types.REGISTER_SUCCESS,
    });

    dispatch(mixpanelActions.onCreateUser(email));
  } catch (err) {
    const errorMessage = dispatch(
      getErrorMessage(err, 'Error registering for Headliner'),
    );

    dispatch({
      payload: { errorMessage },
      type: types.REGISTER_FAILURE,
    });

    throw err;
  }
};

/**
 * Credentials are processed via @sparemin/auth and this function is called with
 * the resulting userId and spareminToken. If registration does not occur via
 * the form with username/password, etc., then it will have occurred via socual auth
 */
export const registerUserCredentials = (userId, token) => async (
  dispatch,
  getState,
) =>
  dispatch(
    register('headliner', async () => {
      // sets token so that we can query for "my" profile, which depends on the token
      // dispatch(authSuccess(userId, token));
      dispatch(authSuccess(undefined, token));
      await dispatch(userServiceActions.getMyProfile());
      const email = userProfileSelector(userId)(getState())?.email;
      dispatch({
        payload: { username: email, token, userId },
        type: types.ACCOUNT_CREATE_SUCCESS,
      });
    }),
  );

export const socialAuth = (userId, token, isNewUser, provider) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.AUTHENTICATE_SOCIAL_REQUEST });

  try {
    if (!isNewUser) {
      await dispatch(login(() => Promise.resolve({ userId, token }, provider)));
    } else {
      // Registration event
      // Server does not send back the user's email address from the social auth
      // endpoint, yet Headliner uses the email address (set in redux as
      // auth.username) as a key to indicate the status of the user's account.
      // See useWebappLoad - the app will not run it's initialization API requests
      // if there's no username.  If API requests aren't run, we don't know the
      // user's pricing info and won't show the onboarding wizard.

      // set userid and token in state since token is needed to make other api
      // requests below
      dispatch(authSuccess(userId, token));

      // token is used to get user's profile so that we can read email and write
      // it to state
      await dispatch(userServiceActions.getMyProfile());
      const profile = userProfileSelector(userId)(getState());

      // write email to state so that after register is dispatched, the user gets
      // redirected to the onboarding wizard
      dispatch({
        payload: {
          token,
          userId,
          username: profile.email,
        },
        type: types.ACCOUNT_CREATE_SUCCESS,
      });

      // complete registration
      await dispatch(register(provider));
    }

    dispatch({ type: types.AUTHENTICATE_SOCIAL_SUCCESS });
  } catch (err) {
    dispatch({ type: types.AUTHENTICATE_SOCIAL_FAILURE });
    throw err;
  }
};

export const forgotPassword = email => (dispatch, getState) => {
  dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
  dispatch(mixpanelActions.onForgotPassword());

  const emailAddress = email || selectors.userNameSelector(getState());

  dispatch(authServiceActions.postForgotPassword(emailAddress))
    .then(() =>
      dispatch({
        type: types.FORGOT_PASSWORD_SUCCESS,
      }),
    )
    .catch(err => {
      const errorCode = getValue(err, ['response', 'body', 'code']);
      let errorMessage = 'An error occurred. Please try again later.';
      if (errorCode === 401) {
        errorMessage = 'The provided email is not registered with SpareMin';
      }

      dispatch({
        type: types.FORGOT_PASSWORD_FAILURE,
        payload: {
          errorMessage,
        },
      });
    });
};

export const updatePassword = (
  currentPassword,
  newPassword,
  confirmPassword,
) => (dispatch, getState) => {
  dispatch({ type: types.PASSWORD_UPDATE_REQUEST });

  const userId = selectors.userIdSelector(getState());
  return dispatch(
    authServiceActions.postUpdatePassword(
      userId,
      currentPassword,
      newPassword,
      confirmPassword,
    ),
  )
    .then(() => dispatch({ type: types.PASSWORD_UPDATE_SUCCESS }))
    .catch(() =>
      dispatch({
        type: types.PASSWORD_UPDATE_FAILURE,
        payload: {
          errorMessage:
            'Error updating password. Make sure current password is correct',
        },
      }),
    );
};

export const clearPasswordStatus = () => dispatch =>
  dispatch({
    type: types.PASSWORD_STATUS_CLEAR,
  });

export const confirmEmail = uuid => async dispatch => {
  try {
    dispatch({ type: types.CONFIRM_EMAIL_REQUEST });
    const { response } = await dispatch(
      authServiceActions.putConfirmEmail(uuid),
    );
    dispatch({
      type: types.CONFIRM_EMAIL_SUCCESS,
      payload: {
        token: response && response.spareminToken,
      },
    });
  } catch (error) {
    dispatch({ type: types.CONFIRM_EMAIL_FAILURE });
    throw error;
  }
};

export const updateEmail = (email, password) => async (dispatch, getState) => {
  const username = selectors.userNameSelector(getState());
  dispatch({ type: types.EMAIL_UPDATE_REQUEST });

  try {
    const { response } = await dispatch(
      authServiceActions.postLogin(username, password),
    );
    const { userId, token } = response;
    dispatch(authSuccess(userId, token));
  } catch (error) {
    dispatch({ type: types.EMAIL_UPDATE_FAILURE });
    if (error.status === 422) {
      error.password = 'Incorrect password — please try another';
    }
    throw error;
  }

  try {
    await dispatch(userServiceActions.updateProfile(email));
    await dispatch(userServiceActions.getMyProfile());
    dispatch({ type: types.EMAIL_UPDATE_SUCCESS, payload: { email } });
  } catch (error) {
    dispatch({ type: types.EMAIL_UPDATE_FAILURE });
    if (error.status === 400) {
      error.email = 'Sorry, that email address is already in use';
    }
    throw error;
  }
};

export const getUserIntegrators = () => dispatch => {
  dispatch({ type: types.AUTH_GET_INTEGRATORS_REQUEST });

  return dispatch(userServiceActions.getMyIntegrators())
    .then(res =>
      dispatch({
        payload: { integrators: res.response.integrators },
        type: types.AUTH_GET_INTEGRATORS_SUCCESS,
      }),
    )
    .catch(() => {
      dispatch({
        error: new Error('Error getting user integrators'),
        type: types.AUTH_GET_INTEGRATORS_FAILURE,
      });
    });
};

export const resendVerificationEmail = () => async (dispatch, getState) => {
  const userId = selectors.userIdSelector(getState());
  dispatch({ type: types.RESEND_VERIFICATION_EMAIL_REQUEST });
  try {
    await dispatch(authServiceActions.postResendVerificationEmail(userId));
    dispatch({ type: types.RESEND_VERIFICATION_EMAIL_SUCCESS });
  } catch (error) {
    dispatch({ type: types.RESEND_VERIFICATION_EMAIL_FAILURE });
    throw error;
  }
};

export const getMyProfile = () => async (dispatch, getState) => {
  const result = await dispatch(userServiceActions.getMyProfile());
  const userId = result.response.result;
  const profile = userProfileSelector(userId)(getState());

  dispatch({
    type: types.MY_PROFILE_GET_SUCCESS,
    payload: {
      userId,
      handle: profile.handle,
      name: profile.fullName,
      username: profile.email,
    },
  });
};

export const deleteUserAccount = () => async dispatch => {
  dispatch({
    type: types.USER_DELETE_REQUEST,
  });

  try {
    await dispatch(userServiceActions.deleteUserAccount());

    dispatch({
      type: types.USER_DELETE_SUCCESS,
    });

    // logout will typically redirect to "home", which is just an external redirect
    // to the brochureware site.  when deleting an account, we want to show a toast
    // that the account was deleted, which we can't do if the user lands on the
    // brochure site, so the auto redirect is disabled and we manually redirect to
    // the login page
    dispatch(logout(false));
    dispatch(routingActions.goToLogin());
  } catch (err) {
    dispatch({
      type: types.USER_DELETE_FAILURE,
    });
    throw err;
  }
};
