export enum Type {
  LOAD_DATA_REQUEST = 'app/clip-select/DATA_LOAD_REQUEST',
  LOAD_DATA_SUCCESS = 'app/clip-select/DATA_LOAD_SUCCESS',
  LOAD_DATA_FAILURE = 'app/clip-select/DATA_LOAD_FAILURE',

  LOAD_CLIP_SUGGESTIONS_REQUEST = 'app/clip-select/LOAD_CLIP_SUGESTIONS_REQUEST',
  LOAD_CLIP_SUGGESTIONS_SUCCESS = 'app/clip-select/LOAD_CLIP_SUGESTIONS_SUCCESS',
  LOAD_CLIP_SUGGESTIONS_FAILURE = 'app/clip-select/LOAD_CLIP_SUGESTIONS_FAILURE',

  LOAD_GROUP_CLIP_SUGGESTIONS_REQUEST = 'app/clip-select/LOAD_GROUP_CLIP_SUGESTIONS_REQUEST',
  LOAD_GROUP_CLIP_SUGGESTIONS_SUCCESS = 'app/clip-select/LOAD_GROUP_CLIP_SUGESTIONS_SUCCESS',
  LOAD_GROUP_CLIP_SUGGESTIONS_FAILURE = 'app/clip-select/LOAD_GROUP_CLIP_SUGESTIONS_FAILURE',

  LOAD_SUBSCRIPTION_ITEM_INFORMATION_REQUEST = 'app/clip-select/LOAD_SUBSCRIPTION_ITEM_INFORMATION_REQUEST',
  LOAD_SUBSCRIPTION_ITEM_INFORMATION_SUCCESS = 'app/clip-select/LOAD_SUBSCRIPTION_ITEM_INFORMATION_SUCCESS',
  LOAD_SUBSCRIPTION_ITEM_INFORMATION_FAILURE = 'app/clip-select/LOAD_SUBSCRIPTION_ITEM_INFORMATION_FAILURE',

  GROUP_CLIP_SUGGESTION_POLLING_REQUEST = 'app/clip-select/GROUP_CLIP_SUGGESTION_POLLING_REQUEST',
  GROUP_CLIP_SUGGESTION_POLLING_SUCCESS = 'app/clip-select/GROUP_CLIP_SUGGESTION_POLLING_SUCCESS',
  GROUP_CLIP_SUGGESTION_POLLING_FAILURE = 'app/clip-select/GROUP_CLIP_SUGGESTION_POLLING_FAILURE',

  GROUP_CLIP_SUGGESTION_DISLIKE = 'app/clip-select/GROUP_CLIP_SUGGESTION_DISLIKE',

  LOAD_CREATION_CLIP_SUGGESTIONS_REQUEST = 'app/clip-select/LOAD_CREATION_CLIP_SUGESTIONS_REQUEST',
  LOAD_CREATION_CLIP_SUGGESTIONS_SUCCESS = 'app/clip-select/LOAD_CREATION_CLIP_SUGESTIONS_SUCCESS',
  LOAD_CREATION_CLIP_SUGGESTIONS_FAILURE = 'app/clip-select/LOAD_CREATION_CLIP_SUGESTIONS_FAILURE',

  CREATION_CLIP_SUGGESTION_POLLING_REQUEST = 'app/clip-select/CREATION_CLIP_SUGGESTION_POLLING_REQUEST',
  CREATION_CLIP_SUGGESTION_POLLING_SUCCESS = 'app/clip-select/CREATION_CLIP_SUGGESTION_POLLING_SUCCESS',
  CREATION_CLIP_SUGGESTION_POLLING_FAILURE = 'app/clip-select/CREATION_CLIP_SUGGESTION_POLLING_FAILURE',

  CREATION_CLIP_SUGGESTION_DISLIKE = 'app/clip-select/CREATION_CLIP_SUGGESTION_DISLIKE',

  CLIP_AUDIO_REQUEST = 'app/clip-select/CLIP_AUDIO_REQUEST',
  CLIP_AUDIO_SUCCESS = 'app/clip-select/CLIP_AUDIO_SUCCESS',
  CLIP_AUDIO_FAILURE = 'app/clip-select/CLIP_AUDIO_FAILURE',

  EMBED_EXPORT_READY = 'app/clip-select/EMBED_EXPORT_READY',

  RESET = 'app/clip-select/RESET',
}
