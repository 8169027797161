import { Spacer } from '@sparemin/blockhead';
import * as React from 'react';
import LinkButton from 'components/LinkButton';
import { block } from './utils';

export interface AutomationPostModalAdditionalDetailsProps {
  title?: string;
  description?: string;
  onClick?: () => void;
}

const AutomationPostModalAdditionalDetails: React.FC<AutomationPostModalAdditionalDetailsProps> = props => {
  const {
    title = 'See additional details',
    description = 'Adjust visibility, audience, category, language, and tags.',
    onClick,
  } = props;

  return (
    <Spacer
      orientation="vertical"
      align="flex-start"
      justify="center"
      className={block('details-section')}
    >
      <LinkButton theme="cta" onClick={onClick}>
        <span className={block('details-section-button-text')}>{title}</span>
      </LinkButton>

      <span className={block('details-section-description')}>
        {description}
      </span>
    </Spacer>
  );
};

export default AutomationPostModalAdditionalDetails;
