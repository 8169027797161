import { Season } from 'redux/modules/automation-cms/types';
import {
  AudioVariationType,
  AutogramClipSelectionMethod,
  AutogramVideoType,
  AutoPostPlatform,
  CompatibilityVideoType,
  IEmbedConfig,
  Omit,
  PreviewVideo,
  Replace,
  SocialSharePlatform,
  TemplateAdjustment,
  TemplateType,
  YoutubeVideoVisibility,
} from 'types';
import {
  LinkedinVideoVisibility,
  VideoTypes,
  YouTubeVideoPostType,
} from '../../../../types/common';
import { Tier } from '../plan-service/types';
import { TikTokPrivacyLevelOptionName } from '../third-party-authentication-service/types';
import { IApiAction, IApiResponse } from '../types';

export type AutoCreateEpisodeId = number;
export type SubscriptionItemId = number;
export type SubscriptionId = number;
export type GroupRequestId = number;
export type EpisodeId = number;
export type VideoId = number;

export const ACTION_KEY = 'PODCAST_SERVICE' as const;

export enum ServiceMethod {
  CLAIM_PODCAST_OWNERSHIP = 'CLAIM_PODCAST_OWNERSHIP',
  REFRESH_PODCAST_FEED = 'REFRESH_PODCAST_FEED',
  GET_PODCAST_REFRESH_STATUS = 'GET_PODCAST_REFRESH_STATUS',
  CREATE_PODCAST_SUBSCRIPTION = 'CREATE_PODCAST_SUBSCRIPTION',
  CREATE_PODCAST_SUBSCRIPTION_PREVIEW = 'CREATE_PODCAST_SUBSCRIPTION_PREVIEW',
  GET_PODCAST_SUBSCRIPTION_PREVIEW = 'GET_PODCAST_SUBSCRIPTION_PREVIEW',
  GET_OWNED_PODCASTS = 'GET_OWNED_PODCASTS',
  DELETE_PODCAST_SUBSCRIPTION = 'DELETE_PODCAST_SUBSCRIPTION',
  UPDATE_PODCAST_SUBSCRIPTION = 'UPDATE_PODCAST_SUBSCRIPTION',
  TOGGLE_PODCAST_SUBSCRIPTION = 'TOGGLE_PODCAST_SUBSCRIPTION',
  SEARCH_FOR_PODCAST = 'SEARCH_FOR_PODCAST',
  SEARCH_FOR_FEED = 'SEARCH_FOR_FEED',
  GET_PODCAST_BY_ID = 'GET_PODCAST_BY_ID',
  GET_MY_PODCAST_FEEDS = 'GET_MY_PODCAST_FEEDS',
  GET_MY_PODCAST_FEED_DETAILS = 'GET_MY_PODCAST_FEED_DETAILS',
  GET_MY_PODCAST_SUBSCRIPTIONS = 'GET_MY_PODCAST_SUBSCRIPTIONS',
  GET_PODCAST_EPISODES = 'GET_PODCAST_EPISODES',
  GET_PODCAST_WORKFLOW_TEMPLATES = 'GET_PODCAST_WORKFLOW_TEMPLATES',
  GET_PODCAST_WORKFLOW_TEMPLATE = 'GET_PODCAST_WORKFLOW_TEMPLATE',
  GET_SEASONS = 'GET_SEASONS',
  GET_YOUTUBE_CATEGORIES = 'GET_YOUTUBE_CATEGORIES',
  GET_YOUTUBE_LANGUAGES = 'GET_YOUTUBE_LANGUAGES',
  CREATE_ENTIRE_EPISODE_TRANSCRIPT = 'CREATE_ENTIRE_EPISODE_TRANSCRIPT',
  CREATE_EPISODE_VIDEO = 'CREATE_EPISODE_VIDEO',
  CREATE_EPISODE_VARIATION = 'CREATE_EPISODE_VARIATION',
  ENABLE_CLIP_SUGGESTION = 'ENABLE_CLIP_SUGGESTION',
  CREATE_PRE_SELECT_VARIATION = 'CREATE_PRE_SELECT_VARIATION',
  GET_EPISODE_TRANSCRIPT_INFO = 'GET_EPISODE_TRANSCRIPT_INFO',
  GET_GROUP_CLIP_SUGGESTIONS = 'GET_GROUP_CLIP_SUGGESTIONS',
  CREATE_GROUP_CLIP_SUGGESTION = 'CREATE_GROUP_CLIP_SUGGESTION',
  GET_SUBSCRIPTION_ITEM_INFORMATION = 'GET_SUBSCRIPTION_ITEM_INFORMATION',
  GET_SUBSCRIPTION_ITEM_DETAIL = 'GET_SUBSCRIPTION_ITEM_DETAIL',
  DELETE_PODCAST_FEED = 'DELETE_PODCAST_FEED',
  GET_SUBSCRIPTION_BY_ID = 'GET_SUBSCRIPTION_BY_ID',
  CREATE_BASE_CONFIGURATION = 'CREATE_BASE_CONFIGURATION',
  GET_EPISODE_BY_VIDEO = 'GET_EPISODE_BY_VIDEO',
  GET_FAVORITE_PODCASTS = 'GET_FAVORITE_PODCASTS',
  MARK_PODCAST_FAVORITE = 'MARK_PODCAST_FAVORITE',
  GET_ENTIRE_EPISODE_TRANSCRIPT_INFO = 'GET_ENTIRE_EPISODE_TRANSCRIPT_INFO',
  GET_ENTIRE_EPISODE_TRANSCRIPT_INFOS = 'GET_ENTIRE_EPISODE_TRANSCRIPT_INFOS',
  GET_EPISODE_BY_ID = 'GET_EPISODE_BY_ID',
  GET_AUTO_CREATE_EPISODE_BY_VIDEO = 'GET_AUTO_CREATE_EPISODE_BY_VIDEO',
  GET_AUTO_CREATE_EPISODE = 'GET_AUTO_CREATE_EPISODE',
  GET_PODCAST_REMOTE_EPISODE = 'GET_PODCAST_REMOTE_EPISODE',
  CREATE_EPISODE_CLIP_SUGGESTION_FEEDBACK = 'CREATE_EPISODE_CLIP_SUGGESTION_FEEDBACK',
  DELETE_FAVORITE_PODCAST = 'DELETE_FAVORITE_PODCAST',
  SHARE_VIDEO = 'SHARE_VIDEO',
  CREATE_SOCIAL_POST_CAPTIONS = 'CREATE_SOCIAL_POST_CAPTIONS',
  GET_SOCIAL_POST_CAPTIONS = 'GET_SOCIAL_POST_CAPTIONS',
  GET_GROUP_CLIP_SUGGESTION = 'GET_GROUP_CLIP_SUGGESTION',
}

export type InstagramMediaType = 'reels' | 'stories';

export type FacebookPostType =
  | 'facebookDefault'
  | 'facebookReel'
  | 'facebookStory';

export interface ShareVideoOptions {
  categoryId?: number;
  defaultLanguage?: string;
  description?: string;
  linkedinAuthorId?: string;
  madeForKids?: boolean;
  playlists?: string[];
  privacyStatus?: YoutubeVideoVisibility;
  tags?: string[];
  tiktokUserId?: string;
  twitterUserId?: string;
  title?: string;
  visibility?: LinkedinVideoVisibility;
  accessTokenSecret?: string;
  pageAccessToken?: string;
  commentEnabled?: boolean;
  instagramUserId?: string;
  instagramMediaType?: InstagramMediaType;
  providerUserId?: string;
  facebookPageId?: string;
  pageId?: string;
  threadsUserId?: string;
}

export interface PageData {
  q: string;
  totalResults: number;
  nextOffset: number;
  offset: number;
  pageSize: number;
}

export type PodcastId = string;

export type RefreshJobId = number;

export interface Podcast {
  podcastId: PodcastId;
  title: string;
  description: string;
  publisher: string;
  imageUrl: string;
  thumbnailUrl: string;
  itunesId: number;
  totalEpisodes?: number;
  nextEpisodePubAtMillis?: number;
  episodes?: string[];
}

export enum FavoritePodcastRole {
  OWNER = 'owner',
  FAN = 'fan',
}

export interface FavoritePodcast {
  podcastId: PodcastId;
  title: string;
  description: string;
  publisher: string;
  imageUrl: string;
  thumbnailUrl: string;
  itunesId: number;
  role: FavoritePodcastRole;
  isDefault: boolean;
}

export interface NormalizedPodcasts {
  entities: {
    podcasts: {
      [key: string]: Podcast;
    };
  };
  result: string[];
}

export interface PodcastDetailEpisode {
  episodeId: string;
  audioUrl: string;
  originalAudioUrl?: string;
  proxyAudioUrl?: string;
  durationSec: number;
  title: string;
  description: string;
  imageUrl: string;
  thumbnailUrl: string;
  publishedAtMillis: number;
}

export type AssetType =
  | 'artTrackVideo'
  | 'composition'
  | 'episode'
  | 'general'
  | 'movie'
  | 'musicVideo'
  | 'season'
  | 'show'
  | 'soundRecording'
  | 'videoGame'
  | 'web';

export type YoutubeAssetInfoType = {
  assetType?: AssetType;
  customId?: string;
};

export interface AutoPostVideoPreferenceOptions {
  title?: string;
  description?: string;
  tags?: string[];
  categoryId?: number;
  googleId?: string;
  privacyStatus?: YoutubeVideoVisibility;
  playlists?: string[];
  defaultLanguage?: string;
  madeForKids?: boolean;
  postType?: YouTubeVideoPostType | FacebookPostType;
  tiktokUserId?: string;
  tiktokPrivacyLevel?: TikTokPrivacyLevelOptionName;
  commentEnabled?: boolean;
  duetEnabled?: boolean;
  stitchEnabled?: boolean;
  brandContentToggle?: boolean;
  brandOrganicToggle?: boolean;
  instagramUserId?: string;
  instagramMediaType?: InstagramMediaType;
  linkedinUserId?: string;
  linkedinAuthorId?: string;
  facebookPageId?: string;
  facebookId?: string;
  assetInfo?: YoutubeAssetInfoType;
  twitterUserId?: string;
  threadsUserId?: string;
}

interface AutoPostVideoPreference {
  options?: AutoPostVideoPreferenceOptions;
  platform: AutoPostPlatform;
}

export type PodcastSubscriptionVideoFrequency =
  | 'daily'
  | 'weekly'
  | 'onPublish'
  | 'fromFirstEpisode'
  | 'targetedSeason';

export interface PodcastSubscriptionType {
  autoPostVideoPreference?: AutoPostVideoPreference;
  clipSelectionMethod?: AutogramClipSelectionMethod;
  isCaptionEnabled?: boolean;
  videoFrequency?: PodcastSubscriptionVideoFrequency;
  videoLengthSec?: number;
  videoType?: AutogramVideoType;
  isAutoSelect?: boolean;
  targetedSeasons?: number[];
  restartBackCatalog?: boolean;
}

interface Dimension {
  height: number;
  width: number;
}

export interface SubscriptionItem
  extends Omit<PodcastSubscriptionType, 'videoFrequency' | 'videoLengthSec'> {
  id: number;
  autoCreateSubId: number;
  videoFrequencyPref?: PodcastSubscriptionType['videoFrequency'];
  videoLengthSecPref?: PodcastSubscriptionType['videoLengthSec'];
}

export interface LastSuccessfulAutogram {
  id: number;
  video?: {
    id: string;
    isErrored: boolean;
    isResolved: boolean;
    status:
      | 'notFound'
      | 'queued'
      | 'processing'
      | 'uploading'
      | 'completed'
      | 'error'
      | 'timedOut';
    widgetId: string;
  };
}

export interface PodcastSubscriptionTemplateThumbnail {
  status?: 'queued' | 'processing' | 'completed' | 'error' | 'errorAck';
  url?: string;
}

export interface PodcastSubscription {
  autoCreateSubscriptionId: number;
  backCatalogNextEpisode: {
    episodeId: number | null;
    imageUrl: string | null;
    title: string | null;
  };
  dimension: Dimension;
  fetch: {
    status: 'tracking' | 'paused' | 'error' | 'processing';
  };
  imageUrl: string;
  isAuthError: boolean;
  isEnabled: boolean;
  itunesId: number;
  isUserEngaged: boolean;
  latestSuccessfulAutoEpisode?: LastSuccessfulAutogram;
  podcastFeedId: number;
  podcastId?: string;
  podcastLanguage?: string;
  templateId: string;
  templateType: TemplateType;
  templateName?: string;
  subscriptionItems?: SubscriptionItem[];
  subscriptionPodcastLanguage?: string;
  subscriptionThumbnailUrl?: string;
  thumbnailUrl: string;
  title: string;
  subscriptionTemplateThumbnail: PodcastSubscriptionTemplateThumbnail;
}

export interface NormalizedPodcastSubscriptions {
  entities: {
    podcastSubscriptions: {
      [key: string]: PodcastSubscription;
    };
  };
  result: string[];
}

export type UpdatePodcastSubscriptionActionArgs = Partial<
  Pick<
    PodcastSubscription,
    'isEnabled' | 'podcastLanguage' | 'templateId' | 'templateType'
  >
> & {
  subscriptionId: number;
  subscriptionTypes: PodcastSubscriptionType[];
  templateAdjustment?: TemplateAdjustment;
};

export interface NormalizedEpisode {
  entities: {
    podcastDetailEpisodes: {
      [key: string]: PodcastDetailEpisode;
    };
  };
  result: string;
}

export type PodcastClaimOwnershipArgs = [string];

export interface PodcastClaimPodcastOwnershipResult {
  claimStatus: 'success' | 'emailMismatch';
  podcastOwnerEmail: string;
  playAppPodcastUrl: string;
}

export type RefreshPodcastFeedArgs = [string];

export interface RefreshPodcastFeedResult {
  podcastFeedRefreshJobId: number;
  isNewEntry: boolean;
}

export type PodcastGetRefreshStatusArgs = [number];

export interface PodcastGetRefreshStatusResult {
  id: 0;
  status: 'queued' | 'processing' | 'completed' | 'error';
}

export interface NormalizedOwnedPodcast {
  entities: {
    podcastFeedId: number;
    podcastId: string;
    title: string;
    thumbnailUrl: string;
  }[];
  result: string[];
}

export interface PodcastGetOwnedResult extends NormalizedOwnedPodcast {}

export type PodcastSearchArgs = [
  string, // query
  number, // offset for pagination
];

export interface PodcastSearchResult extends NormalizedPodcasts {
  queries: {
    current: PageData;
  };
}

export type PodcastSearchFeedArgs = [
  string, // url
];

export interface PodcastSearchFeedResult extends NormalizedPodcasts {}

export type GetPodcastByIdArgs = [string /* podcast-id */];
export type GetPodcastByIdResult = Omit<NormalizedPodcasts, 'result'> & {
  result: string;
};

export type PodcastTemplateConfig = Pick<
  IEmbedConfig,
  | 'edgeVideos'
  | 'layerOrder'
  | 'mainMediaContainer'
  | 'progress'
  | 'slideshowInfo'
  | 'soundwave'
  | 'textOverlayInfo'
  | 'videoClips'
  | 'watermark'
  | 'timer'
  | 'captions'
>;

export interface PodcastWorkflowTemplate {
  compatibleVideoTypes: CompatibilityVideoType[];
  compatibleTiers: Tier[];
  dimension: {
    height: number;
    width: number;
  };
  displayName: string;
  isProjectEditable: boolean;
  isCaptionEnabled: boolean;
  name: string;
  previewConfiguration: PodcastTemplateConfig;
  previewVideos: PreviewVideo[];
  templateId: string;
  templateType: TemplateType;
  thumbnailImageUrl: string;
  isUCSEditorCompatible?: boolean;
  isUCSEditorCompatibleLoading?: boolean;
  subscriptionTemplateThumbnail: {
    url?: string;
    status?: 'queued' | 'processing' | 'completed' | 'error' | 'errorAck';
  };
}

interface NormalizedPodcastWorkflowTemplates {
  entities: {
    podcastWorkflowTemplates: {
      [key: string]: PodcastWorkflowTemplate;
    };
  };
  result: string[];
}

export type GetEpisodesArgs = [
  string, // podcast id
  number, // next pub millis
];

export interface GetEpisodesResult {
  entities: {
    podcastDetailEpisodes: {
      [key: string]: PodcastDetailEpisode;
    };
    podcasts: {
      [key: string]: Partial<Podcast>;
    };
  };
  result: {
    podcastDetailEpisodes: string[];
    podcasts: string[];
  };
  nextEpisodePubAtMillis: number;
}

export type CreateSubscriptionArgs = [
  string, // podcast id
  string, // podcast language
  string, // template id,
  PodcastSubscriptionType[],
  TemplateType,
  TemplateAdjustment,
];
export type CreateSubscriptionResult = void;

export type CreateSubscriptionPreviewArgs = [
  string, // podcast id
  TemplateAdjustment,
  string, // template id,
  TemplateType,
];
export interface CreateSubscriptionPreviewResult {
  subscriptionPreviewJobId: number;
}

export type GetSubscriptionPreviewArgs = [
  number, // subscriptionPreviewJobId
];
export interface GetSubscriptionPreviewResult {
  id: number;
  recordingId: number;
  status: 'queued' | 'processing' | 'completed' | 'error';
  episodeConfigs: {
    episodeId: number;
    widgetId: string;
  }[];
}

export type GetMySubscriptionArgs = [string /* podcastFeedId */];
export type GetMySubscriptionResult = NormalizedPodcastSubscriptions;

export type DeleteSubscriptionArgs = [number /* subscription id */];
export interface DeleteSubscriptionResult {
  id: number;
}

export type UpdateSubscriptionArgs = [
  number, // subscription id
  string, // podcast language
  string, // template id,
  PodcastSubscriptionType[],
  TemplateType,
  TemplateAdjustment,
  boolean, // isEnabled
];
export type UpdateSubscriptionResult = void;

export type ToggleSubscriptionArgs = [number, boolean];

export type ToggleSubscriptionResult = void;

export type GetPodcastWorkflowTemplatesArgs = [
  number, // dimension width
  number, // dimension height
  string, // subject image url
];
export type GetPodcastWorkflowTemplatesResult = NormalizedPodcastWorkflowTemplates;

export type GetPodcastWorkflowTemplateArgs = [
  string, // templateId
  TemplateType,
];
export type GetSeasonsArgs = [
  string, // podcastId
];
export type GetPodcastWorkflowTemplateResult = Replace<
  NormalizedPodcastWorkflowTemplates,
  {
    result: string;
  }
>;

export type GetSeasonsResult = {
  seasons: Season[];
  totalEpisodes: number;
};

export type CreateEpisodeVariationArgs = [
  number, // autoCreateEpisodeId
  number, // newTrimStartMillis
  number, // newTrimEndMillis
  boolean, // isCaptionEnabled
];
export type CreateEpisodeVariationResult = {
  autoCreateEpisodeId: number;
};

export type EnableClipSuggestionArgs = [
  number, // autoCreateEpisodeId
];
export type EnableClipSuggestionResult = null;

export type CreateEpisodeVideoArgs = [
  number, // episodeId
  number[], // autoCreateSubscriptionIds
];
export type CreateEpisodeVideoResult = void;

type EmbedVideoId = number;

export type GetEpisodeByVideoArgs = [EmbedVideoId];

export type GetEpisodeByVideoResult = NormalizedPodcastFeedDetails;

export type GetSubscriptionByIdArgs = [number /* subscriptionId */];
export type GetSubscriptionByIdResult = NormalizedPodcastSubscriptions;

export interface PodcastEpisode {
  id: number;
  title: string;
  publishedAtMillis: number;
  audioDurationMillis: number;
  imageUrl?: string;
  thumbnailImageUrl?: string;
  url?: string;
  entireAudioInstanceId?: number;
  audioUrl: string;
  originalAudioUrl: string;
  podcast: {
    podcastId: number;
    title: string;
    imageUrl: string;
    thumbnailImageUrl?: string;
  };
  socialPostCaptionInfo: SocialPostCaptionInfo;
  playAppEpisodeUrl: string;
}

interface NormalizedPodcastEpisode {
  entities: {
    podcastEpisodes: {
      [key: string]: PodcastEpisode;
    };
  };
  result: string;
}

export type GetEpisodeByIdArgs = [EpisodeId];
export type GetEpisodeByIdResult = NormalizedPodcastEpisode;

export type CreateSocialPostCaptionsArgs = [EpisodeId];
export type CreateSocialPostCaptionsResult = {
  jobId: number;
};

export type GetSocialPostCaptionsArgs = [EpisodeId, number];

export type GetGroupClipSuggestionArgs = [number];
export type GetGroupClipSuggestionResult = GroupClipSuggestion;

export type GetSocialPostCaptionsResult = {
  id: number;
  status: SocialPostCaptionInfoStatus;
  socialPostCaptions: SocialPostCaptionInfo;
};

export type CreatePreSelectVariationArgs = [
  EpisodeId,
  SubscriptionItemId,
  SubscriptionId,
  number, // trimStartMillis
  number, // trimEndMillis
  boolean, // isCaptionEnabled
];
export type CreatePreSelectVariationResult = {
  autoCreateEpisodeId: number;
};

interface EpisodeClipSuggestion {
  startMillis: number;
  endMillis: number;
  text: string;
  suggestionId: number;
}

export type ClipSuggestionStatus =
  | 'queued'
  | 'processing'
  | 'completed'
  | 'error';

export interface GroupClipSuggestion {
  autoCreateEpisodeId: number;
  startMillis: number;
  endMillis: number;
  text?: string;
  widgetId?: string;
  videoUrl?: string;
  status: ClipSuggestionStatus;
}

export type SubscriptionItemInformationVideoType =
  | VideoTypes.FULL_EPISODE
  | VideoTypes.RANDOM_CLIP
  | VideoTypes.SHORT_CLIP;

export interface SubscriptionItemInformation {
  id: number;
  autoCreateSubId: number;
  userId: number;
  videoType: SubscriptionItemInformationVideoType;
  videoFrequencyPref: PodcastSubscriptionVideoFrequency;
  videoLengthSecPref: number;
  isCaptionEnabled: boolean;
  isAutoSelect: boolean;
  clipSelectionMethod:
    | AutogramClipSelectionMethod.MANUAL
    | AutogramClipSelectionMethod.SMART
    | AutogramClipSelectionMethod.SOUNDBITE_TAG;
  targetedSeasons: number[];
  autoPostVideoPreference: AutoPostVideoPreference;
}

export interface EpisodeTranscriptInfo {
  clipSuggestions: EpisodeClipSuggestion[];
  fullTranscriptParts: Array<{
    startMillis: number;
    endMillis: number;
    text: string;
  }>;
}

export interface NormalizedEpisodeTranscriptInfo {
  entities: {
    episodeClipSuggestion: {
      [key: string]: EpisodeClipSuggestion;
    };
  };
  result: string[];
}

export type GetEpisodeTranscriptInfoArgs = [
  AutoCreateEpisodeId,
  SubscriptionItemId,
];
export type GetEpisodeTranscriptInfoResult = NormalizedEpisodeTranscriptInfo;

export type GetGroupClipSuggestionsArgs = [
  EpisodeId,
  SubscriptionItemId,
  GroupRequestId,
];

export type CreateGroupClipSuggestionArgs = [
  number,
  number,
  number,
  number,
  number,
];
export type CreateGroupClipSuggestionResult = GroupClipSuggestion;

export interface GroupClipSuggestions {
  clipSuggestions: GroupClipSuggestion[];
  groupRequestId: number;
}

export interface NormalizeGroupClipSuggestions {
  entities: {
    groupClipSuggestions: GroupClipSuggestions;
  };
  result: GroupClipSuggestions;
}

export type GetGroupClipSuggestionsResult = NormalizeGroupClipSuggestions;

export type GetSubscriptionItemInformationArgs = [SubscriptionItemId];
export type GetSubscriptionItemInformationResult = SubscriptionItemInformation;

export type GetSubscriptionItemDetailArgs = [number, number];
export type GetSubscriptionItemDetailResult = SubscriptionItem;

export interface PodcastFeed {
  autoCreateSubscriptions?: Array<{ id: number }>;
  listenNotesPodcastId: string;
  podcastFeedId: number;
  thumbnailUrl: string;
  title: string;
}

export interface NormalizedPodcastFeed {
  entities: {
    podcastFeed: {
      [key: string]: PodcastFeed;
    };
  };
  result: string[];
}

export type GetMyPodcastFeedsArgs = void;
export type GetMyPodcastFeedsResult = NormalizedPodcastFeed;

export type DeletePodcastFeedArgs = [string /* podcastFeedId */];
export type DeletePodcastFeedResult = void;

export interface PodcastFeedPageData {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export type EntireEpisodeTranscriptStatus =
  | 'notFound'
  | 'queued'
  | 'createTranscriptRequest'
  | 'transcriptRequested'
  | 'cleanUpTranscript'
  | 'completed'
  | 'error'
  | 'errorAck';

export type EddyProjectStatus = 'queued' | 'processing' | 'completed' | 'error';

export interface PodcastFeedEpisodeEddyProjectInfo {
  url: string | null;
  status: EddyProjectStatus | null;
  isResolved: boolean | null;
}

export type SocialPostCaptionInfoStatus =
  | 'queued'
  | 'processing'
  | 'completed'
  | 'error';

export type SocialPostCaptionInfoPlatform =
  | 'linkedin'
  | 'instagram'
  | 'facebook'
  | 'twitter'
  | 'tiktok'
  | 'youtube'
  | 'threads';

export interface SocialPostCaptions {
  platform: SocialPostCaptionInfoPlatform;
  postText: SocialPostCaptionInfoPlatform;
}

export interface SocialPostCaptionInfo {
  jobId: number | null;
  status: SocialPostCaptionInfoStatus | null;
  socialPostCaptions: SocialPostCaptions[] | null;
  canGenerateSocialPostCaptions?: boolean;
}

export interface PodcastFeedEpisode {
  audioDurationMillis: number;
  id: number;
  imageUrl?: string;
  podcastFeedId: string;
  publishedAtMillis?: number;
  projectInfo?: {
    projectListUrl?: string;
    totalActiveProjectCount: number;
    status: 'notFound' | 'processing' | 'completed' | 'partialProcessing';
  };
  thumbnailImageUrl?: string;
  title?: string;
  transcriptInfo?: {
    episodeId: number;
    isResolved: boolean;
    status: EntireEpisodeTranscriptStatus;
  };
  eddyProjectInfo: PodcastFeedEpisodeEddyProjectInfo;
  playAppEpisodeUrl: string;
  url: string;
  socialPostCaptionInfo?: SocialPostCaptionInfo;
}

interface NormalizedPodcastFeedDetails {
  entities: {
    podcastFeedEpisodes: {
      [id: string]: PodcastFeedEpisode;
    };
  };
  result: string;
}

export type GetMyPodcastFeedDetailsArgs = [
  string /* podcast feed id */,
  number /* page */,
  number /* size */,
];
export interface GetMyPodcastFeedDetailsResult
  extends NormalizedPodcastFeedDetails {
  page: PodcastFeedPageData;
  canRefreshFeed: boolean;
}

export type CreateBaseConfigArgs = [
  TemplateType,
  string /* template id */,
  number /* recording id */,
  number /* duration millis */,
  string /* podcast title */,
  string /* episode title */,
  string /* image url */,
];
export type CreateBaseConfigResult = IEmbedConfig;

export type GetFavoritePodcastsArgs = [];
export type GetFavoritePodcastsResult = NormalizedPodcasts;

export type MarkPodcastFavoriteArgs = [
  {
    podcastId: PodcastId;
    role?: FavoritePodcastRole;
    isDefault?: boolean;
  },
];
export type MarkPodcastFavoriteResult = void;

export interface AutoCreateEpisode {
  id: AutoCreateEpisodeId;
  autoCreateSubId: number;
  episodeId: EpisodeId;
  audioVariationId: number;
  videoId?: number;
  status:
    | 'queued'
    | 'processing'
    | 'completed'
    | 'error'
    | 'errorAck'
    | 'sourceDepleted'
    | 'subscriptionInactive';
}

export interface AudioVariation {
  id: number;
  trimStartMillis: number;
  trimDurationMillis: number;
  audioVariationType: AudioVariationType;
}

interface NormalizedAutoCreateEpisode {
  entities: {
    autoCreateEpisodes: {
      [id: string]: AutoCreateEpisode;
    };
    audioVariations: {
      [id: string]: AudioVariation;
    };
  };
  result: string;
}

export enum FeedbackType {
  OtherReason = 'otherReason',
  ItsAnAdvertisement = 'itsAnAdvertisement',
  ItsNotInteresting = 'itsNotInteresting',
  ItCutsOffMidSentence = 'itCutsOffMidSentence',
}

export interface EpisodeClipSuggestionFeedback {
  suggestionId?: number;
  subscriptionItemId?: number;
  autoCreateEpisodeId?: number;
  feedbackType: FeedbackType;
  customFeedback?: string;
}

export type YoutubePlaylist = {
  id: string;
  title: string;
};

export type YoutubeCategories = {
  id: number;
  title: string;
};

export type YoutubeLanguage = {
  languageCode: string;
  name: string;
};

export type CreateEpisodeClipSuggestionFeedbackArgs = [
  EpisodeClipSuggestionFeedback,
];
export type CreateEpisodeClipSuggestionFeedbackResult = void;

export type GetAutoCreateEpisodeByVideoArgs = [VideoId];
export type GetAutoCreateEpisodeByVideoResult = NormalizedAutoCreateEpisode;

export type GetAutoCreateEpisodeArgs = [AutoCreateEpisodeId];
export type GetAutoCreateEpisodeResult = NormalizedAutoCreateEpisode;

export type RemoteEpisodeId = string;

export interface RemoteEpisodePodcastInfo {
  podcastId: string;
  remotePodcastId: string;
  title: string;
  imageUrl: string;
  description: string;
}

export interface PodcastRemoteEpisode {
  episodeId: RemoteEpisodeId;
  excerpts: Array<{
    startMillis: number;
    durationMillis: number;
  }>;
  title?: string;
  description?: string;
  sanitizedTags?: string[];
  url: string;
  playAppEpisodeUrl: string;
  tags: string[];
  podcast: RemoteEpisodePodcastInfo;
  episodeNumber: string;
}

export type GetPodcastRemoteEpisodeArgs = [PodcastId, RemoteEpisodeId];
export type GetPodcastRemoteEpisodeResult = {
  result: RemoteEpisodeId;
  entities: {
    podcastRemoteEpisodes: Record<RemoteEpisodeId, PodcastRemoteEpisode>;
  };
};

export type DeleteFavoritePodcastArgs = [PodcastId];
export type DeleteFavoritePodcastResult = void;

export type EntireEpisodeTranscriptCreationStatus =
  | 'submitted'
  | 'transcribeTimeLimitReached'
  | 'concurrentLimitReached'
  | 'languageNotSupported';

export type CreateEntireEpisodeTranscriptArgs = [string /* episode id */];
export interface CreateEntireEpisodeTranscriptResult {
  status: EntireEpisodeTranscriptCreationStatus;
  language: string;
}

interface NormalizedEntireEpisodeTranscriptInfo<R> {
  result: R;
  entities: {
    podcastFeedEpisodes: {
      [id: string]: Pick<PodcastFeedEpisode, 'transcriptInfo'>;
    };
  };
}

export type GetEntireEpisodeTranscriptInfoArgs = [number /* episode id */];
export type GetEntireEpisodeTranscriptInfoResult = NormalizedEntireEpisodeTranscriptInfo<
  string
>;

export type GetEntireEpisodeTranscriptInfosArgs = [number[] /* episode ids */];
export type GetEntireEpisodeTranscriptInfosResult = NormalizedEntireEpisodeTranscriptInfo<
  string[]
>;

export type GetYoutubePlaylistsResult = YoutubePlaylist[];

export type GetYoutubeCategoriesResult = YoutubeCategories[];

export type GetYoutubeLanguagesResult = {
  result: string;
  entities: {
    youtubeLanguages: {
      [id: string]: YoutubeLanguage;
    };
  };
};

export type ShareVideoArgs = [
  string, // embedVideoId
  SocialSharePlatform, // platform
  string, // accessToken
  string, // title
  string, // desc
  string[], // tags
  YoutubeVideoVisibility,
  boolean, // made for kids
  number, // categoryId
  string[], // youtube playlists ids array
  LinkedinVideoVisibility,
  string, // linkedinAuthorId (can be person or organization)
  string, // tiktokUserId
  string, // twitterUserId
  string, // youtube default language
  string, // accessTokenSecret
  boolean, // commentEnabled
  string, // instagramUserId
  InstagramMediaType, // instagramMediaType
  string, // providerUserId
  string, // facebookPageId
  string, // threadsUserId
];
export interface ShareVideoResult {
  socialShareId: number;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<
  typeof ACTION_KEY,
  M,
  A
>;

export type PodcastClaimOwnershipAction = ApiAction<
  ServiceMethod.CLAIM_PODCAST_OWNERSHIP,
  PodcastClaimOwnershipArgs
>;

export type PodcastGetOwnedAction = ApiAction<
  ServiceMethod.GET_OWNED_PODCASTS,
  []
>;

export type RefreshPodcastFeedAction = ApiAction<
  ServiceMethod.REFRESH_PODCAST_FEED,
  RefreshPodcastFeedArgs
>;

export type PodcastGetRefreshStatusAction = ApiAction<
  ServiceMethod.GET_PODCAST_REFRESH_STATUS,
  PodcastGetRefreshStatusArgs
>;

export type PodcastSearchAction = ApiAction<
  ServiceMethod.SEARCH_FOR_PODCAST,
  PodcastSearchArgs
>;
export type PodcastSearchFeedAction = ApiAction<
  ServiceMethod.SEARCH_FOR_FEED,
  PodcastSearchFeedArgs
>;
export type GetPodcastByIdAction = ApiAction<
  ServiceMethod.GET_PODCAST_BY_ID,
  GetPodcastByIdArgs
>;
export type GetEpisodesAction = ApiAction<
  ServiceMethod.GET_PODCAST_EPISODES,
  GetEpisodesArgs
>;
export type CreateSubscriptionAction = ApiAction<
  ServiceMethod.CREATE_PODCAST_SUBSCRIPTION,
  CreateSubscriptionArgs
>;
export type CreateSubscriptionPreviewAction = ApiAction<
  ServiceMethod.CREATE_PODCAST_SUBSCRIPTION_PREVIEW,
  CreateSubscriptionPreviewArgs
>;
export type GetSubscriptionPreviewAction = ApiAction<
  ServiceMethod.GET_PODCAST_SUBSCRIPTION_PREVIEW,
  GetSubscriptionPreviewArgs
>;
export type GetMySubscriptionAction = ApiAction<
  ServiceMethod.GET_MY_PODCAST_SUBSCRIPTIONS,
  GetMySubscriptionArgs
>;
export type DeleteSubscriptionAction = ApiAction<
  ServiceMethod.DELETE_PODCAST_SUBSCRIPTION,
  DeleteSubscriptionArgs
>;
export type UpdateSubscriptionAction = ApiAction<
  ServiceMethod.UPDATE_PODCAST_SUBSCRIPTION,
  UpdateSubscriptionArgs
>;
export type ToggleSubscriptionAction = ApiAction<
  ServiceMethod.TOGGLE_PODCAST_SUBSCRIPTION,
  ToggleSubscriptionArgs
>;
export type GetPodcastWorkflowTemplatesAction = ApiAction<
  ServiceMethod.GET_PODCAST_WORKFLOW_TEMPLATES,
  GetPodcastWorkflowTemplatesArgs
>;
export type GetPodcastWorkflowTemplateAction = ApiAction<
  ServiceMethod.GET_PODCAST_WORKFLOW_TEMPLATE,
  GetPodcastWorkflowTemplateArgs
>;
export type GetSeasonsAction = ApiAction<
  ServiceMethod.GET_SEASONS,
  GetSeasonsArgs
>;
export type CreateEpisodeVariationAction = ApiAction<
  ServiceMethod.CREATE_EPISODE_VARIATION,
  CreateEpisodeVariationArgs
>;
export type EnableClipSuggestionAction = ApiAction<
  ServiceMethod.ENABLE_CLIP_SUGGESTION,
  EnableClipSuggestionArgs
>;
export type CreatePreSelectVariationAction = ApiAction<
  ServiceMethod.CREATE_PRE_SELECT_VARIATION,
  CreatePreSelectVariationArgs
>;
export type CreateEpisodeVideoAction = ApiAction<
  ServiceMethod.CREATE_EPISODE_VIDEO,
  CreateEpisodeVideoArgs
>;
export type GetEpisodeTranscriptInfoAction = ApiAction<
  ServiceMethod.GET_EPISODE_TRANSCRIPT_INFO,
  GetEpisodeTranscriptInfoArgs
>;
export type GetGroupClipSuggestionsAction = ApiAction<
  ServiceMethod.GET_GROUP_CLIP_SUGGESTIONS,
  GetGroupClipSuggestionsArgs
>;
export type CreateGroupClipSuggestionAction = ApiAction<
  ServiceMethod.CREATE_GROUP_CLIP_SUGGESTION,
  CreateGroupClipSuggestionArgs
>;
export type GetSubscriptionItemInformationAction = ApiAction<
  ServiceMethod.GET_SUBSCRIPTION_ITEM_INFORMATION,
  GetSubscriptionItemInformationArgs
>;
export type GetSubscriptionItemDetailAction = ApiAction<
  ServiceMethod.GET_SUBSCRIPTION_ITEM_DETAIL,
  GetSubscriptionItemDetailArgs
>;
export type GetMyPodcastFeedsAction = ApiAction<
  ServiceMethod.GET_MY_PODCAST_FEEDS,
  GetMyPodcastFeedsArgs
>;
export type DeletePodcastFeedAction = ApiAction<
  ServiceMethod.DELETE_PODCAST_FEED,
  DeletePodcastFeedArgs
>;
export type GetMyPodcastFeedDetailsAction = ApiAction<
  ServiceMethod.GET_MY_PODCAST_FEED_DETAILS,
  GetMyPodcastFeedDetailsArgs
>;
export type GetSubscriptionByIdAction = ApiAction<
  ServiceMethod.GET_SUBSCRIPTION_BY_ID,
  GetSubscriptionByIdArgs
>;
export type CreateBaseConfigAction = ApiAction<
  ServiceMethod.CREATE_BASE_CONFIGURATION,
  CreateBaseConfigArgs
>;
export type GetEpisodeByVideoAction = ApiAction<
  ServiceMethod.GET_EPISODE_BY_VIDEO,
  GetEpisodeByVideoArgs
>;
export type GetFavoritePodcastsAction = ApiAction<
  ServiceMethod.GET_FAVORITE_PODCASTS,
  GetFavoritePodcastsArgs
>;
export type MarkPodcastFavoriteAction = ApiAction<
  ServiceMethod.MARK_PODCAST_FAVORITE,
  MarkPodcastFavoriteArgs
>;

export type GetEpisodeByIdAction = ApiAction<
  ServiceMethod.GET_EPISODE_BY_ID,
  GetEpisodeByIdArgs
>;

export type CreateSocialPostCaptionsAction = ApiAction<
  ServiceMethod.CREATE_SOCIAL_POST_CAPTIONS,
  CreateSocialPostCaptionsArgs
>;

export type GetSocialPostCaptionsAction = ApiAction<
  ServiceMethod.GET_SOCIAL_POST_CAPTIONS,
  GetSocialPostCaptionsArgs
>;

export type GetGroupClipSuggestionAction = ApiAction<
  ServiceMethod.GET_GROUP_CLIP_SUGGESTION,
  GetGroupClipSuggestionArgs
>;

export type GetAutoCreateEpisodeByVideoAction = ApiAction<
  ServiceMethod.GET_AUTO_CREATE_EPISODE_BY_VIDEO,
  GetAutoCreateEpisodeByVideoArgs
>;

export type GetAutoCreateEpisodeAction = ApiAction<
  ServiceMethod.GET_AUTO_CREATE_EPISODE,
  GetAutoCreateEpisodeArgs
>;

export type GetPodcastRemoteEpisodeAction = ApiAction<
  ServiceMethod.GET_PODCAST_REMOTE_EPISODE,
  GetPodcastRemoteEpisodeArgs
>;

export type CreateEpisodeClipSuggestionFeedbackAction = ApiAction<
  ServiceMethod.CREATE_EPISODE_CLIP_SUGGESTION_FEEDBACK,
  CreateEpisodeClipSuggestionFeedbackArgs
>;

export type DeleteFavoritePodcastAction = ApiAction<
  ServiceMethod.DELETE_FAVORITE_PODCAST,
  DeleteFavoritePodcastArgs
>;

export type CreateEntireEpisodeTranscriptAction = ApiAction<
  ServiceMethod.CREATE_ENTIRE_EPISODE_TRANSCRIPT,
  CreateEntireEpisodeTranscriptArgs
>;

export type GetEntireEpisodeTranscriptInfoAction = ApiAction<
  ServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFO,
  GetEntireEpisodeTranscriptInfoArgs
>;

export type GetEntireEpisodeTranscriptInfosAction = ApiAction<
  ServiceMethod.GET_ENTIRE_EPISODE_TRANSCRIPT_INFOS,
  GetEntireEpisodeTranscriptInfosArgs
>;

export type ShareVideoAction = ApiAction<
  ServiceMethod.SHARE_VIDEO,
  ShareVideoArgs
>;

export type GetYoutubeCategoriesAction = ApiAction<
  ServiceMethod.GET_YOUTUBE_CATEGORIES,
  GetYoutubeCategoriesResult
>;

export type GetYoutubeLanguagesAction = ApiAction<
  ServiceMethod.GET_YOUTUBE_LANGUAGES,
  GetYoutubeLanguagesResult
>;

export interface PodcastServiceDispatch {
  (action: PodcastClaimOwnershipAction): Promise<
    IApiResponse<PodcastClaimPodcastOwnershipResult>
  >;
  (action: RefreshPodcastFeedAction): Promise<
    IApiResponse<RefreshPodcastFeedResult>
  >;
  (action: PodcastGetRefreshStatusAction): Promise<
    IApiResponse<PodcastGetRefreshStatusResult>
  >;
  (action: PodcastGetOwnedAction): Promise<IApiResponse<PodcastGetOwnedResult>>;
  (action: PodcastGetOwnedAction): Promise<IApiResponse<PodcastGetOwnedResult>>;
  (action: PodcastSearchAction): Promise<IApiResponse<PodcastSearchResult>>;
  (action: PodcastSearchFeedAction): Promise<
    IApiResponse<PodcastSearchFeedResult>
  >;
  (action: GetPodcastByIdAction): Promise<IApiResponse<GetPodcastByIdResult>>;
  (action: GetEpisodesAction): Promise<IApiResponse<GetEpisodesResult>>;
  (action: CreateSubscriptionAction): Promise<
    IApiResponse<CreateSubscriptionResult>
  >;
  (action: CreateSubscriptionPreviewAction): Promise<
    IApiResponse<CreateSubscriptionPreviewResult>
  >;
  (action: GetSubscriptionPreviewAction): Promise<
    IApiResponse<GetSubscriptionPreviewResult>
  >;
  (action: GetMySubscriptionAction): Promise<
    IApiResponse<GetMySubscriptionResult>
  >;
  (action: DeleteSubscriptionAction): Promise<
    IApiResponse<DeleteSubscriptionResult>
  >;
  (action: UpdateSubscriptionAction): Promise<
    IApiResponse<UpdateSubscriptionResult>
  >;
  (action: ToggleSubscriptionAction): Promise<
    IApiResponse<ToggleSubscriptionResult>
  >;
  (action: GetPodcastWorkflowTemplateAction): Promise<
    IApiResponse<GetPodcastWorkflowTemplateResult>
  >;
  (action: GetSeasonsAction): Promise<IApiResponse<GetSeasonsResult>>;
  (action: GetPodcastWorkflowTemplatesAction): Promise<
    IApiResponse<GetPodcastWorkflowTemplatesResult>
  >;
  (action: CreateEpisodeVariationAction): Promise<
    IApiResponse<CreateEpisodeVariationResult>
  >;
  (action: EnableClipSuggestionAction): Promise<
    IApiResponse<EnableClipSuggestionResult>
  >;
  (action: CreatePreSelectVariationAction): Promise<
    IApiResponse<CreatePreSelectVariationResult>
  >;
  (action: CreateEpisodeVideoAction): Promise<
    IApiResponse<CreateEpisodeVideoResult>
  >;
  (action: GetEpisodeTranscriptInfoAction): Promise<
    IApiResponse<GetEpisodeTranscriptInfoResult>
  >;
  (action: GetSubscriptionItemDetailAction): Promise<
    IApiResponse<GetSubscriptionItemDetailResult>
  >;
  (action: GetMyPodcastFeedsAction): Promise<
    IApiResponse<GetMyPodcastFeedsResult>
  >;
  (action: DeletePodcastFeedAction): Promise<
    IApiResponse<DeletePodcastFeedResult>
  >;
  (action: GetMyPodcastFeedDetailsAction): Promise<
    IApiResponse<GetMyPodcastFeedDetailsResult>
  >;
  (action: GetSubscriptionByIdAction): Promise<
    IApiResponse<GetSubscriptionByIdResult>
  >;
  (action: CreateBaseConfigAction): Promise<
    IApiResponse<CreateBaseConfigResult>
  >;
  (action: GetEpisodeByVideoAction): Promise<
    IApiResponse<GetEpisodeByVideoResult>
  >;
  (action: GetFavoritePodcastsAction): Promise<
    IApiResponse<GetFavoritePodcastsAction>
  >;
  (action: MarkPodcastFavoriteAction): Promise<
    IApiResponse<MarkPodcastFavoriteResult>
  >;
  (action: GetEpisodeByIdAction): Promise<IApiResponse<GetEpisodeByIdResult>>;
  (action: GetAutoCreateEpisodeByVideoAction): Promise<
    IApiResponse<GetAutoCreateEpisodeByVideoResult>
  >;
  (action: GetAutoCreateEpisodeAction): Promise<
    IApiResponse<GetAutoCreateEpisodeResult>
  >;
  (action: GetGroupClipSuggestionsAction): Promise<
    IApiResponse<GetGroupClipSuggestionsResult>
  >;
  (action: CreateGroupClipSuggestionAction): Promise<
    IApiResponse<CreateGroupClipSuggestionResult>
  >;
  (action: GetSubscriptionItemInformationAction): Promise<
    IApiResponse<GetSubscriptionItemInformationResult>
  >;
  (action: GetPodcastRemoteEpisodeAction): Promise<
    IApiResponse<GetPodcastRemoteEpisodeResult>
  >;
  (action: CreateEpisodeClipSuggestionFeedbackAction): Promise<
    IApiResponse<CreateEpisodeClipSuggestionFeedbackResult>
  >;
  (action: DeleteFavoritePodcastAction): Promise<
    IApiResponse<DeleteFavoritePodcastResult>
  >;
  (action: CreateEntireEpisodeTranscriptAction): Promise<
    IApiResponse<CreateEntireEpisodeTranscriptResult>
  >;
  (action: GetEntireEpisodeTranscriptInfoAction): Promise<
    IApiResponse<GetEntireEpisodeTranscriptInfoResult>
  >;
  (action: GetEntireEpisodeTranscriptInfosAction): Promise<
    IApiResponse<GetEntireEpisodeTranscriptInfosResult>
  >;

  (action: ShareVideoAction): Promise<IApiResponse<ShareVideoResult>>;
  (action: GetYoutubeCategoriesAction): Promise<
    IApiResponse<GetYoutubeCategoriesResult>
  >;
  (action: GetYoutubeLanguagesAction): Promise<
    IApiResponse<GetYoutubeLanguagesResult>
  >;
  (action: CreateSocialPostCaptionsAction): Promise<
    IApiResponse<CreateSocialPostCaptionsResult>
  >;
  (action: GetSocialPostCaptionsAction): Promise<
    IApiResponse<GetSocialPostCaptionsResult>
  >;
  (action: GetGroupClipSuggestionAction): Promise<
    IApiResponse<GetGroupClipSuggestionResult>
  >;
}
