import cn from 'classnames';
import React from 'react';
import FontAwesome from 'components/FontAwesome';
import bem from 'utils/bem';

type SwitchAccountIconProps = {
  className?: string;
};

const block = bem('icon');

const SwitchAccountIcon: React.FC<SwitchAccountIconProps> = ({ className }) => (
  <FontAwesome
    className={cn(block('switch-account-icon'), className)}
    icon="sign-out-alt"
  />
);

export default SwitchAccountIcon;
