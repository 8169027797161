import * as React from 'react';
import { useDispatch } from 'react-redux';
import useAutomationSuggestedClipInfo from 'blocks/AutomationSuggestedClipsPage/useAutomationSuggestedClipInfo';
import ClipSelectPage from 'blocks/ClipSelect';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import Page from 'components/Page';
import useQuery from 'hooks/useQuery';
import {
  loadClippingData,
  selectEpisodeClip,
} from 'redux/modules/automation-clip-preselect';
import { dislikeGroupSuggestedClip } from 'redux/modules/clip-select';
import { showError } from 'redux/modules/notification/actions';
import { Dispatch } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types';

interface Props {}

type HandleSubmit = React.ComponentProps<typeof ClipSelectPage>['onSubmit'];
type HandleDislikeClip = React.ComponentProps<
  typeof ClipSelectPage
>['onDislikeClip'];

const { useEffect } = React;

const AutomationClipPreSelect: React.FC<Props> = () => {
  const query = useQuery();
  const episodeId = parseInt(query.episodeId as string, 10);
  const subscriptionItemId = parseInt(query.subItemId as string, 10);
  const subscriptionId = parseInt(query.subId as string, 10);
  const dispatch = useDispatch<Dispatch>();

  const {
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo,
  } = useAutomationSuggestedClipInfo();

  const handleSubmit: HandleSubmit = async ({
    region,
    isCaptionEnabled,
  }): Promise<void> => {
    try {
      await dispatch(
        selectEpisodeClip(
          episodeId,
          subscriptionItemId,
          subscriptionId,
          region.startMillis,
          region.endMillis,
          isCaptionEnabled,
        ),
      );
    } catch {
      dispatch(showError('Error updating clip', 5));
    }
  };

  const handleDislikeClip: HandleDislikeClip = React.useCallback(
    async (
      reason: ClipSuggestionDislikeReason,
      clipSuggestion: ClipSuggestion,
    ): Promise<void> => {
      await dispatch(
        dislikeGroupSuggestedClip(reason, clipSuggestion.id, clipSuggestion.id),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(loadClippingData(episodeId, subscriptionId, subscriptionItemId));
  }, [dispatch, episodeId, subscriptionId, subscriptionItemId]);

  return (
    <Page>
      <ClipSelectPage
        {...{
          suggestedClips,
          dislikedSuggestionIds,
          clipsPageInfo,
        }}
        onSelectClip={handleSubmit}
        onDislikeClip={handleDislikeClip}
        onSubmit={handleSubmit}
        source="PreSelect"
      />
    </Page>
  );
};

export default AutomationClipPreSelect;
