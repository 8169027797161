import * as React from 'react';

import { useSocialSection } from 'blocks/DownloadPage/containers/DownloadPageSocialSection/SocialSectionContext';
import AutomationPostModalAdditionalDetails from 'components/AutomationPostModalAdditionalDetails';
import LoadingOverlay from 'components/LoadingOverlay';
import SocialAccountInfo, {
  SocialAccountInfoProps,
} from 'components/SocialAccountInfo';

import { block } from '../utils';
import YoutubePlaylistSelector from '../YoutubePlaylistSelector';
import { YoutubePlaylistSelectorProps } from '../YoutubePlaylistSelector/types';
import YoutubeVideoDescriptionInput, {
  YoutubeVideoDescriptionInputProps,
} from '../YoutubeVideoDescriptionInput';
import YoutubeVideoTitleInput, {
  YoutubeVideoTitleInputProps,
} from '../YoutubeVideoTitleInput';

type PickedSocialAccountProps = Pick<
  SocialAccountInfoProps,
  'avatarSrc' | 'onSwitchAccountClick' | 'username'
>;

export interface YoutubeManualPostComposerProps
  extends PickedSocialAccountProps {
  description: YoutubeVideoDescriptionInputProps['value'];
  isMetadataEnabled?: boolean;
  isUploading?: boolean;
  playlists: YoutubePlaylistSelectorProps['options'];
  onAdditionalStepClick: () => void;
  onCreatePlaylistClick: YoutubePlaylistSelectorProps['onCreatePlaylistClick'];
  onDescriptionChange: YoutubeVideoDescriptionInputProps['onChange'];
  onPlaylistChange: YoutubePlaylistSelectorProps['onChange'];
  onTitleChange: YoutubeVideoTitleInputProps['onChange'];
  readonly?: boolean;
  selectedPlaylistsIds: YoutubePlaylistSelectorProps['value'];
  title: YoutubeVideoTitleInputProps['value'];
  onUnlockAICaptions: () => void;
  onCancelUnlockAICaptions: () => void;
}

const YoutubeManualPostComposer: React.FC<YoutubeManualPostComposerProps> = ({
  avatarSrc,
  description,
  isMetadataEnabled = false,
  isUploading,
  playlists,
  onAdditionalStepClick,
  onCreatePlaylistClick,
  onDescriptionChange,
  onPlaylistChange,
  onSwitchAccountClick,
  onTitleChange,
  readonly,
  selectedPlaylistsIds,
  title,
  onUnlockAICaptions,
  onCancelUnlockAICaptions,
  username,
}) => {
  const { allowAiDisclaimer } = useSocialSection();

  return (
    <>
      {isUploading && <LoadingOverlay title="Posting Video" />}
      <div className={block('share-form')}>
        <SocialAccountInfo {...{ avatarSrc, onSwitchAccountClick, username }} />
        <YoutubeVideoTitleInput
          isMetadataEnabled={isMetadataEnabled}
          onChange={onTitleChange}
          readonly={readonly}
          value={title}
        />
        <YoutubeVideoDescriptionInput
          onUnlockAICaptions={onUnlockAICaptions}
          onCancelUnlockAICaptions={onCancelUnlockAICaptions}
          isMetadataEnabled={isMetadataEnabled}
          allowAiDisclaimer={allowAiDisclaimer}
          onChange={onDescriptionChange}
          readonly={readonly}
          value={description}
          showLabel={false}
        />
        <YoutubePlaylistSelector
          options={playlists}
          onCreatePlaylistClick={onCreatePlaylistClick}
          onChange={onPlaylistChange}
          value={selectedPlaylistsIds}
        />
        <AutomationPostModalAdditionalDetails onClick={onAdditionalStepClick} />
      </div>
    </>
  );
};

export default YoutubeManualPostComposer;
