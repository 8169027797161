import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isUndefined } from 'underscore';

import { getPresetMaxDurationKey } from 'blocks/DestinationPlatforms';
import { Navigator } from 'components/Wizard';
import {
  PODCAST_EPISODE_TITLE_PLACEHOLDER,
  PODCAST_NAME_PLACEHOLDER,
} from 'hooks/usePodcastData';
import { waitingForStandardizationSelector } from 'redux/modules/async-audio-clipper/selectors';
import { traceIntegratorNameSelector } from 'redux/modules/audio-wizard';
import { clearAudioWizard } from 'redux/modules/audio-wizard/actions';
import {
  allowAudioClippingSelector,
  audioUrlSelector,
  clipTitleSelector,
  colorSelector,
  entireAudioInstanceIdSelector,
  imageUrlSelector,
  isAudiogramSelector,
  transcriptionConfigSelector,
  transcriptUrlSelector,
} from 'redux/modules/audio-wizard/selectors';
import { getMyDisplayPref } from 'redux/modules/display-pref';
import { podcastEpisodesSelector } from 'redux/modules/entities';
import {
  clearMixpanel,
  onCancelWizard,
  onCompleteWizard,
  onStartAudioProject,
  onWizardNext,
} from 'redux/modules/mixpanel/actions';
import { getMixpanelBlurValue } from 'redux/modules/mixpanel/utils';
import { showError } from 'redux/modules/notification/actions';
import { goToCreate, goToProject } from 'redux/modules/router/actions';
import { getMyUserPref } from 'redux/modules/user-pref/actions';
import { exportFromWizard } from 'redux/modules/wizard-export/actions';
import { projectIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch, GetState, State, ThunkAction } from 'redux/types';
import { ImageOriginId, SoundwavePositionValue } from 'types';
import { isApplicationError } from 'utils/ApplicationError';
import { createClippingOption, getDuration } from 'utils/audio';
import { getValue } from 'utils/collections';
import { DEFAULT_PROJECT_NAME } from 'utils/constants';
import { getBlurRadius, hasSlideFromOrigin } from 'utils/embed/embed';
import {
  getPositionIdFromWave,
  mapSoundwaveGeneration,
} from 'utils/embed/soundwave';
import { FatalError } from 'utils/FatalError';
import { MIXPANEL_WIZARD_STEP_MAP } from '../constants';
import AudioWizard, { WizardNextStepMetadata } from './AudioWizard';

type AudioWizardProps = React.ComponentProps<typeof AudioWizard>;

export type StateProps = Pick<
  AudioWizardProps,
  | 'allowAudioClipping'
  | 'defaultImageSrc'
  | 'defaultProjectName'
  | 'defaultText'
  | 'defaultSoundwaveColor'
  | 'episodeTitle'
  | 'isAudiogram'
  | 'podcastId'
  | 'podcastTitle'
  | 'waitingForStandardization'
  | 'transcriptionConfig'
  | 'transcriptUrl'
> & {
  integratorName?: string;
};
export type DispatchProps = Pick<
  AudioWizardProps,
  | 'onSubmitClick'
  | 'onCancelClick'
  | 'onError'
  | 'onMount'
  | 'onStepChange'
  | 'onUnmount'
  | 'onComplete'
>;
type OwnProps = Pick<
  AudioWizardProps,
  'traceId' | 'customTraceId' | 'episodeId'
>;

const ERROR_ID = 'audio-wizard-error';

const getMixpanelProjectLengthName = isAudiogram =>
  isAudiogram ? 'short' : 'long';

const mixpanelProjectLengthSelector = createSelector(
  isAudiogramSelector,
  isAudiogram => (isAudiogram ? 'short' : 'long'),
);

const sendWizardStartEvent: ThunkAction<void> = (dispatch, getState) => {
  const length = mixpanelProjectLengthSelector(getState());
  dispatch(onStartAudioProject(length));
};

const onComplete = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());
  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const sendStepChangeEvent = (
  stepId: string,
  metadata?: WizardNextStepMetadata,
): ThunkAction<void> => dispatch => {
  const step = MIXPANEL_WIZARD_STEP_MAP[stepId];
  if (step) {
    dispatch(
      onWizardNext({
        step,
        type: 'audio',
        ...(stepId === 'clipAudio' && {
          destination: metadata?.socialPreset?.key ?? 'Custom',
          maxDuration: getPresetMaxDurationKey(metadata?.socialPreset?.key),
        }),
      }),
    );
  }
};

const podcastIdSelector = createSelector(
  podcastEpisodesSelector,
  (_, props: OwnProps) => props.episodeId,
  (episodes, episodeId) => {
    if (!episodes || !episodeId) {
      return undefined;
    }

    return episodes.getIn([episodeId, 'podcast', 'podcastId']);
  },
);

const defaultTextsSelectors = createSelector(
  clipTitleSelector,
  podcastEpisodesSelector,
  (_, props: OwnProps) => props.episodeId,
  (defaultText, episodes, episodeId) => {
    if (!episodes || !episodeId) {
      return {
        defaultText,
        episodeTitle: PODCAST_EPISODE_TITLE_PLACEHOLDER,
        podcastTitle: PODCAST_NAME_PLACEHOLDER,
      };
    }

    return {
      defaultText,
      episodeTitle: episodes.getIn([episodeId, 'title']),
      podcastTitle: episodes.getIn([episodeId, 'podcast', 'title']),
    };
  },
);

const mapStateToProps = (state: State, props: OwnProps): StateProps => ({
  allowAudioClipping: allowAudioClippingSelector(state),
  transcriptUrl: transcriptUrlSelector(state),
  defaultImageSrc: imageUrlSelector(state),
  defaultProjectName: clipTitleSelector(state) || DEFAULT_PROJECT_NAME,
  defaultSoundwaveColor: colorSelector(state),
  defaultText: clipTitleSelector(state),
  integratorName: traceIntegratorNameSelector(state),
  isAudiogram: isAudiogramSelector(state),
  podcastId: podcastIdSelector(state, props),
  waitingForStandardization: waitingForStandardizationSelector(state),
  transcriptionConfig: transcriptionConfigSelector(state),
  ...defaultTextsSelectors(state, props),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps => ({
  onCancelClick: (nav: Navigator) =>
    dispatch((__, getState) => {
      const length = mixpanelProjectLengthSelector(getState());
      dispatch(
        onCancelWizard('audio', MIXPANEL_WIZARD_STEP_MAP[nav.stepId], length),
      );
      dispatch(goToCreate());
    }),
  onError: error => {
    if (error instanceof FatalError) {
      dispatch(
        showError({
          message: 'There was an error creating your project',
          code: 'ER007',
          dismissAfterSec: 5,
          id: ERROR_ID,
        }),
      );
      dispatch(goToCreate());
    } else {
      const code = isApplicationError(error) ? error.code : null;
      dispatch(showError({ message: error.message, id: ERROR_ID, code }));
    }
  },
  onMount: () => {
    dispatch(sendWizardStartEvent);
    dispatch(clearMixpanel());
  },
  // onNextClick: nav => dispatch(sendStepChangeEvent(nav.stepId)),
  onSubmitClick: (type, config) =>
    dispatch(async (_, getState: GetState) => {
      const {
        audioClip,
        backgroundColor,
        isAudiogram,
        progress,
        slideshow,
        soundwave,
        templateId,
        textOverlays,
        transcription,
      } = config;

      // this data is queried for when the wizard loads, however the user might
      // not have been authenticated at that point
      await Promise.all([
        dispatch(getMyDisplayPref()),
        dispatch(getMyUserPref()),
      ]);

      const allowClipper = allowAudioClippingSelector(getState());
      const audioUrl = audioUrlSelector(getState());
      const transcriptUrl = transcriptUrlSelector(getState());
      const entireAudioInstanceId = entireAudioInstanceIdSelector(getState());

      const shouldTranscribe = !!transcription.transcribe;

      const originalAudioDuration = !isUndefined(audioClip)
        ? audioClip.originalDurationMillis
        : (await getDuration(audioUrl)) * 1000;

      const clippingOption = !isUndefined(audioClip)
        ? createClippingOption(audioClip)
        : undefined;

      const waveOptions = {
        waveColor: getValue(soundwave, 'waveColor'),
        waveGeneration: mapSoundwaveGeneration(
          getValue(soundwave, 'waveGeneration'),
        ),
        wavePosition: getPositionIdFromWave(
          soundwave,
        ) as SoundwavePositionValue,
        waveStyle: getValue(soundwave, 'waveType'),
        waveformPrefId: getValue(soundwave, 'waveformPrefId'),
      };

      dispatch(
        onCompleteWizard({
          backgroundColor,
          originalAudioDuration,
          templateId,
          addedImage: slideshow?.length > 0,
          addedText: textOverlays?.length > 0,
          audioSource: 'upload',
          submissionType: type,
          canvaImage: hasSlideFromOrigin(ImageOriginId.CANVA, config),
          enabledProgressBar: progress.enabled,
          length: getMixpanelProjectLengthName(isAudiogram),
          transcription: shouldTranscribe,
          type: 'audio',
          blurValue: getMixpanelBlurValue(getBlurRadius(config)),
          ...clippingOption,
          ...waveOptions,
        }),
      );

      try {
        await dispatch(
          exportFromWizard({
            ...config,
            audio: allowClipper
              ? {
                  entireAudioInstanceId,
                  ...audioClip,
                }
              : {
                  source: audioUrl,
                },
            initiateExport: type === 'export',
            projectCreatMethod: isAudiogram ? 'audio' : 'audioLong',
            soundwave: {
              waveGeneration: 'amplitudeBased',
              ...config.soundwave,
            },
            transcription: {
              ...transcription,
              transcriptUrl: shouldTranscribe ? transcriptUrl : undefined,
            },
            traceId: ownProps.traceId,
            customTraceId: ownProps.customTraceId,
          }),
        );
      } catch (err) {
        if (isApplicationError(err)) {
          dispatch(
            showError({
              message: err.message,
              code: err.code,
              dismissAfterSec: 5,
              id: ERROR_ID,
            }),
          );
        } else {
          dispatch(
            showError({
              message: 'There was an error creating your project',
              code: 'ER007',
              dismissAfterSec: 5,
              id: ERROR_ID,
            }),
          );
        }
        dispatch(goToCreate());
      }
    }),
  onStepChange: (step, _, metadata) => {
    dispatch(sendStepChangeEvent(step, metadata));
  },
  onUnmount: () => {
    dispatch(clearAudioWizard());
  },

  onComplete: () => {
    dispatch(onComplete());
  },
});

export default function(component: React.ComponentType<AudioWizardProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
