import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'underscore';
import MaxDurationShareButton, {
  MaxDurationShareButtonProps,
} from 'blocks/DownloadPage/components/MaxDurationShareButton';
import { TikTokButton, TikTokButtonProps } from 'components/SocialButton';
import TiktokPostVideoModal from 'containers/TiktokPostVideoModal';
import useTikTokAuthenticator from 'hooks/useTikTokAuthenticator';
import { videoIdSelector } from 'redux/modules/download';
import {
  postTiktokVideo,
  tiktokProviderUserIdSelector,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';
import { createChainedFunction } from 'utils/functions';
import { useSocialSection } from '../DownloadPageSocialSection/SocialSectionContext';
import useTiktokPostButtonDispatch from './useTiktokPostButtonDispatch';
import { MAX_VIDEO_DURATION_SEC } from './utils';

type PickedButtonProps = Pick<TikTokButtonProps, 'disabled'>;
type PickedMaxDurationButtonProps = Pick<
  MaxDurationShareButtonProps,
  'videoDurationSec'
>;

export interface TiktokPostButtonProps
  extends PickedButtonProps,
    PickedMaxDurationButtonProps {
  onAuthenticatedClick?: () => void;
}

const { useCallback, useState } = React;

const TiktokPostButton: React.FC<TiktokPostButtonProps> = ({
  disabled,
  videoDurationSec,
}) => {
  const dispatch = useDispatch();
  const { onClick } = useTiktokPostButtonDispatch();
  const providerUserId = useSelector(tiktokProviderUserIdSelector);
  const { activeModal, toggleModalVisibility } = useSocialSection();

  const [error, setError] = useState<string>(undefined);
  const [shouldReauthenticate, setShouldReauthenticate] = useState<boolean>(
    false,
  );

  const handleAuthSuccess = useCallback(() => {
    setError(undefined);
  }, [setError]);

  const handleModalHide = useCallback(() => {
    setError(undefined);
    toggleModalVisibility('tiktok', false);
  }, [toggleModalVisibility]);

  const handleError = useCallback(
    (err: Error) => {
      if (err instanceof FatalError) {
        setError('Error authenticating with TikTok');
        setShouldReauthenticate(true);
        toggleModalVisibility('tiktok', false);
      } else {
        setError(err.message);
        toggleModalVisibility('tiktok', true);
      }
    },
    [toggleModalVisibility],
  );

  const handleSubmit = useCallback(
    ({ tiktokUserId }) => {
      dispatch((_, getState) => {
        const videoId = videoIdSelector(getState());
        dispatch(
          postTiktokVideo(videoId, {
            tiktokUserId,
            providerUserId,
          }),
        );
      });
    },
    [dispatch, providerUserId],
  );

  const handleClick = useCallback(() => {
    toggleModalVisibility('tiktok', true);
  }, [toggleModalVisibility]);

  const { authenticating, withAuthentication } = useTikTokAuthenticator({
    onError: handleError,
    onAuthSuccess: handleAuthSuccess,
    force: shouldReauthenticate,
  });

  return (
    <>
      <MaxDurationShareButton
        maxVideoDurationSec={MAX_VIDEO_DURATION_SEC}
        platform="TikTok"
        videoDurationSec={videoDurationSec}
      >
        <TikTokButton
          disabled={disabled || authenticating}
          onClick={createChainedFunction(
            onClick,
            withAuthentication(handleClick),
          )}
          params={withAuthentication}
          size="small"
        />
      </MaxDurationShareButton>

      <TiktokPostVideoModal
        show={activeModal === 'tiktok'}
        onHide={handleModalHide}
        onSubmit={handleSubmit}
        error={error}
      />
    </>
  );
};

TiktokPostButton.defaultProps = {
  onAuthenticatedClick: noop,
};

export default TiktokPostButton;
