import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountSelect, { SWITCH_ACCOUNT_VALUE } from 'components/AccountSelect';
import {
  AppStoreIcon,
  ArrowRight,
  InstagramGradientSquareButton,
} from 'components/icons';
import Modal from 'components/Modal';
import { Overrides } from 'components/Select';
import { onInstagramPostModalLoad } from 'redux/modules/mixpanel';
import {
  instagramAccountsSelector,
  instagramSelectUser,
  instagramUserIdSelector,
} from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import AccountOption from './AccountOption';
import { InstagramAccountOption } from './types';
import { block } from './utils';

export interface SelectAccountStepProps {
  onSwitchAccountClick?: () => void;
}

const selectComponentOverrides: Overrides<InstagramAccountOption> = {
  control: {
    props: p => ({
      ...p,
      className: cn(p.className, block('select-account-step--control')),
    }),
  },
  option: {
    component: props => <AccountOption {...props} />,
  },
};

const SelectAccountStep: React.FC<SelectAccountStepProps> = ({
  onSwitchAccountClick,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const instagramAccounts = useSelector(instagramAccountsSelector);

  const accountsList = useMemo((): InstagramAccountOption[] => {
    return instagramAccounts.map(
      ({ username, profilePictureUrl, instagramId }) => ({
        label: username,
        logo: profilePictureUrl,
        value: instagramId,
      }),
    );
  }, [instagramAccounts]);

  const selectedUserId =
    useSelector(instagramUserIdSelector) || accountsList[0]?.value;

  const handleAccountSelect = useCallback(
    (newAccount: InstagramAccountOption): void => {
      if (newAccount.value === SWITCH_ACCOUNT_VALUE) {
        onSwitchAccountClick();
      } else {
        dispatch(instagramSelectUser(newAccount.value));
      }
    },
    [dispatch, onSwitchAccountClick],
  );

  useEffect(() => {
    dispatch(onInstagramPostModalLoad('AccountsExist'));
  }, [dispatch]);

  return (
    <Modal.Body className={block('select-account-step')}>
      <div className={block('select-account-step--icons')}>
        <div className={block('icon-headliner-container')}>
          <AppStoreIcon
            className={block('select-account-step--icon-headliner')}
          />
        </div>

        <ArrowRight
          className={block('select-account-step--arrow')}
          height={26}
        />

        <InstagramGradientSquareButton
          className={block('select-account-step--icon-instagram')}
          width="86"
          height="86"
        />
      </div>

      <p className={block('title')}>Share to Instagram</p>

      <p className={block('description')}>
        Select the location you would like to share to.
      </p>
      <AccountSelect
        options={accountsList}
        overrides={selectComponentOverrides}
        value={accountsList.find(({ value }) => value === selectedUserId)}
        className={block('select-account-step--accounts-list')}
        onChange={handleAccountSelect}
      />
    </Modal.Body>
  );
};

export default SelectAccountStep;
