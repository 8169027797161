import { DestinationSteps } from 'blocks/AutomationOptions/types';
import {
  DESTINATION_PRESETS,
  destinationConfigMap,
  PlatformVideoType,
} from 'blocks/DestinationPlatforms';
import {
  AudiogramDestinationConfig,
  AudiogramVideoTypeConfig,
  MultiPathAudiogramDestination,
  VideoTypes,
} from 'types';
import { getAudiogramWizardPath, getFullEpisodeWizardPath } from 'utils/routes';
import { millisToSec } from 'utils/time';

type VideoTypeConfig = Pick<
  AudiogramVideoTypeConfig,
  'destination' | 'autogramWizardSettings' | 'metadata'
>;

const facebookVideoTypeConfig: Record<
  Extract<PlatformVideoType, 'reel' | 'story' | 'post' | 'episode'>,
  VideoTypeConfig
> = {
  reel: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.facebook.reel.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.facebook.reel.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.facebook.reel.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.facebook.reel.durationMs),
      aspectRatioName: DESTINATION_PRESETS.facebook.reel.aspectRatio,
    },
  },
  story: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.facebook.story.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.facebook.story.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.facebook.story.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.facebook.story.durationMs),
      aspectRatioName: DESTINATION_PRESETS.facebook.story.aspectRatio,
    },
  },
  post: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.facebook.post.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.facebook.post.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.facebook.post.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.facebook.post.durationMs),
      aspectRatioName: DESTINATION_PRESETS.facebook.post.aspectRatio,
    },
  },
  episode: {
    destination: getFullEpisodeWizardPath({
      defaultAspectRatio: DESTINATION_PRESETS.facebook.episode.aspectRatio,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.FULL_EPISODE,
      clipDuration: null,
      aspectRatioName: DESTINATION_PRESETS.facebook.episode.aspectRatio,
    },
  },
};

const instagramVideoTypeConfig: Record<
  Extract<PlatformVideoType, 'reel' | 'story' | 'post'>,
  VideoTypeConfig
> = {
  reel: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.instagram.reel.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.instagram.reel.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.instagram.reel.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.instagram.reel.durationMs),
      aspectRatioName: DESTINATION_PRESETS.instagram.reel.aspectRatio,
    },
  },
  story: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.instagram.story.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.instagram.story.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.instagram.story.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.instagram.story.durationMs),
      aspectRatioName: DESTINATION_PRESETS.instagram.story.aspectRatio,
    },
  },
  post: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.instagram.post.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.instagram.post.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.instagram.post.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.instagram.post.durationMs),
      aspectRatioName: DESTINATION_PRESETS.instagram.post.aspectRatio,
    },
  },
};

const tiktokVideoTypeConfig: Record<
  Extract<PlatformVideoType, 'post' | 'story'>,
  VideoTypeConfig
> = {
  post: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.tiktok.post.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.tiktok.post.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.tiktok.post.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.tiktok.post.durationMs),
      aspectRatioName: DESTINATION_PRESETS.tiktok.post.aspectRatio,
    },
  },
  story: {
    destination: getAudiogramWizardPath({
      defaultClipDurationMillis: DESTINATION_PRESETS.tiktok.story.durationMs,
      defaultAspectRatio: DESTINATION_PRESETS.tiktok.story.aspectRatio,
      defaultPresetKey: DESTINATION_PRESETS.tiktok.story.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.tiktok.story.durationMs),
      aspectRatioName: DESTINATION_PRESETS.tiktok.story.aspectRatio,
    },
  },
};

const youtubeVideoTypeConfig: Record<
  Extract<PlatformVideoType, 'episode' | 'clip' | 'short'>,
  VideoTypeConfig
> = {
  episode: {
    destination: getFullEpisodeWizardPath({
      defaultAspectRatio: DESTINATION_PRESETS.youtube.episode.aspectRatio,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.FULL_EPISODE,
      clipDuration: null,
      aspectRatioName: DESTINATION_PRESETS.youtube.episode.aspectRatio,
    },
  },
  clip: {
    destination: getAudiogramWizardPath({
      defaultAspectRatio: DESTINATION_PRESETS.youtube.clip.aspectRatio,
      defaultClipDurationMillis: DESTINATION_PRESETS.youtube.clip.durationMs,
      defaultPresetKey: DESTINATION_PRESETS.youtube.clip.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.youtube.clip.durationMs),
      aspectRatioName: DESTINATION_PRESETS.youtube.clip.aspectRatio,
    },
  },
  short: {
    destination: getAudiogramWizardPath({
      defaultAspectRatio: DESTINATION_PRESETS.youtube.short.aspectRatio,
      defaultClipDurationMillis: DESTINATION_PRESETS.youtube.short.durationMs,
      defaultPresetKey: DESTINATION_PRESETS.youtube.short.key,
    }),
    autogramWizardSettings: {
      destination: DestinationSteps.AUTO_POSTING,
      videoType: VideoTypes.RANDOM_CLIP,
      clipDuration: millisToSec(DESTINATION_PRESETS.youtube.short.durationMs),
      aspectRatioName: DESTINATION_PRESETS.youtube.short.aspectRatio,
    },
  },
};

const { facebook, instagram, tiktok, youtube } = destinationConfigMap;

export const audiogramDestinationConfig: Record<
  Exclude<MultiPathAudiogramDestination, 'threads'>,
  AudiogramDestinationConfig
> = {
  facebook: {
    ...facebook,
    id: 'facebook',
    videoTypes: facebook.videoTypes.map(videoType => ({
      ...videoType,
      ...facebookVideoTypeConfig[videoType.id],
    })),
  },
  instagram: {
    ...instagram,
    id: 'instagram',
    videoTypes: instagram.videoTypes.map(videoType => ({
      ...videoType,
      ...instagramVideoTypeConfig[videoType.id],
    })),
  },
  tiktok: {
    ...tiktok,
    id: 'tiktok',
    videoTypes: tiktok.videoTypes.map(videoType => ({
      ...videoType,
      ...tiktokVideoTypeConfig[videoType.id],
    })),
  },
  youtube: {
    ...youtube,
    id: 'youtube',
    videoTypes: youtube.videoTypes.map(videoType => ({
      ...videoType,
      ...youtubeVideoTypeConfig[videoType.id],
    })),
  },
};
