import React from 'react';
import { Metadata } from 'components/Metadata';
import { ShareAICaptionsField } from 'components/ShareForm';
import { ShareFormTextareaProps } from 'components/ShareForm/ShareFormTextarea';
import { makeSocialPostLengthCounter } from 'utils/string';
import {
  block,
  MAX_CHARS_DESCRIPTION,
  MAX_CHARS_DESCRIPTION_EXTRA,
  videoDescriptionMetadata,
} from './utils';

export interface YoutubeVideoDescriptionInputProps
  extends Pick<ShareFormTextareaProps, 'onChange' | 'readonly' | 'value'> {
  isMetadataEnabled?: boolean;
  showLabel?: boolean;
  hideProgress?: boolean;
  allowAiDisclaimer?: boolean;
  placeholder?: string;
  onUnlockAICaptions?: () => void;
  onCancelUnlockAICaptions?: () => void;
}

const zeroSocialPostLengthCounter = makeSocialPostLengthCounter(0);

const YoutubeVideoDescriptionInput: React.FC<YoutubeVideoDescriptionInputProps> = ({
  isMetadataEnabled,
  showLabel = true,
  hideProgress,
  allowAiDisclaimer,
  placeholder,
  onChange,
  readonly,
  value,
  onUnlockAICaptions,
  onCancelUnlockAICaptions,
}) => {
  return (
    <>
      <ShareAICaptionsField
        {...{ allowAiDisclaimer, onUnlockAICaptions, onCancelUnlockAICaptions }}
        inputProps={{
          controlClassName: block('description-control'),
          hideProgress: isMetadataEnabled || readonly || hideProgress,
          label: showLabel ? 'Video description' : null,
          labelHint: isMetadataEnabled && '(up to 5000 characters)',
          maxChars: isMetadataEnabled
            ? MAX_CHARS_DESCRIPTION_EXTRA
            : MAX_CHARS_DESCRIPTION,
          countRemaining: zeroSocialPostLengthCounter,
          onChange,
          readonly,
          value,
          placeholder,
        }}
      />

      {isMetadataEnabled && (
        <Metadata
          fields={videoDescriptionMetadata}
          label="Add metadata (shows nothing if not found in feed):"
          tooltip="Add content from podcast feed into video description"
          onClick={metaValue => {
            onChange(`${value} ${metaValue}`);
          }}
        />
      )}
    </>
  );
};

export default YoutubeVideoDescriptionInput;
