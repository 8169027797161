import { fromJS } from 'immutable';
import { isNumber } from 'underscore';
import { TextShadowV2 } from 'components/TextToolbar';
import { AspectRatioName, DeepImmutableMap } from 'types';
import {
  getAspectRatioName,
  getAspectRatioNameFromRatio,
} from 'utils/aspect-ratio';
import { DEFAULT_TEXT_OVERLAY } from 'utils/constants';
import { extractStyle, htmlStringQueryAll } from 'utils/dom';
import { getFamilyName } from 'utils/fonts';
import measurement from 'utils/measurement';
import { round } from 'utils/numbers';
import { kebabToCamel } from 'utils/string';
import { parseTextShadowString } from 'utils/ui';

import { getViewportMap } from 'utils/viewport';
import { ITextOverlayV2, PaddingsBox, TextOverlayV2 } from './types';
import { DEFAULT_FONT_PX_RATIO_MAP } from './utils';

type OverlayValidator = (textOverlay: TextOverlayV2) => boolean;

type StyleTransformer<TStyle> = (
  val: string,
  textOverlay: TextOverlayV2,
) => TStyle;

const TARGET_SPAN_NODE_SELECTOR = 'span';
const TARGET_P_NODE_SELECTOR = 'p';

/**
 * As a way for checking if there are is no mixed rich text styles, the text
 * overlay string is checked for detecting if there is more than a single span
 * element.
 */
export const validateSingleSpan: OverlayValidator = (
  textOverlay: TextOverlayV2,
): boolean => {
  const baseHtml = `<div>${textOverlay.get('textHtml')}</div>`;
  const queryResult = htmlStringQueryAll(baseHtml, TARGET_SPAN_NODE_SELECTOR);

  return queryResult.length === 1;
};

/**
 * As a way for checking if there are is no mixed rich text styles, the text
 * overlay string is checked for detecting if there is more than a single p
 * element.
 */
export const validateSingleParagraph: OverlayValidator = (
  textOverlay: TextOverlayV2,
): boolean => {
  const baseHtml = `<div>${textOverlay.get('textHtml')}</div>`;
  const queryResult = htmlStringQueryAll(baseHtml, TARGET_P_NODE_SELECTOR);

  return queryResult.length === 1;
};

/**
 * This last validation adds a small overhead, but it attempts the upgrade of the overlay
 * if there is any issue that would prevent the overlay migration, it should be caught
 * by this verification.
 */
export const validateUpgrade: OverlayValidator = (textOverlay): boolean => {
  try {
    convertLegacyOverlay(textOverlay);
    return true;
  } catch {
    return false;
  }
};

const VALIDATORS: OverlayValidator[] = [
  validateSingleSpan,
  validateSingleParagraph,
  validateUpgrade,
];

/**
 * Runs a series of validators over the text overlay for checking if it is a candidate
 * for being used at the text overlay modal.
 */
export const isLegacyOverlayConvertAble = (
  textOverlay: TextOverlayV2,
): boolean => {
  try {
    return VALIDATORS.every(validator => validator(textOverlay));
  } catch (err) {
    return false;
  }
};

/**
 * Some styles require a transformation in order to be moved from the html style
 * to the editor modal text style state.
 */

/**
 * Font family required the "serif", "sansSerif", etc string to be removed from the
 * name for the font family picker to be able to pick the values.
 */
export const fontFamilyTransformer: StyleTransformer<string> = val => {
  return getFamilyName(val);
};

/**
 * Font size needs to be re-scaled from the px value saved at the html string to the
 * real pure editor font size. Given the viewport of the text overlay suffers a
 * normalization process that alters its dimensions, the font size needs to know those
 * normalization coefs in order to be adjusted to the text modal editor state.
 * While normalizing the box, a fontNormalizationCoef is saved. The mentioned coef
 * considers both a default viewport width percentage per font size px and the normalization
 * coef used for rescaling the viewport. The font size for the editor is then calculated
 * by multiplying the curr html string font px value * the coef * the width.
 */
export const fontSizeTransformer: StyleTransformer<number> = (
  val,
  textOverlay,
) => {
  const fontNormalizationCoef = textOverlay.getIn([
    'editor',
    'scaler',
    'fontNormalizationCoef',
  ]);
  const currViewportWidth = textOverlay.getIn(['viewport', 'width']);

  return Math.round(
    parseFloat(val) * fontNormalizationCoef * currViewportWidth,
  );
};

/**
 * Text shadow is expressed as a tring at the style string. The value is recovered by
 * transforming that string into a valid shadow object and later into a Immutable map.
 */
export const textShadowTransformer: StyleTransformer<DeepImmutableMap<
  TextShadowV2
>> = val => {
  return fromJS(parseTextShadowString(val));
};

/**
 * Line height can not be modified from within the text editor modal, for this
 * reason the overlay default value is used.
 */
export const lineHeightTransformer: StyleTransformer<number> = () => {
  return DEFAULT_TEXT_OVERLAY.getIn(['style', 'lineHeight']);
};

/**
 * Some ovelay's html can contain padding. As text padding is not supported
 * by the overlay modal, these padding values will be removed and set to 0.
 */
export const paddingTransformer: StyleTransformer<string | number> = () => {
  return 0;
};

const STYLE_TRANSFORMATIONS: Record<string, StyleTransformer<unknown>> = {
  fontFamily: fontFamilyTransformer,
  fontSize: fontSizeTransformer,
  lineHeight: lineHeightTransformer,
  paddingBottom: paddingTransformer,
  paddingLeft: paddingTransformer,
  paddingRight: paddingTransformer,
  paddingTop: paddingTransformer,
  textShadow: textShadowTransformer,
};

const STYLE_KEY_TRANSFORMS: Record<string, string> = {
  backgroundColor: 'textHighlight',
  textDecorationLine: 'textDecoration',
};

/**
 * The following keys and style map remove all styles that are assumed to be
 * non defined if they are not provided by the template html.
 */
const DEFAULT_STYLES_TO_CLEAR = ['fontWeight', 'textShadow', 'textHighlight'];

const DEFAULT_STYLES_MAP = DEFAULT_TEXT_OVERLAY.get('style').withMutations(
  s => {
    DEFAULT_STYLES_TO_CLEAR.forEach(styleKey => {
      s.set(styleKey, undefined);
    });
    s.set('textAlign', 'left');
    s.set('color', 'rgba(0, 0, 0, 1)');
  },
);

/**
 * Merges a given set of html extracted styles into a given style map for a text
 * overlay. Each style will be checked agains a given set of style transformations
 * and also key renamings in the necessary cases.
 */
const mergeHtmlStylesToOverlayStyle = (
  textOverlay: TextOverlayV2,
  styleMap: DeepImmutableMap<ITextOverlayV2<number, number>['style']>,
  htmlStyles: CSSStyleDeclaration,
): DeepImmutableMap<ITextOverlayV2<number, number>['style']> => {
  return styleMap.withMutations(s => {
    for (let i = 0; i < htmlStyles.length; i += 1) {
      const property = htmlStyles.item(i);
      const styleKey = kebabToCamel(property);

      const transformation = STYLE_TRANSFORMATIONS[styleKey];
      const styleValue = htmlStyles.getPropertyValue(property);

      if (STYLE_KEY_TRANSFORMS[styleKey]) {
        s.set(
          STYLE_KEY_TRANSFORMS[styleKey],
          transformation?.(styleValue, textOverlay) ?? styleValue,
        );
        s.delete(styleKey);
      } else {
        s.set(
          styleKey,
          transformation?.(styleValue, textOverlay) ?? styleValue,
        );
      }
    }
  });
};

/**
 * Composes a base style map for the overlay. It extracts the available styles at
 * the textHtml string for span elements and merges them into the base default overlay
 * styles.
 */
export const composeBaseSpanStyleMap = (
  textOverlay: TextOverlayV2,
): DeepImmutableMap<ITextOverlayV2<number, number>['style']> => {
  const textHtml = textOverlay.get('textHtml');

  const extractedSpanStyles: CSSStyleDeclaration = extractStyle(
    textHtml,
    TARGET_SPAN_NODE_SELECTOR,
  );
  const extractedParagraphStyles: CSSStyleDeclaration = extractStyle(
    textHtml,
    TARGET_P_NODE_SELECTOR,
  );

  return DEFAULT_STYLES_MAP.withMutations(s => {
    mergeHtmlStylesToOverlayStyle(textOverlay, s, extractedParagraphStyles);
    mergeHtmlStylesToOverlayStyle(textOverlay, s, extractedSpanStyles);
  });
};

const BOX_ADJUSTER_KEY_MAP = {
  paddingTop: {
    updates: [
      { path: ['position', 'top'], modifier: 1 },
      { path: ['size', 'height'], modifier: -1 },
    ],
    viewportDim: 'height',
  },
  paddingRight: {
    updates: [{ path: ['size', 'width'], modifier: -1 }],
    viewportDim: 'width',
  },
  paddingBottom: {
    updates: [{ path: ['size', 'height'], modifier: -1 }],
    viewportDim: 'height',
  },
  paddingLeft: {
    updates: [
      { path: ['position', 'left'], modifier: 1 },
      { path: ['size', 'width'], modifier: -1 },
    ],
    viewportDim: 'width',
  },
};

/**
 * Applies a paddings box expressed in vw to the text overlay box size and position.
 * This util was extracted from adjustBoxSize for being used with different ways
 * for generating the paddings.
 */
export const applyPaddingBoxTransformation = (
  textOverlay: TextOverlayV2,
  paddings: PaddingsBox,
): TextOverlayV2 => {
  return textOverlay.withMutations(s => {
    Object.entries(BOX_ADJUSTER_KEY_MAP).forEach(
      ([paddingKey, { updates, viewportDim }]) => {
        updates.forEach(({ path, modifier }) => {
          const currValue = s.getIn(path);
          const dim = s.getIn(['viewport', viewportDim]);
          s.setIn(
            path,
            currValue + ((dim * paddings[paddingKey]) / 100) * modifier,
          );
        });
      },
    );
  });
};

/**
 * Fix for https://sparemin.atlassian.net/browse/SPAR-23708
 * There was a missing use case where the user can export all the captions to text overlay.
 * For some reason this feature sets all the padding values to "undefinedvw" which produces
 * the numeric padding value to be set to NaN which produces the box boundaries and position
 * to get broken when adjusting the values.
 */
export const getPaddingValue = (paddingValue: number): number =>
  !isNaN(paddingValue) && isNumber(paddingValue) ? paddingValue : 0;

/**
 * As some templates have padding applied to the textbox, this transformation will
 * adjust the box size for simulating that padding adjusment.
 * Each padding transformation is based on a viewport dimension (height/width) and
 * it is meant to be applied to a given path at position or size. At the same time,
 * depending on the type of tranformation it is necessary to use a negative modifier
 * for the cases in which the size is being modified.
 * All transformations are stated at BOX_ADJUSTER_KEY_MAP
 */
export const adjustBoxSize = (textOverlay: TextOverlayV2): TextOverlayV2 => {
  const paddings = {
    paddingTop: getPaddingValue(textOverlay.getIn(['style', 'paddingTop'])),
    paddingRight: getPaddingValue(textOverlay.getIn(['style', 'paddingRight'])),
    paddingBottom: getPaddingValue(
      textOverlay.getIn(['style', 'paddingBottom']),
    ),
    paddingLeft: getPaddingValue(textOverlay.getIn(['style', 'paddingLeft'])),
  };

  return applyPaddingBoxTransformation(textOverlay, paddings);
};

/**
 * Caculates the scaler for the upgraded overlay based over the already parsed
 * styles for upgraded embed.
 */
export const calculateScaler = (textOverlay: TextOverlayV2): TextOverlayV2 => {
  const fontSizePx = textOverlay.getIn(['style', 'fontSize']);
  const fontSizeVw = measurement(fontSizePx, 'px').toUnit(
    'vw',
    textOverlay.getIn(['viewport', 'width']),
  ).value;
  const fontWidthPercPerPx = fontSizeVw / fontSizePx / 100;

  return textOverlay.withMutations(s =>
    s
      .setIn(['editor', 'scaler', 'fontSizeVw'], fontSizeVw)
      .setIn(['editor', 'scaler', 'fontWidthPercPerPx'], fontWidthPercPerPx),
  );
};

/**
 * Obtains the aspect ratio for a given overlay configuration.
 * There some cases where the aspect ratio name can not be obtained
 * without rounding the viewport ratio calculation.
 * For those cases, the viewport ratio calculation is rounded to
 * 4 decimal digits and the aspect ratio obtained again. If after this
 * the aspect ratio name is still not defined, it will be defaulted to
 * square for avoiding crashes
 */
export const getOverlayAspectRatioName = (
  textOverlay: TextOverlayV2,
): AspectRatioName => {
  let aspectRatioName: AspectRatioName = getAspectRatioName(
    textOverlay.getIn(['viewport', 'height']),
    textOverlay.getIn(['viewport', 'width']),
  );

  if (!aspectRatioName && textOverlay.getIn(['viewport', 'height'])) {
    const ratio =
      textOverlay.getIn(['viewport', 'width']) /
      textOverlay.getIn(['viewport', 'height']);
    aspectRatioName = getAspectRatioNameFromRatio(round(ratio, -4)) ?? 'square';
  }

  return aspectRatioName;
};

/**
 * This prevents font scaling errors based on the initial viewport dimensions
 * based on the current user screen dimension. There are 2 basic problems that
 * forces this normalization to be necessary:
 * - The UCS (which the current only client for this modal) forces the font size
 * in px by adusting the font size for making the text content fit the text box.
 * That size is biased and dependant on a given viewport without scalers that
 * allow rehidrate the font size. At least not without losing the current scale
 * relation.
 * - On the other side, the font size vw can be obtained when parsing the overlay
 * config. This is useful but given the editor context of the template overlay is
 * not always known (specially in prod), the obvious calculation: fontSizeVw *
 * vieportWidth is not accurate either.
 *
 * As there are no scalers that allow going from the adjusted font size in px for
 * the text overlay into the modal editor's fixed value, the scalers are
 * regenerated.
 * The first step is to get a default viewport map, there is also a default px
 * scaler coefs that for each aspect ratio.
 * The overlay viewport is updated to match one of the default ones. During that
 * process one normalization coef is obtained for each dimension. Using the aspect ratio,
 * a px scaler for font is picked too.
 * As a result of the normalization process, the size and position of the text
 * box is adjusted as well as the new viewport is saved. Finally, a font scaling
 * coef is saved at a temporal normalizers object at the editor scalers object.
 */
export const normalizeViewport = (
  textOverlay: TextOverlayV2,
): TextOverlayV2 => {
  const aspectRatioName = getOverlayAspectRatioName(textOverlay);
  const viewportMap = getViewportMap(aspectRatioName);

  const horizontalNormalizeCoef =
    textOverlay.getIn(['viewport', 'width']) / viewportMap.get('width');
  const verticalNormalizeCoef =
    textOverlay.getIn(['viewport', 'height']) / viewportMap.get('height');

  const fontSizePxToWidthCoef = DEFAULT_FONT_PX_RATIO_MAP[aspectRatioName];
  const fontNormalizationCoef = fontSizePxToWidthCoef / horizontalNormalizeCoef;

  return textOverlay.withMutations(s =>
    s
      .setIn(
        ['size', 'width'],
        s.getIn(['size', 'width']) / horizontalNormalizeCoef,
      )
      .setIn(
        ['size', 'height'],
        s.getIn(['size', 'height']) / verticalNormalizeCoef,
      )
      .setIn(
        ['position', 'left'],
        s.getIn(['position', 'left']) / horizontalNormalizeCoef,
      )
      .setIn(
        ['position', 'top'],
        s.getIn(['position', 'top']) / verticalNormalizeCoef,
      )
      .set('viewport', viewportMap)
      .setIn(
        ['editor', 'scaler', 'fontNormalizationCoef'],
        fontNormalizationCoef,
      ),
  );
};

// Converts an already validated legacy text overlay into a v2 ready base overlay.
export const convertLegacyOverlay = (
  textOverlay: TextOverlayV2,
): TextOverlayV2 => {
  const normalizedBoxOverlay = normalizeViewport(textOverlay);
  const boxSizeAdjustedOverlay = adjustBoxSize(normalizedBoxOverlay);

  return calculateScaler(
    boxSizeAdjustedOverlay.withMutations(s => {
      s.set('style', composeBaseSpanStyleMap(s));
    }),
  );
};
