import React, { useEffect, useMemo } from 'react';
import { Flipped } from 'react-flip-toolkit';
import AudioClipCard from 'components/AudioClipCard/AudioClipCard';

import { selectorBlock as block } from '../../utils';
import { useClipSelectAnimation } from '../ClipSelectAnimationContext';
import { useClipSelect } from '../ClipSelectContext';
import { DEFAULT_LOADING_CLIP_SUGGESTION_TRANSCRIPT_MESSAGE } from './constants';

export interface AudioClipSuggestionProps {
  active?: boolean;
  id: number;
}

const AudioClipSuggestion: React.FC<AudioClipSuggestionProps> = ({
  active,
  id,
}) => {
  const {
    dislikedSuggestionIds,
    onSuggestionSelect,
    togglePlayback,
    playing,
    selectedRegion,
    suggestedClips,
  } = useClipSelect();

  const suggestionIndex = useMemo(
    (): number => suggestedClips.findIndex(suggestion => suggestion.id === id),
    [id, suggestedClips],
  );

  const { endMillis, startMillis, text, status, videoUrl } =
    suggestedClips?.[suggestionIndex] || {};

  const { createSuggestionCardAnimationConfig } = useClipSelectAnimation();

  const isLoadingClipSuggestion = status !== 'completed';

  useEffect(() => {
    if (
      active &&
      (!selectedRegion ||
        selectedRegion.startMillis !== startMillis ||
        selectedRegion.endMillis !== endMillis)
    ) {
      onSuggestionSelect({ startMillis, endMillis });
    }
  }, [active, endMillis, onSuggestionSelect, selectedRegion, startMillis]);

  const disliked = dislikedSuggestionIds.includes(id);

  const {
    sizer,
    sizerInverter,
    vanisher,
  } = createSuggestionCardAnimationConfig(id);
  const { className: sizerClassName, ...sizerFlippedProps } = sizer;
  const { className: inverterClassName, ...inverterProps } = sizerInverter;
  const { className: vanisherClassName, ...vanisherFlippedProps } = vanisher;

  return (
    <Flipped {...sizerFlippedProps}>
      <div className={sizerClassName}>
        <Flipped {...inverterProps}>
          <div style={{ position: 'relative' }}>
            {!disliked && (
              <Flipped {...vanisherFlippedProps}>
                <div className={vanisherClassName}>
                  <AudioClipCard
                    className={block('suggestion', { active })}
                    clipEndMillis={endMillis}
                    clipIndex={suggestionIndex}
                    clipStartMillis={startMillis}
                    onClickControlButton={togglePlayback}
                    playable={active}
                    playing={active && playing}
                    id={id}
                    text={
                      isLoadingClipSuggestion && !text
                        ? DEFAULT_LOADING_CLIP_SUGGESTION_TRANSCRIPT_MESSAGE
                        : text
                    }
                    totalClips={suggestedClips.length}
                    isLoading={isLoadingClipSuggestion}
                    videoUrl={videoUrl}
                  />
                </div>
              </Flipped>
            )}
          </div>
        </Flipped>
      </div>
    </Flipped>
  );
};

export default AudioClipSuggestion;
