import React from 'react';

import {
  DESTINATION_PRESETS,
  DestinationConfig,
} from 'blocks/DestinationPlatforms';
import { InstagramReels, InstagramStories, PlusBold } from 'components/icons';
import { InstagramSocialCircle } from 'components/SocialCircle';

import VideoTypeIcon from '../VideoTypeIcon';

const INSTAGRAM_GRADIENT =
  'linear-gradient(171deg, #6c14c6 6%, #f1532e 60%, #f8c45c 95%)';

const instagram: DestinationConfig = {
  id: 'instagram',
  icon: <InstagramSocialCircle />,
  videoTypes: [
    {
      id: 'reel',
      title: 'Instagram Reel',
      description: 'Share a short clip to go viral',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.instagram.reel.aspectRatio}
          background={INSTAGRAM_GRADIENT}
        >
          <InstagramReels width="15px" color="white" />
        </VideoTypeIcon>
      ),
    },
    {
      id: 'story',
      title: 'Instagram Story',
      description: 'Share an ephemeral clip for 24 hours',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.instagram.story.aspectRatio}
          background={INSTAGRAM_GRADIENT}
        >
          <InstagramStories width="15px" color="white" />
        </VideoTypeIcon>
      ),
    },
    {
      id: 'post',
      title: 'Instagram Post',
      description: 'Clip a full segment from an episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.instagram.post.aspectRatio}
          background={INSTAGRAM_GRADIENT}
        >
          <PlusBold width="13px" color="white" />
        </VideoTypeIcon>
      ),
    },
  ],
};

export default instagram;
