import {
  createSubscriptionTypes as mapSubscriptionItemToType,
  PodcastSubscriptionType,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';

import {
  AudiogramDestination,
  AutogramClipSelectionMethod,
  AutogramSubscriptionOptions,
  AutogramVideoType,
  AutoPostPlatform,
  VideoTypes,
  YouTubeVideoPostType,
} from 'types';

function getVideoPostType(
  platform: AudiogramDestination,
  videoType: AutogramVideoType,
): YouTubeVideoPostType | undefined {
  if (platform !== 'youtube') {
    return undefined;
  }

  return videoType === VideoTypes.FULL_EPISODE
    ? 'youtubeDefault'
    : 'youtubeShorts';
}

export function checkIsAutoPostPlatform(platform: AutoPostPlatform): boolean {
  return [
    'youtube',
    'tiktok',
    'facebook',
    'linkedin',
    'instagram',
    'twitter',
    'threads',
  ].includes(platform);
}

/**
 * Takes the subscription options output from the Autogrma wizard and formats
 * them for submission to the API
 */
export function createSubscriptionTypes(
  options: AutogramSubscriptionOptions,
): PodcastSubscriptionType[] {
  const {
    autopost,
    clipSelectionMethod,
    durationSeconds,
    frequency,
    targetedSeasons,
    isCaptionEnabled,
    videoType,
  } = options;

  const commonProperties = {
    videoType,
    videoFrequency: frequency,
    targetedSeasons,
  };

  // If the user opted to use soundbite tags, then duration is dictated by
  // the timecodes in the tag.
  const optionalProperties: Partial<PodcastSubscriptionType> =
    clipSelectionMethod === AutogramClipSelectionMethod.SOUNDBITE_TAG
      ? undefined
      : { videoLengthSec: durationSeconds };

  if (
    videoType === VideoTypes.FULL_EPISODE ||
    checkIsAutoPostPlatform(autopost?.platform)
  ) {
    const {
      defaultLanguage,
      madeForKids,
      categoryId,
      platform,
      playlists,
      visibility,
      googleId,
      title = '',
      description = '',
      tags = [],
      tiktokUserId,
      tiktokPrivacyLevel,
      commentEnabled,
      duetEnabled,
      stitchEnabled,
      brandContentToggle,
      brandOrganicToggle,
      instagramUserId,
      instagramMediaType,
      linkedinUserId,
      linkedinAuthorId,
      postType: selectedPostType,
      facebookPageId,
      facebookId,
      assetInfo,
      twitterUserId,
      threadsUserId,
    } = autopost;
    const postType = selectedPostType ?? getVideoPostType(platform, videoType);

    const autopostOptions: PodcastSubscriptionType =
      platform === undefined
        ? undefined
        : {
            autoPostVideoPreference: {
              platform,
              options: {
                defaultLanguage,
                description,
                googleId,
                madeForKids,
                categoryId,
                playlists,
                tags,
                title,
                privacyStatus: visibility,
                postType,
                tiktokUserId,
                tiktokPrivacyLevel,
                commentEnabled,
                duetEnabled,
                stitchEnabled,
                brandContentToggle,
                brandOrganicToggle,
                instagramUserId,
                instagramMediaType,
                facebookId,
                linkedinUserId,
                linkedinAuthorId,
                facebookPageId,
                assetInfo,
                twitterUserId,
                threadsUserId,
                ...(linkedinUserId && { visibility }),
              },
            },
          };

    return [
      {
        ...commonProperties,
        ...autopostOptions,
        ...optionalProperties,
        isCaptionEnabled,
      },
    ];
  }

  return [
    {
      ...commonProperties,
      ...optionalProperties,
      isCaptionEnabled,
      clipSelectionMethod,
    },
  ];
}

export const mapSubscriptionItemToSubscriptionTypes = (
  subscriptionItems?: SubscriptionItem[],
) =>
  subscriptionItems?.reduce(
    (acc, curr) => [...acc, ...mapSubscriptionItemToType(curr)],
    [],
  ) ?? [];
