import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasNotificationAlertSelector } from 'redux/modules/entities/selectors';
import { getNotificationAlert } from 'redux/modules/notification-alert';

interface UseNotificationAlert {
  hasNewData: boolean;
}

const useNotificationAlert = (): UseNotificationAlert => {
  const dispatch = useDispatch();
  const hasNewData = useSelector(hasNotificationAlertSelector);

  React.useEffect(() => {
    dispatch(getNotificationAlert());
  }, [dispatch]);

  return { hasNewData };
};

export default useNotificationAlert;
