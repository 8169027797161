import React, { useMemo } from 'react';
import SocialSectionContext from './SocialSectionContext';
import { SocialSectionProviderProps } from './types';

const SocialSectionProvider: React.FC<SocialSectionProviderProps> = ({
  activeModal,
  allowAiDisclaimer,
  toggleModalVisibility,
  onUnlockAICaptions,
  onCancelUnlockAICaptions,
  children,
}) => {
  return (
    <SocialSectionContext.Provider
      value={useMemo(
        () => ({
          activeModal,
          allowAiDisclaimer,
          toggleModalVisibility,
          onUnlockAICaptions,
          onCancelUnlockAICaptions,
        }),
        [
          activeModal,
          allowAiDisclaimer,
          onCancelUnlockAICaptions,
          onUnlockAICaptions,
          toggleModalVisibility,
        ],
      )}
    >
      {children}
    </SocialSectionContext.Provider>
  );
};

export default SocialSectionProvider;
