import React from 'react';

import {
  DESTINATION_PRESETS,
  DestinationConfig,
} from 'blocks/DestinationPlatforms';
import { TiktokLogo } from 'components/icons';
import { TikTokSocialCircle } from 'components/SocialCircle';

import VideoTypeIcon from '../VideoTypeIcon';

const tiktok: DestinationConfig = {
  id: 'tiktok',
  icon: <TikTokSocialCircle />,
  videoTypes: [
    {
      id: 'post',
      title: 'TikTok Post',
      description: 'Share a short clip to go viral',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.tiktok.post.aspectRatio}
          background="#000"
        >
          <TiktokLogo width="13px" />
        </VideoTypeIcon>
      ),
    },
    {
      id: 'story',
      title: 'TikTok Story',
      description: 'Share an ephemeral clip for 24 hours',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.tiktok.story.aspectRatio}
          background="#000"
        >
          <TiktokLogo width="13px" />
        </VideoTypeIcon>
      ),
    },
  ],
};

export default tiktok;
