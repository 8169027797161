import React from 'react';
import { NO_SELECTION_VALUE } from 'containers/YoutubePostModal/constants';
import YoutubeSelect from 'containers/YoutubePostModal/YoutubeSelect';
import { AssetType } from 'redux/middleware/api/podcast-service';
import { block } from '../utils';
import { assetType, NO_SELECTION_OPTION } from './constants';
import { AssetTypeOption } from './types';

type AssetTypeSelectorProps = {
  onChange: (assetType: AssetType) => void;
  value?: string;
  disabled?: boolean;
};

const AssetTypeSelector: React.FC<AssetTypeSelectorProps> = ({
  onChange,
  value,
  disabled = false,
}) => {
  const getSelectedOptionValue = (
    option: AssetTypeOption,
  ): string | undefined => {
    // "none" is an artificial value that maps to undefined from the parent.
    // If the value is picked at the selector, the output value is set to undefined.
    if (option.value === NO_SELECTION_VALUE) {
      return undefined;
    }

    return String(option.value);
  };

  const handleSelectValue = React.useCallback(
    (option: AssetTypeOption) => {
      const nextValue = getSelectedOptionValue(option);
      onChange(nextValue as AssetType | undefined);
    },
    [onChange],
  );

  const getAssetOption = (
    options: AssetTypeOption[],
    val?: string,
  ): AssetTypeOption => {
    return options.find(opt => opt.value === val) ?? NO_SELECTION_OPTION;
  };

  return (
    <YoutubeSelect
      label="Asset Type"
      options={assetType}
      onChange={handleSelectValue}
      value={getAssetOption(assetType, value)}
      disabled={disabled}
      className={disabled ? block('disabled') : undefined}
    />
  );
};
export default AssetTypeSelector;
