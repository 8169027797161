import { Record, RecordOf } from 'immutable';
import {
  YoutubeCategories,
  YoutubeLanguage,
} from 'redux/middleware/api/podcast-service/types';
import { YoutubePlaylist } from 'redux/middleware/api/third-party-authentication-service/types';

import { Action } from 'redux/types';
import { RequestStatus } from 'types';
import { PostStatus } from '../constants';
import { Type } from './action-types';

export interface GooglePostMessageData {
  tokenInfo: {
    accessToken: string;
    idToken: string;
    provider: 'youtube';
    providerUserId: string;
  };
}

export interface YoutubeChannelInfo {
  postStatus?: RequestStatus;
  providerUserId?: string;
  id?: string;
  title?: string;
  isChannelMonetizationEnabled?: boolean;
}

export type YoutubeChannelInfoState = RecordOf<YoutubeChannelInfo>;

export type YouTubeAuthData = Pick<
  GooglePostMessageData['tokenInfo'],
  'accessToken' | 'idToken' | 'providerUserId'
>;

export type PlaylistRequestStatus =
  | 'idle'
  | 'pending'
  | 'resolved'
  | 'rejected';

interface State extends YouTubeAuthData {
  accessToken: string;
  channelInfo: YoutubeChannelInfoState;
  createPlaylistStatus: RequestStatus | undefined;
  lastCreatedPlaylistId: string | undefined;
  postStatus: PostStatus;
  profileImageUrl: string;
  socialShareId: number;
  username: string;
  playlists?: {
    status: PlaylistRequestStatus;
    data: YoutubePlaylist[];
  };
  categories?: {
    data: YoutubeCategories[];
  };
}

export type YoutubeState = RecordOf<State>;

export const youtubeChannelInfoFactory = Record<YoutubeChannelInfo>({
  postStatus: undefined,
  providerUserId: undefined,
  id: undefined,
  title: undefined,
  isChannelMonetizationEnabled: undefined,
});

export const youtubeStateFactory = Record<State>({
  accessToken: undefined,
  channelInfo: youtubeChannelInfoFactory(),
  createPlaylistStatus: undefined,
  idToken: undefined,
  lastCreatedPlaylistId: undefined,
  postStatus: undefined,
  profileImageUrl: undefined,
  providerUserId: undefined,
  socialShareId: undefined,
  username: undefined,
  playlists: {
    status: 'idle',
    data: [],
  },
  categories: {
    data: [],
  },
});

type GetYoutubeLanguagesRequestAction = Action<Type.YOUTUBE_LANGUAGES_REQUEST>;
type GetYoutubeLanguagesFailureAction = Action<Type.YOUTUBE_LANGUAGES_FAILURE>;
type GetYoutubeLanguagesSuccessAction = Action<
  Type.YOUTUBE_LANGUAGES_SUCCESS,
  {
    languages: YoutubeLanguage[];
  }
>;

type GetYoutubeUserRequestAction = Action<Type.YOUTUBE_USER_DATA_GET_REQUEST>;
type GetYoutubeUserSuccessAction = Action<
  Type.YOUTUBE_USER_DATA_GET_SUCCESS,
  {
    username: string;
    profileImageUrl: string;
  }
>;
type GetYoutubeUserFailureAction = Action<Type.YOUTUBE_USER_DATA_GET_FAILURE>;

type ClearYoutubeUserAction = Action<Type.YOUTUBE_USER_DATA_CLEAR>;

type PostVideoRequestAction = Action<Type.YOUTUBE_VIDEO_POST_REQUEST>;
type PostVideoFailureAction = Action<Type.YOUTUBE_VIDEO_POST_FAILURE>;
type PostVideoSuccessAction = Action<
  Type.YOUTUBE_VIDEO_POST_SUCCESS,
  {
    socialShareId: number;
  }
>;

type GetYoutubeChannelsRequest = Action<Type.YOUTUBE_CHANNEL_GET_REQUEST>;
type GetYoutubeChannelsFailure = Action<Type.YOUTUBE_CHANNEL_GET_FAILURE>;
type GetYoutubeChannelsSuccess = Action<
  Type.YOUTUBE_CHANNEL_GET_SUCCESS,
  {
    id: string;
    providerUserId: string;
    title: string;
    isChannelMonetizationEnabled: boolean;
  }
>;

type AwaitPostRequestAction = Action<Type.YOUTUBE_VIDEO_POST_AWAIT_REQUEST>;
type AwaitPostSuccessAction = Action<Type.YOUTUBE_VIDEO_POST_AWAIT_SUCCESS>;
type AwaitPostFailurection = Action<Type.YOUTUBE_VIDEO_POST_AWAIT_FAILURE>;

export type AuthorizeSuccessAction = Action<
  Type.YOUTUBE_AUTHORIZE_SUCCESS,
  YouTubeAuthData
>;

type GetYoutubePlaylistsRequestAction = Action<Type.PLAYLISTS_GET_REQUEST>;
type GetYoutubePlaylistsSuccessAction = Action<
  Type.PLAYLISTS_GET_SUCCESS,
  YoutubePlaylist[]
>;
type GetYoutubePlaylistsFailureAction = Action<Type.PLAYLISTS_GET_FAILURE>;

type GetYoutubeCategoriesSuccessAction = Action<
  Type.CATEGORIES_GET_SUCCESS,
  YoutubeCategories[]
>;
type GetYoutubeCategoriesFailureAction = Action<Type.CATEGORIES_GET_FAILURE>;

type CreateYoutubePlaylistsRequestAction = Action<Type.PLAYLIST_CREATE_REQUEST>;
type CreateYoutubePlaylistsSuccessAction = Action<
  Type.PLAYLIST_CREATE_SUCCESS,
  { playlistId: string }
>;
type CreateYoutubePlaylistsFailureAction = Action<Type.PLAYLIST_CREATE_FAILURE>;

export type YoutubeAction =
  | GetYoutubeLanguagesRequestAction
  | GetYoutubeLanguagesFailureAction
  | GetYoutubeLanguagesSuccessAction
  | GetYoutubeUserRequestAction
  | GetYoutubeUserSuccessAction
  | GetYoutubeUserFailureAction
  | GetYoutubeChannelsRequest
  | GetYoutubeChannelsFailure
  | GetYoutubeChannelsSuccess
  | ClearYoutubeUserAction
  | PostVideoRequestAction
  | PostVideoSuccessAction
  | PostVideoFailureAction
  | AuthorizeSuccessAction
  | AwaitPostRequestAction
  | AwaitPostSuccessAction
  | AwaitPostFailurection
  | GetYoutubePlaylistsRequestAction
  | GetYoutubePlaylistsSuccessAction
  | GetYoutubePlaylistsFailureAction
  | GetYoutubeCategoriesSuccessAction
  | GetYoutubeCategoriesFailureAction
  | CreateYoutubePlaylistsRequestAction
  | CreateYoutubePlaylistsSuccessAction
  | CreateYoutubePlaylistsFailureAction;
