import { IApiAction, IApiResponse } from '../types';

type ActionKey = 'MEDIA_UPLOAD_SERVICE';
export const ACTION_KEY: ActionKey = 'MEDIA_UPLOAD_SERVICE';

export enum ServiceMethod {
  UPLOAD_TEXT = 'UPLOAD_TEXT',
  UPLOAD_VIDEO = 'UPLOAD_VIDEO',
  DIRECT_UPLOAD = 'DIRECT_UPLOAD',
  GET_VIDEO = 'GET_VIDEO',
  SEARCH_VIDEO = 'SEARCH_VIDEO',
  GET_TEXT = 'GET_TEXT',
  CONVERT_GIF_TO_VIDEO = 'CONVERT_GIF_TO_VIDEO',
  PARSE_EXTERNAL_CONTENT = 'PARSE_EXTERNAL_CONTENT',
  GET_MEDIA_CONTENT_INFO = 'GET_MEDIA_CONTENT_INFO',
}

export type Source = File | string;

export interface UploadVideoOptions {
  trimStart?: number;
  trimEnd?: number;
  transcribe?: boolean;
  language?: string;
  directUploadBucket?: string;
  directUploadKey?: string;
  initiateProcessing?: boolean;
  providerUserId?: string;
  googleDriveFileId?: string;
  zoomMeetingId?: string;
  zoomRecordingFileId?: string;
  youtubeUrl?: string;
}

export interface UploadVideoToStorageOptions {
  src: File | string;
  trimStartMillis: number;
  trimEndMillis: number;
  language: string;
  initiateProcessing?: boolean;
}

type VideoUploadStatus =
  | 'requestReceived'
  | 'originalUploaded'
  | 'transcodeInProgress'
  | 'completed';

type AudioExtractStatus =
  | 'submitted'
  | 'processing'
  | 'completed'
  | 'failed'
  | 'audioStreamNotFound';

type TextUploadStatus = 'uploading' | 'completed' | 'failed';

export type TranscriptionStatus =
  | 'notSubmitted'
  | 'inProgress'
  | 'completed'
  | 'failed'
  | 'failureAcked';

export interface IVideoUpload {
  id: number;
  acceptableFinalDurationSecs: number;
  isResolved: boolean;
  status: VideoUploadStatus;
  transcodedVideoUrl?: string;
  uploaderId?: number;
  videoWidth?: number;
  videoHeight?: number;
  durationMillis: number;
  audioExtract?: {
    id: number;
    url: string;
    status: AudioExtractStatus;
    isResolved: boolean;
  };
  previewThumbnail: {
    thumbnails: Array<{
      id: number;
      url: string;
      width: number;
      height: number;
      type: 'uploaded' | 'firstFrame' | 'firstFrameMedium';
      status: 'completed' | 'failed';
      isResolved: boolean;
    }>;
  };
  variations: VideoVariation[];
}

export interface ITextUpload {
  textBlobId: string;
  isResolved: string;
  status: TextUploadStatus;
  transcript?: {
    status: TranscriptionStatus;
    isResolved: boolean;
    url: string;
  };
}

export interface INormalizedVideoUpload {
  entities: {
    videos: {
      [id: number]: IVideoUpload;
    };
  };
  result: number;
}

export interface INormalizedTextUpload {
  entities: {
    text: {
      [id: string]: ITextUpload;
    };
  };
  result: string;
}

export type UploadTextArgs = [string, string];
export type UploadTextAction = ApiAction<
  ServiceMethod.UPLOAD_TEXT,
  UploadTextArgs
>;

export type UploadVideoArgs = [
  Source,
  boolean,
  number,
  number,
  string,
  string,
  string,
  boolean,
  string,
  string,
  string,
  string,
  string,
];
export type UploadVideoAction = ApiAction<
  ServiceMethod.UPLOAD_VIDEO,
  UploadVideoArgs
>;

export type SearchVideoArgs = [string, number, number, boolean, string];
export type SearchVideoAction = ApiAction<
  ServiceMethod.SEARCH_VIDEO,
  SearchVideoArgs
>;
export type SearchVideoResult = IVideoUpload[];

export type GetVideoArgs = [number | string];
export type GetVideoAction = ApiAction<ServiceMethod.GET_VIDEO, GetVideoArgs>;

export type GetTextArgs = [string];
export type GetTextAction = ApiAction<ServiceMethod.GET_TEXT, GetTextArgs>;

interface VideoVariation {
  isTransparent: boolean;
  type: 'transparent';
  url: string;
}
export type ConvertGifToVideoArgs = [Source];
export interface ConvertGifToVideoResult {
  url: string;
  variations: VideoVariation[];
}
export type ConvertGifToVideoAction = ApiAction<
  ServiceMethod.CONVERT_GIF_TO_VIDEO,
  ConvertGifToVideoArgs
>;

export type ParseExternalContentArgs = [string /* url */];
export interface ParseExternalContentResult {
  audioUrl: string | null;
  transcriptUrl: string | null;
}

export type ParseExternalContentAction = ApiAction<
  ServiceMethod.PARSE_EXTERNAL_CONTENT,
  ParseExternalContentArgs
>;

export type DirectUploadFileType = 'audio' | 'video';
export type DirectUploadArgs = [
  DirectUploadFileType,
  string, // content-type
  string, // filename
];
export interface DirectUploadResult {
  bucket: string;
  key: string;
  upload: {
    fields: any;
    url: string;
  };
}
export type DirectUploadAction = ApiAction<
  ServiceMethod.DIRECT_UPLOAD,
  DirectUploadArgs
>;

export type MediaContentInfo = {
  isError: boolean;
  contentType?: string;
  isAnimated?: boolean;
};

export type GetMediaContentInfoArgs = [string /* url */];

export type GetMediaContentInfoAction = ApiAction<
  ServiceMethod.GET_MEDIA_CONTENT_INFO,
  GetMediaContentInfoArgs
>;

type ApiAction<M extends ServiceMethod, A> = IApiAction<ActionKey, M, A>;

export interface MediaUploadServiceDispatch {
  (action: UploadTextAction | GetTextAction): Promise<
    IApiResponse<INormalizedTextUpload>
  >;
  (action: ConvertGifToVideoAction): Promise<
    IApiResponse<ConvertGifToVideoResult>
  >;
  (action: UploadVideoAction | GetVideoAction): Promise<
    IApiResponse<INormalizedVideoUpload>
  >;
  (action: SearchVideoAction): Promise<IApiResponse<SearchVideoResult>>;
  (action: ParseExternalContentAction): Promise<
    IApiResponse<ParseExternalContentResult>
  >;
  (action: DirectUploadAction): Promise<IApiResponse<DirectUploadResult>>;
  (action: GetMediaContentInfoAction): Promise<IApiResponse<MediaContentInfo>>;
}
