import cn from 'classnames';
import * as React from 'react';
import { isFunction, noop } from 'underscore';

import bem from 'utils/bem';

const block = bem('ml-textarea');

export interface MaxLengthTextareaRenderProgressProps {
  className: string;
  count: number;
  maxChars: number;
}

export interface MaxLengthTextareaProps {
  className?: string;
  inputClassName?: string;
  onChange?: (value: string) => void;
  maxChars: number;
  placeholder?: string;
  renderProgress?: (props: MaxLengthTextareaRenderProgressProps) => JSX.Element;
  type?: 'input' | 'textarea';
  value: string;
  countRemaining?: (value: string) => number;
  readonly?: boolean;
  autoFocus?: boolean;
}

export default class MaxLengthTextarea extends React.Component<
  MaxLengthTextareaProps
> {
  public static defaultProps: Partial<MaxLengthTextareaProps> = {
    onChange: noop,
    type: 'textarea',
    countRemaining: value => value?.length ?? 0,
  };

  private handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { onChange, maxChars, countRemaining } = this.props;
    const newValue = e.target.value;
    const length = countRemaining(newValue);
    // we can't use max-length because of the dynamic nature of char length calculation:
    // actual char length !== physical char length (eg.: links can count as 0, etc...)
    if (length <= maxChars) {
      onChange(newValue);
    }
  };

  private renderInput() {
    const {
      placeholder,
      type,
      value,
      inputClassName,
      readonly,
      autoFocus = true,
    } = this.props;
    const commonProps = {
      placeholder,
      value,
      readOnly: readonly,
      autoFocus,
      onChange: this.handleChange,
    };

    if (type === 'input') {
      return (
        <input
          type="text"
          {...commonProps}
          className={cn(block('input'), inputClassName)}
        />
      );
    }

    return (
      <textarea
        {...commonProps}
        className={cn(block('textarea'), inputClassName)}
      />
    );
  }

  public render() {
    const {
      className,
      maxChars,
      renderProgress,
      countRemaining,
      value,
      type,
    } = this.props;

    const progressProps: MaxLengthTextareaRenderProgressProps = {
      maxChars,
      className: block('char-count'),
      count: countRemaining(value),
    };

    const progressElement = isFunction(renderProgress) ? (
      renderProgress(progressProps)
    ) : (
      <span className={progressProps.className}>
        {progressProps.count} / {maxChars}
      </span>
    );

    return (
      <div className={cn(block({ input: type === 'input' }), className)}>
        <div className={block('input-container')}>{this.renderInput()}</div>

        {progressElement}
      </div>
    );
  }
}
