import * as headlinerUserService from 'redux/middleware/api/headliner-user-service';
import { ThunkAction } from 'redux/types';

import { Type } from './action-types';

export const getNotificationAlert = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  dispatch({
    type: Type.NOTIFICATION_ALERT_REQUEST,
  });

  try {
    await dispatch(headlinerUserService.getNotificationAlert());

    dispatch({
      type: Type.NOTIFICATION_ALERT_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: Type.NOTIFICATION_ALERT_FAILURE,
    });
  }
};

export const updateNotificationAlert = (
  args: headlinerUserService.UpdateNotificationAlertArgs,
): ThunkAction<Promise<void>> => async dispatch => {
  try {
    dispatch({
      type: Type.NOTIFICATION_ALERT_UPDATE_REQUEST,
    });

    await dispatch(headlinerUserService.updateNotificationAlert(args));
    await dispatch(getNotificationAlert());

    dispatch({ type: Type.NOTIFICATION_ALERT_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({ type: Type.NOTIFICATION_ALERT_UPDATE_FAILURE });
  }
};
