import * as React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';

import { TransitionGroup } from 'react-transition-group';
import { compose } from 'redux';
import BemCSSTransition from 'components/BemCssTransition';
import * as auth from 'components/hoc/auth';
import Page from 'components/Page';
import withRedirectOnMobile from 'containers/Redirect/withRedirectOnMobile';
import bem from 'utils/bem';
import useVideoTranscriptionSuggestedClipInfo from './useVideoTranscriptionSuggestedClipInfo';
import VideoTranscriptionSuggestedClipsPage from './VideoTranscriptionSuggestedClipsPage';
import VideoTranscriptionSuggestedClipsPageContainer from './VideoTranscriptionSuggestedClipsPageContainer';

const block = bem('download-routes');

const desktopOnly = withRedirectOnMobile(location =>
  location.pathname.substr(0, location.pathname.lastIndexOf('/') + 1),
);

const protectedAutomationSuggestedClipsPage = (creationRequestId: number) =>
  compose(
    auth.userIsSuggestionsOwner(creationRequestId),
    desktopOnly,
  )(VideoTranscriptionSuggestedClipsPage);

const VideoTranscriptionSuggestedClipsRoutes: React.FC = () => {
  const { creationRequestId } = useVideoTranscriptionSuggestedClipInfo();
  const { path } = useRouteMatch();
  const location = useLocation();

  const ProtectedPage = React.useMemo(
    () => protectedAutomationSuggestedClipsPage(creationRequestId),
    [creationRequestId],
  );

  return (
    <VideoTranscriptionSuggestedClipsPageContainer>
      <Page>
        <TransitionGroup>
          <BemCSSTransition
            key={location.pathname}
            timeout={{
              exit: 600,
              enter: 1200,
            }}
            unmountOnExit
            transitionClassName={block('page-animation')}
          >
            <Switch location={location}>
              <Route exact path={path} component={ProtectedPage} />
            </Switch>
          </BemCSSTransition>
        </TransitionGroup>
      </Page>
    </VideoTranscriptionSuggestedClipsPageContainer>
  );
};

export default VideoTranscriptionSuggestedClipsRoutes;
