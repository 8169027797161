import * as React from 'react';
import { connect } from 'react-redux';

import { RejectedReason } from 'components/FileUploader';
import { Navigator } from 'components/Wizard';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import { uploadVideo } from 'redux/middleware/api/media-upload-service/actions';
import { Tier } from 'redux/middleware/api/plan-service';
import * as mixpanelActions from 'redux/modules/mixpanel';
import { pushModal } from 'redux/modules/modal';
import {
  clearNotification,
  showError,
} from 'redux/modules/notification/actions';
import { tierSelector } from 'redux/modules/pricing';
import { goToCreate, goToProject } from 'redux/modules/router/actions';
import {
  handleUploadVideo,
  searchForVideo,
  VideoUploadArgs,
} from 'redux/modules/video-upload';
import { createVideoTranscriptionProject } from 'redux/modules/video-wizard/actions';
import { projectIdSelector } from 'redux/modules/wizard-export/selectors';
import { Dispatch, State, ThunkAction } from 'redux/types';
import { ApplicationError } from 'utils/ApplicationError';
import { DropZoneType } from 'utils/constants';
import { FatalError } from 'utils/FatalError';
import { MIXPANEL_WIZARD_STEP_MAP } from './constants';
import { VideoWizardProps } from './VideoWizard';

type StateProps = Pick<VideoWizardProps, 'tier'>;

type DispatchProps = Pick<
  VideoWizardProps,
  | 'onCancel'
  | 'onClickSampleFile'
  | 'onMount'
  | 'onStepChange'
  | 'onSubmit'
  | 'onError'
  | 'onFileUpload'
  | 'onCompleted'
>;

const ERROR_ID = 'video-wizard-error';

const onCompleted = (): ThunkAction<void> => (dispatch, getState) => {
  const projectId = projectIdSelector(getState());

  dispatch(goToProject({ projectId, history: false, fromWizard: true }));
};

const createProject = (
  args: Partial<VideoUploadArgs>,
  projectName: string,
  uploadedVideoId?: number,
): ThunkAction<void> => async dispatch => {
  const {
    aspectRatioName,
    src,
    durationMillis,
    trimStartMillis,
    trimEndMillis,
    language,
    isClipSuggestion,
    clipSuggestionLengthSecs,
  } = args;

  dispatch(
    createVideoTranscriptionProject(
      aspectRatioName,
      !uploadedVideoId ? src : undefined,
      durationMillis,
      trimStartMillis,
      trimEndMillis,
      language,
      uploadedVideoId,
      isClipSuggestion,
      clipSuggestionLengthSecs,
      projectName,
      isClipSuggestion,
    ),
  ).catch(() => {
    dispatch(
      showError({
        message: 'There was an error creating your project.',
        code: 'ER009',
        id: ERROR_ID,
      }),
    );
    dispatch(goToCreate());
  });
};

const createProjectWithImportedFile = (
  args: VideoUploadArgs,
  projectName: string,
  mediaImportedArgs?: MediaImportedArgs,
): ThunkAction<void> => async dispatch => {
  const { trimStartMillis, trimEndMillis, language } = args;
  const { provider } = mediaImportedArgs;

  return dispatch(
    uploadVideo(undefined, {
      trimStart: trimStartMillis,
      trimEnd: trimEndMillis,
      transcribe: true,
      language,
      initiateProcessing: provider !== 'youtube',
      ...(provider === 'googleDrive'
        ? {
            providerUserId: mediaImportedArgs.providerUserId,
            googleDriveFileId: mediaImportedArgs.googleDriveFileId,
          }
        : {}),
      ...(provider === 'zoom'
        ? {
            providerUserId: mediaImportedArgs.providerUserId,
            zoomMeetingId: mediaImportedArgs.zoomMeetingId,
            zoomRecordingFileId: mediaImportedArgs.zoomRecordingFileId,
          }
        : {}),
      ...(provider === 'youtube'
        ? {
            youtubeUrl: mediaImportedArgs.youtubeUrl,
          }
        : {}),
    }),
  ).then(res =>
    dispatch(createProject(args, projectName, res.response.result)),
  );
};

const onError = (
  err: ApplicationError,
  file?: File,
  reason?: RejectedReason,
): ThunkAction<void> => (dispatch, getState) => {
  const { message, code } = err;

  if (err instanceof FatalError) {
    dispatch(
      showError({
        message: 'There was an error creating your video',
        code: 'ER003',
        id: ERROR_ID,
      }),
    );
    dispatch(goToCreate());
    return;
  }

  if (file) {
    dispatch(
      mixpanelActions.onFileUpload(
        DropZoneType.VIDEO_TRANSCRIPTION,
        file,
        message,
      ),
    );
  }

  const tier = tierSelector(getState());
  const size = file.size / 1024 ** 2;

  if (
    reason === RejectedReason.SIZE_LIMIT_EXCEEDED &&
    tier === Tier.FREE &&
    size < spareminConfig.uploadVideoMaxFileSizeMb
  ) {
    dispatch(
      pushModal({
        name: 'FileSizeUpsell',
        params: {
          variant: 'file',
        },
      }),
    );
  } else {
    dispatch(showError({ message, code }));
  }
};

const mapStateToProps = (state: State): StateProps => ({
  tier: tierSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancel: (nav: Navigator) => {
    dispatch(
      mixpanelActions.onCancelWizard(
        'videoTranscription',
        MIXPANEL_WIZARD_STEP_MAP[nav.stepId],
      ),
    );
    dispatch(goToCreate());
  },
  onClickSampleFile: () =>
    dispatch(mixpanelActions.onUseSampleVideo('videoTranscription')),
  onError: (err, file, reason) => dispatch(onError(err, file, reason)),
  onFileUpload: (file: File) =>
    dispatch(
      mixpanelActions.onFileUpload(DropZoneType.VIDEO_TRANSCRIPTION, file),
    ),
  onMount: () => {
    dispatch(clearNotification(ERROR_ID));
    dispatch(mixpanelActions.clearMixpanel());
  },
  onStepChange: (stepId, props) => {
    dispatch(
      mixpanelActions.onWizardNext({
        step: MIXPANEL_WIZARD_STEP_MAP[stepId],
        type: 'videoTranscription',
        ...props,
      }),
    );
  },

  onSubmit: async (
    args: VideoUploadArgs,
    projectName: string,
    mediaImportedArgs?: MediaImportedArgs,
  ) => {
    const {
      aspectRatioName,
      src,
      durationMillis,
      trimStartMillis,
      trimEndMillis,
      language,
      platform,
      videoType,
    } = args;

    const clipDurationInMillis = trimEndMillis - trimStartMillis;

    dispatch(
      mixpanelActions.onCompleteWizard({
        aspectRatio: aspectRatioName,
        audioSource: 'upload',
        clipDuration: durationMillis,
        clipEnd: trimEndMillis,
        clipped: durationMillis - clipDurationInMillis !== 0,
        clipStart: trimStartMillis,
        transcription: true,
        type: 'videoTranscription',
        platform: platform ?? 'None',
        videoType: videoType?.id ?? 'None',
      }),
    );

    if (mediaImportedArgs) {
      return dispatch(
        createProjectWithImportedFile(args, projectName, mediaImportedArgs),
      );
    }

    if (typeof src === 'string') {
      return dispatch(createProject(args, projectName));
    }

    const video = await dispatch(
      searchForVideo({
        src,
        trimStartMillis,
        trimEndMillis,
        language,
      }),
    );

    // If video exists (is already uploaded), then create the new project
    // with existing video id.
    if (video) {
      return dispatch(createProject(args, projectName, video.id));
    }

    // If video does not exists (is not uploaded), then upload it and use the
    // new video id to create the new project.
    const { result: uploadedVideoId } = await dispatch(
      handleUploadVideo(src, args),
    );

    return dispatch(createProject(args, projectName, uploadedVideoId));
  },
  onCompleted: () => dispatch(onCompleted()),
});

export default function(component: React.ComponentType<VideoWizardProps>) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
