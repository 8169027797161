import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextOverlay } from 'blocks/TextOverlayModal';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import { useTextOverlaySwitch } from 'blocks/TextOverlaySwitchModal';
import { useAddAssetModal } from 'containers/ConnectedModal/useAddAssetModal';
import { pushModal } from 'redux/modules/modal';
import { subtierSelector } from 'redux/modules/pricing';
import { isEnterpriseSubTier } from 'utils/pricing';

type ActiveModal = 'legacy' | 'current' | 'none';

interface UseAddTextModalSwitcher {
  activeModal: ActiveModal;
  onExited: () => void;
  onHide: () => void;
  onToggleLegacyMode: (partialOverlay?: TextOverlay | TextOverlayV2) => void;
  transitionOverlay: TextOverlay | TextOverlayV2;
}

const useAddTextModalSwitcher = (): UseAddTextModalSwitcher => {
  const dispatch = useDispatch();

  const [hasPickedMode, setHasPickedMode] = React.useState(false);

  const { onHide, onExited, show } = useAddAssetModal('AddTextModal');

  // Enterprise users should have all the text boxes modals
  // defaulted to the the legacy editor by default.
  // However as the new modal has no constraints, the new text
  // overlay modal should keep track when the user has picked to
  // use the new modal.
  const userSubtier = useSelector(subtierSelector);
  const isEnterpriseUser = isEnterpriseSubTier(userSubtier);
  const shouldForceLegacyMode = show && isEnterpriseUser && !hasPickedMode;
  const startingModalType = shouldForceLegacyMode ? 'legacy' : undefined;

  const handleOpen = React.useCallback(() => {
    dispatch(pushModal({ name: 'AddTextModal' }));
  }, [dispatch]);

  const handleSwitchMode = React.useCallback(() => {
    setHasPickedMode(true);
  }, []);

  const {
    activeModal,
    onToggleLegacyMode,
    transitionOverlay,
  } = useTextOverlaySwitch({
    onHide,
    onOpen: handleOpen,
    onSwitchMode: handleSwitchMode,
    show,
    startingModalType,
  });

  return {
    activeModal,
    onExited,
    onHide,
    onToggleLegacyMode,
    transitionOverlay,
  };
};

export default useAddTextModalSwitcher;
