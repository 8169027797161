import * as React from 'react';

import { useSelector } from 'react-redux';
import { DestinationSteps } from 'blocks/AutomationOptions/types';
import AutomationSuccessStep from 'blocks/AutomationWorkflowWizard/AutomationSuccessStep';
import AutomationDestinationStep from 'blocks/AutomationWorkflowWizard/components/AutomationDestinationStep';
import AutomationEpisodesStep from 'blocks/AutomationWorkflowWizard/components/AutomationEpisodesStep';
import { AutoPostingPlatform } from 'blocks/AutomationWorkflowWizard/types';
import LinkButton from 'components/LinkButton';
import Wizard from 'components/Wizard';
import AddPodcastStep from 'containers/ProjectWizards/AddPodcastStep';
import TemplateStep from 'containers/ProjectWizards/TemplateStep';
import { isLoadingSeasonsSelector } from 'redux/modules/automation-cms/selectors';
import { TemplateType, VideoTypes } from 'types';
import { getAspectRatioValue } from 'utils/aspect-ratio';

import AutomationLanguageStep from '../../components/AutomationLanguageStep';
import { wizardBlock as block } from '../../utils';
import { AutogramPreviewStep } from '../AutogramPreviewStep';
import AutomationCustomizeStep from '../AutomationCustomizeStep';
import { headerTitles } from './constants';
import { AutogramWizardStep, AutomationWorkflowWizardProps } from './types';
import useAutomationWorkflowWizard from './useAutomationWorkflowWizard';
import useAutomationWorkflowWizardBlocker from './useAutomationWorkflowWizardBlocker';
import {
  checkIsPlatformWithoutVideoType,
  checkViewIsAutomationOptionsView,
  checkViewIsAutoPosting,
  getCompatibilityType,
} from './utils';

const returnNull = () => null;

const AddWorkflowWizard: React.SFC<AutomationWorkflowWizardProps> = props => {
  const {
    defaultPodcastId,
    defaultStepIndex = 0,
    defaultSubStep,
    defaultWorkflowId,
  } = props;

  const {
    aspectRatioName,
    creatingWorkflow,
    creatingWorkflowPreview,
    customizeStepDraftState,
    isLastStep,
    language,
    pollingWorkflowPreview,
    onBackClick,
    onCancel,
    onPreviewProject,
    onLanguageChange,
    onTranscriptionChange,
    onCaptionsChange,
    onTargetedSeasonsChange,
    onNextClick,
    onSkipSeasonsSelection,
    onPodcastClick,
    onDestinationOptionsChange,
    onEpisodeOptionChange,
    onSubmitTemplateCustomization,
    destinationOptions,
    onStepChange,
    podcastId,
    onEpisodesComplete,
    onTemplateSelect,
    onAspectRatioChange,
    handleDestinationSelectionFinished,
    episodeOption,
    step,
    subscriptionPreviewConfig,
    subscriptionPreviewId,
    subscriptionOptions,
    templateId,
    isAutopost,
    checkShouldAuthenticate,
    handleAutomationPlatformAuthentication,
    wizard,
    transcription,
    captions,
    onSubscriptionOptionsChange,
  } = useAutomationWorkflowWizard({
    defaultPodcastId,
    defaultSubscriptionId: defaultWorkflowId,
  });

  const isSeasonsLoading = useSelector(isLoadingSeasonsSelector);
  const confirm = useAutomationWorkflowWizardBlocker(step);

  return (
    <Wizard<AutogramWizardStep>
      className={block({ 'no-flex': step === 'template' })}
      bodyClassName={block('body', {
        preview: step === 'customize' || step === 'preview',
      })}
      buttonClassName={block('buttons', {
        submit: step === 'customize' || step === 'template',
      })}
      disabledSteps={defaultWorkflowId ? [0, 1] : undefined}
      onCancelClick={onCancel}
      onStepChange={onStepChange}
      onNextClick={onNextClick}
      onStepClick={confirm}
      ref={wizard}
      canBacktrack={!creatingWorkflow && !isLastStep}
      step={defaultStepIndex}
      steps={[
        {
          completedName: 'Audio Selected',
          component: <AddPodcastStep onPodcastClick={onPodcastClick} />,
          description:
            'We’ll pull in audio from each episode to automatically create videos for you.',
          name: 'Select Audio',
          renderCancelButton: returnNull,
          renderNextButton: returnNull,
          stepId: 'podcast',
          title: 'Choose a podcast',
        },
        {
          component: (
            <AutomationLanguageStep
              onChange={onLanguageChange}
              language={language}
            />
          ),
          description: 'Please select the language that this podcast is in.',
          name: 'Language',
          stepId: 'language',
          title: 'Select language',
        },
        {
          component: (
            <AutomationDestinationStep
              onDestinationOptionsChange={onDestinationOptionsChange}
              destinationOptions={destinationOptions}
            />
          ),
          description: checkViewIsAutomationOptionsView(
            destinationOptions?.stepView,
          ) ? (
            <LinkButton
              onClick={() =>
                onDestinationOptionsChange({
                  stepView: checkIsPlatformWithoutVideoType(
                    destinationOptions?.platform,
                  )
                    ? DestinationSteps.CHOOSE_DESTINATION
                    : DestinationSteps.VIDEO_TYPES,
                  automationOption: null,
                })
              }
              theme="cta"
              uppercase
              className={block('back-link')}
            >
              ← return to video types
            </LinkButton>
          ) : null,
          name: 'Destination',
          stepId: 'destination',
          title: checkViewIsAutomationOptionsView(destinationOptions?.stepView)
            ? headerTitles[destinationOptions?.stepView]
            : null,
          renderCancelButton: buttonProps =>
            checkViewIsAutomationOptionsView(destinationOptions?.stepView) ? (
              <Wizard.Button {...buttonProps} theme="cancel">
                cancel
              </Wizard.Button>
            ) : null,
          renderNextButton: buttonProps =>
            checkViewIsAutomationOptionsView(destinationOptions?.stepView) ? (
              <Wizard.Button
                {...buttonProps}
                theme="next"
                disabled={
                  !destinationOptions?.[
                    checkViewIsAutoPosting(destinationOptions?.stepView)
                      ? 'automationOption'
                      : 'clipSelectionMethod'
                  ]
                }
                onClick={() => {
                  if (checkShouldAuthenticate()) {
                    return handleAutomationPlatformAuthentication();
                  }

                  return handleDestinationSelectionFinished();
                }}
              >
                next
              </Wizard.Button>
            ) : null,
        },
        {
          ...(isSeasonsLoading
            ? { title: '', description: '' }
            : {
                title:
                  episodeOption === 'targetedSeason'
                    ? 'Select seasons to include'
                    : 'Choose episodes',
                description:
                  episodeOption === 'targetedSeason' ? (
                    <LinkButton
                      onClick={() => {
                        onEpisodeOptionChange(undefined);
                        onTargetedSeasonsChange(undefined);
                      }}
                      theme="cta"
                      uppercase
                      className={block('back-link')}
                    >
                      ← return to previous step
                    </LinkButton>
                  ) : (
                    'This will determine which are included in your automation'
                  ),
              }),
          stepId: 'episodes',
          name: 'Episodes',

          component: (
            <AutomationEpisodesStep
              {...{
                podcastId,
                episodeOption,
                onEpisodeOptionChange,
                onTargetedSeasonsChange,
                onSkipSeasonsSelection,
              }}
              isFullEpisode={
                destinationOptions.videoType === VideoTypes.FULL_EPISODE
              }
              targetedSeasons={subscriptionOptions.targetedSeasons}
              onSubmit={onEpisodesComplete}
            />
          ),
          renderNextButton: returnNull,
          renderCancelButton: returnNull,
        },
        {
          component: (
            <TemplateStep
              aspectRatioName={aspectRatioName}
              compatibilityTypes={[
                getCompatibilityType(destinationOptions?.videoType),
              ]}
              initialSubStep={defaultSubStep}
              onChangeAspectRatio={onAspectRatioChange}
              onSelect={onTemplateSelect}
              podcastId={podcastId}
              templateTypes={[
                'blank',
                TemplateType.HEADLINER_DEFAULT,
                TemplateType.USER_GENERATED,
              ]}
            />
          ),
          name: 'Template',
          renderCancelButton: returnNull,
          renderNextButton: returnNull,
          stepId: 'template',
          title: 'Choose a template',
        },
        {
          component: (
            <AutomationCustomizeStep
              aspectRatio={getAspectRatioValue(aspectRatioName)}
              disabled={creatingWorkflowPreview}
              transcription={transcription}
              onTranscriptionChange={onTranscriptionChange}
              captions={captions}
              onCaptionsChange={onCaptionsChange}
              initialEditorState={customizeStepDraftState.editorState}
              initialTemplateIsDirty={customizeStepDraftState.isTemplateDirty}
              onSubmit={onPreviewProject}
              onTemplatesClick={onBackClick}
              templateId={templateId}
              videoType={destinationOptions?.videoType}
              podcastId={podcastId}
              language={language}
              onSubscriptionOptionsChange={onSubscriptionOptionsChange}
            />
          ),
          name: 'Customize',
          renderCancelButton: returnNull,
          renderNextButton: returnNull,
          showInNav: false,
          stepId: 'customize',
        },
        {
          component: (
            <AutogramPreviewStep
              aspectRatioName={aspectRatioName}
              isSubmitting={creatingWorkflow}
              previewLoading={pollingWorkflowPreview}
              onGoBack={onBackClick}
              onCancel={onCancel}
              onSubmit={onSubmitTemplateCustomization}
              subscriptionPreviewConfig={subscriptionPreviewConfig}
              subscriptionPreviewId={subscriptionPreviewId}
            />
          ),
          stepId: 'preview',
          stepType: 'takeover',
        },
        {
          component: (
            <AutomationSuccessStep
              autopost={isAutopost}
              autopostPlatform={
                isAutopost
                  ? (destinationOptions.platform as AutoPostingPlatform)
                  : undefined
              }
              podcastId={podcastId}
            />
          ),
          stepId: 'completed',
          stepType: 'takeover',
        },
      ]}
    />
  );
};

export default AddWorkflowWizard;
