import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { H1, H2 } from 'components/Heading';
import ObjectiveCardOptions from './ObjectiveCardOptions';
import { VideoWizardObjective } from './types';
import { block } from './utils';

interface VideoWizardObjectiveStepProps {
  onSelectObjective: (selectedObjectiveStepType: VideoWizardObjective) => void;
}

const VideoWizardObjectiveStep: React.FC<VideoWizardObjectiveStepProps> = ({
  onSelectObjective,
}) => {
  return (
    <Spacer
      orientation="vertical"
      align="center"
      justify="space-between"
      space="48px"
      className={block()}
    >
      <Spacer
        orientation="vertical"
        align="center"
        justify="space-between"
        space={2.5}
      >
        <H1>Lights, camera, caption!</H1>

        <H2>Let’s get your video podcast ready to be shared across the web.</H2>
      </Spacer>

      <ObjectiveCardOptions onSelectObjective={onSelectObjective} />
    </Spacer>
  );
};

export default VideoWizardObjectiveStep;
