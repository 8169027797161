import * as React from 'react';

import LoadingScreen from 'components/LoadingScreen';
import Page from 'components/Page';
import useAutomationSuggestedClipsPage from './useAutomationSuggestedClipsPage';

export interface AutomationSuggestedClipsPageContainerProps {
  children?: React.ReactElement;
}

const AutomationSuggestedClipsPageContainer: React.FC<AutomationSuggestedClipsPageContainerProps> = ({
  children,
}) => {
  const { isLoading } = useAutomationSuggestedClipsPage();

  return (
    <>
      <LoadingScreen message="" show={isLoading} fadeout />
      {
        // Don't want to mount children until loading is complete, but the
        // LoadingScreen component needs something to fill the page underneath
        // it so an empty <Page /> is used.
      }
      {!isLoading ? children : <Page />}
    </>
  );
};

export default AutomationSuggestedClipsPageContainer;
