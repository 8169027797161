import { FeedbackType } from 'redux/middleware/api/podcast-service';
import { ClipSuggestionDislikeReason } from 'types';

export const GROUP_CLIP_SUGGESTION_POLL_ID =
  'app/clip-select/group-clip-suggestion-poll-id';

export const CREATION_CLIP_SUGGESTION_POLL_ID =
  'app/clip-select/CREATION-clip-suggestion-poll-id';

export const CLIP_FEEDBACK_REASON_TO_TYPE: Record<
  ClipSuggestionDislikeReason,
  FeedbackType
> = {
  [ClipSuggestionDislikeReason.Advertisement]: FeedbackType.ItsAnAdvertisement,
  [ClipSuggestionDislikeReason.CutsOffMidSentence]:
    FeedbackType.ItCutsOffMidSentence,
  [ClipSuggestionDislikeReason.NotInteresting]: FeedbackType.ItsNotInteresting,
  [ClipSuggestionDislikeReason.Other]: FeedbackType.OtherReason,
};
