import {
  createCreationClipSuggestion as createCreationClipSuggestionAction,
  createCreationClipSuggestionFeedback,
  getCreationClipSuggestion,
  getCreationClipSuggestions,
} from 'redux/middleware/api/creation-service/actions';
import {
  EpisodeId,
  actions as podcastService,
} from 'redux/middleware/api/podcast-service';
import { actions as videoExportService } from 'redux/middleware/api/video-export-service';
import { ThunkAction } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types/common';
import { cancelReduxPoll, reduxPoll } from 'utils/redux-poll';
import {
  creationClipSuggestionsByIdSelector,
  creationClipSuggestionsSelector,
  groupClipSuggestionsByIdSelector,
  groupClipSuggestionsSelector,
} from '../entities';
import { Type } from './action-types';
import {
  CLIP_FEEDBACK_REASON_TO_TYPE,
  CREATION_CLIP_SUGGESTION_POLL_ID,
  GROUP_CLIP_SUGGESTION_POLL_ID,
} from './constants';
import {
  autoCreateEpisodeIdSelector,
  autoCreateEpisodeSelector,
  subscriptionItemIdSelector,
} from './selectors';

export const loadClipSuggestions = (
  episodeId: EpisodeId,
  subscriptionItemId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.LOAD_CLIP_SUGGESTIONS_REQUEST });

  try {
    const { response } = await dispatch(
      podcastService.getEpisodeTranscriptInfo(episodeId, subscriptionItemId),
    );

    dispatch({
      type: Type.LOAD_CLIP_SUGGESTIONS_SUCCESS,
      payload: response.result,
    });
  } catch (error) {
    dispatch({ type: Type.LOAD_CLIP_SUGGESTIONS_FAILURE });
  }
};

export const loadGroupClipSuggestions = (
  episodeId: number,
  subscriptionItemId: number,
  groupRequestId?: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.LOAD_GROUP_CLIP_SUGGESTIONS_REQUEST,
  });

  try {
    const { response } = await dispatch(
      podcastService.getGroupClipSuggestions(
        episodeId,
        subscriptionItemId,
        groupRequestId,
      ),
    );

    dispatch({
      type: Type.LOAD_GROUP_CLIP_SUGGESTIONS_SUCCESS,
      response: {
        entities: {
          groupClipSuggestions: response.entities.groupClipSuggestions,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: Type.LOAD_GROUP_CLIP_SUGGESTIONS_FAILURE,
    });
  }
};

export const loadSubscriptionItemInformation = (
  subscriptionItemId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({ type: Type.LOAD_SUBSCRIPTION_ITEM_INFORMATION_REQUEST });

  try {
    const { response } = await dispatch(
      podcastService.getSubscriptionItemInformation(subscriptionItemId),
    );

    dispatch({
      type: Type.LOAD_SUBSCRIPTION_ITEM_INFORMATION_SUCCESS,
      response: {
        entities: {
          subscriptionItemInformation: response,
        },
      },
    });
  } catch (error) {
    dispatch({ type: Type.LOAD_SUBSCRIPTION_ITEM_INFORMATION_FAILURE });
  }
};

const EXPORT_POLL_ID = 'clip-select';

const waitForVideoId = (): ThunkAction<Promise<number>> => async (
  dispatch,
  getState,
) => {
  const autoCreateEpisodeId = autoCreateEpisodeIdSelector(getState());

  if (!autoCreateEpisodeId) {
    throw new Error('Auto create episode missing');
  }

  await reduxPoll(
    dispatch,
    () => dispatch(podcastService.getAutoCreateEpisode(autoCreateEpisodeId)),
    {
      id: EXPORT_POLL_ID,
      intervalMillis: spareminConfig.videoExportPollIntervalMillis,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      retryAttempts: spareminConfig.videoExportRetryAttempts,
      shouldContinue: (err, value) => {
        if (err) return false;
        const { response } = value;
        const { entities } = response;
        const autoCreateEpisode =
          entities.autoCreateEpisodes[autoCreateEpisodeId];

        if (autoCreateEpisode.status === 'completed') {
          return false;
        }

        if (
          autoCreateEpisode.status === 'queued' ||
          autoCreateEpisode.status === 'processing'
        ) {
          return true;
        }

        throw new Error('Error generating video');
      },
    },
  );

  const autoCreateEpisode = autoCreateEpisodeSelector(getState());
  return autoCreateEpisode?.videoId;
};

export const waitForWidgetId = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  const videoId = await dispatch(waitForVideoId());

  const { response } = await dispatch(
    videoExportService.fetchEmbedVideos(videoId),
  );

  const [widgetId] = response.result;

  dispatch({
    type: Type.EMBED_EXPORT_READY,
    payload: { widgetId },
  });
};

export const cancelWaitForWidgetId = (): ThunkAction<void> => dispatch => {
  cancelReduxPoll(dispatch, EXPORT_POLL_ID);
};

export const dislikeGroupSuggestedClip = (
  reason: ClipSuggestionDislikeReason,
  suggestionId?: number,
  autoCreateEpisodeId?: number,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const subscriptionItemId = subscriptionItemIdSelector(getState());

  dispatch({
    type: Type.GROUP_CLIP_SUGGESTION_DISLIKE,
    payload: { suggestionId },
  });

  await dispatch(
    podcastService.createEpisodeClipSuggestionFeedback({
      suggestionId,
      subscriptionItemId,
      autoCreateEpisodeId,
      feedbackType: CLIP_FEEDBACK_REASON_TO_TYPE[reason],
    }),
  );
};

export const pollForGroupClipSuggestion = (
  autoCreateEpisodeId: number,
  startMillis?: number,
  endMillis?: number,
): ThunkAction<void> => async (dispatch, getState) => {
  const currentSuggestions = groupClipSuggestionsSelector(getState())?.toJS();

  if (startMillis !== undefined && endMillis !== undefined) {
    dispatch({
      type: Type.GROUP_CLIP_SUGGESTION_POLLING_REQUEST,
      response: {
        entities: {
          groupClipSuggestions: {
            clipGroup: {
              ...currentSuggestions,
              clipSuggestions: [
                ...currentSuggestions.clipSuggestions,
                {
                  autoCreateEpisodeId,
                  startMillis,
                  endMillis,
                  status: 'queued',
                  text: '',
                  videoUrl: null,
                  widgetId: null,
                },
              ],
            },
          },
        },
      },
    });
  }

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        podcastService.getGroupClipSuggestion(autoCreateEpisodeId),
      );

      const groupClipSuggestions = groupClipSuggestionsSelector(
        getState(),
      )?.toJS();
      const { clipSuggestions } = groupClipSuggestions;

      const { text } =
        groupClipSuggestionsByIdSelector(getState())[autoCreateEpisodeId] || {};

      if (response?.status === 'completed' || !text) {
        // Find the index of the existing suggestion with the same autoCreateEpisodeId.
        const index = clipSuggestions.findIndex(
          suggestion =>
            suggestion.autoCreateEpisodeId === response.autoCreateEpisodeId,
        );

        // Create a new array with the updated or new suggestion.
        const updatedSuggestions =
          index === -1
            ? [...clipSuggestions, response] // Add new suggestion.
            : clipSuggestions.map((suggestion, i) =>
                i === index ? response : suggestion,
              ); // Update existing suggestion.

        dispatch({
          type: Type.GROUP_CLIP_SUGGESTION_POLLING_SUCCESS,
          response: {
            entities: {
              groupClipSuggestions: {
                clipGroup: {
                  ...groupClipSuggestions,
                  clipSuggestions: updatedSuggestions,
                },
              },
            },
          },
        });
      }

      return response;
    },
    {
      id: `${GROUP_CLIP_SUGGESTION_POLL_ID}_${autoCreateEpisodeId}`,
      shouldContinue: (_, value) => {
        const groupClipSuggestions = groupClipSuggestionsSelector(
          getState(),
        )?.toJS();

        if (value?.status === 'error') {
          dispatch({
            type: Type.GROUP_CLIP_SUGGESTION_POLLING_FAILURE,
            response: {
              entities: {
                groupClipSuggestions: {
                  clipGroup: {
                    ...groupClipSuggestions,
                    clipSuggestions: groupClipSuggestions.clipSuggestions.filter(
                      suggestion =>
                        suggestion.autoCreateEpisodeId !== autoCreateEpisodeId,
                    ),
                  },
                },
              },
            },
          });

          return false;
        }

        if (value?.status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createGroupClipSuggestion = (
  episodeId: number,
  subItemId: number,
  groupRequestId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
): ThunkAction<Promise<number | void>> => async dispatch => {
  const { response } = await dispatch(
    podcastService.createGroupClipSuggestion(
      episodeId,
      subItemId,
      groupRequestId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  dispatch(
    pollForGroupClipSuggestion(
      response.autoCreateEpisodeId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  return response.autoCreateEpisodeId;
};

export const loadCreationClipSuggestions = (
  creationRequestId: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.LOAD_CREATION_CLIP_SUGGESTIONS_REQUEST,
  });

  try {
    const { response } = await dispatch(
      getCreationClipSuggestions(creationRequestId),
    );

    dispatch({
      type: Type.LOAD_CREATION_CLIP_SUGGESTIONS_SUCCESS,
      response: {
        entities: {
          creationClipSuggestions: response.entities.creationClipSuggestions,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: Type.LOAD_CREATION_CLIP_SUGGESTIONS_FAILURE,
    });
  }
};

export const dislikeCreationSuggestedClip = (
  reason: ClipSuggestionDislikeReason,
  creationRequestId?: number,
  suggestionId?: number,
): ThunkAction<Promise<void>> => async dispatch => {
  dispatch({
    type: Type.CREATION_CLIP_SUGGESTION_DISLIKE,
    payload: { suggestionId },
  });

  await dispatch(
    createCreationClipSuggestionFeedback(
      CLIP_FEEDBACK_REASON_TO_TYPE[reason],
      creationRequestId,
      suggestionId,
    ),
  );
};

export const pollForCreationClipSuggestion = (
  creationRequestId: number,
  suggestionId: number,
  startMillis?: number,
  endMillis?: number,
): ThunkAction<void> => async (dispatch, getState) => {
  const currentSuggestions = creationClipSuggestionsSelector(
    getState(),
  )?.toJS();

  if (startMillis !== undefined && endMillis !== undefined) {
    dispatch({
      type: Type.CREATION_CLIP_SUGGESTION_POLLING_REQUEST,
      response: {
        entities: {
          creationClipSuggestions: {
            clipGroup: {
              ...currentSuggestions,
              clipSuggestions: [
                ...currentSuggestions.clipSuggestions,
                {
                  suggestionId,
                  startMillis,
                  endMillis,
                  status: 'submitted',
                  text: '',
                  videoUrl: null,
                  widgetId: null,
                },
              ],
            },
          },
        },
      },
    });
  }

  await reduxPoll(
    dispatch,
    async () => {
      const { response } = await dispatch(
        getCreationClipSuggestion(creationRequestId, suggestionId),
      );

      const creationClipSuggestions = creationClipSuggestionsSelector(
        getState(),
      )?.toJS();
      const { clipSuggestions } = creationClipSuggestions;

      const { text } =
        creationClipSuggestionsByIdSelector(getState())[suggestionId] || {};

      if (response?.status === 'completed' || !text) {
        // Find the index of the existing suggestion with the same suggestionId.
        const index = clipSuggestions.findIndex(
          suggestion => suggestion.suggestionId === response.suggestionId,
        );

        // Create a new array with the updated or new suggestion.
        const updatedSuggestions =
          index === -1
            ? [...clipSuggestions, response] // Add new suggestion.
            : clipSuggestions.map((suggestion, i) =>
                i === index ? response : suggestion,
              ); // Update existing suggestion.

        dispatch({
          type: Type.CREATION_CLIP_SUGGESTION_POLLING_SUCCESS,
          response: {
            entities: {
              creationClipSuggestions: {
                clipGroup: {
                  ...creationClipSuggestions,
                  clipSuggestions: updatedSuggestions,
                },
              },
            },
          },
        });
      }

      return response;
    },
    {
      id: `${CREATION_CLIP_SUGGESTION_POLL_ID}_${suggestionId}`,
      shouldContinue: (_, value) => {
        const creationClipSuggestions = creationClipSuggestionsSelector(
          getState(),
        )?.toJS();

        if (value?.status === 'error') {
          dispatch({
            type: Type.CREATION_CLIP_SUGGESTION_POLLING_FAILURE,
            response: {
              entities: {
                creationClipSuggestions: {
                  clipGroup: {
                    ...creationClipSuggestions,
                    clipSuggestions: creationClipSuggestions.clipSuggestions.filter(
                      suggestion => suggestion.suggestionId !== suggestionId,
                    ),
                  },
                },
              },
            },
          });

          return false;
        }

        if (value?.status === 'completed') {
          return false;
        }

        return true;
      },
      intervalMillis: 2500,
      maxAttempts: Infinity,
    },
  );
};

export const createCreationClipSuggestion = (
  creationRequestId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
): ThunkAction<Promise<number | void>> => async dispatch => {
  const { response } = await dispatch(
    createCreationClipSuggestionAction(
      creationRequestId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  dispatch(
    pollForCreationClipSuggestion(
      creationRequestId,
      response.suggestionId,
      newTrimStartMillis,
      newTrimEndMillis,
    ),
  );

  return response.suggestionId;
};

export const reset = () => ({
  type: Type.RESET,
});
