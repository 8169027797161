import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';
import { AutogramSubscriptionOptions, DeepPartial } from 'types';

export interface UseYouTubeAutomationAuthenticationResults {
  authenticate: (
    podcastName?: string,
    podcastId?: string,
  ) => Promise<DeepPartial<AutogramSubscriptionOptions>>;
}

export default function useYouTubeAutomationAuthentication(): UseYouTubeAutomationAuthenticationResults {
  const reduxDispatch = useDispatch<Dispatch>();

  const authenticate = useCallback(
    async (
      podcastName?: string,
      podcastId?: string,
    ): Promise<DeepPartial<AutogramSubscriptionOptions>> => {
      const result = await reduxDispatch(
        pushModal({
          name: 'YoutubeAutoPost',
          params: {
            defaultPlaylistName: podcastName,
            defaultPodcastId: podcastId,
            source: 'Autogram',
          },
        }),
      );

      if (result) {
        const {
          defaultLanguage,
          madeForKids,
          categoryId,
          visibility,
          ytAccessData,
          title = '',
          description = '',
          playlists = [],
          tags = [],
          assetInfo,
        } = result;

        return {
          autopost: {
            defaultLanguage,
            madeForKids,
            categoryId,
            visibility,
            googleId: ytAccessData?.providerUserId,
            title,
            description,
            playlists,
            tags,
            assetInfo,
          },
        };
      }

      return undefined;
    },
    [reduxDispatch],
  );

  return {
    authenticate,
  };
}
