import {
  ExternalLink,
  getThemeColor,
  Quote,
  useTheme,
} from '@sparemin/blockhead';
import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';
import { EDDY_URL } from 'utils/constants';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const block = bem('transcribe-button');

const TranscribeButton: React.FC<INewProjectButtonProps> = ({
  className,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ProjectButton
      className={cn('transcribe-button', className)}
      icon={
        <Quote width={50} height={43} color={getThemeColor('s2')({ theme })} />
      }
      projectType="transcribe"
      title={
        <>
          Transcribe
          <br />
          audio or video
        </>
      }
      description={
        <>
          Transcribe and edit your podcast with our new AI-powered app called
          Eddy
          <ExternalLink width={12} height={12} className={block('icon')} />
        </>
      }
      target="_blank"
      to={{
        pathname: EDDY_URL,
      }}
      {...rest}
    />
  );
};

export default TranscribeButton;
