import { has } from 'underscore';
import {
  PodcastSubscription,
  PodcastSubscriptionType,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';
import {
  OutputOption,
  PartialUpdateAutomationData,
} from 'redux/modules/edit-automation';
import { YoutubeChannelInfo } from 'redux/modules/social/types';
import { AudiogramDestination, RequestStatus, VideoTypes } from 'types';
import bem from 'utils/bem';
import { AutopostPreference, AutopostPreferenceModalOutput } from '../types';
import { OUTPUT_OPTIONS_MAP } from './constants';

export const block = bem('edit-automation-output');

const YOUTUBE_CHANNEL_LOADING_PLACEHOLDER = '...';
const YOUTUBE_CHANNEL_NAME_PLACEHOLDER = 'YouTube';

/**
 * Extract youtube channel name text from the youtube channel info. If requesting
 * data a loading placeholder will be get whereas if loading has ended, title or
 * a "YouTube" placeholder will be shown instead
 * @param youtubeChannelData Youtube channel request data
 * @returns Youtube channel name or a placeholder
 */
export const mapYoutubeChannelName = (
  youtubeChannelData: YoutubeChannelInfo,
): string => {
  if (youtubeChannelData.postStatus === RequestStatus.REQUEST) {
    return YOUTUBE_CHANNEL_LOADING_PLACEHOLDER;
  }

  return youtubeChannelData.title ?? YOUTUBE_CHANNEL_NAME_PLACEHOLDER;
};

/**
 * Maps current subscription options to a specific output key
 * @param subscriptionOptions object containing autogram subscription options
 * @returns key for being displayed at output card
 */
export const getOutputOption = (
  subscriptionItem?: SubscriptionItem,
): OutputOption => {
  if (!subscriptionItem) {
    return 'EMAIL';
  }

  const platform = subscriptionItem.autoPostVideoPreference?.platform;

  if (has(OUTPUT_OPTIONS_MAP, platform)) {
    return OUTPUT_OPTIONS_MAP[platform];
  }

  return 'EMAIL';
};

/**
 * maps autopost preference data
 * @param autopostData YT composer modal output data
 * @returns mapped autopost preference data
 */
export const mapAutopostPreference = (
  autopostData: AutopostPreferenceModalOutput,
  platform: AudiogramDestination,
): AutopostPreference => {
  return {
    platform,
    options: {
      defaultLanguage: autopostData?.defaultLanguage,
      description: autopostData?.description,
      googleId: autopostData?.ytAccessData?.providerUserId,
      madeForKids: autopostData?.madeForKids,
      categoryId: autopostData?.categoryId,
      playlists: autopostData?.playlists ?? [],
      tags: autopostData?.tags ?? [],
      privacyStatus: autopostData?.visibility,
      title: autopostData?.title,
      tiktokUserId: autopostData?.tiktokUserId,
      tiktokPrivacyLevel: autopostData?.tiktokPrivacyLevel,
      commentEnabled: autopostData?.commentEnabled,
      duetEnabled: autopostData?.duetEnabled,
      stitchEnabled: autopostData?.stitchEnabled,
      brandContentToggle: autopostData?.brandContentToggle,
      brandOrganicToggle: autopostData?.brandOrganicToggle,
      instagramUserId: autopostData?.instagramUserId,
      instagramMediaType: autopostData?.instagramMediaType,
      linkedinUserId: autopostData?.linkedinUserId,
      linkedinAuthorId: autopostData?.linkedinAuthorId,
      ...(autopostData?.linkedinUserId && {
        visibility: autopostData?.visibility,
      }),
      postType: autopostData?.postType,
      facebookPageId: autopostData?.facebookPageId,
      facebookId: autopostData?.facebookId,
      twitterUserId: autopostData?.twitterUserId,
      threadsUserId: autopostData?.threadsUserId,
    },
  };
};

/**
 * Maps output data to the correct subscriptionType object for being sent to the backend.
 * As this update can only be triggered for entire episodes, only this case should be considered.
 * @param selectedOutput UI format selected output option (email or autopost to youtube)
 * @param subscriptionItem pre-update subscription item
 * @param autopostPreference selected autopost preference
 * @returns mapped AutogramSubscriptionType object for being sent over the update request
 */
const mapOutputSubscriptionTypes = (
  selectedOption: OutputOption,
  subscriptionItem: SubscriptionItem,
  autopostPreference?: AutopostPreference,
): PodcastSubscriptionType[] => {
  const { videoType, videoFrequencyPref, isCaptionEnabled } = subscriptionItem;
  const enrichedSubscriptionItem = {
    videoType,
    videoFrequency: videoFrequencyPref,
    isCaptionEnabled,
    autoPostVideoPreference:
      selectedOption === 'EMAIL' ? null : autopostPreference,
  };

  const randomClipProperties = {
    videoLengthSec: subscriptionItem.videoLengthSecPref,
    clipSelectionMethod: subscriptionItem.clipSelectionMethod,
  };
  if (subscriptionItem.videoType === VideoTypes.FULL_EPISODE) {
    return [enrichedSubscriptionItem];
  }

  return [
    {
      ...enrichedSubscriptionItem,
      ...randomClipProperties,
    },
  ];
};

/**
 * Maps UI selection and merges it with the changes made by the user for
 * generating the data to be sent with the update subscription request
 * @param data pre-update subscription data and current selected duration/key for audio selection
 * @returns mapped UpdateSubscriptionData for being sent with the update request.
 */
export const mapOutputUpdateData = (data: {
  autopostPreference?: AutopostPreference;
  selectedOption: OutputOption;
  subscription: PodcastSubscription;
  subscriptionItem: SubscriptionItem | undefined;
}): PartialUpdateAutomationData => {
  const {
    autopostPreference,
    selectedOption,
    subscription,
    subscriptionItem,
  } = data;

  const { autoCreateSubscriptionId } = subscription;

  return {
    subscriptionId: autoCreateSubscriptionId,
    subscriptionTypes: mapOutputSubscriptionTypes(
      selectedOption,
      subscriptionItem,
      autopostPreference,
    ),
  };
};
