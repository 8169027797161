import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import {
  SWITCH_ACCOUNT_VALUE,
  SwitchAccountIcon,
} from 'components/AccountSelect';
import { LinkedinOrganizationSelectorOption } from 'containers/LinkedinPostVideoModal/LinkedinOrganizationSelector/types';
import { organizationToOption } from 'containers/LinkedinPostVideoModal/LinkedinOrganizationSelector/utils';
import useLinkedinPostModalSelector from 'containers/LinkedinPostVideoModal/useLinkedinPostModalSelector';
import {
  linkedinIsLoading,
  linkedinOrganizationsSelector,
  linkedinUserDataSelector,
} from 'redux/modules/social';
import {
  UseAutopostingComposerStep,
  UseAutopostingComposerStepConfig,
} from './types';
import usePostComposerStep from './usePostComposerStep';

const useAutopostingComposerStep = (
  config: UseAutopostingComposerStepConfig,
): UseAutopostingComposerStep => {
  const {
    activeStep,
    initialValues,
    onHideModal,
    onSubmit,
    onSwitchAccount,
  } = config;

  const isActive = activeStep === 'composer';

  const {
    defaultDescription,
    defaultOrganization,
    preselectedOrganization,
  } = useLinkedinPostModalSelector({
    defaultDescription: initialValues.description,
    linkedinAuthorId: initialValues.linkedinAuthorId,
  });

  const { providerUserId } = useSelector(linkedinUserDataSelector);
  const organizations = useSelector(linkedinOrganizationsSelector);
  const isLoading = useSelector(linkedinIsLoading);

  const [prevActiveState, setPrevActiveState] = useState<boolean>(isActive);
  const [prevInitialValues, setPrevInitialValues] = useState(initialValues);
  const [prevIsLoading, setPrevIsLoading] = useState(isLoading);
  const [description, setDescription] = useState<string>(defaultDescription);
  const [organization, setOrganization] = useState<
    LinkedinOrganizationSelectorOption
  >(preselectedOrganization);

  const handleSetDefaultProps = useCallback(() => {
    setDescription(defaultDescription);
    setOrganization(preselectedOrganization);
  }, [defaultDescription, preselectedOrganization]);

  const handleSubmit = useCallback((): void => {
    onSubmit?.({
      linkedinUserId: providerUserId,
      linkedinAuthorId: organization.id,
      visibility: 'public',
      description,
    });

    handleSetDefaultProps();
  }, [
    description,
    handleSetDefaultProps,
    onSubmit,
    organization.id,
    providerUserId,
  ]);

  const handleOrganizationChange = useCallback(
    (org: LinkedinOrganizationSelectorOption) => {
      if (org.id === SWITCH_ACCOUNT_VALUE) {
        onSwitchAccount();
      } else {
        setOrganization(org);
      }
    },
    [onSwitchAccount],
  );

  const postComposer = usePostComposerStep({
    allowSubmit: Boolean(description),
    description,
    organization,
    organizations: isLoading
      ? []
      : [
          defaultOrganization,
          ...organizations.map(organizationToOption),
          {
            id: SWITCH_ACCOUNT_VALUE,
            label: 'Switch Account',
            logo: <SwitchAccountIcon />,
            value: SWITCH_ACCOUNT_VALUE,
          } as LinkedinOrganizationSelectorOption,
        ],
    onDescriptionChange: setDescription,
    onOrganizationChange: handleOrganizationChange,
    onHideModal,
    onSubmit: handleSubmit,
  });

  if (
    prevIsLoading &&
    !isLoading &&
    !organization.id &&
    Boolean(organizations.length)
  ) {
    setOrganization(preselectedOrganization);
    setPrevIsLoading(isLoading);
  }

  if (prevActiveState !== isActive) {
    handleSetDefaultProps();
    setPrevActiveState(isActive);
  }

  if (!isEqual(prevInitialValues, initialValues)) {
    handleSetDefaultProps();
    setPrevInitialValues(initialValues);
  }

  return postComposer;
};

export default useAutopostingComposerStep;
