import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import CircleProgress from 'components/CircleProgress';
import { H1, H2 } from 'components/Heading';
import Link from 'components/Link';
import EmailDisabledCallout from 'containers/EmailDisabledCallout';
import { navigationBarHiddenSelector } from 'redux/modules/display-pref/selectors';
import { videoExportEmailEnabledSelector } from 'redux/selectors';
import { getProjectsPath } from 'utils/routes';
import { block } from './utils';

export interface ProgressBlockProps {
  className?: string;
  estimatedDurationSec?: number;
  layout?: 'vertical' | 'horizontal';
  progress: number;
  /**
   * Custom message to replace the default “Your video should be ready by” text.
   * This message is displayed above the progress indicator, just before the
   * estimated processing completion time.
   */
  progressMessage?: React.ReactNode;
  exportMessage?: React.ReactNode;
  subtitle?: React.ReactNode;
  showEmailCallout?: boolean;
}

const ProgressBlock: React.FC<ProgressBlockProps> = ({
  className,
  estimatedDurationSec,
  layout = 'horizontal',
  progress,
  progressMessage = 'Your video should be ready by',
  exportMessage = 'Exporting your video',
  subtitle,
  showEmailCallout = true,
}) => {
  const exportStartDate = useMemo(() => new Date(), []);
  const videoExportEmailEnabled = useSelector(videoExportEmailEnabledSelector);
  const reducedNavigation = useSelector(navigationBarHiddenSelector);

  return (
    <div
      className={cn(block('progress-block', { [layout]: !!layout }), className)}
    >
      <div className={block('progress-bar')}>
        <CircleProgress
          percentage={progress}
          size={layout === 'horizontal' ? 'md' : undefined}
        />
      </div>
      <header className={block('header')}>
        <H1 className={block('title')}>
          {estimatedDurationSec ? (
            <>
              {progressMessage}{' '}
              {dayjs(exportStartDate)
                .add(estimatedDurationSec, 'seconds')
                .format('h:mm A')}
            </>
          ) : (
            <>{exportMessage}</>
          )}
        </H1>
        <H2 className={block('subtitle')}>
          {subtitle || (
            <>
              You don't need to wait here,
              {videoExportEmailEnabled && !reducedNavigation ? (
                <> we'll email it to you soon.</>
              ) : (
                <>
                  we'll add it to your{' '}
                  <Link to={getProjectsPath()}>Projects Tab</Link> soon.
                </>
              )}
            </>
          )}
        </H2>
        {!reducedNavigation && showEmailCallout && (
          <EmailDisabledCallout className={block('callout')} />
        )}
      </header>
    </div>
  );
};

export default ProgressBlock;
