import React from 'react';

import {
  DESTINATION_PRESETS,
  DestinationConfig,
} from 'blocks/DestinationPlatforms';
import {
  Facebook,
  InstagramReels,
  InstagramStories,
  PlusBold,
} from 'components/icons';
import { FacebookSocialCircle } from 'components/SocialCircle';
import VideoTypeIcon from '../VideoTypeIcon';

const FACEBOOK_BLUE = '#3b5998';

const facebook: DestinationConfig = {
  id: 'facebook',
  icon: <FacebookSocialCircle />,
  videoTypes: [
    {
      id: 'reel',
      title: 'Facebook Reel',
      description: 'Share a short clip to go viral',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.reel.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <InstagramReels width="15px" color="white" />
        </VideoTypeIcon>
      ),
    },
    {
      id: 'story',
      title: 'Facebook Story',
      description: 'Share an ephemeral clip for 24 hours',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.story.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <InstagramStories width="15px" color="white" />
        </VideoTypeIcon>
      ),
    },
    {
      id: 'post',
      title: 'Facebook Post',
      description: 'Clip a full segment from an episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.post.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <PlusBold width="9px" color="white" />
        </VideoTypeIcon>
      ),
    },
    {
      id: 'episode',
      title: 'Full episode',
      description: 'Add an entire podcast episode',
      icon: (
        <VideoTypeIcon
          aspectRatio={DESTINATION_PRESETS.facebook.episode.aspectRatio}
          background={FACEBOOK_BLUE}
        >
          <Facebook width="12px" color="white" />
        </VideoTypeIcon>
      ),
    },
  ],
};

export default facebook;
