import {
  goBack as goBackAction,
  push,
  replace,
} from 'connected-react-router/immutable';
import queryString from 'query-string';
import { isEmpty, isFunction, isUndefined } from 'underscore';

import routeUtils, { createRedirector } from '../../../utils/routes';
import * as embedSelectors from '../embed/selectors';
import * as notificationActions from '../notification';
import { selectors as projectSelectors } from '../project';
import { appHomeSelector } from './selectors';

/**
 *  queryParams is either an object or a function. if an object, uses
 *  the object as query params.  if a function, passes an object containing the
 *  existing query params and uses the return value of the function as the new
 *  query params
 */
export const updateEditorUrl = (
  widgetId: string,
  force?: boolean,
  queryParams?: (
    query: queryString.ParsedQuery,
  ) => queryString.ParsedQuery | queryString.ParsedQuery,
) => (dispatch, getState) => {
  const wid = widgetId || embedSelectors.embedWidgetIdSelector(getState());
  const project = projectSelectors.projectSelector(getState());
  const query = isFunction(queryParams)
    ? queryParams(queryString.parse(window.location.search))
    : queryParams;

  if (project && routeUtils.isProjectActive()) {
    const newRoute = `/project/${project.get('projectUuid')}`;

    if (routeUtils.willRouteChange({ newRoute }) || force) {
      return dispatch(
        replace({
          search: queryString.stringify(query),
          pathname: newRoute,
        }),
      );
    }

    return undefined;
  }

  if (routeUtils.isEditorActive()) {
    return dispatch(
      replace({
        pathname: '/edit',
        search: queryString.stringify({ wid, ...query }),
      }),
    );
  }

  return undefined;
};

export const goToOrphanedVideoEditor = wid => dispatch =>
  dispatch(
    push({
      pathname: '/edit',
      search: queryString.stringify({ wid }),
    }),
  );

export const goToEditor = () => dispatch => {
  dispatch(notificationActions.clearAllNotifications());
  dispatch(push('/project'));
};

/**
 * @param {Object} params
 * @param {string} params.projectId
 * @param {number} [params.recordingId]
 * @param {boolean} [params.history=true]
 * @param {boolean} [params.fromWizard=false]
 */
export const goToProject = ({
  projectId,
  recordingId,
  history = true,
  fromWizard = false,
}: {
  projectId: string;
  recordingId?: number;
  history?: boolean;
  fromWizard?: boolean;
}) => dispatch => {
  const query: { rid?: number; fromWizard?: boolean } = {};

  if (!isUndefined(recordingId)) {
    query.rid = recordingId;
  }
  if (fromWizard) {
    query.fromWizard = true;
  }

  const segments = ['/project'];
  projectId && segments.push(projectId);

  const method = history ? push : replace;

  dispatch(
    method({
      pathname: segments.join('/'),
      search: isEmpty(query) ? undefined : queryString.stringify(query),
    }),
  );
};

export const goToProjects = () => dispatch => dispatch(push('/projects'));

export const goToCreate = () => dispatch => dispatch(push('/create'));

export const goToLabs = () => dispatch => dispatch(push('/labs'));

export const goToNewProject = () => dispatch =>
  dispatch(replace('/new-project'));

export const goToHome = () => dispatch => dispatch(push('/home'));

export const goToAppHome = () => dispatch => {
  dispatch(push(appHomeSelector()));
};

export const goToBetaSignUp = createRedirector(
  '/sign-up',
  location => dispatch => dispatch(push(location)),
);

export const goToLogin = createRedirector('/login', location => dispatch =>
  dispatch(push(location)),
);

export const goToResetPassword = () => dispatch =>
  dispatch(push('/forgot-password'));

export const goBack = () => dispatch => dispatch(goBackAction());

export const goToSettings = () => dispatch => dispatch(push('/settings'));

export const goToPurchaseSuccess = tier => dispatch =>
  dispatch(push(`/welcome-to-${tier}`));

export const goToDownload = widgetId => push(`/download/${widgetId}`);

export const goToSuggestedClips = (creationRequestId: number) => dispatch =>
  dispatch(push(`/suggested-clips/${creationRequestId}`));
