import { X } from '@sparemin/blockhead';
import React from 'react';

import {
  DESTINATION_PRESETS,
  DestinationPreset,
} from 'blocks/DestinationPlatforms';
import FacebookRounded from 'components/icons/FacebookRounded';
import InstagramRounded from 'components/icons/InstagramRounded';
import TiktokRounded from 'components/icons/TiktokRounded';
import YoutubeRounded from 'components/icons/YoutubeRounded';
import bem from 'utils/bem';
import { SocialPresetOption, SocialPresetPopoverConfig } from './types';

export const block = bem('audio-clipper');

export const SOCIAL_PRESET_BLOCKING_OPTIONS: DestinationPreset['key'][] = [
  'InstagramReel',
  'InstagramStory',
  'InstagramPost',
  'FacebookReel',
  'FacebookStory',
  'FacebookPost',
  'YouTubeShort',
  'TikTokStory',
  'TikTokPost',
];

export const INSTAGRAM_OPTIONS: SocialPresetOption[] = [
  {
    ...DESTINATION_PRESETS.instagram.reel,
    text: 'REEL',
  },
  {
    ...DESTINATION_PRESETS.instagram.story,
    text: 'STORY',
  },
  {
    ...DESTINATION_PRESETS.instagram.post,
    text: 'POST',
  },
];

export const FACEBOOK_OPTIONS: SocialPresetOption[] = [
  {
    ...DESTINATION_PRESETS.facebook.reel,
    text: 'REEL',
  },
  {
    ...DESTINATION_PRESETS.facebook.story,
    text: 'STORY',
  },
  {
    ...DESTINATION_PRESETS.facebook.post,
    text: 'POST',
  },
];

export const YOUTUBE_OPTIONS: SocialPresetOption[] = [
  {
    ...DESTINATION_PRESETS.youtube.short,
    text: 'SHORT',
  },
  {
    ...DESTINATION_PRESETS.youtube.clip,
    text: 'CLIP',
  },
];

export const TIKTOK_OPTIONS: SocialPresetOption[] = [
  {
    ...DESTINATION_PRESETS.tiktok.story,
    text: 'STORY',
  },
  {
    ...DESTINATION_PRESETS.tiktok.post,
    text: 'POST',
  },
];

export const MORE_OPTIONS: SocialPresetOption[] = [
  {
    ...DESTINATION_PRESETS.more.twitter,
    icon: <X width="12px" height="10px" className={block('x-icon')} />,
    text: 'X (TWITTER)',
  },
  {
    ...DESTINATION_PRESETS.more.linkedin,
    icon: 'linkedin',
    text: 'LINKEDIN',
  },
  {
    ...DESTINATION_PRESETS.more.snapchat,
    key: 'SnapChat',
    icon: 'snapchat-ghost',
    text: 'SNAPCHAT',
  },
  {
    ...DESTINATION_PRESETS.more.fullclip,
    hideDuration: true,
    icon: 'arrows-alt-h',
    text: 'SELECT FULL CLIP',
    useMaxAvailableDuration: true,
  },
];

export const SOCIAL_PRESET_POPOVER_OPTIONS: Record<
  Exclude<DestinationPreset['key'], 'Threads'>,
  SocialPresetPopoverConfig
> = {
  Custom: {
    icon: React.Fragment,
    ...DESTINATION_PRESETS.more.fullclip,
  },
  FacebookEpisode: {
    icon: FacebookRounded,
    ...DESTINATION_PRESETS.facebook.episode,
  },
  FacebookPost: {
    icon: FacebookRounded,
    ...DESTINATION_PRESETS.facebook.post,
  },
  FacebookReel: {
    icon: FacebookRounded,
    ...DESTINATION_PRESETS.facebook.reel,
  },
  FacebookStory: {
    icon: FacebookRounded,
    ...DESTINATION_PRESETS.facebook.story,
  },
  FullClip: {
    icon: React.Fragment,
    ...DESTINATION_PRESETS.more.fullclip,
  },
  InstagramPost: {
    icon: InstagramRounded,
    ...DESTINATION_PRESETS.instagram.post,
  },
  InstagramReel: {
    icon: InstagramRounded,
    ...DESTINATION_PRESETS.instagram.reel,
  },
  InstagramStory: {
    icon: InstagramRounded,
    ...DESTINATION_PRESETS.instagram.story,
  },
  LinkedIn: {
    icon: React.Fragment,
    ...DESTINATION_PRESETS.more.linkedin,
  },
  SnapChat: {
    icon: React.Fragment,
    ...DESTINATION_PRESETS.more.snapchat,
  },
  TikTokPost: {
    icon: TiktokRounded,
    ...DESTINATION_PRESETS.tiktok.post,
  },
  TikTokStory: {
    icon: TiktokRounded,
    ...DESTINATION_PRESETS.tiktok.story,
  },
  Twitter: {
    icon: React.Fragment,
    ...DESTINATION_PRESETS.more.twitter,
  },
  YouTubeClip: {
    icon: YoutubeRounded,
    ...DESTINATION_PRESETS.youtube.clip,
  },
  YouTubeEpisode: {
    icon: YoutubeRounded,
    ...DESTINATION_PRESETS.youtube.episode,
  },
  YouTubeShort: {
    icon: YoutubeRounded,
    ...DESTINATION_PRESETS.youtube.short,
  },
};

export const isPresetBlocked = (
  selectedPreset?: DestinationPreset | DestinationPreset['key'],
): boolean =>
  !!SOCIAL_PRESET_BLOCKING_OPTIONS.includes(
    typeof selectedPreset === 'string' ? selectedPreset : selectedPreset?.key,
  );
