import { isString } from 'underscore';
import { FeedbackType } from '../podcast-service';
import { createApiAction } from '../utils';
import {
  ACTION_KEY,
  CreateCreationClipSuggestionArgs,
  CreationAction,
  CreationActionOptions,
  EnableCreationClipSuggestionArgs,
  GetCreationAction,
  GetCreationClipSuggestionAction,
  GetCreationClipSuggestionArgs,
  GetCreationClipSuggestionsArgs,
  GetCreationClipSuggestionsProgressArgs,
  ServiceMethod,
} from './types';

function apiCallAction<M extends ServiceMethod, A>(method: M, args: A) {
  return createApiAction(ACTION_KEY, method, args);
}

export function create(opts: CreationActionOptions): CreationAction {
  const { audioSource, videoSource, baseConfigJson, ...restOpts } = opts;

  return apiCallAction(ServiceMethod.CREATE, [
    {
      ...restOpts,
      ...(audioSource && {
        [isString(audioSource) ? 'audioUrl' : 'audioFile']: audioSource,
      }),
      ...(videoSource && {
        [isString(videoSource) ? 'videoUrl' : 'videoFile']: videoSource,
      }),
      baseConfigJson: JSON.stringify(baseConfigJson),
    },
  ]);
}

export function getCreation(id: number): GetCreationAction {
  return apiCallAction(ServiceMethod.CREATION_GET, [id]);
}

export function enableCreationClipSuggestion(
  creationRequestId: number,
  suggestionId: number,
) {
  return apiCallAction(ServiceMethod.ENABLE_CREATION_CLIP_SUGGESTION, [
    creationRequestId,
    suggestionId,
  ] as EnableCreationClipSuggestionArgs);
}

export function getCreationClipSuggestions(creationRequestId: number) {
  return apiCallAction(ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS, [
    creationRequestId,
  ] as GetCreationClipSuggestionsArgs);
}

export function getCreationClipSuggestion(
  creationRequestId?: number,
  suggestionId?: number,
): GetCreationClipSuggestionAction {
  return apiCallAction(ServiceMethod.GET_CREATION_CLIP_SUGGESTION, [
    creationRequestId,
    suggestionId,
  ] as GetCreationClipSuggestionArgs);
}

export function createCreationClipSuggestionFeedback(
  feedbackType: FeedbackType,
  creationRequestId?: number,
  suggestionId?: number,
) {
  return apiCallAction(ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION_FEEDBACK, [
    feedbackType,
    creationRequestId,
    suggestionId,
  ]);
}

export function createCreationClipSuggestion(
  creationRequestId: number,
  newTrimStartMillis: number,
  newTrimEndMillis: number,
) {
  return apiCallAction(ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION, [
    creationRequestId,
    newTrimStartMillis,
    newTrimEndMillis,
  ] as CreateCreationClipSuggestionArgs);
}

export function getCreationClipSuggestionsProgress(creationRequestId: number) {
  return apiCallAction(ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS_PROGRESS, [
    creationRequestId,
  ] as GetCreationClipSuggestionsProgressArgs);
}
