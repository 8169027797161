import { Spacer } from '@sparemin/blockhead';
import React from 'react';

import FontAwesome from 'components/FontAwesome';
import { formatDurationMillis, millisToHours } from 'utils/time';
import { block } from './utils';

export interface ClipTimecodesProps {
  clipEndMillis: number;
  clipStartMillis: number;
}

function formatDuration(value: number) {
  return formatDurationMillis(value, {
    hour: millisToHours(value) >= 1 ? 'numeric' : undefined,
    minute: '2-digit',
    second: '2-digit',
    trim: false,
  });
}

const ClipTimecodes: React.FC<ClipTimecodesProps> = ({
  clipEndMillis,
  clipStartMillis,
}) => (
  <Spacer
    orientation="horizontal"
    space="6px"
    align="center"
    justify="center"
    className={block('timecodes-container')}
  >
    <FontAwesome icon="clock" />

    <span className={block('timecodes')}>
      {formatDuration(clipStartMillis)} - {formatDuration(clipEndMillis)}
    </span>
  </Spacer>
);

export default ClipTimecodes;
