import cn from 'classnames';
import React, { useCallback } from 'react';
import { InstagramAccountOption } from './types';
import { block } from './utils';

export interface AccountOptionProps {
  option: InstagramAccountOption;
  selected?: boolean;
  onClick?: (newOption: InstagramAccountOption) => void;
}

const AccountOption: React.FC<AccountOptionProps> = ({
  option,
  selected,
  onClick,
}) => {
  const handleClick = useCallback((): void => {
    onClick(option);
  }, [onClick, option]);

  const isImg = typeof option?.logo === 'string';
  return (
    <div
      className={cn(block('account-option', { selected }))}
      onClick={handleClick}
    >
      {isImg ? (
        <img
          src={option?.logo as string}
          className={block('account-option--logo')}
        />
      ) : (
        option?.logo
      )}

      <span className={block('account-option--label')}>{option?.label}</span>
    </div>
  );
};

export default AccountOption;
