import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaxDurationShareButton, {
  MaxDurationShareButtonProps,
} from 'blocks/DownloadPage/components/MaxDurationShareButton';
import { TwitterButton, TwitterButtonProps } from 'components/SocialButton';
import TwitterPostVideoModal from 'containers/TwitterPostVideoModal/TwitterPostVideoModal';
import useTwitterAuthenticator from 'hooks/useTwitterAuthenticator';
import { videoIdSelector } from 'redux/modules/download';
import {
  postTwitterVideo,
  twitterProviderUserIdSelector,
} from 'redux/modules/social';
import { FatalError } from 'utils/FatalError';
import { createChainedFunction } from 'utils/functions';
import { useSocialSection } from '../DownloadPageSocialSection/SocialSectionContext';
import useTwitterPostButtonDispatch from './useTwitterPostButtonDispatch';
import { MAX_VIDEO_DURATION_SEC } from './utils';

type PickedButtonProps = Pick<TwitterButtonProps, 'disabled'>;

type PickedMaxDurationButtonProps = Pick<
  MaxDurationShareButtonProps,
  'videoDurationSec'
>;
export interface TwitterPostButtonProps
  extends PickedButtonProps,
    PickedMaxDurationButtonProps {}

const TwitterPostButton: React.FC<TwitterPostButtonProps> = ({
  disabled,
  videoDurationSec,
}) => {
  const dispatch = useDispatch();
  const { onClick } = useTwitterPostButtonDispatch();
  const { activeModal, toggleModalVisibility } = useSocialSection();
  const providerUserId = useSelector(twitterProviderUserIdSelector);

  const [error, setError] = useState<string>(undefined);
  const [shouldReauthenticate, setShouldReauthenticate] = useState<boolean>(
    false,
  );

  const handleAuthSuccess = useCallback(() => {
    setError(undefined);
  }, []);

  const handleModalHide = useCallback(() => {
    setError(undefined);
    toggleModalVisibility('twitter', false);
  }, [toggleModalVisibility]);

  const handleError = useCallback(
    (err: Error) => {
      if (err instanceof FatalError) {
        setError('Error authenticating with Twitter');
        setShouldReauthenticate(true);
        toggleModalVisibility('twitter', false);
      } else {
        setError(err.message);
        toggleModalVisibility('twitter', true);
      }
    },
    [toggleModalVisibility],
  );

  const handleSubmit = useCallback(
    ({ twitterUserId, description }) => {
      dispatch((_, getState) => {
        const videoId = videoIdSelector(getState());
        dispatch(
          postTwitterVideo(videoId, {
            twitterUserId,
            description,
            providerUserId,
          }),
        );
      });
    },
    [dispatch, providerUserId],
  );

  const handleClick = useCallback(() => {
    toggleModalVisibility('twitter', true);
  }, [toggleModalVisibility]);

  const { authenticating, withAuthentication } = useTwitterAuthenticator({
    onError: handleError,
    onAuthSuccess: handleAuthSuccess,
    force: shouldReauthenticate,
  });

  return (
    <>
      <MaxDurationShareButton
        maxVideoDurationSec={MAX_VIDEO_DURATION_SEC}
        platform="X"
        videoDurationSec={videoDurationSec}
      >
        <TwitterButton
          disabled={disabled || authenticating}
          onClick={createChainedFunction(
            onClick,
            withAuthentication(handleClick),
          )}
          params={withAuthentication}
          size="small"
        />
      </MaxDurationShareButton>

      <TwitterPostVideoModal
        show={activeModal === 'twitter'}
        onHide={handleModalHide}
        onSubmit={handleSubmit}
        error={error}
      />
    </>
  );
};

export default TwitterPostButton;
