import { Flipped, Flipper } from 'react-flip-toolkit';
import Slider from 'react-slick';
import {
  AudioClipperComponent,
  RegionUpdateAction,
} from 'components/AudioClipper';
import { BemCssTransitionProps } from 'components/BemCssTransition';
import VideoPlayer from 'components/VideoPlayer';
import { AsyncAudioClipperProps } from 'containers/AsyncAudioClipper';
import { ClipSuggestionStatus } from 'redux/middleware/api/podcast-service';
import { AspectRatioName, ClipSuggestionDislikeReason, Omit } from 'types';
import { useNavigation } from './containers/ClipSelectNavigationContext/navigation-machine';

export type ClipSelectNavigationContextType = ReturnType<typeof useNavigation>;

export type Region = AsyncAudioClipperProps['region'];

export type ClipSelectPageView = 'select' | 'clip';

export interface ClipSuggestion {
  id: number;
  startMillis: number;
  endMillis: number;
  text?: string;
  widgetId?: string;
  videoUrl?: string;
  status: ClipSuggestionStatus;
}

export interface ClipsPageInfo {
  title?: string;
  subtitle?: string;
  artwork?: string;
  aspectRatioName?: AspectRatioName;
  artworkAspectRatioName?: AspectRatioName;
  entireAudioInstanceId?: number;
  videoUrl?: string;
  mediaDurationMillis?: number;
  publishedAtMillis?: number;
}

export enum ClipSelectionType {
  DEFAULT = 'Default',
  SUGGESTED_CLIP = 'SuggestedClip',
  CUSTOM_CLIP = 'CustomClip',
}

export interface FormValues {
  region: Region;
  lastClipSelectionType: ClipSelectionType;
  isCaptionEnabled: boolean;
  clipSuggestion: ClipSuggestion;
}

export type CreateVideo = (values: FormValues) => void;

export type VideoPlayerRefs = Record<string, React.RefObject<HTMLVideoElement>>;

export interface VideoPlayerControls {
  playing: boolean;
  onPlay: () => void;
  onPause: () => void;
}

export type ClipSelectMediaType = 'audio' | 'video';

export interface ClipSelectContextType {
  activeSuggestionId: number;
  dislikedSuggestionIds: number[];
  playerRef: React.RefObject<HTMLVideoElement>;
  videoPlayerRefs: VideoPlayerRefs;
  audioClipperRef: React.RefObject<AudioClipperComponent>;
  videoPlayer: VideoPlayer;
  setVideoPlayer: (videoPlayer: VideoPlayer) => void;
  playing: boolean;
  region?: Region;
  removedSuggestionIds: number[];
  removeSuggestion: (id: number) => void;
  selectedRegion?: Region;
  setActiveSuggestionId: (id: number) => void;
  isClipSuggestionLoading: boolean;
  suggestionIds: number[] | undefined;
  onSuggestionSelect: (region: Region) => void;
  onRegionChange: (region: Region, action?: RegionUpdateAction) => void;
  onAdjust: () => void;
  onSubmit: (isCaptionsEnabled?: boolean) => void;
  onSelectClip: (isCaptionsEnabled?: boolean) => Promise<number | void>;
  togglePlayback: () => void;
  visibleSuggestionIds: number[];
  clipSuggestion: ClipSuggestion;
  hasClipSuggestions: boolean;
  suggestedClips: ClipSuggestion[];
  clipsPageInfo: ClipsPageInfo;
  videoPlayerControls: VideoPlayerControls;
  mediaType: ClipSelectMediaType;
  addVideoPlayerRef: (suggestionId: number) => void;
  onDislikeClip?: (
    reason: ClipSuggestionDislikeReason,
    clipSuggestion: ClipSuggestion,
  ) => void;
}

type AnimateableComponents =
  | 'clipper'
  | 'clipSelector'
  | 'closeClipperButton'
  | 'export'
  | 'footer'
  | 'footerCta'
  | 'header'
  | 'intro'
  | 'learnButton'
  | 'loader';

type FlipperProps = Omit<
  React.ComponentPropsWithoutRef<typeof Flipper>,
  'children'
>;
type FlippedProps = Omit<
  React.ComponentPropsWithoutRef<typeof Flipped>,
  'children'
>;

type SuggestionCardAnimationConfig = Record<
  'sizer' | 'sizerInverter' | 'vanisher',
  FlippedProps & { className: string }
>;

export interface ClipSelectAnimationContextType
  extends Record<AnimateableComponents, BemCssTransitionProps> {
  clipSelectorFlipper: FlipperProps;
  clipSelectorSliderRef: React.MutableRefObject<Slider>;
  createSuggestionCardAnimationConfig: (
    id: number,
  ) => SuggestionCardAnimationConfig;
}

export interface ClipSelectTrackingContextType {
  onAddClip: () => void;
  onAdjustClip: () => void;
  onCaptionsModalSubmit: (enabled: boolean) => void;
  onDislikeClip: (reason: string) => void;
  onSelectOwnClip: () => void;
}
