import * as React from 'react';
import { noop } from 'underscore';

import AccountSelect, { SWITCH_ACCOUNT_VALUE } from 'components/AccountSelect';
import { Overrides, SelectProps } from 'components/Select';
import PageOption from './PageOption';
import { Option } from './types';

type PickedSelectProps = Pick<SelectProps<Option>, 'onChange' | 'value'>;

export interface FacebookPageSelectorProps extends PickedSelectProps {
  onSwitchAccountClick?: () => void;
  options: Option[];
}

const selectComponentOverrides: Overrides<Option> = {
  option: {
    component: props => <PageOption {...props} />,
  },
};

const FacebookPageSelector: React.SFC<FacebookPageSelectorProps> = ({
  onChange,
  onSwitchAccountClick,
  options,
  value,
}) => {
  const handleChange = (val: Option) => {
    if (val.value === SWITCH_ACCOUNT_VALUE) {
      onSwitchAccountClick();
    } else {
      onChange(val);
    }
  };

  return (
    <AccountSelect
      onChange={handleChange}
      options={options}
      overrides={selectComponentOverrides}
      value={value}
    />
  );
};

FacebookPageSelector.defaultProps = {
  onChange: noop,
  onSwitchAccountClick: noop,
};

// TODO maybe put in types file
export { Option };

export default FacebookPageSelector;
