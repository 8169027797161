import cn from 'classnames';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { isUndefined, noop } from 'underscore';
import {
  DESTINATION_PRESETS,
  DestinationPlatform,
  VideoTypeConfig,
} from 'blocks/DestinationPlatforms';
import { RejectedReason } from 'components/FileUploader';
import { renderTemplateDimensionsInfoByAspectRatio } from 'components/FileUploader/utils';
import LinkButton from 'components/LinkButton';
import Tooltip from 'components/Tooltip';
import { UseHook } from 'components/UseHook';
import Wizard, {
  IndexedStep,
  Navigator,
  Step,
  WizardProps,
} from 'components/Wizard';
import { MediaImportedArgs } from 'containers/MediaUploadModal/types';
import useTranscriptionBalance from 'hooks/useTranscriptionBalance';
import { Tier } from 'redux/middleware/api/plan-service';
import {
  MixpanelWizardStep,
  OnWizardNextProps,
} from 'redux/modules/mixpanel/types';
import { VideoUploadArgs } from 'redux/modules/video-upload';
import { AspectRatioName } from 'types';
import { ApplicationError } from 'utils/ApplicationError';
import { getAspectRatioValue } from 'utils/aspect-ratio';
import bem from 'utils/bem';

import { DEFAULT_PROJECT_NAME } from 'utils/constants';
import { createChainedFunction } from 'utils/functions';
import { round } from 'utils/numbers';
import { capitalize } from 'utils/string';
import { millisToSec } from 'utils/time';
import AspectRatioStep from '../AspectRatioStep';
import ClipVideoStep from '../ClipVideoStep';
import UploadVideoStep from '../UploadVideoStep';
import useVideoWizardBlocker from './useVideoWizardBlocker';
import VideoWizardDestinationStep from './VideoWizardDestinationStep';
import { VideoWizardObjective } from './VideoWizardObjectiveStep/types';
import EpisodeWizardSourceStep from './VideoWizardObjectiveStep/VideoWizardObjectiveStep';
import VideoWizardProgressStep from './VideoWizardProgressStep';
import VideoWizardVideoExportStep from './VideoWizardVideoExportStep';

type PickedWizardProps = Pick<WizardProps, 'onFileUpload'>;

interface IProps
  extends PickedWizardProps,
    Partial<RouteChildrenProps<{}, { file: any }>> {
  tier: Tier;
  onCancel?: (nav: Navigator) => void;
  onClickSampleFile?: () => void;
  onError?: (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => void;
  onMount?: () => void;
  onSubmit?: (
    args: VideoUploadArgs,
    projectName: string,
    mediaImportedArgs: MediaImportedArgs,
  ) => void;
  onStepChange?: (stepId: string, props?: OnWizardNextProps) => void;
  onCompleted?: () => void;
}

interface IState {
  aspectRatioName: AspectRatioName;
  step: string;
  clipEndMillis: number;
  clipStartMillis: number;
  videoSrc: string;
  isSampleVideo: boolean;
  language: string;
  mediaImportedArgs: MediaImportedArgs;
  objective: VideoWizardObjective;
  projectName: string;
  isExportingVideo: boolean;
}

enum Steps {
  ASPECT_RATIO = 0,
  UPLOAD_VIDEO = 1,
  CLIP_VIDEO = 2,
}

const clipMaxDuration = spareminConfig.videoClipMaxDurationSeconds * 1000;

const block = bem('video-wizard');

export default class VideoWizard extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    onCancel: noop,
    onClickSampleFile: noop,
    onError: noop,
    onFileUpload: noop,
    onMount: noop,
    onStepChange: noop,
    onSubmit: noop,
  };

  private wizard: Wizard;
  private videoDurationMillis: number;

  // will only be set if user uploads file, not if user clicks the sample video link
  private videoFile: File;

  public state: IState = {
    step: undefined,
    aspectRatioName: 'square',
    clipEndMillis: undefined,
    clipStartMillis: 0,
    isSampleVideo: false,
    language: 'en-US',
    videoSrc: undefined,
    mediaImportedArgs: undefined,
    objective: undefined,
    projectName: DEFAULT_PROJECT_NAME,
    isExportingVideo: false,
  };

  public componentDidMount() {
    const { onMount } = this.props;

    onMount();
  }

  private clearDefaultFile = () => {
    const { location, history } = this.props;
    if (location.state?.file) {
      const { state, ...locationWithoutState } = location;
      history.replace(locationWithoutState);
    }
  };

  private handleStepChange = (to: IndexedStep, from?: IndexedStep) => {
    const { objective } = this.state;
    const { onStepChange } = this.props;

    /*
     * user already selected a video but navigated back to a step where a video shouldn't be
     * selected.
     */
    if (
      from &&
      from.index >= Steps.UPLOAD_VIDEO &&
      to.index <= Steps.UPLOAD_VIDEO
    ) {
      this.videoFile = undefined;
      this.setVideoSrc();
      this.setState(prev => ({
        ...prev,
        clipEndMillis: undefined,
      }));
    }

    this.setState(prev => ({ ...prev, step: to.stepId }));

    const isAspectRatioStep =
      from?.stepId === 'objective' && objective === 'SingleClip';

    onStepChange(from?.stepId, {
      type: 'videoTranscription',
      step: isAspectRatioStep
        ? 'Choose Aspect Ratio'
        : (from?.stepId as MixpanelWizardStep),
      ...(from?.stepId === 'objective' &&
        !isAspectRatioStep && { objectiveSelection: objective }),
    });
  };

  private handleAspectRatioSelect = (__, name: AspectRatioName) =>
    this.setState({
      aspectRatioName: name,
    });

  private handleObjectiveSelect = (newObjective: VideoWizardObjective) => {
    const { onStepChange } = this.props;

    this.setState({
      objective: newObjective,
    });

    if (newObjective === 'MultipleClips') {
      this.wizard.next();
    }

    if (newObjective === 'SingleClip') {
      onStepChange('objective', {
        type: 'videoTranscription',
        step: 'objective',
        objectiveSelection: 'SingleClip',
      });
    }
  };

  private handleReturnToAllOptions = () => {
    this.setState({
      objective: undefined,
    });
  };

  private handleFileUploadAccepted = (file: File) => {
    const { onFileUpload } = this.props;

    this.clearDefaultFile();

    this.videoFile = file;
    this.setVideoSrc(URL.createObjectURL(file));

    this.setState({
      projectName: file.name,
    });

    onFileUpload(file);
  };

  private handleFileUploadRejected = (
    error: ApplicationError,
    file?: File,
    reason?: RejectedReason,
  ) => {
    const { onError } = this.props;

    this.clearDefaultFile();

    onError?.(error, file, reason);
  };

  private handleSampleVideoClick = () => {
    const { onClickSampleFile } = this.props;
    onClickSampleFile();
    this.setVideoSrc(spareminConfig.sampleVideoUrl, true);
    this.wizard.next();
  };

  private handleMediaAccepted = (
    mediaImportedArgs: MediaImportedArgs,
  ): void => {
    if (!mediaImportedArgs) {
      return;
    }

    this.setVideoSrc(mediaImportedArgs.previewUrl, false);
    this.setState({
      mediaImportedArgs,
      projectName: mediaImportedArgs.title,
    });
  };

  private handleVideoExportStart = () =>
    this.setState({
      isExportingVideo: true,
    });

  private handleLanguageSelect = (language: string) =>
    this.setState({ language });

  private handleVideoDurationChange = (millis: number) => {
    this.videoDurationMillis = millis;
    this.setState({
      clipEndMillis: Math.min(millis, clipMaxDuration),
    });
  };

  private handleClipMillisChange = (startMillis: number, endMillis: number) =>
    this.setState({
      clipEndMillis: round(endMillis),
      clipStartMillis: round(startMillis),
    });

  private handleVideoSrcRevoke = () => {
    const { videoSrc, mediaImportedArgs } = this.state;

    // Might not (but likely will) have an object url here but no harm in calling it either way.
    URL.revokeObjectURL(videoSrc);

    if (mediaImportedArgs) {
      URL.revokeObjectURL(mediaImportedArgs.previewUrl);
    }
  };

  private handleSubmit = () => {
    const {
      aspectRatioName,
      clipEndMillis,
      clipStartMillis,
      language,
      videoSrc,
      mediaImportedArgs,
      projectName,
    } = this.state;

    this.handleVideoSrcRevoke();

    const { onSubmit } = this.props;

    onSubmit(
      {
        aspectRatioName,
        src: this.videoFile || videoSrc,
        durationMillis: this.videoDurationMillis,
        trimStartMillis: clipStartMillis,
        trimEndMillis: clipEndMillis,
        language,
        isClipSuggestion: false,
      },
      projectName,
      mediaImportedArgs,
    );
  };

  private handleDestinationChosen = (
    platform: DestinationPlatform,
    videoType?: VideoTypeConfig,
  ) => {
    this.wizard.next();

    const platformInfo =
      platform in DESTINATION_PRESETS
        ? DESTINATION_PRESETS?.[platform]?.[videoType.id]
        : DESTINATION_PRESETS.more?.[platform];

    const clipSuggestionLengthSecs = millisToSec(platformInfo?.durationMs);
    const aspectRatioName = platformInfo?.aspectRatio;

    const {
      clipEndMillis,
      clipStartMillis,
      language,
      videoSrc,
      mediaImportedArgs,
      projectName,
    } = this.state;

    this.handleVideoSrcRevoke();

    const { onSubmit } = this.props;

    onSubmit(
      {
        aspectRatioName,
        src: this.videoFile || videoSrc,
        durationMillis: this.videoDurationMillis,
        trimStartMillis: clipStartMillis,
        trimEndMillis: clipEndMillis,
        language,
        platform,
        videoType,
        clipSuggestionLengthSecs,
        isClipSuggestion: true,
      },
      projectName,
      mediaImportedArgs,
    );
  };

  private setVideoSrc(src?: string, isSample: boolean = false) {
    this.setState({
      isSampleVideo: !src ? undefined : isSample,
      videoSrc: !src ? undefined : src,
    });
  }

  private getSteps({
    canTranscribe,
    newBalanceMillis,
    outOfTimeMessage,
    showBalance,
  }: ReturnType<typeof useTranscriptionBalance>): Step[] {
    const { onCompleted, onError, tier, location } = this.props;

    const {
      aspectRatioName,
      clipEndMillis,
      clipStartMillis,
      isSampleVideo,
      language,
      videoSrc,
      objective,
      isExportingVideo,
    } = this.state;

    const isSingleClip = objective === 'SingleClip';
    const isUndefinedOrMultipleClipsObjective = !objective || !isSingleClip;

    return [
      {
        completedName: isUndefinedOrMultipleClipsObjective
          ? 'Objective'
          : capitalize(aspectRatioName),
        component: isUndefinedOrMultipleClipsObjective ? (
          <EpisodeWizardSourceStep
            onSelectObjective={this.handleObjectiveSelect}
          />
        ) : (
          <AspectRatioStep
            onSelect={this.handleAspectRatioSelect}
            value={aspectRatioName}
          />
        ),
        description: isSingleClip && (
          <LinkButton
            uppercase
            theme="cta"
            size="md"
            className={block('back-link')}
            onClick={this.handleReturnToAllOptions}
          >
            ← return to all options
          </LinkButton>
        ),
        name: 'Objective',
        stepId: 'objective',
        title: isSingleClip && 'Select an aspect ratio',
        ...(isUndefinedOrMultipleClipsObjective && {
          renderCancelButton: () => null,
          renderNextButton: () => null,
        }),
      },
      {
        completedName: 'Video Selected',
        component: (
          <UploadVideoStep
            templateDimensionsInfo={
              objective === 'MultipleClips'
                ? null
                : renderTemplateDimensionsInfoByAspectRatio(
                    getAspectRatioValue(aspectRatioName),
                  )
            }
            uploadMethod="mediaImporter"
            language={language}
            onError={this.handleFileUploadRejected}
            onFileAccepted={this.handleFileUploadAccepted}
            onLanguageSelect={this.handleLanguageSelect}
            onMediaAccepted={this.handleMediaAccepted}
            /*
             * clicking the sample video should automatically advance to the next step, so the
             * isSampleVideo check is here to prevent the uploader from flashing the "selected
             * video" state when transitioning to the next step
             */
            videoSelected={!isSampleVideo && !isUndefined(videoSrc)}
            footer={
              <LinkButton onClick={this.handleSampleVideoClick}>
                No video file? Try our sample.
              </LinkButton>
            }
            className={block('uploader')}
            tier={tier}
            defaultFile={location.state?.file}
          />
        ),
        name: 'Upload Video',
        renderNextButton: props => (
          <Wizard.Button
            {...props}
            disabled={isUndefined(videoSrc)}
            theme="next"
          >
            Next
          </Wizard.Button>
        ),
        stepId: 'uploadVideo',
      },
      {
        completedName: 'Video Clipped',
        component: isUndefinedOrMultipleClipsObjective ? (
          <VideoWizardDestinationStep
            onDestinationChosen={this.handleDestinationChosen}
          />
        ) : (
          <ClipVideoStep
            aspectRatioName={aspectRatioName}
            clipEndMillis={clipEndMillis}
            clipStartMillis={clipStartMillis}
            onDurationChange={this.handleVideoDurationChange}
            onSelectedMillisChange={this.handleClipMillisChange}
            src={videoSrc}
            showTranscriptionBalance={showBalance}
            transcriptionBalanceMillis={newBalanceMillis}
          />
        ),
        description:
          isSingleClip &&
          'Clip your video by dragging the handles at either end of the blue box.',
        name: isUndefinedOrMultipleClipsObjective
          ? 'Clip Selection'
          : 'Clip Video',
        renderNextButton: ({ className, ...props }) => {
          if (isUndefinedOrMultipleClipsObjective) {
            return null;
          }

          return (
            <Tooltip
              content={outOfTimeMessage}
              show={!canTranscribe ? undefined : false}
              placement="top"
              id="video-clip-tooltip"
            >
              <div
                className={cn(
                  block('create-button-wrapper', {
                    disabled: !canTranscribe,
                  }),
                  className,
                )}
              >
                <Wizard.Button
                  {...props}
                  className={block('create-button')}
                  disabled={!canTranscribe}
                  onClick={createChainedFunction(
                    this.handleSubmit,
                    props.onClick,
                  )}
                  theme="next"
                >
                  create project
                </Wizard.Button>
              </div>
            </Tooltip>
          );
        },
        ...(isUndefinedOrMultipleClipsObjective && {
          renderCancelButton: () => null,
        }),
        stepId: isSingleClip ? 'clipVideo' : 'destination',
      },
      {
        component: !isExportingVideo ? (
          <VideoWizardProgressStep
            {...{ isSingleClip, onCompleted, onError }}
            onVideoExportStart={this.handleVideoExportStart}
          />
        ) : (
          <VideoWizardVideoExportStep onError={onError} />
        ),
        name: 'submitting',
        stepId: 'submitting',
        renderCancelButton: () => null,
        renderNextButton: () => null,
        showInNav: false,
      },
    ];
  }

  public render() {
    const { onCancel } = this.props;
    const { step, clipEndMillis, clipStartMillis } = this.state;

    return (
      <UseHook hook={useVideoWizardBlocker} args={[step]}>
        {onStepClick => (
          <UseHook
            hook={useTranscriptionBalance}
            args={[
              {
                durationMillis: clipEndMillis - clipStartMillis,
                transcriptionEnabled: true,
              },
            ]}
          >
            {transcriptionBalance => (
              <Wizard
                canBacktrack={step !== 'submitting'}
                className={block()}
                bodyClassName={block('body', { [step]: true })}
                onCancelClick={onCancel}
                onStepChange={this.handleStepChange}
                onStepClick={onStepClick}
                steps={this.getSteps(transcriptionBalance)}
                ref={el => {
                  this.wizard = el;
                }}
              />
            )}
          </UseHook>
        )}
      </UseHook>
    );
  }
}

export { IProps as VideoWizardProps };
