import { configure as configureApi, SuperAgentClient } from '@sparemin/api-sdk';
import { configure as configureAuth, sso } from '@sparemin/auth';
import { Action, DeepPartial, Reducer, StoreEnhancerStoreCreator } from 'redux';

const configEnv = {
  development: 'dev',
  local: 'local',
  production: 'prod',
}[process.env.NODE_ENV];

configureAuth({
  appleRedirectURI: spareminConfig.appleAuth.redirectURI,
  application: 'makeHeadliner',
  environment: configEnv,
});

configureApi({
  client: new SuperAgentClient(),
  environment: configEnv,
  getSpareminToken: sso.getToken,
});

interface TokenEnhancerConfig<TState> {
  setToken: (
    state: DeepPartial<TState>,
    token: string | undefined,
  ) => DeepPartial<TState>;
  getToken: (state: TState | undefined) => string | undefined;
}

export function createTokenEnhancer<TState>({
  getToken,
  setToken,
}: TokenEnhancerConfig<TState>) {
  return (next: StoreEnhancerStoreCreator) => {
    return <TAction extends Action>(
      reducer: Reducer<TState, TAction>,
      preloadedState?: DeepPartial<TState>,
    ) => {
      // read the stored token when the app is initialized
      let currentToken = sso.getToken();

      // set the stored token on the preloaded state that will be used to
      // create the store.  this will effectively "rehydrate" the token
      const preloadedStateWithToken = setToken(preloadedState, currentToken);
      const store = next(reducer, preloadedStateWithToken);

      // listen for store changes.  if the token changes, call storeToken to
      // persist it
      store.subscribe(() => {
        const nextToken = getToken(store.getState());

        if (nextToken !== currentToken) {
          currentToken = nextToken;
          sso.storeToken(nextToken);
        }
      });

      return store;
    };
  };
}
