import { NO_SELECTION_VALUE } from 'containers/YoutubePostModal/constants';
import { AssetTypeOption } from './types';

export const NO_SELECTION_OPTION: AssetTypeOption = {
  label: 'None specified',
  value: NO_SELECTION_VALUE,
};

export const assetType: AssetTypeOption[] = [
  NO_SELECTION_OPTION,
  { label: 'Art track video', value: 'artTrackVideo' },
  { label: 'Composition', value: 'composition' },
  { label: 'Episode', value: 'episode' },
  { label: 'General', value: 'general' },
  { label: 'Movie', value: 'movie' },
  { label: 'Music video', value: 'musicVideo' },
  { label: 'Season', value: 'season' },
  { label: 'Show', value: 'show' },
  { label: 'Sound recording', value: 'soundRecording' },
  { label: 'Video game', value: 'videoGame' },
  { label: 'Web', value: 'web' },
];
