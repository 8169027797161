import { Spacer, Video } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import ClippingOptions, { ClippingOption } from 'components/ClippingOptions';
import VideoClipper from 'components/VideoClipper';
import { VideoScaling } from 'components/VideoPlayer';
import { min, round } from 'utils/numbers';
import { pageBlock as block } from '../utils';
import AddToVideoButton from './AddToVideoButton';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';

interface Props {
  className?: string;
}

const ClipSelectVideoClipper: React.FC<Props> = ({ className }) => {
  const {
    visibleSuggestionIds,
    region,
    setVideoPlayer,
    onSelectClip,
    onRegionChange,
    videoPlayerControls,
    clipsPageInfo: { aspectRatioName, mediaDurationMillis, videoUrl },
  } = useClipSelect();
  const [durationMillis, setDurationMillis] = useState(mediaDurationMillis);

  const { clipSelectorSliderRef } = useClipSelectAnimation();

  const handleSelectClip = useCallback(async (): Promise<void> => {
    await onSelectClip();

    clipSelectorSliderRef?.current?.slickGoTo(
      visibleSuggestionIds?.length,
      true,
    );
  }, [clipSelectorSliderRef, onSelectClip, visibleSuggestionIds]);

  const handleClippingOptionsBlur = useCallback(
    (millis: number, option: ClippingOption): void => {
      switch (option) {
        case 'start':
          onRegionChange({
            startMillis: round(millis),
            endMillis: round(
              min(region.endMillis, millis + mediaDurationMillis),
            ),
          });
          break;

        case 'end':
          onRegionChange({
            startMillis: round(region.startMillis),
            endMillis: round(
              min(millis, region.startMillis + mediaDurationMillis),
            ),
          });
          break;

        case 'duration':
          onRegionChange({
            startMillis: round(region.startMillis),
            endMillis: round(
              min(
                durationMillis,
                region.startMillis + min(millis, mediaDurationMillis),
              ),
            ),
          });
          break;

        default:
      }
    },
    [
      durationMillis,
      mediaDurationMillis,
      onRegionChange,
      region.endMillis,
      region.startMillis,
    ],
  );

  return (
    <div className={className}>
      <Spacer
        orientation="vertical"
        space="16px"
        align="center"
        justify="center"
        className={block('clip-select-video-clipper')}
      >
        <VideoClipper
          {...videoPlayerControls}
          {...region}
          defaultPositionMillis={region.startMillis}
          playerRef={setVideoPlayer}
          aspectRatio={aspectRatioName}
          lengthMillis={durationMillis}
          scaling={VideoScaling.FIT}
          className={block('video-clipper-container')}
          playerClassName={block('video-player', {
            [aspectRatioName]: true,
          })}
          onSelectedMillisChange={(startMillis, endMillis) =>
            onRegionChange({ startMillis, endMillis })
          }
          onDurationChange={setDurationMillis}
          showDisclaimer={false}
          showPlayTimer={false}
          src={videoUrl}
        />
        <ClippingOptions
          noWrap
          startMillis={region.startMillis}
          endMillis={region.endMillis}
          maxMillis={min(
            durationMillis,
            region.startMillis + mediaDurationMillis,
          )}
          onBlur={handleClippingOptionsBlur}
        />

        <AddToVideoButton
          disabled={region.endMillis - region.startMillis < 1000}
          className={block('clip-select-submit-button')}
          theme="submit"
          icon={<Video style={{ width: 17, height: 12 }} />}
          onClick={handleSelectClip}
        >
          select clip
        </AddToVideoButton>
      </Spacer>
    </div>
  );
};

export default ClipSelectVideoClipper;
