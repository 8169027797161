import { createSelector } from 'reselect';
import { YoutubeLanguage } from 'redux/middleware/api/podcast-service/types';
import {
  socialSharesSelector,
  youtubeLanguagesSelector,
} from 'redux/modules/entities';

import { stateSelector } from '../selectors';

export const youtubeSelector = createSelector(stateSelector, state =>
  state.get('youtube'),
);

export const socialShareIdSelector = createSelector(
  youtubeSelector,
  yt => yt.socialShareId,
);

export const youtubeProviderUserIdSelector = createSelector(
  youtubeSelector,
  youtube => youtube.providerUserId,
);

export const youtubeUserDataSelector = createSelector(youtubeSelector, yt => ({
  accessToken: yt.accessToken,
  idToken: yt.idToken,
  providerUserId: yt.providerUserId,
  playlists: yt.playlists,
  categories: yt.categories,
}));

export const youtubePlaylistsSelector = createSelector(
  youtubeUserDataSelector,
  yt => ({
    playlists: yt.playlists?.data ?? [],
    isIdle: yt.playlists?.status === 'idle',
    isLoading: yt.playlists?.status === 'pending',
  }),
);

export const youtubeCategoriesSelector = createSelector(
  youtubeUserDataSelector,
  yt => yt.categories?.data ?? [],
);

export const socialShareSelector = createSelector(
  socialShareIdSelector,
  socialSharesSelector,
  (id, shares) =>
    !id || !shares || shares.size === 0
      ? undefined
      : shares.get(String(id))?.toJS(),
);

export const youtubeChannelSelector = createSelector(youtubeSelector, yt =>
  yt.channelInfo.toJS(),
);

export const isChannelMonetizationEnabledSelector = createSelector(
  youtubeSelector,
  yt => yt.channelInfo.isChannelMonetizationEnabled,
);

export const createPlaylistStatusSelector = createSelector(
  youtubeSelector,
  yt => yt.createPlaylistStatus,
);

export const lastCreatedPlaylistIdSelector = createSelector(
  youtubeSelector,
  yt => yt.lastCreatedPlaylistId,
);

export const youtubeLanguagesListSelector = createSelector(
  youtubeLanguagesSelector,
  youtubeLanguagesMap =>
    Object.values(youtubeLanguagesMap?.toJS() ?? {}) as YoutubeLanguage[],
);
