import { List } from 'immutable';
import { RequestStatus } from 'types';
import { Type } from './action-types';
import { stateFactory } from './factories';
import { ClipSelectAction, ClipSelectState } from './types';

const defaultState = stateFactory();

export default function reducer(
  state: ClipSelectState = defaultState,
  action: ClipSelectAction,
) {
  switch (action.type) {
    case Type.LOAD_DATA_REQUEST:
      return defaultState.set('status', RequestStatus.REQUEST);

    case Type.LOAD_DATA_SUCCESS: {
      const {
        episodeId,
        subscriptionId,
        subscriptionItemId,
        entireAudioInstanceId,
      } = action.payload;
      return state.merge({
        status: RequestStatus.SUCCESS,
        episodeId,
        subscriptionId,
        subscriptionItemId,
        entireAudioInstanceId,
      });
    }

    case Type.LOAD_DATA_FAILURE:
      return state.set('status', RequestStatus.FAILURE);

    case Type.LOAD_CLIP_SUGGESTIONS_REQUEST:
      return state.set('suggestionsStatus', RequestStatus.REQUEST);

    case Type.LOAD_CLIP_SUGGESTIONS_SUCCESS: {
      return state
        .set('suggestionsStatus', RequestStatus.SUCCESS)
        .set('suggestionIds', List(action.payload));
    }

    case Type.GROUP_CLIP_SUGGESTION_DISLIKE: {
      const { suggestionId } = action.payload;

      return state.update('dislikedGroupSuggestionIds', ids =>
        ids.push(suggestionId),
      );
    }

    case Type.LOAD_CLIP_SUGGESTIONS_FAILURE:
      return state.set('suggestionsStatus', RequestStatus.FAILURE);

    case Type.LOAD_GROUP_CLIP_SUGGESTIONS_REQUEST:
      return state.set('groupClipSuggestionsStatus', RequestStatus.REQUEST);

    case Type.LOAD_GROUP_CLIP_SUGGESTIONS_SUCCESS: {
      return state.set('groupClipSuggestionsStatus', RequestStatus.SUCCESS);
    }

    case Type.LOAD_GROUP_CLIP_SUGGESTIONS_FAILURE:
      return state.set('groupClipSuggestionsStatus', RequestStatus.FAILURE);

    case Type.LOAD_CREATION_CLIP_SUGGESTIONS_REQUEST:
      return state.set('creationClipSuggestionsStatus', RequestStatus.REQUEST);

    case Type.LOAD_CREATION_CLIP_SUGGESTIONS_SUCCESS: {
      return state.set('creationClipSuggestionsStatus', RequestStatus.SUCCESS);
    }

    case Type.LOAD_CREATION_CLIP_SUGGESTIONS_FAILURE:
      return state.set('creationClipSuggestionsStatus', RequestStatus.FAILURE);

    case Type.CREATION_CLIP_SUGGESTION_DISLIKE: {
      const { suggestionId } = action.payload;

      return state.update('dislikedCreationSuggestionIds', ids =>
        ids.push(suggestionId),
      );
    }

    case Type.CLIP_AUDIO_REQUEST:
      return state
        .set('submitStatus', RequestStatus.REQUEST)
        .set('autoCreateEpisodeId', null);

    case Type.CLIP_AUDIO_SUCCESS: {
      const { autoCreateEpisodeId } = action.payload;
      return state
        .set('submitStatus', RequestStatus.SUCCESS)
        .set('autoCreateEpisodeId', autoCreateEpisodeId);
    }

    case Type.CLIP_AUDIO_FAILURE:
      return state.set('submitStatus', RequestStatus.FAILURE);

    case Type.EMBED_EXPORT_READY: {
      const { widgetId } = action.payload;
      return state.set('widgetId', widgetId);
    }

    case Type.RESET:
      return defaultState;

    default:
      return state;
  }
}
