import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import LoadingOverlay from 'components/LoadingOverlay';
import { enableClipSuggestion } from 'redux/middleware/api/podcast-service/actions';
import { getDownloadPath } from 'utils/routes';
import { useClipSelect } from './ClipSelectContext';

export interface ClipSelectVideoExportProps {
  className?: string;
}

const ClipSelectVideoExport: React.FC<ClipSelectVideoExportProps> = ({
  className,
}) => {
  const { suggestedClips } = useClipSelect();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (!suggestedClips?.length) {
      return;
    }

    const clipSuggestion = suggestedClips?.[0];

    if (clipSuggestion?.status === 'completed') {
      dispatch(enableClipSuggestion(clipSuggestion.id));

      history.push(getDownloadPath(clipSuggestion.widgetId));
    }
  }, [dispatch, history, suggestedClips]);

  return (
    <LoadingOverlay
      title="This clip will be ready soon"
      className={className}
    />
  );
};

export default ClipSelectVideoExport;
