import React, { useCallback, useEffect, useRef } from 'react';
import { noop } from 'underscore';
import SteppedModal from 'components/SteppedModal';
import usePrevious from 'hooks/usePrevious';
import useAccessDeniedStep from './steps/useAccessDeniedStep';
import useAuthenticationProgressStep from './steps/useAuthenticationProgressStep';
import useComposerStep, { UseComposerStepValue } from './steps/useComposerStep';
import useSwitchAccountStep from './steps/useSwitchAccountStep';
import { LinkedinPostModalProps } from './types';
import { block } from './utils';

export interface LinkedinPostVideoModalProps
  extends Pick<LinkedinPostModalProps, 'onHide' | 'show'> {
  error?: string;
  onSubmit?: (vals: UseComposerStepValue) => void;
}

const LinkedinPostVideoModal: React.FC<LinkedinPostVideoModalProps> = ({
  error,
  onHide = noop,
  onSubmit = noop,
  show = false,
}) => {
  const modalRef = useRef<SteppedModal>(null);
  const prevShow = usePrevious(show);

  const handlePostSuccess = useCallback(() => {
    if (show) {
      onHide();
    }
  }, [onHide, show]);
  const handleGoToProgressStep = useCallback(() => {
    return modalRef.current.stepHistory.replace('authentication-in-progress');
  }, []);

  const handleSwitchAccountClick = useCallback(() => {
    return modalRef.current.stepHistory.replace('switch');
  }, []);

  const handleError = useCallback(err => {
    if (err === 'access_denied') {
      modalRef.current.stepHistory.replace('access-denied');
    }
  }, []);

  const handleSwitchAccountSuccess = useCallback(
    () => modalRef.current.stepHistory.replace('composer'),
    [],
  );

  useEffect(() => {
    if (show && !prevShow) {
      modalRef.current.stepHistory.replace('composer');
    }

    if (!prevShow && show && error) handleError(error);
  }, [error, handleError, prevShow, show]);

  const composerStep = useComposerStep({
    onPostSuccess: handlePostSuccess,
    onSubmit,
    readonly: false,
    onSwitchAccountClick: handleSwitchAccountClick,
  });

  const accessDeniedStep = useAccessDeniedStep({
    onError: handleError,
    onReviseAccess: handleSwitchAccountSuccess,
  });

  const switchAccountStep = useSwitchAccountStep({
    onError: handleError,
    onSwitchAccountSuccess: handleSwitchAccountSuccess,
    onSwitchAccount: handleGoToProgressStep,
  });

  const authenticationProgress = useAuthenticationProgressStep();

  return (
    <SteppedModal
      defaultStep="composer"
      baseClassName={block()}
      onHide={onHide}
      ref={modalRef}
      show={show}
      steps={[
        composerStep,
        accessDeniedStep,
        switchAccountStep,
        authenticationProgress,
      ]}
      title="post your video on LinkedIn"
      footerButtonsDisabled={false}
      onExited={noop}
    />
  );
};

export default LinkedinPostVideoModal;
