import React from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router';
import { compose } from 'underscore';

import AuthRoutes from 'blocks/Authentication/AuthRoutes';
import AutomationSuggestedClipsRoutes from 'blocks/AutomationSuggestedClipsPage/AutomationSuggestedClipsRoutes';
import DownloadRoutes from 'blocks/DownloadPage/DownloadRoutes';
import ConfirmEmailPage from 'blocks/EmailVerification/ConfirmEmailPage';
import { ProSuccessMessage } from 'blocks/Pricing';
import BasicSuccessMessage from 'blocks/Pricing/components/BasicSuccessMessage';
import UnlimitedSuccessMessage from 'blocks/Pricing/components/UnlimitedSuccessMessage';
import ProjectsPage, { LabsPage } from 'blocks/ProjectsPage';
import VideoTranscriptionSuggestedClipsRoutes from 'blocks/VideoTranscriptionSuggestedClipsPage/VideoTranscriptionSuggestedClipsRoutes';
import WizardPage from 'blocks/WizardPage';
import * as auth from 'components/hoc/auth';
import RouteToggler from 'components/RouteToggler';
import AccountSettings from 'containers/AccountSettings';
import ClipPreselectPage from 'containers/ClipPreselectPage';
import EditorPage from 'containers/EditorPage';
import { withQueryStringAuth } from 'containers/QueryStringAuthenticator';
import withRedirectOnMobile from 'containers/Redirect/withRedirectOnMobile';
import { AUTH_ROUTES, getHomepage } from 'utils/routes';

// TODO consolidate with editorpage routes
const userIsOnboardedOnDesktop = compose(
  auth.userIsAuthenticatedAndOnboarded,
  withRedirectOnMobile(),
);

const AuthenticatedEditor = userIsOnboardedOnDesktop(EditorPage);
const AuthenticatedLabsPage = userIsOnboardedOnDesktop(LabsPage);
const AuthenticatedProjects = userIsOnboardedOnDesktop(ProjectsPage);
const AuthenticatedSettings = userIsOnboardedOnDesktop(AccountSettings);
const AuthenticatedPreselectPage = userIsOnboardedOnDesktop(ClipPreselectPage);
const AuthenticatedSuggestedClipsRoutes = userIsOnboardedOnDesktop(
  AutomationSuggestedClipsRoutes,
);
const OptionalQueryAuthWizardPage = withQueryStringAuth(WizardPage, {
  requireAuth: false,
});
// AuthenticatedProjects is used for the inital pages after login. So 'withQueryStringAuth'
// can be used to authenticate the user when the first route is '/' (root).
const OptionalQueryAuthAuthenticatedProjectsPages = withQueryStringAuth(
  AuthenticatedProjects,
  {
    requireAuth: true,
  },
);

const homepage = getHomepage();

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  const location = useLocation();
  const editorPaths = ['/project', '/project/:pid', '/edit'];
  const isEditorMatch = matchPath(location.pathname, {
    path: editorPaths,
    exact: true,
  });

  return (
    <>
      <Route exact path={editorPaths}>
        {props => (
          <RouteToggler keepMountedRoute="/settings" {...props}>
            <AuthenticatedEditor />
          </RouteToggler>
        )}
      </Route>
      <Switch>
        <Route
          exact
          path="/"
          // The location object is passed to the Redirect so
          // query parameters are not lost.
          render={() => <Redirect to={{ ...location, pathname: homepage }} />}
        />
        <Route
          exact
          path={['/projects', '/create']}
          component={OptionalQueryAuthAuthenticatedProjectsPages}
        />
        <Route exact path="/wizard" component={OptionalQueryAuthWizardPage} />
        <Route exact path="/labs" component={AuthenticatedLabsPage} />
        <Route path="/settings" component={AuthenticatedSettings} />
        <Route path="/download/:wid" component={DownloadRoutes} />
        <Route
          path="/automation-suggested-clips/:episodeId"
          component={AuthenticatedSuggestedClipsRoutes}
        />
        <Route
          path="/suggested-clips/:creationRequestId"
          component={VideoTranscriptionSuggestedClipsRoutes}
        />
        <Route exact path="/verify-email" component={ConfirmEmailPage} />
        <Route path={AUTH_ROUTES} component={AuthRoutes} />
        <Route exact path="/welcome-to-pro" component={ProSuccessMessage} />
        <Route exec path="/welcome-to-basic" component={BasicSuccessMessage} />
        <Route
          exec
          path="/welcome-to-unlimited"
          component={UnlimitedSuccessMessage}
        />
        <Route
          exact
          path="/automation/pre-select-clip"
          component={AuthenticatedPreselectPage}
        />
        <Redirect exact from="/automation" to="/projects" />
        {!isEditorMatch && <Redirect from="*" to={homepage} />}
      </Switch>
    </>
  );
};

export default Routes;
