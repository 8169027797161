import { createSelector } from 'reselect';
import { every, indexBy, isNull, isUndefined, mapObject } from 'underscore';

import {
  PodcastEpisode,
  PodcastSubscription,
  SocialPostCaptionInfoPlatform,
  SocialPostCaptions,
} from 'redux/middleware/api/podcast-service/types';
import {
  EmailVerificationStatus,
  IProfile,
} from 'redux/middleware/api/user-service/types';
import { State } from 'redux/types';
import {
  AutogramClipSelectionMethod,
  ImageOriginId,
  RequestStatus,
  VideoTypes,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue } from 'utils/collections';
import { DEFAULT_PROJECT_NAME, HEADLINER_SHOUTOUT } from 'utils/constants';
import { getBlurRadiusFromConfig, hasSlideFromOrigin } from 'utils/embed/embed';
import {
  loggedOutSelector,
  userIdSelector,
  userIntegratorsSelector,
  userNameSelector,
} from '../auth/selectors';
import {
  audioVariationsSelector,
  autoCreateEpisodesSelector,
  autoCreateEpisodeVideosSelector,
  displayPrefsSelector,
  embedExportsSelector,
  podcastFeedEpisodesSelector,
  podcastSubscriptionsSelector,
  projectsSelector,
  userProfilesSelector,
} from '../entities';
import { isFreeSelector } from '../pricing/selectors';
import { getProjectTypeByProject, getTemplateId } from '../project/utils';
import { DownloadState } from './types';

const DEFAULT_VIDEO_NAME = `${DEFAULT_PROJECT_NAME}.mp4`;

const stateSelector = (state: State): DownloadState => state.get('download');

export const loadingStateSelector = createSelector(
  stateSelector,
  state => state.loadingState,
);

export const isLoadingSelector = createSelector(
  loadingStateSelector,
  loadingState => loadingState === RequestStatus.REQUEST,
);

const configurationSelector = createSelector(
  stateSelector,
  state => state.configuration,
);

export const projectIdSelector = createSelector(
  stateSelector,
  state => state.projectId,
);

export const podcastInfoSelector = createSelector(stateSelector, state =>
  state.podcastInfo?.toJS(),
);

const projectSelector = createSelector(
  [projectsSelector, projectIdSelector],
  (projects, projectId) => {
    if (!projects || !projectId) return undefined;
    return projects.get(projectId);
  },
);

export const configurationIdSelector = createSelector(
  configurationSelector,
  config => config && config.get('wid'),
);

const exportSelector = createSelector(
  [embedExportsSelector, configurationIdSelector],
  (embedExports, configId) => {
    if (!embedExports || !configId) return undefined;
    return embedExports.get(configId);
  },
);

export const templateIdSelector = createSelector(projectSelector, project =>
  getTemplateId(project),
);

export const subscriptionSelector = createSelector(
  projectSelector,
  podcastSubscriptionsSelector,
  (project, subscriptions) => {
    const subscriptionId = project?.getIn([
      'automationSubscriptionSource',
      'subId',
    ]);
    return subscriptions
      ?.get(String(subscriptionId))
      ?.toJS() as PodcastSubscription;
  },
);

export const subscriptionItemSelector = createSelector(
  projectSelector,
  subscriptionSelector,
  (project, subscription) => {
    const subscriptionItemId = project?.getIn([
      'automationSubscriptionSource',
      'subItemId',
    ]);
    return subscription?.subscriptionItems?.find(
      item => item.id === subscriptionItemId,
    );
  },
);

export const projectTypeSelector = createSelector(projectSelector, project => {
  if (!project) return undefined;
  return getProjectTypeByProject(project);
});

export const queuerUserIdSelector = createSelector(
  exportSelector,
  embedExport => embedExport?.get('queuerUserId'),
);

export const projectOwnerIdSelector = createSelector(
  projectSelector,
  queuerUserIdSelector,
  (project, queuerUserId) => {
    return project?.get('creatorUserId') ?? queuerUserId;
  },
);

export const queuerUserProfileSelector = createSelector(
  queuerUserIdSelector,
  userProfilesSelector,
  (queuerUserId, profiles): IProfile => {
    return profiles?.get(String(queuerUserId))?.toJS();
  },
);

export const isPodcastAutomationSelector = createSelector(
  exportSelector,
  embedExport => {
    return embedExport?.get('isPodcastAutomation');
  },
);

export const isQueuerUserVerifiedSelector = createSelector(
  queuerUserProfileSelector,
  profile => {
    return (
      profile?.emailVerification?.emailVerificationStatus ===
      EmailVerificationStatus.VERIFIED
    );
  },
);

export const automationSubIdSelector = createSelector(
  projectSelector,
  project => {
    return project?.getIn(['automationSubscriptionSource', 'subId']);
  },
);

export const videoUrlSelector = createSelector(exportSelector, embedExport => {
  if (!embedExport) return undefined;
  return embedExport.get('videoUrl');
});

export const videoIdSelector = createSelector(exportSelector, embedExport =>
  embedExport?.get('id'),
);

export const aspectRatioNameSelector = createSelector(
  configurationSelector,
  config => {
    const dimensions = getValue(config, ['embedConfig', 'dimensions']);
    if (!dimensions) return undefined;

    const height = dimensions.get('height');
    const width = dimensions.get('width');

    return getAspectRatioName(height, width);
  },
);

export const waveformPrefIdSelector = createSelector(
  configurationSelector,
  config => getValue(config, ['embedConfig', 'soundwave', 'waveformPrefId']),
);

export const projectNameSelector = createSelector(projectSelector, project => {
  if (!project) return DEFAULT_PROJECT_NAME;

  return getValue(
    project,
    ['projectConfig', 'projectName'],
    DEFAULT_PROJECT_NAME,
  );
});

export const videoFileNameSelector = createSelector(
  [projectSelector, projectNameSelector, isFreeSelector, loggedOutSelector],
  (project, projectName, isFree, loggedOut) => {
    if (!project) return DEFAULT_VIDEO_NAME;

    if (isFree || loggedOut) {
      return `${projectName} (Made by Headliner).mp4`;
    }
    return `${projectName}.mp4`;
  },
);

export const eddyProjectUrlSelector = createSelector(
  projectSelector,
  project => {
    if (!project) return undefined;

    return getValue(project, ['projectConfig', 'eddyProjectUrl']);
  },
);

export const episodeIdSelector = createSelector(projectSelector, project => {
  if (!project) return undefined;
  return project.getIn(['automationSubscriptionSource', 'episodeId']);
});

export const subscriptionItemIdSelector = createSelector(
  projectSelector,
  project => {
    if (!project) return undefined;
    return project.getIn(['automationSubscriptionSource', 'subItemId']);
  },
);

export const subscriptionIdSelector = createSelector(
  projectSelector,
  project => {
    if (!project) return undefined;
    return project.getIn(['automationSubscriptionSource', 'subId']);
  },
);

export const podcastEpisodeIdSelector = createSelector(
  projectSelector,
  project => {
    if (!project) return undefined;
    return project.getIn(['podcastSource', 'podcastEpisodeId']);
  },
);

export const audioVariationTypeSelector = createSelector(
  videoIdSelector,
  autoCreateEpisodeVideosSelector,
  autoCreateEpisodesSelector,
  audioVariationsSelector,
  (videoId, videos, episodes, audioVariations) => {
    const autoCreateEpisodeId = videos
      ?.get(String(videoId))
      ?.get('autoCreateEpisode');
    const autoCreateEpisode = episodes?.get(String(autoCreateEpisodeId));
    const variation = audioVariations?.get(
      String(autoCreateEpisode?.get('audioVariationId')),
    );
    return variation?.get('audioVariationType');
  },
);

export const autoCreateEpisodeSelector = createSelector(
  videoIdSelector,
  autoCreateEpisodeVideosSelector,
  autoCreateEpisodesSelector,
  (videoId, videos, episodes) => {
    const autoCreateEpisodeId = videos
      ?.get(String(videoId))
      ?.get('autoCreateEpisode');
    const autoCreateEpisode = episodes?.get(String(autoCreateEpisodeId));

    return autoCreateEpisode;
  },
);

export const clipSelectionMethodSelector = createSelector(
  subscriptionItemSelector,
  subscription => subscription?.clipSelectionMethod,
);

export const canReclipSelector = createSelector(
  [userIdSelector, podcastSubscriptionsSelector],
  (userId, subscription) => {
    const subscriptionOwnerId = subscription?.first()?.get('userId');
    const subscriptionItems = subscription
      ?.first()
      .get('subscriptionItems')
      ?.first();
    const videoType = subscriptionItems?.get('videoType');
    const clipSelectionMethod = subscriptionItems?.get('clipSelectionMethod');

    return (
      userId &&
      subscriptionOwnerId &&
      userId === subscriptionOwnerId &&
      videoType === VideoTypes.RANDOM_CLIP &&
      clipSelectionMethod === AutogramClipSelectionMethod.SMART
    );
  },
);

export const recordingIdSelector = createSelector(
  configurationSelector,
  config => getValue(config, ['embedConfig', 'recordingId']),
);

export const createMethodSelector = createSelector(projectSelector, project =>
  getValue(project, 'createMethod'),
);

export const videoFrequencySelector = createSelector(
  stateSelector,
  state => state.videoFrequency,
);

export const videoDurationMillisSelector = createSelector(
  stateSelector,
  state => state.videoDurationMillis,
);

export const isYouTubeEnabledSelector = createSelector(
  [userNameSelector, userIdSelector, userIntegratorsSelector],
  (userName, userId, userIntegrators) => {
    const {
      emailPatterns,
      enabledForIntegrators,
      userWhitelist,
    } = spareminConfig.youtube;

    const omnyIntegrator = userIntegrators.find(integrator =>
      enabledForIntegrators.includes(integrator.integratorIdentityProviderName),
    );

    return (
      !isUndefined(omnyIntegrator) ||
      userWhitelist.some(
        whitelistUserId => whitelistUserId && userId === whitelistUserId,
      ) ||
      emailPatterns.some(pat => pat && RegExp(pat).test(userName))
    );
  },
);

export const podcastEpisodeSelector = createSelector(
  [podcastFeedEpisodesSelector, podcastEpisodeIdSelector],
  (podcastEpisodes, episodeId) =>
    podcastEpisodes?.get(String(episodeId))?.toJS() as PodcastEpisode,
);

export const isPollingSocialPostCaptionsSelector = createSelector(
  stateSelector,
  state => state?.isPollingSocialPostCaptions,
);

export const socialPostCaptionInfoSelector = createSelector(
  podcastEpisodeSelector,
  episode => episode?.socialPostCaptionInfo,
);

export const canGenerateSocialPostCaptionsSelector = createSelector(
  socialPostCaptionInfoSelector,
  socialPostCaptionInfo => socialPostCaptionInfo?.canGenerateSocialPostCaptions,
);

export const socialPostCaptionInfoByPlatformSelector = createSelector(
  socialPostCaptionInfoSelector,
  socialPostCaptionInfo =>
    mapObject(
      indexBy(socialPostCaptionInfo?.socialPostCaptions, 'platform'),
      platform => platform,
    ) as Record<SocialPostCaptionInfoPlatform, SocialPostCaptions>,
);

export const hasSocialPostCaptionInfoSelector = createSelector(
  socialPostCaptionInfoSelector,
  socialPostCaptionInfo =>
    !every(socialPostCaptionInfo?.socialPostCaptions, isNull),
);

export const episodeUrlSelector = createSelector(
  subscriptionSelector,
  podcastEpisodeSelector,
  podcastInfoSelector,
  (subscription, episode, podcastInfo) => {
    let episodeUrl = '';

    if (subscription) {
      // As subscription videos don't bring episodeInfo, the url has to be obtained from episode
      episodeUrl = episode?.playAppEpisodeUrl || episode?.url;
    } else {
      // For autograms episode url will be obtained from podcastInfo
      episodeUrl = podcastInfo?.episode.playAppUrl || podcastInfo?.episode.url;
    }

    return episodeUrl;
  },
);

/**
 * returns the title of the podcast that was used to create this project.
 */
export const projectPodcastNameSelector = createSelector(
  [podcastInfoSelector],
  podcastInfo => podcastInfo?.podcast?.title,
);

export const defaultPostTextSelector = createSelector(
  projectPodcastNameSelector,
  episodeUrlSelector,
  isFreeSelector,
  loggedOutSelector,
  (podcastName, episodeUrl, isFree, loggedOut) => {
    let post = '';

    if (podcastName) {
      post += `Check out the latest episode of ${podcastName}!`;
    } else {
      post += 'Check out my new video!';
    }

    if (episodeUrl) {
      post += ` Listen to the full podcast here ${episodeUrl}`;
    }

    if (isFree || loggedOut) {
      return `${post} ${HEADLINER_SHOUTOUT}`;
    }

    return post;
  },
);

export const captionsEnabledSelector = createSelector(
  configurationSelector,
  config => {
    const captionsEnabled = getValue(config, [
      'embedConfig',
      'captions',
      'enabled',
    ]);
    return captionsEnabled;
  },
);

export const hasSlideFromOriginSelector = (origin: ImageOriginId, state) => {
  return hasSlideFromOrigin(origin, configurationSelector(state));
};

export const blurRadiusSelector = state => {
  const config = configurationSelector(state)
    ?.get('embedConfig')
    ?.toJS();
  return getBlurRadiusFromConfig(config);
};

export const projectOwnerShareMethodOverridesSelector = createSelector(
  displayPrefsSelector,
  projectOwnerIdSelector,
  (displayPrefs, projectOwnerId) => {
    const displayPref = displayPrefs?.get(String(projectOwnerId));
    return displayPref
      ?.getIn([
        'displayAdjustment',
        'videoShare',
        'shareMethod',
        'overrideOptions',
      ])
      ?.toJS();
  },
);

export const dpaPerformedSelector = createSelector(stateSelector, state =>
  state.get('dpaPerformed'),
);
