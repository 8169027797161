import bem from 'utils/bem';
import { DESTINATION_PRESETS, MAX_DURATION_VALID_KEYS } from './constants';
import { DestinationPreset, MaxDurationKey } from './types';

export const block = bem('destination-platforms');

export function checkIfDestinationPresetExists(presetName: string): boolean {
  return presetName in DESTINATION_PRESETS;
}

export function getPresetMaxDurationKey(
  selectedPreset: DestinationPreset['key'],
): MaxDurationKey {
  return MAX_DURATION_VALID_KEYS.includes(selectedPreset as MaxDurationKey)
    ? (selectedPreset as MaxDurationKey)
    : 'not set';
}
