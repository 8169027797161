import { Button, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import AutomationPostModalBackButton from 'components/AutomationPostModalBackButton';
import FadingScrollBars from 'components/FadingScrollBars';
import InputFieldAsButton from 'components/Form/InputFieldAsButton';
import { EditThin } from 'components/icons';
import ModalContentWithMedia from 'components/Modal/ModalContentWithMedia';
import { Step } from 'components/SteppedModal';
import YoutubePartnerProgramSettings from 'containers/YoutubePostModal/YoutubePartnerProgramSettings';
import { AssetValues } from 'containers/YoutubePostModal/YoutubePartnerProgramSettings/AssetTypeSelector/types';
import { YoutubePartnerProgramSettingsProps } from 'containers/YoutubePostModal/YoutubePartnerProgramSettings/YoutubePartnerProgramSettings';
import {
  YoutubeCategories,
  YoutubeLanguage,
} from 'redux/middleware/api/podcast-service/types';
import { showError } from 'redux/modules/notification';
import { isChannelMonetizationEnabledSelector } from 'redux/modules/social';
import { YoutubeVideoVisibility } from 'types';

import { convertTagsListToString } from '../../TagsInput/utils';
import { block, isValidCustomId } from '../../utils';
import YoutubeAudienceSelector from '../../YoutubeAudienceSelector';
import YoutubeCategorySelector from '../../YoutubeCategorySelector';
import YoutubeLanguageSelector from '../../YoutubeLanguageSelector';
import YoutubeVisibilitySelector, {
  YoutubeVisibilitySelectorProps,
} from '../../YoutubeVisibilitySelector';

interface UseAdditionalDetailsStepProps
  extends Pick<
    YoutubePartnerProgramSettingsProps,
    | 'customId'
    | 'assetType'
    | 'onAssetTypeChange'
    | 'onMonetizationChange'
    | 'onCustomIdChange'
  > {
  language?: string;
  isActive: boolean;
  tags: string[];
  categories: YoutubeCategories[];
  languages: YoutubeLanguage[];
  visibility: YoutubeVideoVisibility;
  madeForKids: boolean;
  categoryId: number;
  onBackClick: () => void;
  onLanguageChange: (languageCode: string) => void;
  onVideoTagsClick: () => void;
  onVisibilityChange: YoutubeVisibilitySelectorProps['onChange'];
  onMadeForKidsChange: (forKids: boolean) => void;
  onCategoryIdChange: (categoryId: number) => void;
  onHideModal: () => void;
}

export default function useAdditionalDetailsStep({
  language,
  isActive,
  languages,
  tags,
  categories,
  visibility,
  madeForKids,
  categoryId,
  onBackClick,
  onLanguageChange,
  onVideoTagsClick,
  onVisibilityChange,
  onMadeForKidsChange,
  onCategoryIdChange,
  onHideModal,
  onAssetTypeChange,
  onMonetizationChange,
  onCustomIdChange,
  customId,
  assetType,
}: UseAdditionalDetailsStepProps): Step {
  const isChannelMonetizationEnabled = useSelector(
    isChannelMonetizationEnabledSelector,
  );
  const [defaultLanguage, setDefaultLanguage] = useState(language);
  const [defaultVisibility, setDefaultVisibility] = useState(visibility);
  const [defaultMadeForKids, setDefaultMadeForKids] = useState(madeForKids);
  const [defaultCategoryId, setDefaultCategoryId] = useState(categoryId);
  const [defaultCustomId, setDefaultCustomId] = useState(customId);
  const [defaultAssetType, setDefaultAssetType] = useState<AssetValues>(
    assetType,
  );
  const dispatch = useDispatch();

  const handleBackClick = useCallback((): void => {
    onLanguageChange(defaultLanguage);
    onVisibilityChange(defaultVisibility);
    onMadeForKidsChange(defaultMadeForKids);
    onCategoryIdChange(defaultCategoryId);
    onCustomIdChange(defaultCustomId);
    onAssetTypeChange(defaultAssetType);
    onBackClick();
  }, [
    onLanguageChange,
    defaultLanguage,
    onVisibilityChange,
    defaultVisibility,
    onMadeForKidsChange,
    defaultMadeForKids,
    onCategoryIdChange,
    defaultCategoryId,
    onCustomIdChange,
    defaultCustomId,
    onAssetTypeChange,
    defaultAssetType,
    onBackClick,
  ]);

  if (!isActive) {
    if (language !== defaultLanguage) {
      setDefaultLanguage(language);
    }

    if (visibility !== defaultVisibility) {
      setDefaultVisibility(visibility);
    }

    if (madeForKids !== defaultMadeForKids) {
      setDefaultMadeForKids(madeForKids);
    }

    if (categoryId !== defaultCategoryId) {
      setDefaultCategoryId(categoryId);
    }
    if (customId !== defaultCustomId) {
      setDefaultCustomId(customId);
    }

    if (assetType !== defaultAssetType) {
      setDefaultAssetType(assetType);
    }
  }

  const handleSave = useCallback(() => {
    if (customId && !isValidCustomId(customId)) {
      dispatch(
        showError({
          dismissAfterSec: 6,
          message:
            'It can be up to 64 bytes and may contain alphanumeric characters, hyphens (-), underscores (_), periods (.), "at" symbols (@), or forward slashes (/).',
          title: 'Please update your CustomID',
        }),
      );
    } else {
      onBackClick();
    }
  }, [customId, dispatch, onBackClick]);

  return {
    component: (
      <>
        <ModalContentWithMedia
          onClose={onHideModal}
          className="composer-step-content"
        >
          <ModalContentWithMedia.ModalContentWithMediaColumn>
            <img
              src="https://static.sparemin.com/static/webapp-assets/auto-posting-detail/auto-posting-detail-image-a-202303301450.png"
              className={block('composer-image-left')}
            />
          </ModalContentWithMedia.ModalContentWithMediaColumn>

          <ModalContentWithMedia.ModalContentWithMediaColumn className="composer-content-column">
            <Spacer
              orientation="vertical"
              align="center"
              justify="center"
              space={2}
              className={cn(
                block('step-container'),
                block('additional-details'),
              )}
            >
              <AutomationPostModalBackButton
                text="Additional details"
                onClick={handleBackClick}
              />
              <FadingScrollBars className={block('contents')}>
                <Spacer
                  orientation="vertical"
                  space={2}
                  className={block('details-container')}
                >
                  <YoutubeVisibilitySelector
                    value={visibility}
                    onChange={onVisibilityChange}
                  />

                  <YoutubeAudienceSelector
                    value={madeForKids}
                    onChange={onMadeForKidsChange}
                  />

                  <YoutubeCategorySelector
                    options={categories}
                    onChange={onCategoryIdChange}
                    value={categoryId}
                  />

                  <YoutubeLanguageSelector
                    languageOptions={languages}
                    onChange={onLanguageChange}
                    value={language}
                  />

                  <InputFieldAsButton
                    label="Example of your video tags"
                    content={
                      tags.length ? convertTagsListToString(tags) : 'Add tags'
                    }
                    tooltipContent="Edit your video tags"
                    icon={EditThin}
                    onClick={onVideoTagsClick}
                    className={block('composer-single-line')}
                  />
                  {isChannelMonetizationEnabled && (
                    <YoutubePartnerProgramSettings
                      onAssetTypeChange={onAssetTypeChange}
                      onMonetizationChange={onMonetizationChange}
                      assetType={assetType}
                      customId={customId}
                      onCustomIdChange={onCustomIdChange}
                    />
                  )}

                  <Button
                    onPress={handleSave}
                    variant="solid"
                    className={block('composer-step-submit-button')}
                  >
                    save changes
                  </Button>
                </Spacer>
              </FadingScrollBars>
            </Spacer>
          </ModalContentWithMedia.ModalContentWithMediaColumn>
        </ModalContentWithMedia>
      </>
    ),
    id: 'additional-details-step',
    hideFooter: true,
    replaceBody: true,
  };
}
