import { useToggle } from '@sparemin/blockhead';
import React from 'react';
import { useDispatch } from 'react-redux';

import { TextOverlay } from 'blocks/TextOverlayModal';
import { TextOverlayV2 } from 'blocks/TextOverlayModal/v2';
import { pushModal } from 'redux/modules/modal';
import { Dispatch } from 'redux/types';

import { ActiveModal, StartingActiveModal } from './types';
import { transformOverlayToLegacy, transformOverlayToV2 } from './utils';

interface UseTextOverlaySwitchConfig {
  onHide: () => void;
  onOpen: () => void;
  onSwitchMode?: (nextMode: ActiveModal) => void;
  show: boolean;
  startingModalType?: StartingActiveModal;
}

interface UseTextOverlaySwitch {
  activeModal: ActiveModal;
  onToggleLegacyMode: () => void;
  transitionOverlay?: TextOverlay | TextOverlayV2;
}

const useTextOverlaySwitch = (
  config: UseTextOverlaySwitchConfig,
): UseTextOverlaySwitch => {
  const { onHide, onOpen, onSwitchMode, show, startingModalType } = config;

  const [prevStartingModalType, setPrevStartingModalType] = React.useState(
    startingModalType,
  );
  const [prevShow, setPrevShow] = React.useState(false);
  const [transitionOverlay, setTransitionOverlay] = React.useState<
    TextOverlay | TextOverlayV2 | undefined
  >();

  const dispatch = useDispatch<Dispatch>();

  const { toggle: toggleLegacyMode, value: showLegacy } = useToggle();

  // Cleans the transition overlay each time the modal is hidden.
  // This is done partially during the transition, but in this case
  // the block is effective when an effective modal hide action is
  // performed.
  if (prevShow !== show) {
    setPrevShow(show);
    if (!show) {
      setTransitionOverlay(undefined);
    }
  }

  // When a starting type is set, the current showLegacy state is overriden.
  // In this way, it can be ensured that the correct modal is going to be picked.
  if (prevStartingModalType !== startingModalType) {
    setPrevStartingModalType(startingModalType);

    if (startingModalType) {
      toggleLegacyMode(startingModalType === 'legacy');
    }
  }

  const handleToggleLegacyMode = React.useCallback(
    async (partialOverlay?: TextOverlay | TextOverlayV2) => {
      // First step in the transition is to hide the modal and to clear
      // the current transition overlay. The necesity for clearing it is
      // enforced by a legacy text editor internal implementation that will
      // lead to a crash if the overlay id remains constant.
      onHide();
      setTransitionOverlay(undefined);

      // second step is to pop the modal and set the transition modal that
      // allows keeping the current partial state without having to commit
      // the text overlay to the state.
      // Overlay transformation is only performed if the modal confirm action
      // is selected as well as toggling the current visible mode.
      const result = await dispatch(pushModal({ name: 'TextOverlaySwitch' }));

      let transformedOverlay = partialOverlay;

      if (result.shouldSwitch) {
        onSwitchMode?.(showLegacy ? 'legacy' : 'current');
        transformedOverlay = showLegacy
          ? transformOverlayToV2(partialOverlay)
          : transformOverlayToLegacy(partialOverlay);

        toggleLegacyMode();
      }

      // Finally the transformed overlay is set to replace the transition
      // overlay and the modal opener is triggered again.
      setTransitionOverlay(transformedOverlay);
      onOpen();
    },
    [dispatch, onHide, onOpen, onSwitchMode, showLegacy, toggleLegacyMode],
  );

  let activeModal: ActiveModal = 'none';

  if (show) {
    activeModal = showLegacy ? 'legacy' : 'current';
  }

  return {
    activeModal,
    onToggleLegacyMode: handleToggleLegacyMode,
    transitionOverlay,
  };
};

export default useTextOverlaySwitch;
