import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingName } from 'blocks/OnboardingAnimations/types';
import usePrevious from 'hooks/usePrevious';
import { getCreation } from 'redux/middleware/api/creation-service/actions';
import { getVideo } from 'redux/middleware/api/media-upload-service/actions';
import { getUserIntegrators, loggedInSelector } from 'redux/modules/auth';
import {
  creationClipSuggestionsStatusSelector,
  loadCreationClipSuggestions,
  pollForCreationClipSuggestion,
} from 'redux/modules/clip-select';
import { getMyDisplayPref } from 'redux/modules/display-pref';
import { pollCreationClipSuggestionsIdsSelector } from 'redux/modules/entities';
import { showError } from 'redux/modules/notification/actions';
import { getOnboardingAction } from 'redux/modules/onboarding';
import { goToAppHome } from 'redux/modules/router/actions';
import { RequestStatus } from 'types';
import useVideoTranscriptionSuggestedClipInfo from './useVideoTranscriptionSuggestedClipInfo';

export default function useVideoTranscriptionSuggestedClipsPage() {
  const {
    creationRequestId,
    creation,
    video,
    hasCreations,
  } = useVideoTranscriptionSuggestedClipInfo();

  const isLoggedIn = useSelector(loggedInSelector);
  const creationClipSuggestionsStatus = useSelector(
    creationClipSuggestionsStatusSelector,
  );
  const pollCreationClipSuggestionsIds = useSelector(
    pollCreationClipSuggestionsIdsSelector,
  );

  const dispatch = useDispatch();

  const prevPollCreationClipSuggestionsIds = usePrevious(
    pollCreationClipSuggestionsIds,
  );

  useEffect(() => {
    Promise.resolve()
      .then(() => {
        dispatch(getMyDisplayPref());
      })
      .then(() => {
        if (isLoggedIn) {
          return Promise.all([
            dispatch(getUserIntegrators()),
            dispatch(getOnboardingAction(OnboardingName.NPS_SURVEY)),
          ]);
        }

        return null;
      })
      .then(() => dispatch(getCreation(creationRequestId)))
      .catch(() => {
        dispatch(
          showError({
            message: 'Error loading clips',
            code: 'ER014',
            dismissAfterSec: 5,
          }),
        );

        dispatch(goToAppHome());
      });
  }, [creationRequestId, dispatch, isLoggedIn]);

  useEffect(() => {
    if (
      prevPollCreationClipSuggestionsIds === undefined &&
      pollCreationClipSuggestionsIds &&
      !!pollCreationClipSuggestionsIds?.length
    ) {
      pollCreationClipSuggestionsIds.forEach(suggestion => {
        dispatch(pollForCreationClipSuggestion(creationRequestId, suggestion));
      });
    }
  }, [
    creationRequestId,
    dispatch,
    pollCreationClipSuggestionsIds,
    prevPollCreationClipSuggestionsIds,
  ]);

  useEffect(() => {
    if (creation) {
      dispatch(getVideo(creation?.requestObj?.videoInfo?.videoId));
    }
  }, [creation, dispatch]);

  useEffect(() => {
    if (!creationRequestId) {
      return;
    }

    dispatch(loadCreationClipSuggestions(creationRequestId));
  }, [creationRequestId, dispatch]);

  return {
    isLoading:
      isLoggedIn &&
      (creationClipSuggestionsStatus !== RequestStatus.SUCCESS ||
        !hasCreations ||
        !video),
  };
}
