import classNames from 'classnames';
import { Record, RecordOf } from 'immutable';
import * as React from 'react';
import _ from 'underscore';

import DraggableWorkspace, {
  OnSizeChange,
} from 'components/DraggableWorkspace';
import Rnd, { RndProps } from 'components/Rnd';
import { min } from 'utils/numbers';
import { formatDurationMillis } from 'utils/time';
import CurrentPlayMarker from './CurrentPlayMarker';
import PlaybackControls from './PlaybackControls';
import { block } from './utils';

const MOUSE_DELTA_MIN = 2; // If the mouse moves more than this during a click, we don't count it

interface IProps {
  containerClassName?: string;
  lengthMillis: number; // the duration of the entire video file
  startMillis: number;
  endMillis: number;
  currentMillis: number;
  maxDurationMillis?: number;
  onSelectedMillisChange?: (start: number, end: number) => void;
  onPlayPositionChange?: (posMillis: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  playing: boolean;
  showDisclaimer: boolean;
}

interface IDataState {
  clipperHeight: number;
  clipperWidth: number;
  lastMouseX: number;
  lastMouseY: number;
}

const dataFactory = Record<IDataState>({
  clipperHeight: 0,
  clipperWidth: 0,
  lastMouseX: 0,
  lastMouseY: 0,
});

interface IState {
  data: RecordOf<IDataState>;
}

export default class VideoClipperControls extends React.Component<
  IProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    maxDurationMillis: spareminConfig.videoClipMaxDurationSeconds * 1000,
    onPause: _.noop,
    onPlay: _.noop,
    onPlayPositionChange: _.noop,
    onSelectedMillisChange: _.noop,
  };

  private container: HTMLElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: dataFactory({
        clipperHeight: 0,
        clipperWidth: 0,
        lastMouseX: 0,
        lastMouseY: 0,
      }),
    };
  }

  private handleClipperSizeChange: OnSizeChange = workspaceSize => {
    const { height, width } = workspaceSize;

    this.setState(({ data }) => ({
      data: data.withMutations(u =>
        u.set('clipperHeight', height).set('clipperWidth', width),
      ),
    }));
  };

  private handleDrag: RndProps['onDrag'] = (__, dragData) => {
    const { x } = dragData;
    const {
      startMillis: oldStartMillis,
      endMillis: oldEndMillis,
      onSelectedMillisChange,
    } = this.props;

    const startMillis = this.convertPosToMillis(x);
    const endMillis = startMillis - oldStartMillis + oldEndMillis;

    onSelectedMillisChange(startMillis, endMillis);
  };

  private handleResize = (__, ___, ref, ____, position) => {
    const { onSelectedMillisChange } = this.props;
    const { x } = position;
    const rect = ref.getBoundingClientRect();

    const startMillis = this.convertPosToMillis(x);
    const endMillis = this.convertPosToMillis(rect.width + x);

    onSelectedMillisChange(startMillis, endMillis);
  };

  private handleBackPushed = () => {
    const { startMillis, onPlayPositionChange } = this.props;
    onPlayPositionChange(startMillis);
  };

  private handleForwardPushed = () => {
    const { endMillis, onPlayPositionChange } = this.props;
    onPlayPositionChange(endMillis);
  };

  private handleContainerMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const { clientX, clientY } = event;
    this.setState(({ data }) => ({
      data: data.withMutations(u =>
        u.set('lastMouseX', clientX).set('lastMouseY', clientY),
      ),
    }));
  };

  private handleContainerMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    const { data } = this.state;
    const { onPlayPositionChange } = this.props;

    // When there is a mouse up, we need to check if there was any movement to treat this
    // as a click vs a drag operation
    if (
      Math.abs(event.clientX - data.lastMouseX) < MOUSE_DELTA_MIN &&
      Math.abs(event.clientY - data.lastMouseY) < MOUSE_DELTA_MIN
    ) {
      const rect = this.container.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const millis = this.convertPosToMillis(x);
      onPlayPositionChange(millis);
    }
  };

  private convertPosToMillis = xPos => {
    const { lengthMillis } = this.props;
    const { data } = this.state;

    return (xPos / data.clipperWidth) * lengthMillis;
  };

  private getClipPosition = () => {
    const { lengthMillis, startMillis } = this.props;
    const { data } = this.state;

    return {
      x: (startMillis / lengthMillis) * data.clipperWidth,
      y: 0,
    };
  };

  private getClipSize = () => {
    const { lengthMillis, startMillis, endMillis } = this.props;
    const { data } = this.state;

    const width =
      ((endMillis - startMillis) / lengthMillis) * data.clipperWidth;

    return {
      width,
      height: data.clipperHeight,
    };
  };

  public render() {
    const {
      containerClassName,
      currentMillis,
      lengthMillis,
      maxDurationMillis,
      onPlayPositionChange,
      onPlay,
      onPause,
      playing,
      showDisclaimer,
    } = this.props;

    const { data } = this.state;

    const className = classNames(
      containerClassName,
      'video-clipper__controls-container',
    );

    const enableResizing = {
      bottom: false,
      bottomLeft: false,
      bottomRight: false,
      left: true,
      right: true,
      top: false,
      topLeft: false,
      topRight: false,
    };

    const maxWidth = !_.isFinite(maxDurationMillis)
      ? undefined
      : (min(maxDurationMillis, lengthMillis) / lengthMillis) *
        data.clipperWidth;

    const RndAsAny = Rnd as any;

    const clipPosition = this.getClipPosition();

    return (
      <div className={className}>
        {_.isFinite(maxDurationMillis) && showDisclaimer && (
          <div className="video-clipper__controls-desc">
            Drag the borders of the blue box to clip up to{' '}
            {formatDurationMillis(maxDurationMillis, { minute: 'numeric' })}{' '}
            minutes of video.
          </div>
        )}
        <div className="video-clipper__controls">
          <PlaybackControls
            playing={playing}
            onPlay={onPlay}
            onPause={onPause}
            onBack={this.handleBackPushed}
            onForward={this.handleForwardPushed}
          />
          <div className={block('clipper')}>
            <DraggableWorkspace
              className={block('draggable')}
              onSizeChange={this.handleClipperSizeChange}
            >
              <div className={block('bg')} />
              <div
                className={block('rnd-container')}
                onMouseDown={this.handleContainerMouseDown}
                onMouseUp={this.handleContainerMouseUp}
                ref={el => (this.container = el)}
              >
                {!isNaN(clipPosition.x) && (
                  <>
                    <RndAsAny
                      className={block('rnd')}
                      bounds="parent"
                      enableResizing={enableResizing}
                      position={clipPosition}
                      size={this.getClipSize()}
                      onDrag={this.handleDrag}
                      onDragStop={this.handleDrag}
                      onResize={this.handleResize}
                      onResizeStop={this.handleResize}
                      minWidth={0}
                      maxWidth={_.isNaN(maxWidth) ? undefined : maxWidth}
                    >
                      <div className={block('selected')} />
                    </RndAsAny>
                    <CurrentPlayMarker
                      currentMillis={currentMillis}
                      totalMillis={lengthMillis}
                      parentWidth={data.get('clipperWidth')}
                      onPlayPositionChange={onPlayPositionChange}
                      width={2}
                      dragWidth={8}
                    />
                  </>
                )}
              </div>
            </DraggableWorkspace>
          </div>
        </div>
      </div>
    );
  }
}

export { IProps as VideoClipperControlsProps };
