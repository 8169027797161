import * as React from 'react';

import { useSocialSection } from 'blocks/DownloadPage/containers/DownloadPageSocialSection/SocialSectionContext';
import LoadingOverlay from 'components/LoadingOverlay';
import { ShareAICaptionsField } from 'components/ShareForm';
import SocialAccountInfo from 'components/SocialAccountInfo';
import { getUsernameHandle } from 'containers/TwitterPostVideoModal/utils';
import useThreadsPostModalSelector from './useThreadsPostModalSelector';
import { block, MAX_CHARS_DESCRIPTION } from './utils';

export interface ThreadsPostComposerProps {
  description: string;
  isUploading?: boolean;
  readonly?: boolean;
  onDescriptionChange: (description: string) => void;
  onSwitchAccountClick: () => void;
}

const ThreadsPostComposer: React.FC<ThreadsPostComposerProps> = ({
  description,
  isUploading = false,
  readonly,
  onDescriptionChange,
  onSwitchAccountClick,
}) => {
  const {
    allowAiDisclaimer,
    onUnlockAICaptions,
    toggleModalVisibility,
  } = useSocialSection();

  const { username, profileImageUrl } = useThreadsPostModalSelector();

  return (
    <>
      {isUploading && <LoadingOverlay title="Posting Video" />}

      <div className={block('share-form')}>
        {username && (
          <SocialAccountInfo
            avatarSrc={profileImageUrl}
            username={getUsernameHandle(username)}
            onSwitchAccountClick={onSwitchAccountClick}
          />
        )}
        <ShareAICaptionsField
          {...{ allowAiDisclaimer, onUnlockAICaptions }}
          inputProps={{
            controlClassName: block('description-control'),
            onChange: onDescriptionChange,
            value: description,
            maxChars: MAX_CHARS_DESCRIPTION,
            readonly,
          }}
          onCancelUnlockAICaptions={() => toggleModalVisibility('threads')}
        />
      </div>
    </>
  );
};

export default ThreadsPostComposer;
