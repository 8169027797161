import cn from 'classnames';
import React, { useCallback } from 'react';
import { noop } from 'underscore';

import SteppedModal from 'components/SteppedModal';
import { NO_SELECTION_VALUE } from './constants';
import { YoutubePostModalProps } from './types';
import { block } from './utils';

const YoutubePostModal = React.forwardRef<SteppedModal, YoutubePostModalProps>(
  (
    {
      className,
      defaultStep,
      footerButtonsDisabled,
      onExited,
      onStepChange,
      onHide,
      show,
      steps,
      title,
    },
    ref,
  ) => {
    const handleHide = useCallback(() => {
      onHide();
    }, [onHide]);

    return (
      <SteppedModal
        baseClassName={cn(block(), className)}
        onHide={handleHide}
        {...{
          defaultStep,
          footerButtonsDisabled,
          onExited,
          onStepChange,
          ref,
          show,
          steps,
          title,
        }}
      />
    );
  },
);

YoutubePostModal.defaultProps = {
  onHide: noop,
  show: false,
};

export default YoutubePostModal;
export { NO_SELECTION_VALUE };
