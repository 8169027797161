import React from 'react';
import { AspectRatioName } from 'types';
import { block } from './utils';

export interface VideoTypeIconProps {
  aspectRatio: AspectRatioName;
  background: string;
  children?: React.ReactNode;
}

const VideoTypeIcon: React.FC<VideoTypeIconProps> = ({
  aspectRatio,
  background,
  children,
}) => (
  <div
    className={block('icon', {
      [aspectRatio]: true,
    })}
    style={{ ['--background' as any]: background }}
  >
    {children}
  </div>
);

export default VideoTypeIcon;
