import { Reply } from '@sparemin/blockhead';
import React from 'react';

import ClipCtaButton from 'blocks/ClipSelect/components/ClipCtaButton';
import { Scissors } from 'components/icons';
import { createChainedFunction } from 'utils/functions';
import { selectorBlock as block } from '../../utils';
import AddToVideoButton from '../AddToVideoButton';
import { useClipSelect } from '../ClipSelectContext';
import { useClipSelectTracking } from '../ClipSelectTrackingContext';
import DislikeClipButton from './DislikeClipButton';

export interface ClipSelectorControlsProps {
  playable?: boolean;
  isDisabled?: boolean;
}

const ClipSelectorControls: React.FC<ClipSelectorControlsProps> = ({
  playable,
  isDisabled,
}) => {
  const {
    activeSuggestionId,
    onAdjust,
    isClipSuggestionLoading,
    onSubmit,
  } = useClipSelect();
  const { onAdjustClip } = useClipSelectTracking();

  const disabled = isClipSuggestionLoading || !playable || isDisabled;

  return (
    <div className={block('controls')}>
      <DislikeClipButton
        disabled={disabled}
        suggestionId={activeSuggestionId}
      />
      <AddToVideoButton
        disabled={disabled}
        id="confirm"
        theme="rainbow"
        tooltip={
          playable
            ? disabled
              ? 'This clip will be ready soon'
              : 'Use this clip without making changes'
            : ''
        }
        icon={<Reply style={{ width: 13, height: 12 }} />}
        onClick={() => onSubmit()}
      >
        share
      </AddToVideoButton>
      <ClipCtaButton
        disabled={disabled}
        icon={<Scissors style={{ height: 16, width: 'auto' }} />}
        id="adjust"
        onClick={createChainedFunction(onAdjust, onAdjustClip)}
        theme="confirm"
        tooltip={!disabled && 'Change the start or end time of this clip'}
      />
    </div>
  );
};

export default ClipSelectorControls;
