import { getDefaultActiveModal } from 'containers/EditTextModal';
import { pushModal } from 'redux/modules/modal';
import { subtierSelector } from 'redux/modules/pricing';
import { ThunkAction } from 'redux/types';
import { isEnterpriseSubTier } from 'utils/pricing';

import { selectedOverlaySelector } from '../selectors';

export const pushEditTextModal = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  // Enterprise users should have all the text boxes modals
  // defaulted to the the legacy editor by default.
  const textOverlay = selectedOverlaySelector(getState());
  const userSubtier = subtierSelector(getState());
  const isEnterpriseUser = isEnterpriseSubTier(userSubtier);
  const shouldDefaultToLegacyModal = isEnterpriseUser;

  dispatch(
    pushModal({
      name: 'EditTextModal',
      params: {
        startingModalType: getDefaultActiveModal(
          textOverlay,
          shouldDefaultToLegacyModal,
        ),
      },
    }),
  );
};
