import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { every, isEmpty } from 'underscore';
import SteppedModal from 'components/SteppedModal';
import useConnectedModal from 'containers/ConnectedModal/useConnectedModal';
import FacebookAutomationModal from 'containers/FacebookAutomationModal';
import { FacebookAutomationModalStep } from 'containers/FacebookAutomationModal/steps/types';
import useAccessDeniedStep from 'containers/FacebookAutomationModal/steps/useAccessDeniedStep';
import useAuthenticationProgressStep from 'containers/FacebookAutomationModal/steps/useAuthenticationProgressStep';
import useAuthorizationStep from 'containers/FacebookAutomationModal/steps/useAuthorizationStep';
import useAutopostingComposerStep from 'containers/FacebookAutomationModal/steps/useAutopostingComposerStep';
import { UseComposerStepValue } from 'containers/FacebookAutomationModal/steps/useAutopostingComposerStep/types';
import useSwitchAccountStep from 'containers/FacebookAutomationModal/steps/useSwitchAccountStep';
import { facebookUserDataSelector } from 'redux/modules/social';
import { FacebookSheetDefaultValues } from './types';

export interface FacebookAutoPostModalProps {}

const FacebookAutoPostModal: React.FC<FacebookAutoPostModalProps> = () => {
  const [activeStep, setActiveStep] = useState('');

  const modalRef = useRef<SteppedModal>(null);

  const { params, show, onExited, onHide } = useConnectedModal(
    'FacebookAutoPost',
  );

  const facebookUserData = useSelector(facebookUserDataSelector);

  const { subscriptionItem, postType } = params ?? {};

  const { description, facebookPageId } =
    subscriptionItem?.autoPostVideoPreference.options || {};

  const initialValues = React.useMemo((): FacebookSheetDefaultValues => {
    return {
      description:
        description ??
        'Here’s a new clip from <Episode Title>! Listen to the full episode here: <Link To Episode>',
      postType,
      facebookPageId,
    };
  }, [description, postType, facebookPageId]);

  const replaceModalStep = useCallback(
    (id: FacebookAutomationModalStep): void => {
      modalRef.current?.stepHistory.replace(id);
    },
    [],
  );

  const handleGoToProgressStep = useCallback(() => {
    replaceModalStep('authentication-in-progress');
  }, [replaceModalStep]);

  const handleAuthorizationError = useCallback((): void => {
    replaceModalStep('access-denied');
  }, [replaceModalStep]);

  const handleGoToComposerStep = useCallback(() => {
    if (every(facebookUserData, isEmpty)) {
      return undefined;
    }

    return replaceModalStep('composer');
  }, [facebookUserData, replaceModalStep]);

  const handleExited = useCallback(() => {
    handleGoToComposerStep();
    onExited();
  }, [handleGoToComposerStep, onExited]);

  const handleHide = useCallback(
    (values?: UseComposerStepValue) => {
      handleGoToComposerStep();
      onHide(values);
    },
    [handleGoToComposerStep, onHide],
  );

  const handleSubmit = useCallback(
    (values: UseComposerStepValue): void => {
      handleHide(values);
    },
    [handleHide],
  );

  const handleAuthenticationSuccess = useCallback(async () => {
    if (postType === 'facebookStory' && !subscriptionItem) {
      return handleSubmit({
        description: '',
        postType: 'facebookStory',
      });
    }

    return replaceModalStep('composer');
  }, [handleSubmit, postType, replaceModalStep, subscriptionItem]);

  const authorization = useAuthorizationStep({
    onAuth: handleGoToProgressStep,
    onAuthSuccess: handleAuthenticationSuccess,
    onError: handleAuthorizationError,
  });

  const authenticationProgress = useAuthenticationProgressStep();

  const accessDenied = useAccessDeniedStep({
    onError: () => null,
    onReviseAccess: () => null,
  });

  const handleSwitchAccountClick = useCallback(() => {
    replaceModalStep('switch');
  }, [replaceModalStep]);

  const composer = useAutopostingComposerStep({
    initialValues,
    onHideModal: handleHide,
    onSubmit: handleSubmit,
    onSwitchAccountClick: handleSwitchAccountClick,
  });

  const switchAccount = useSwitchAccountStep({
    onClick: () => replaceModalStep('authorization'),
  });

  const isComposerStep = activeStep === 'composer';

  return (
    <FacebookAutomationModal
      {...{ show }}
      ref={modalRef}
      title={!isComposerStep && 'auto-post videos'}
      defaultStep={authorization.id}
      steps={[
        authorization,
        authenticationProgress,
        accessDenied,
        composer,
        switchAccount,
      ]}
      className={isComposerStep && 'composer-step-container'}
      onStepChange={setActiveStep}
      onHide={() => handleHide()}
      onExited={handleExited}
    />
  );
};

export default FacebookAutoPostModal;
