import {
  AutomationCardsOptions,
  AutoPostOptions,
  DestinationSteps,
  DestinationStepView,
} from 'blocks/AutomationOptions/types';
import {
  PodcastSubscription,
  SubscriptionItem,
} from 'redux/middleware/api/podcast-service';
import {
  AspectRatioName,
  AudiogramDestination,
  AutogramClipSelectionMethod,
  AutogramMixpanelPlatform,
  AutogramSubscriptionOptions,
  AutogramVideoType,
  CompatibilityVideoType,
  DeepImmutableMap,
  VideoTypes,
} from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { AUTOGRAM_DURATION_OPTIONS } from '../../constants';
import { AutomationWorkflowWizardState } from './types';

type CompatibilityTypeMap = {
  [k in AutogramVideoType]: CompatibilityVideoType;
};

const COMPATIBILITY_TYPE_MAP: CompatibilityTypeMap = {
  fullEpisode: VideoTypes.FULL_EPISODE,
  randomClip: VideoTypes.SHORT_CLIP,
};

const AUTOMATION_OPTION_MAP = {
  [AutogramClipSelectionMethod.SMART]: 'headliner',
  [AutogramClipSelectionMethod.SOUNDBITE_TAG]: 'soundbite',
};

export function getMixpanelLength(opts: AutogramSubscriptionOptions) {
  switch (opts.videoType) {
    default:
      return undefined;

    case VideoTypes.FULL_EPISODE:
      return 'long';

    case VideoTypes.RANDOM_CLIP:
      return 'short';
  }
}

export function getMixpanelPlatform(opts: AutogramSubscriptionOptions) {
  if (opts.videoType !== VideoTypes.RANDOM_CLIP) {
    return undefined;
  }

  const durationOpt = AUTOGRAM_DURATION_OPTIONS.find(
    o => o.value === opts.durationSeconds,
  );

  if (!durationOpt) return undefined;

  // removes spaces, slashes, and duration from the label.
  // e.g. "Instagram/LinkedIn: 30 sec" --> "InstagramLinkedIn"
  return durationOpt.label.replace(
    / |\/|(:.*$)/g,
    '',
  ) as AutogramMixpanelPlatform;
}

export function getCompatibilityType(
  autogramVideoType: AutogramVideoType,
): CompatibilityVideoType {
  return COMPATIBILITY_TYPE_MAP[autogramVideoType];
}

export function getOptionsFromSubscription(
  subscription: any,
): AutogramSubscriptionOptions {
  const firstItem = subscription?.get('subscriptionItems')?.get(0);

  if (!firstItem) {
    return undefined;
  }

  return {
    autopost: {
      platform: firstItem.getIn(['autoPostVideoPreference', 'platform']),
      description: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'description',
      ]),
      googleId: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'googleId',
      ]),
      madeForKids: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'madeForKids',
      ]),
      categoryId: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'categoryId',
      ]),
      playlists:
        firstItem
          .getIn(['autoPostVideoPreference', 'options', 'playlists'])
          ?.toJS() ?? [],
      tags:
        firstItem
          .getIn(['autoPostVideoPreference', 'options', 'tags'])
          ?.toJS() ?? [],
      title: firstItem.getIn(['autoPostVideoPreference', 'options', 'title']),
      visibility: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'privacyStatus',
      ]),
      tiktokUserId: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'tiktokUserId',
      ]),
      tiktokPrivacyLevel: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'tiktokPrivacyLevel',
      ]),
      commentEnabled: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'commentEnabled',
      ]),
      duetEnabled: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'duetEnabled',
      ]),
      stitchEnabled: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'stitchEnabled',
      ]),
      brandContentToggle: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'brandContentToggle',
      ]),
      brandOrganicToggle: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'brandOrganicToggle',
      ]),

      assetInfo: firstItem.getIn([
        'autoPostVideoPreference',
        'options',
        'assetInfo',
      ]),
    },
    durationSeconds: firstItem.get('videoLengthSecPref'),
    frequency: firstItem.get('videoFrequencyPref'),
    isCaptionEnabled: firstItem.get('isCaptionEnabled'),
    videoType: firstItem.get('videoType'),
    clipSelectionMethod: firstItem.get('clipSelectionMethod'),
  };
}

export function getAspectRatioNameFromSubscription(
  subscription: any,
): AspectRatioName {
  const dimensions = subscription?.get('dimension');
  return getAspectRatioName(
    dimensions?.get('height'),
    dimensions?.get('width'),
  );
}

export function checkViewIsClipSelection(view: DestinationStepView): boolean {
  return view === DestinationSteps.CLIP_SELECTION;
}

export function checkViewIsAutoPosting(view: DestinationStepView): boolean {
  return view === DestinationSteps.AUTO_POSTING;
}

export function checkViewIsAutomationOptionsView(
  view: DestinationStepView,
): boolean {
  return checkViewIsClipSelection(view) || checkViewIsAutoPosting(view);
}

export function checkIsPlatformWithoutVideoType(
  newPlatform: AudiogramDestination,
): boolean {
  return ['twitter', 'linkedin', 'threads'].includes(newPlatform);
}

export const getAutomationOption = (
  subscriptionItem: DeepImmutableMap<SubscriptionItem>,
): AutomationCardsOptions => {
  if (subscriptionItem.get('videoType') === VideoTypes.FULL_EPISODE) {
    return subscriptionItem.getIn(['autoPostVideoPreference', 'platform']) ===
      'youtube'
      ? AutoPostOptions.AUTO_POST
      : AutoPostOptions.EMAIL;
  }
  return (
    AUTOMATION_OPTION_MAP[subscriptionItem.get('clipSelectionMethod')] ?? null
  );
};

export const initializeDestinationOptions = (
  subscription?: DeepImmutableMap<PodcastSubscription>,
): AutomationWorkflowWizardState['destinationOptions'] | undefined => {
  const subscriptionItemMap = subscription?.getIn(['subscriptionItems', '0']);

  if (!subscription || !subscriptionItemMap) {
    return undefined;
  }

  return {
    aspectRatioName: getAspectRatioNameFromSubscription(subscription),
    automationOption: getAutomationOption(subscriptionItemMap),
    autopostVideoToYouTube:
      subscriptionItemMap.getIn(['autoPostVideoPreference', 'platform']) ===
      'youtube',
    clipDuration: subscriptionItemMap.get('videoLengthSecPref'),
    clipSelectionMethod: subscriptionItemMap.get('clipSelectionMethod'),
    isDestinationOptionsComplete: true,
    // There is no way for obtaining this information when loading an existing automation
    // as the information is not stored at the BE.
    platform: undefined,
    platformVideoType: undefined,
    stepView: DestinationSteps.CLIP_SELECTION,
    videoType: subscriptionItemMap.get('videoType'),
  };
};
