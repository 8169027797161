import { DestinationConfig, DestinationPlatform } from '../types';
import facebook from './facebook';
import instagram from './instagram';
import tiktok from './tiktok';
import youtube from './youtube';

const destinationConfigMap: Record<
  Extract<DestinationPlatform, 'facebook' | 'instagram' | 'tiktok' | 'youtube'>,
  DestinationConfig
> = {
  facebook,
  instagram,
  tiktok,
  youtube,
};

export default destinationConfigMap;
