import cn from 'classnames';
import React from 'react';

import Select, { Option, Overrides } from 'components/Select';
import { ShareFormField } from 'components/ShareForm';

import { block } from './utils';
import YoutubeSelectOption from './YoutubeSelectOption';

interface YoutubeSelectProps {
  disabled?: boolean;
  label: string;
  options: Option[];
  onChange?: (option: Option) => void;
  value: Option;
  className?: string;
}

const selectComponentOverrides: Overrides<Option> = {
  control: {
    props: p => ({
      ...p,
      className: cn(block('control'), p.className),
    }),
  },
  option: {
    component: YoutubeSelectOption,
  },
};

const YoutubeSelect: React.FunctionComponent<YoutubeSelectProps> = props => {
  const { disabled, label, onChange, options, value, className } = props;

  return (
    <ShareFormField label={label} className={className}>
      <Select
        className={block()}
        disabled={disabled}
        onChange={onChange}
        options={options}
        overrides={selectComponentOverrides}
        value={value}
      />
    </ShareFormField>
  );
};

export default YoutubeSelect;
