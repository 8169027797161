import React from 'react';

import { DestinationWizardMixpanelKey } from 'blocks/DestinationPlatforms';
import FontAwesome, { IconProp } from 'components/FontAwesome';
import { PopperRadio } from 'components/Popper';

import ClipOption from './ClipOption';
import { SocialPresetOption } from './types';
import { block } from './utils';

interface SocialPresetOptionGroupProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  onPickOption: (preset: SocialPresetOption) => void;
  options: SocialPresetOption[];
}

const SocialPresetOptionGroup: React.FunctionComponent<SocialPresetOptionGroupProps> = props => {
  const { icon, label, onPickOption, options } = props;

  const handlePickPreset = React.useCallback(
    (key: DestinationWizardMixpanelKey) => {
      const preset = options.find(option => option.key === key);
      if (preset) {
        onPickOption(preset);
      }
    },
    [onPickOption, options],
  );

  return (
    <div className={block('preset-group')}>
      <h5 className={block('preset-group-header', { 'with-icon': !!icon })}>
        <span className={block('preset-group-icon')}>{icon}</span>
        {label}
      </h5>
      <div className={block('preset-option-block', { 'with-icon': !!icon })}>
        <PopperRadio
          value={null}
          onChange={handlePickPreset}
          optionClassName={block('clip-option')}
          options={options.map(option => ({
            value: option.key,
            label: (
              <>
                {option.icon && (
                  <span className={block('option-icon')}>
                    {React.isValidElement(option.icon) ? (
                      option.icon
                    ) : (
                      <FontAwesome icon={option.icon as IconProp} />
                    )}
                  </span>
                )}
                <ClipOption
                  durationMillis={option.durationMs}
                  hideDuration={option.hideDuration}
                  label={option.text}
                />
              </>
            ),
          }))}
        />
      </div>
    </div>
  );
};

export default SocialPresetOptionGroup;
