import React from 'react';

export function getIntroMessage(
  suggestedClipCount: number | undefined,
): React.ReactNode {
  if (suggestedClipCount === undefined) {
    return null;
  }

  if (suggestedClipCount === 0) {
    return (
      <>We can't find any clips for this episode, please select your own...</>
    );
  }

  return (
    <>
      We reviewed this episode
      <br />
      and found{' '}
      <strong>
        {suggestedClipCount} awesome clip
        {suggestedClipCount > 1 ? 's' : ''}!
      </strong>
    </>
  );
}
