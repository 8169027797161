import { Video } from '@sparemin/blockhead';
import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import AsyncAudioClipper from 'containers/AsyncAudioClipper';
import {
  entireAudioInstancesSelector,
  entireAudiosSelector,
} from 'redux/modules/entities/selectors';
import { pageBlock as block } from '../utils';
import AddToVideoButton from './AddToVideoButton';
import { useClipSelectAnimation } from './ClipSelectAnimationContext';
import { useClipSelect } from './ClipSelectContext';

interface Props {
  className?: string;
}

const ClipSelectAudioClipper: React.FC<Props> = ({ className }) => {
  const {
    visibleSuggestionIds,
    region,
    onRegionChange,
    audioClipperRef,
    onSelectClip,
    clipsPageInfo: { entireAudioInstanceId },
  } = useClipSelect();
  const { clipSelectorSliderRef } = useClipSelectAnimation();

  const entireAudio = useSelector(state => {
    const entireAudioInstance = entireAudioInstancesSelector(state)?.get(
      String(entireAudioInstanceId),
    );
    return entireAudiosSelector(state)?.get(
      String(entireAudioInstance?.get('entireAudio')),
    );
  });

  useLayoutEffect(() => {
    if (region) {
      audioClipperRef.current?.seek(region.startMillis);
    }
  }, [audioClipperRef, region]);

  const handleSelectClip = useCallback(async (): Promise<void> => {
    await onSelectClip();

    clipSelectorSliderRef?.current?.slickGoTo(
      visibleSuggestionIds?.length,
      true,
    );
  }, [clipSelectorSliderRef, onSelectClip, visibleSuggestionIds]);

  return (
    <AsyncAudioClipper
      {...{ className, region, entireAudioInstanceId }}
      src={entireAudio?.get('url')}
      ref={audioClipperRef}
      onRegionUpdate={onRegionChange}
      captionsControl={
        <AddToVideoButton
          disabled={region.endMillis - region.startMillis < 1000}
          className={block('clip-select-submit-button')}
          theme="submit"
          icon={<Video style={{ width: 17, height: 12 }} />}
          onClick={handleSelectClip}
        >
          select clip
        </AddToVideoButton>
      }
    />
  );
};

export default ClipSelectAudioClipper;
