import { Spacer } from '@sparemin/blockhead';
import { LocationDescriptor } from 'history';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DestinationPlatformsVideoTypes } from 'blocks/DestinationPlatforms';
import { audiogramDestinationConfig } from 'constants/audiogram';
import { onOnboardingWizardNext } from 'redux/modules/mixpanel';
import {
  AudiogramDestination,
  AudiogramVideoTypeConfig,
  AutogramVideoType,
} from 'types';
import { onboardingVideoTypeStepBlock as block } from '../utils';
import CardWizardStep from './CardWizard/CardWizardStep';

interface VideoTypeStepProps {
  platform?: AudiogramDestination;
  onProjectTypeSelected?: (
    videoType: AutogramVideoType,
    destination: LocationDescriptor,
  ) => void;
  onBackClick?: () => void;
}

function VideoTypeStep(props: VideoTypeStepProps) {
  const { platform, onProjectTypeSelected, onBackClick } = props;

  const dispatch = useDispatch<Dispatch>();

  const handleVideoTypeSelected = useCallback(
    (videoType: AudiogramVideoTypeConfig) => {
      dispatch(
        onOnboardingWizardNext('videoTypes', {
          platform,
          videoType: videoType.id,
        }),
      );

      onProjectTypeSelected(
        videoType.autogramWizardSettings.videoType,
        videoType.destination,
      );
    },
    [dispatch, onProjectTypeSelected, platform],
  );

  return (
    <CardWizardStep className={block()}>
      <Spacer
        as="div"
        orientation="vertical"
        align="center"
        justify="center"
        className={block('video-type-step-wrapper')}
      >
        <DestinationPlatformsVideoTypes<AudiogramVideoTypeConfig>
          icon={audiogramDestinationConfig[platform]?.icon}
          videoTypes={audiogramDestinationConfig[platform]?.videoTypes}
          onClick={handleVideoTypeSelected}
          onBackClick={onBackClick}
        />
      </Spacer>
    </CardWizardStep>
  );
}

export default VideoTypeStep;
