import { isEmpty } from 'underscore';
import { ShareVideoOptions } from 'redux/middleware/api/podcast-service';
import {
  getYoutubeCategoriesAction,
  getYoutubeLanguagesAction,
  shareVideo,
} from 'redux/middleware/api/podcast-service/actions';
import {
  createYoutubePlaylist as createYoutubePlaylistAction,
  getSocialProfile,
  getYoutubeChannel,
  getYoutubePlaylists as getYoutubePlaylistAction,
} from 'redux/middleware/api/third-party-authentication-service/actions';
import {
  GetSocialYoutubeChannelResult,
  YouTubeChannelLongStatus,
} from 'redux/middleware/api/third-party-authentication-service/types';
import { isShareStatusResolved } from 'redux/middleware/api/video-export-service';
import { getSocialShare } from 'redux/middleware/api/video-export-service/actions';
import { youtubeLanguagesSelector } from 'redux/modules/entities';
import { onPostYoutubeVideoSuccess } from 'redux/modules/mixpanel/actions';
import { showNotification } from 'redux/modules/notification/actions';
import { ThunkAction } from 'redux/types';
import { SocialSharePlatform, YoutubeVideoVisibility } from 'types';
import {
  PLAYLIST_POLL_INTERVAL_MILLIS,
  PLAYLIST_POLL_MAX_ATTEMPTS,
} from 'utils/constants';
import reduxPoll from 'utils/redux-poll';
import { Type } from './action-types';
import {
  socialShareIdSelector,
  socialShareSelector,
  youtubePlaylistsSelector,
  youtubeUserDataSelector,
} from './selectors';
import { AuthorizeSuccessAction, GooglePostMessageData } from './types';

const YOUTUBE_POST_ID = 'app/youtube/post-poll';
const YOUTUBE_PLAYLIST_POLL_ID = 'app/youtube/create-playlist-poll';

export const getYoutubeUser = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.YOUTUBE_USER_DATA_GET_REQUEST });

  try {
    const { providerUserId, idToken, accessToken } = youtubeUserDataSelector(
      getState(),
    );
    const { response } = await dispatch(
      getSocialProfile(
        SocialSharePlatform.YOUTUBE,
        providerUserId,
        idToken,
        accessToken,
      ),
    );

    dispatch({
      payload: response,
      type: Type.YOUTUBE_USER_DATA_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting user data'),
      type: Type.YOUTUBE_USER_DATA_GET_FAILURE,
    });

    throw error;
  }
};

export const getYoutubeLanguages = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  // Languages data for YT is static, if it is already loaded, it should not be be
  // necessary to fetch it again
  if (!isEmpty(youtubeLanguagesSelector(getState())?.toJS())) {
    return;
  }

  dispatch({ type: Type.YOUTUBE_LANGUAGES_REQUEST });

  try {
    const { response } = await dispatch(getYoutubeLanguagesAction());

    dispatch({
      type: Type.YOUTUBE_LANGUAGES_SUCCESS,
      payload: { languages: response.result },
    });
  } catch (error) {
    dispatch({
      type: Type.YOUTUBE_LANGUAGES_FAILURE,
      error: new Error('Error getting youtube languages'),
    });

    throw error;
  }
};

export const getYoutubePlaylists = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.YOUTUBE_USER_DATA_GET_REQUEST });

  try {
    const { providerUserId, accessToken } = youtubeUserDataSelector(getState());
    const { response } = await dispatch(
      getYoutubePlaylistAction(providerUserId, accessToken),
    );

    dispatch({
      payload: response,
      type: Type.PLAYLISTS_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting youtube playlists'),
      type: Type.PLAYLISTS_GET_FAILURE,
    });

    throw error;
  }
};

export const getYoutubeCategories = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  try {
    const { response } = await dispatch(getYoutubeCategoriesAction());

    dispatch({
      payload: response,
      type: Type.CATEGORIES_GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      error: new Error('Error getting youtube categories'),
      type: Type.CATEGORIES_GET_FAILURE,
    });

    throw error;
  }
};

const waitForPlaylist = (playlistId: string): ThunkAction<Promise<void>> => (
  dispatch,
  getState,
) => {
  return reduxPoll(dispatch, () => dispatch(getYoutubePlaylists()), {
    id: YOUTUBE_PLAYLIST_POLL_ID,
    intervalMillis: PLAYLIST_POLL_INTERVAL_MILLIS,
    maxAttempts: PLAYLIST_POLL_MAX_ATTEMPTS,
    shouldContinue: err => {
      if (err) return false;

      const { playlists } = youtubePlaylistsSelector(getState());
      const targetPlaylist = playlists.find(p => p.id === playlistId);
      return targetPlaylist === undefined;
    },
  });
};

export const createYoutubePlaylist = (
  playlistTitle: string,
  privacyStatus: YoutubeVideoVisibility,
  imageUrl?: string,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.PLAYLIST_CREATE_REQUEST });
  try {
    const { accessToken, providerUserId } = youtubeUserDataSelector(getState());
    const result = await dispatch(
      createYoutubePlaylistAction({
        accessToken,
        providerUserId,
        playlistTitle,
        privacyStatus,
        imageUrl,
      }),
    );
    const playlistId = result.response.result;
    await dispatch(waitForPlaylist(playlistId));
    dispatch({
      type: Type.PLAYLIST_CREATE_SUCCESS,
      payload: { playlistId },
    });
  } catch (err) {
    dispatch({ type: Type.PLAYLIST_CREATE_FAILURE });
    throw err;
  }
};

export const clearYoutubeUser = () => ({ type: Type.YOUTUBE_USER_DATA_CLEAR });

const showSuccessNotification = (): ThunkAction<void> => (
  dispatch,
  getState,
) => {
  const { sharePostUrl } = socialShareSelector(getState());

  dispatch(
    showNotification(
      'You can see your post {{link}}',
      'success',
      0,
      'link',
      'Your video was posted',
      undefined,
      sharePostUrl,
      'here',
    ),
  );
};

const showErrorNotification = () =>
  showNotification({
    message: 'Please try again or {{link}} so we can help',
    code: 'ER013',
    level: 'error',
    type: 'help',
    title: 'Sorry, we couldn’t post your video',
    actionLabel: 'contact us',
  });

const waitForYoutubePost = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  dispatch({ type: Type.YOUTUBE_VIDEO_POST_AWAIT_REQUEST });

  const socialShareId = socialShareIdSelector(getState());

  try {
    await reduxPoll(dispatch, () => dispatch(getSocialShare(socialShareId)), {
      id: YOUTUBE_POST_ID,
      intervalMillis: 3000,
      maxAttempts: spareminConfig.videoExportPollMaxAttempts,
      shouldContinue: err => {
        if (err) return false;
        const share = socialShareSelector(getState());
        return isShareStatusResolved(share);
      },
    });

    dispatch({
      type: Type.YOUTUBE_VIDEO_POST_AWAIT_SUCCESS,
    });
    dispatch(showSuccessNotification());
    dispatch(onPostYoutubeVideoSuccess());
  } catch (err) {
    // NB: error isn't send in the action because it is thrown and handled by
    // postYoutubeVideo, which will send it to the reducer
    dispatch({ type: Type.YOUTUBE_VIDEO_POST_AWAIT_FAILURE });
    throw err;
  }
};

export const getYoutubeChannels = (
  providerUserId: string,
): ThunkAction<Promise<GetSocialYoutubeChannelResult>> => async dispatch => {
  try {
    dispatch({ type: Type.YOUTUBE_CHANNEL_GET_REQUEST });
    const { response: channelData } = await dispatch(
      getYoutubeChannel(providerUserId, undefined),
    );
    dispatch({ type: Type.YOUTUBE_CHANNEL_GET_SUCCESS, payload: channelData });
    return channelData;
  } catch (err) {
    dispatch({ type: Type.YOUTUBE_CHANNEL_GET_FAILURE });
    throw err;
  }
};

// This action may not necessary but it was added for making this action more
// verbose than just fetching the YT channels and evaluate the data obtained.
export const validateYoutubeAccountElegibility = (): ThunkAction<Promise<YouTubeChannelLongStatus | null>> => async (
  dispatch,
  getState,
) => {
  const { providerUserId } = youtubeUserDataSelector(getState());

  const channelData = await dispatch(getYoutubeChannels(providerUserId));
  return channelData?.longUploadsStatus;
};

export const postYoutubeVideo = (
  embedVideoId: string,
  opts: ShareVideoOptions,
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: Type.YOUTUBE_VIDEO_POST_REQUEST });

  const { accessToken } = youtubeUserDataSelector(getState());

  try {
    const {
      response: { socialShareId },
    } = await dispatch(
      shareVideo(embedVideoId, SocialSharePlatform.YOUTUBE, accessToken, opts),
    );

    dispatch({
      payload: { socialShareId },
      type: Type.YOUTUBE_VIDEO_POST_SUCCESS,
    });

    await dispatch(waitForYoutubePost());
  } catch (err) {
    dispatch({
      error: err,
      type: Type.YOUTUBE_VIDEO_POST_FAILURE,
    });
    dispatch(showErrorNotification());
  }
};

export const youtubeAuthorizeSuccess = (
  data: Pick<
    GooglePostMessageData['tokenInfo'],
    'accessToken' | 'idToken' | 'providerUserId'
  >,
): AuthorizeSuccessAction => ({
  type: Type.YOUTUBE_AUTHORIZE_SUCCESS,
  payload: data,
});
