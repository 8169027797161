import * as React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';

import { TransitionGroup } from 'react-transition-group';
import BemCSSTransition from 'components/BemCssTransition';
import Page from 'components/Page';
import bem from 'utils/bem';
import DownloadPage from './containers/DownloadPage';
import DownloadPageContainer from './containers/DownloadPageContainer';

const block = bem('download-routes');

const DownloadRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <DownloadPageContainer>
      <Page>
        <TransitionGroup>
          <BemCSSTransition
            key={location.pathname}
            timeout={{
              exit: 600,
              enter: 1200,
            }}
            unmountOnExit
            transitionClassName={block('page-animation')}
          >
            <Switch location={location}>
              <Route exact path={path} component={DownloadPage} />
            </Switch>
          </BemCSSTransition>
        </TransitionGroup>
      </Page>
    </DownloadPageContainer>
  );
};

export default DownloadRoutes;
