import { Button } from '@sparemin/blockhead';
import React from 'react';
import { H1 } from 'components/Heading';
import LinkButton from 'components/LinkButton';
import List from 'components/List';
import ScrollBars from 'components/ScrollBars';
import { VideoTypeConfig } from './types';
import { block } from './utils';

export interface DestinationPlatformsVideoTypesProps<
  TVideoType extends VideoTypeConfig
> {
  title?: React.ReactNode;
  icon: React.ReactNode;
  videoTypes: TVideoType[];
  onClick: (videoType: VideoTypeConfig) => void;
  onBackClick?: () => void;
}

const DestinationPlatformsVideoTypes = <TVideoType extends VideoTypeConfig>({
  title = 'Choose a video type',
  icon,
  videoTypes,
  onClick,
  onBackClick,
}: DestinationPlatformsVideoTypesProps<TVideoType>) => {
  return (
    <div className={block('video-types')}>
      <div className={block('main-icon')}>{icon}</div>

      <H1 className={block('title')}>{title}</H1>

      <LinkButton
        onClick={onBackClick}
        className={block('choose-another-button')}
        size="md"
        theme="cta"
        uppercase
      >
        ← choose another destination
      </LinkButton>

      <List>
        <ScrollBars autoHeight autoHeightMax={340} hideTracksWhenNotNeeded>
          {videoTypes.map(config => {
            return (
              <List.Item key={config.id} modifiers={['no-padding']}>
                <Button
                  {...config.metadata}
                  className={block('video-type-component')}
                  onPress={() => onClick(config)}
                >
                  <List.Item.Contents>
                    <List.Item.Artwork>
                      <div className={block('icon-container')}>
                        {config.icon}
                      </div>
                    </List.Item.Artwork>

                    <List.Item.Text
                      className={block('text')}
                      primary={config.title}
                      secondary={
                        <span className={block('desc')}>
                          {config.description}
                        </span>
                      }
                    />
                  </List.Item.Contents>
                </Button>
              </List.Item>
            );
          })}
        </ScrollBars>
      </List>
    </div>
  );
};

export default DestinationPlatformsVideoTypes;
