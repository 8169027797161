import React from 'react';
import Modal from 'components/Modal';
import SwitchFacebookAccountMessage from 'containers/FacebookPostModal/SwitchFacebookAccountMessage';

type useSwitchAccountStepConfig = {
  onClick: () => void;
};
export default function useSwitchAccountStep({
  onClick,
}: useSwitchAccountStepConfig) {
  return {
    title: 'switch facebook accounts',
    component: <SwitchFacebookAccountMessage />,
    id: 'switch',
    renderFooterButtons: ({ cancel, submit }) => [
      <Modal.FooterButton {...cancel} key="cancel">
        cancel
      </Modal.FooterButton>,
      <Modal.FooterButton
        {...submit}
        theme="submit"
        key="submit"
        onClick={onClick}
      >
        switch accounts
      </Modal.FooterButton>,
    ],
  };
}
