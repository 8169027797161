import dayjs from 'dayjs';
import * as React from 'react';

import { formatDurationMillis } from 'utils/time';
import { episodeListBlock as block } from '../utils';
import EpisodeListRow from './EpisodeListRow';

const { useMemo } = React;

export interface EpisodeListEpisodeDescriptionProps {
  durationMillis: number;
  subtitle?: string;
  publishedAtMillis: number;
  title: string;
}

const EpisodeListEpisodeDescription: React.FC<EpisodeListEpisodeDescriptionProps> = ({
  durationMillis,
  subtitle,
  publishedAtMillis,
  title,
}) => {
  const durationString = useMemo(
    () =>
      formatDurationMillis(durationMillis, {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
      }),
    [durationMillis],
  );
  const dateString = useMemo(
    () => dayjs(publishedAtMillis).format('MMM D, YYYY'),
    [publishedAtMillis],
  );

  return (
    <EpisodeListRow.Description
      primary={title}
      secondary={
        <span className={block('duration')}>
          {subtitle && <>{subtitle}&nbsp;&nbsp;•&nbsp;&nbsp;</>}
          {dateString}
          &nbsp;&nbsp;•&nbsp;&nbsp;
          {durationString}
        </span>
      }
    />
  );
};

export default EpisodeListEpisodeDescription;
