import * as React from 'react';

import LoadingScreen from 'components/LoadingScreen';
import Page from 'components/Page';
import useVideoTranscriptionSuggestedClipsPage from './useVideoTranscriptionSuggestedClipsPage';

export interface VideoTranscriptionSuggestedClipsPageContainerProps {
  children?: React.ReactElement;
}

const VideoTranscriptionSuggestedClipsPageContainer: React.FC<VideoTranscriptionSuggestedClipsPageContainerProps> = ({
  children,
}) => {
  const { isLoading } = useVideoTranscriptionSuggestedClipsPage();

  return !isLoading ? (
    children
  ) : (
    <Page>
      <LoadingScreen message="Loading..." show={isLoading} fadeout />
    </Page>
  );
};

export default VideoTranscriptionSuggestedClipsPageContainer;
