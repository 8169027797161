import { useCallback } from 'react';
import usePrevious from 'hooks/usePrevious';
import { DEFAULT_CLIP_START_MILLIS } from '../constants';
import { ClipSuggestion, VideoPlayerRefs } from '../types';

export interface UseClipSelectVideoPlayerResult {
  onSelectionChange: () => void;
  play: () => void;
  pause: () => void;
}

export default function useClipSelectVideoPlayer(
  videoPlayerRefs: VideoPlayerRefs,
  clipSuggestion: ClipSuggestion,
): UseClipSelectVideoPlayerResult {
  const activeSuggestionId = clipSuggestion?.id;
  const player = videoPlayerRefs?.[activeSuggestionId]?.current;

  const prevActiveSuggestionId = usePrevious(activeSuggestionId);

  const prevVideoPlayer = videoPlayerRefs?.[prevActiveSuggestionId]?.current;

  const play = useCallback(
    (element?: HTMLVideoElement): void => {
      const currentPlayer = videoPlayerRefs?.[activeSuggestionId]?.current;
      const videoElement = element ?? currentPlayer;

      if (videoElement) {
        videoElement.play();
      }
    },
    [activeSuggestionId, videoPlayerRefs],
  );

  const pause = useCallback(
    (element?: HTMLVideoElement): void => {
      const currentPlayer = videoPlayerRefs?.[activeSuggestionId]?.current;
      const videoElement = element ?? currentPlayer;

      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = DEFAULT_CLIP_START_MILLIS;
      }
    },
    [activeSuggestionId, videoPlayerRefs],
  );

  const handleSelectionChange = useCallback((): void => {
    const isPlaying = prevVideoPlayer && !prevVideoPlayer?.paused;

    if (player) {
      player.currentTime = DEFAULT_CLIP_START_MILLIS;
    }

    if (prevVideoPlayer) {
      pause(prevVideoPlayer);
    }

    if (isPlaying && clipSuggestion?.status === 'completed') {
      play(player);
    }
  }, [clipSuggestion, pause, play, player, prevVideoPlayer]);

  return {
    onSelectionChange: handleSelectionChange,
    play: () => play(),
    pause: () => pause(),
  };
}
