import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SWITCH_ACCOUNT_VALUE } from 'components/AccountSelect';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Step } from 'components/SteppedModal';
import usePostModalDescription from 'hooks/usePostModalDescription';
import usePrevious from 'hooks/usePrevious';
import { loggedInSelector } from 'redux/modules/auth';
import {
  episodeUrlSelector,
  isPollingSocialPostCaptionsSelector,
} from 'redux/modules/download';
import { isFreeSelector } from 'redux/modules/pricing';
import {
  linkedinIsLoading,
  linkedinOrganizationsSelector,
} from 'redux/modules/social';
import { PostStatus } from 'redux/modules/social/constants';
import { checkIsLinkedinOrganizationProfile } from 'redux/modules/social/linkedin/utils';
import { addSocialUTMSource } from 'utils/social/add-social-utm-source';
import { switchAccount } from '../constants';
import { LinkedinOrganizationSelectorOption } from '../LinkedinOrganizationSelector/types';
import { organizationToOption } from '../LinkedinOrganizationSelector/utils';
import LinkedinPostComposer, {
  LinkedinPostComposerProps,
} from '../LinkedinPostComposer';
import useLinkedinPostModalSelector from '../useLinkedinPostModalSelector';

export interface UseComposerStepValue {
  description: string;
  linkedinAuthorId: string;
}

export interface UseComposerStepConfig
  extends Pick<LinkedinPostComposerProps, 'readonly'>,
    Pick<Step, 'submitButtonLabel'> {
  defaultDescription?: string;
  onPostSuccess?: () => void;
  onSubmit?: (value: UseComposerStepValue) => void;
  onSwitchAccountClick?: () => void;
}

export default function useComposerStep(
  config: UseComposerStepConfig = {},
): Step {
  const {
    defaultDescription: defaultDescriptionProp,
    onPostSuccess,
    onSubmit,
    readonly,
    submitButtonLabel = 'post your video',
    onSwitchAccountClick,
  } = config;

  const {
    avatarSrc,
    defaultDescription,
    defaultOrganization,
    postStatus,
    username,
  } = useLinkedinPostModalSelector({
    defaultDescription: defaultDescriptionProp,
  });

  const {
    description,
    setDescription,
    resetDescription,
  } = usePostModalDescription({
    platform: 'linkedin',
    defaultDescription,
  });

  const isPollingSocialPostCaptions = useSelector(
    isPollingSocialPostCaptionsSelector,
  );
  const isFree = useSelector(isFreeSelector);
  const isAuthenticated = useSelector(loggedInSelector);
  const organizations = useSelector(linkedinOrganizationsSelector);
  const episodeUrl = useSelector(episodeUrlSelector);
  const isLoading = useSelector(linkedinIsLoading);

  const [organization, setOrganization] = useState<
    LinkedinOrganizationSelectorOption
  >(defaultOrganization);
  const prevPostStatus = usePrevious(postStatus);

  const isUploading = postStatus === PostStatus.IN_PROGRESS;

  const handleSubmit = useCallback(() => {
    const selected =
      organization.id == null ? defaultOrganization : organization;
    onSubmit?.({
      description: addSocialUTMSource('linkedin', episodeUrl, description),
      linkedinAuthorId: selected?.id ?? '',
    });
  }, [organization, defaultOrganization, onSubmit, episodeUrl, description]);

  const handleOrganizationClick = (
    selected: LinkedinOrganizationSelectorOption,
  ) => {
    if (selected.id === SWITCH_ACCOUNT_VALUE) {
      onSwitchAccountClick?.();
    } else {
      setOrganization(selected);
    }
  };

  useEffect(() => {
    if (
      prevPostStatus === PostStatus.IN_PROGRESS &&
      postStatus === PostStatus.SUCCESS
    ) {
      resetDescription();
      setOrganization(defaultOrganization);

      onPostSuccess?.();
    }
  }, [
    defaultDescription,
    onPostSuccess,
    postStatus,
    prevPostStatus,
    defaultOrganization,
    organizations,
    isFree,
    isAuthenticated,
    setDescription,
    resetDescription,
  ]);

  return {
    component: (
      <LinkedinPostComposer
        organization={
          organization.id == null ? defaultOrganization : organization
        }
        organizations={
          isLoading
            ? []
            : [
                defaultOrganization,
                ...organizations.map(organizationToOption),
                switchAccount,
              ]
        }
        onDescriptionChange={setDescription}
        onOrganizationChange={handleOrganizationClick}
        isOrganizationProfile={checkIsLinkedinOrganizationProfile(
          organization.id,
        )}
        {...{
          alert,
          avatarSrc,
          description,
          isUploading,
          readonly,
          username,
        }}
      />
    ),
    id: 'composer',
    onSubmit: handleSubmit,
    fluidFooterButtons: true,
    renderFooterButtons: ({ submit }) => [
      <ModalFooterButton
        {...submit}
        key="submit"
        theme="submit"
        disabled={isLoading || isUploading || isPollingSocialPostCaptions}
      >
        {submitButtonLabel}
      </ModalFooterButton>,
    ],
  };
}
