import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { createSelector } from 'reselect';
import { omit } from 'underscore';
import { ClipsPageInfo, ClipSuggestion } from 'blocks/ClipSelect/types';
import { PodcastEpisode } from 'redux/middleware/api/podcast-service';
import { dislikedGroupSuggestionIdsSelector } from 'redux/modules/clip-select';
import {
  groupClipSuggestionsListSelector,
  makePodcastEpisodeSelector,
  podcastSubscriptionsSelector,
  subscriptionItemInformationSelector,
} from 'redux/modules/entities';
import { ClipSelectPageSource } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { getValue } from 'utils/collections';
import { parseSearch } from 'utils/routes';
import { ClipSelectPageLocationSearch } from 'utils/routes/types';

const makeSubscriptionSelector = (autoCreateSubscriptionId: number) =>
  createSelector([podcastSubscriptionsSelector], subscriptions => {
    if (!autoCreateSubscriptionId) {
      return undefined;
    }

    return getValue(subscriptions, String(autoCreateSubscriptionId))?.toJS();
  });

const makePodcastSelector = (podcastId: number) =>
  createSelector([podcastSubscriptionsSelector], podcasts => {
    if (!podcastId || !podcasts) {
      return undefined;
    }

    return podcasts.get(`${podcastId}`)?.toJS();
  });

export interface UseAutomationSuggestedClipInfoResult {
  episodeId?: string;
  subscriptionItemId?: number;
  groupRequestId?: number;
  entireAudioInstanceId?: number;
  source?: ClipSelectPageSource;
  podcast?: PodcastEpisode['podcast'];
  episode?: PodcastEpisode;
  artwork?: string;
  aspectRatioName?: string;
  suggestedClips?: ClipSuggestion[];
  dislikedSuggestionIds?: number[];
  clipsPageInfo?: ClipsPageInfo;
}

export default function useAutomationSuggestedClipInfo(): UseAutomationSuggestedClipInfoResult {
  const suggestedClips = useSelector(groupClipSuggestionsListSelector);
  const subscriptionItemInformation = useSelector(
    subscriptionItemInformationSelector,
  )?.toJS();
  const dislikedSuggestionIds = useSelector(dislikedGroupSuggestionIdsSelector);

  const {
    params: { episodeId },
  } = useRouteMatch<{
    episodeId?: string;
  }>();

  const subscriptionSelector = useMemo(
    () =>
      makeSubscriptionSelector(subscriptionItemInformation?.autoCreateSubId),
    [subscriptionItemInformation],
  );
  const podcastSelector = useMemo(
    () => makePodcastSelector(subscriptionItemInformation?.autoCreateSubId),
    [subscriptionItemInformation],
  );
  const episodeSelector = useMemo(
    () => makePodcastEpisodeSelector(Number(episodeId)),
    [episodeId],
  );

  const subscription = useSelector(subscriptionSelector);
  const podcast = useSelector(podcastSelector);
  const episode = useSelector(episodeSelector);

  const location = useLocation();

  const {
    subscriptionItemId,
    groupRequestId,
    source,
  }: ClipSelectPageLocationSearch = parseSearch(location.search);
  const { dimension } = subscription || {};
  const artwork = episode?.thumbnailImageUrl || podcast?.thumbnailImageUrl;
  const aspectRatioName = getAspectRatioName(
    dimension?.height,
    dimension?.width,
  );
  const entireAudioInstanceId = episode?.entireAudioInstanceId;

  return {
    episodeId,
    subscriptionItemId,
    groupRequestId,
    entireAudioInstanceId,
    source,
    podcast,
    episode,
    suggestedClips: suggestedClips?.map(suggestion => ({
      ...omit(suggestion, 'autoCreateEpisodeId'),
      id: suggestion.autoCreateEpisodeId,
    })),
    dislikedSuggestionIds,
    clipsPageInfo: {
      title: episode?.title,
      subtitle: podcast?.title,
      artwork,
      aspectRatioName,
      artworkAspectRatioName: 'square',
      entireAudioInstanceId,
      mediaDurationMillis: episode?.audioDurationMillis,
      publishedAtMillis: episode?.publishedAtMillis,
    },
  };
}
