import { Location, LocationDescriptor } from 'history';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router';
import { parseQueryParams } from 'blocks/Authentication/utils';
import { HeadlinerUsageStepProps } from 'blocks/OnboardingWizard/components/HeadlinerUsageStep';
import { SubTier, Tier } from 'redux/middleware/api/plan-service';
import { plansSelector } from 'redux/modules/entities';
import { setMixpanelWhatYouDo } from 'redux/modules/mixpanel';
import { onboardingSubmitStatusSelector } from 'redux/modules/onboarding/selectors';
import { subtierSelector } from 'redux/modules/pricing';

import { AudiogramDestination, HeadlinerUsage } from 'types';
import { setAuthWrapperRedirect } from 'utils/routes';
import {
  FileStepProps,
  RoutingStepProps,
  StepId,
  WizardRouterOption,
  WizardStep,
} from '../../types';
import { WelcomeType } from '../WelcomeStep';
import useOnboardingWizardDispatch from './useOnboardingWizardDispatch';

const useWelcomeType = (location: Location): WelcomeType => {
  const subTier = useSelector(subtierSelector);
  const plans = useSelector(plansSelector);
  const typeRef = useRef<WelcomeType | null>(null);
  const params = parseQueryParams(location);

  if (!typeRef.current && subTier && plans) {
    if (subTier === SubTier.PRO_TRIAL) {
      typeRef.current = WelcomeType.TRIAL;
    } else if (params.tier === 'pro') {
      typeRef.current = WelcomeType.PRO;
    } else if (params.tier === 'basic') {
      typeRef.current = WelcomeType.BASIC;
    } else if (params.tier === 'unlimited') {
      typeRef.current = WelcomeType.UNLIMITED;
    } else {
      typeRef.current = WelcomeType.DEFAULT;
    }
  }

  return typeRef.current;
};

const welcomeTypeToWizardStep: Record<WelcomeType, WizardStep> = {
  [WelcomeType.DEFAULT]: 'welcomeScreen',
  [WelcomeType.PRO]: 'purchasePro',
  [WelcomeType.BASIC]: 'purchaseBasic',
  [WelcomeType.UNLIMITED]: 'purchaseUnlimited',
  [WelcomeType.TRIAL]: 'referralUpsell',
};

export default function useOnboardingWizard() {
  const location = useLocation();
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [stepId, setStepId] = useState<StepId>('route-selection');
  const [route, setRoute] = useState<WizardRouterOption>();
  const [usage, setUsage] = useState<HeadlinerUsage>();
  const [newsletter, setNewsletter] = useState<boolean>();
  const [referrer, setReferrer] = useState<string>();
  const [destinationPlatform, setDestinationPlatform] = useState<
    AudiogramDestination
  >();
  const { onNext, onSubmit } = useOnboardingWizardDispatch();
  const welcomeType = useWelcomeType(location);
  const submitStatus = useSelector(onboardingSubmitStatusSelector);

  const onWelcomeNext = useCallback(
    (tier?: Tier) => {
      const welcomeStep = welcomeTypeToWizardStep[welcomeType];
      onNext(welcomeStep, { selection: tier });
    },
    [onNext, welcomeType],
  );

  const onChangeUsage: HeadlinerUsageStepProps['onSubmit'] = useCallback(
    value => {
      setUsage(value);
      setMixpanelWhatYouDo(value);
      onNext('whatYouDo');
    },
    [onNext],
  );

  const onSubmitNewsletter = useCallback(
    optIn => {
      setNewsletter(optIn);
      onNext('newsletter');
    },
    [onNext],
  );

  const onSubmitReferrer = useCallback(
    (value: string) => {
      setReferrer(value);
      onNext('howDidYouFindUs');
    },
    [onNext],
  );

  const onRouteSelect: RoutingStepProps['onSelectRoute'] = useCallback(
    (value, e) => {
      const shouldRedirect = value !== 'other' && value !== 'podcast';

      setRoute(value);

      // let the auth wrapper handle redirect rather than following the anchor click
      e.preventDefault();

      onNext('wizardRouter');

      if (shouldRedirect) {
        const path = e.currentTarget.getAttribute('href');
        history.replace(setAuthWrapperRedirect(location, path));

        onSubmit({
          referrer,
          subscribeToNewsletter: newsletter,
          usage,
          wizardRoute: value,
        });
      }
    },
    [onNext, history, location, onSubmit, referrer, newsletter, usage],
  );

  const onFileSelect: FileStepProps['onSelect'] = useCallback(
    (value, e) => {
      e.preventDefault();

      const path = e.currentTarget.getAttribute('href');
      history.replace(setAuthWrapperRedirect(location, path));

      onNext('fileRouter', { fileRouterSelection: value });
      onSubmit({
        referrer,
        subscribeToNewsletter: newsletter,
        usage,
        wizardRoute: route,
      });
    },
    [history, location, onNext, onSubmit, referrer, newsletter, usage, route],
  );

  const onSkipFeedCollect = (e: React.MouseEvent) => {
    e.preventDefault();

    setStepId('destination-selection');
  };

  const onCompleteFeedCollect = async (destination: LocationDescriptor) => {
    await onSubmit({
      referrer,
      subscribeToNewsletter: newsletter,
      usage,
      wizardRoute: route,
    });

    history.replace(
      destination || setAuthWrapperRedirect(location, '/wizard?type=audiogram'),
    );
  };

  return {
    submitStatus,
    newsletter,
    onChangeUsage,
    onFileSelect,
    onRouteSelect,
    onSubmitNewsletter,
    onSubmitReferrer,
    onWelcomeNext,
    onSkipFeedCollect,
    onCompleteFeedCollect,
    setDestinationPlatform,
    setStepId,
    destinationPlatform,
    step,
    stepId,
    usage,
    onStepChange: setStep,
    welcomeType,
  };
}
