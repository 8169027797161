import * as React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';

import { TransitionGroup } from 'react-transition-group';
import { compose } from 'redux';
import BemCSSTransition from 'components/BemCssTransition';
import * as auth from 'components/hoc/auth';
import Page from 'components/Page';
import withRedirectOnMobile from 'containers/Redirect/withRedirectOnMobile';
import bem from 'utils/bem';
import AutomationSuggestedClipsPage from './AutomationSuggestedClipsPage';
import AutomationSuggestedClipsPageContainer from './AutomationSuggestedClipsPageContainer';

const block = bem('download-routes');

const desktopOnly = withRedirectOnMobile(location =>
  location.pathname.substr(0, location.pathname.lastIndexOf('/') + 1),
);

const ProtectedAutomationSuggestedClipsPage = compose(
  auth.canReclip,
  desktopOnly,
)(AutomationSuggestedClipsPage);

const AutomationSuggestedClipsRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <AutomationSuggestedClipsPageContainer>
      <Page>
        <TransitionGroup>
          <BemCSSTransition
            key={location.pathname}
            timeout={{
              exit: 600,
              enter: 1200,
            }}
            unmountOnExit
            transitionClassName={block('page-animation')}
          >
            <Switch location={location}>
              <Route
                exact
                path={path}
                component={ProtectedAutomationSuggestedClipsPage}
              />
            </Switch>
          </BemCSSTransition>
        </TransitionGroup>
      </Page>
    </AutomationSuggestedClipsPageContainer>
  );
};

export default AutomationSuggestedClipsRoutes;
