import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import { H1, H2 } from 'components/Heading';
import { LinkedinSocialCircle } from 'components/SocialCircle';
import bem from 'utils/bem';

type LinkedInAccountMessageProps = {};

const block = bem('manual-switch-account-message');

const LinkedInAccountMessage: React.FC<LinkedInAccountMessageProps> = () => {
  return (
    <Spacer
      orientation="vertical"
      justify="center"
      align="center"
      className={block()}
      space="14px"
    >
      <LinkedinSocialCircle className={block('icon')} />
      <H1>Switch account</H1>
      <H2>
        In order to switch your account, we need you to visit LinkedIn and
        manually sign out of your account there first.
      </H2>
    </Spacer>
  );
};
export default LinkedInAccountMessage;
