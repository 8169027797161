import * as Sentry from '@sentry/browser';
import { uniq } from 'underscore';
import { EXPERIMENTS_NAMES } from 'blocks/ABTest';
import * as headlinerUserService from 'redux/middleware/api/headliner-user-service';
import { ThunkAction } from 'redux/types';
import { setExperimentVariants } from '../mixpanel/actions';

export const loadExperiments = (): ThunkAction<Promise<
  void
>> => async dispatch => {
  try {
    const experiments = uniq(Object.values(EXPERIMENTS_NAMES));

    if (experiments.length) {
      await dispatch(headlinerUserService.getExperimentVariant(experiments));
      dispatch(setExperimentVariants(experiments));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};
