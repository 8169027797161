import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { reset } from 'redux/modules/clip-select/actions';
import { Dispatch } from 'redux/types';
import { ClipSelectPageSource, ClipSuggestionDislikeReason } from 'types';
import {
  ClipSelectMediaType,
  ClipsPageInfo,
  ClipSuggestion,
  FormValues,
} from '../types';
import { pageBlock as block } from '../utils';
import { ClipSelectAnimationProvider } from './ClipSelectAnimationContext';
import { ClipSelectProvider } from './ClipSelectContext';
import { ClipSelectNavigationProvider } from './ClipSelectNavigationContext';
import { useNavigation } from './ClipSelectNavigationContext/navigation-machine';
import ClipSelectPageContents from './ClipSelectPageContents';
import { ClipSelectTrackingProvider } from './ClipSelectTrackingContext';

export interface ClipSelectPageProps {
  suggestedClips?: ClipSuggestion[];
  dislikedSuggestionIds?: number[];
  clipsPageInfo: ClipsPageInfo;
  source: ClipSelectPageSource;
  mediaType?: ClipSelectMediaType;
  onSubmit?: (values: FormValues) => Promise<void>;
  onDislikeClip?: (
    reason: ClipSuggestionDislikeReason,
    clipSuggestion: ClipSuggestion,
  ) => void;
  onSelectClip?: (values: FormValues) => Promise<number | void>;
}

const ClipSelectPage: React.FC<ClipSelectPageProps> = ({
  suggestedClips,
  dislikedSuggestionIds,
  clipsPageInfo,
  source,
  mediaType = 'audio',
  onSubmit,
  onDislikeClip,
  onSelectClip,
}) => {
  const machine = useNavigation();
  const dispatch = useDispatch<Dispatch>();

  const [state] = machine;
  const isLoading = state.matches('loading');
  const isIntro = state.matches('intro');

  useEffect(
    () => () => {
      dispatch(reset);
    },
    [dispatch],
  );

  return (
    <div
      className={block({
        exporting: state.matches('export'),
        scrollable: !isIntro && !isLoading,
      })}
    >
      <ClipSelectNavigationProvider value={machine}>
        <ClipSelectTrackingProvider {...{ suggestedClips, source }}>
          <ClipSelectProvider
            {...{
              suggestedClips,
              dislikedSuggestionIds,
              clipsPageInfo,
              mediaType,
              onSubmit,
              onDislikeClip,
              onSelectClip,
            }}
          >
            <ClipSelectAnimationProvider>
              <ClipSelectPageContents />
            </ClipSelectAnimationProvider>
          </ClipSelectProvider>
        </ClipSelectTrackingProvider>
      </ClipSelectNavigationProvider>
    </div>
  );
};

export default ClipSelectPage;
