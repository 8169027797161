import cn from 'classnames';
import * as React from 'react';
import { constant } from 'underscore';

import MaxLengthTextarea, {
  MaxLengthTextareaProps,
} from 'components/MaxLengthTextarea';
import ShareFormField, { ShareFormFieldProps } from './ShareFormField';
import { block } from './utils';

type PickedTextareaProps = Pick<
  MaxLengthTextareaProps,
  | 'countRemaining'
  | 'maxChars'
  | 'onChange'
  | 'placeholder'
  | 'readonly'
  | 'renderProgress'
  | 'type'
  | 'value'
  | 'autoFocus'
>;

export interface ShareFormTextareaProps
  extends ShareFormFieldProps,
    PickedTextareaProps {
  className?: string;
  controlClassName?: string;
  hideProgress?: boolean;
}

const ShareFormTextarea: React.FC<ShareFormTextareaProps> = ({
  className,
  controlClassName,
  countRemaining,
  hideProgress,
  maxChars,
  onChange,
  placeholder,
  readonly,
  renderProgress,
  type,
  value,
  autoFocus,
  ...fieldProps
}) => (
  <ShareFormField className={cn(block('textarea'), className)} {...fieldProps}>
    <MaxLengthTextarea
      className={cn(block('textarea-control', { readonly }), controlClassName)}
      inputClassName={block('textarea-input', {
        'input-type': type === 'input',
        'textarea-type': type === 'textarea',
      })}
      renderProgress={hideProgress ? constant(null) : renderProgress}
      {...{
        countRemaining,
        maxChars,
        onChange,
        placeholder,
        readonly,
        type,
        value,
        autoFocus,
      }}
    />
  </ShareFormField>
);

export default ShareFormTextarea;
