import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClipSelectPage from 'blocks/ClipSelect';
import { ClipSuggestion } from 'blocks/ClipSelect/types';
import { enableClipSuggestion } from 'redux/middleware/api/podcast-service/actions';
import {
  createGroupClipSuggestion,
  dislikeGroupSuggestedClip,
} from 'redux/modules/clip-select';
import { groupRequestIdSelector } from 'redux/modules/entities';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { ClipSuggestionDislikeReason } from 'types';
import { getDownloadPath } from 'utils/routes';
import useAutomationSuggestedClipInfo from './useAutomationSuggestedClipInfo';

export interface AutomationSuggestedClipsPageProps {}

type HandleSubmit = React.ComponentProps<typeof ClipSelectPage>['onSubmit'];
type HandleSelectClip = React.ComponentProps<
  typeof ClipSelectPage
>['onSelectClip'];
type HandleDislikeClip = React.ComponentProps<
  typeof ClipSelectPage
>['onDislikeClip'];

const AutomationSuggestedClipsPage: React.FC<AutomationSuggestedClipsPageProps> = () => {
  const groupRequestId = useSelector(groupRequestIdSelector);

  const dispatch = useDispatch<Dispatch>();

  const {
    episodeId,
    subscriptionItemId,
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo,
    source,
  } = useAutomationSuggestedClipInfo();

  const handleSubmit: HandleSubmit = async ({ clipSuggestion }) => {
    try {
      await dispatch(enableClipSuggestion(clipSuggestion.id));

      window.open(getDownloadPath(clipSuggestion.widgetId), '_blank');
    } catch {
      dispatch(showError('Error sharing clip', 5));
    }
  };

  const handleDislikeClip: HandleDislikeClip = useCallback(
    async (
      reason: ClipSuggestionDislikeReason,
      clipSuggestion: ClipSuggestion,
    ): Promise<void> => {
      await dispatch(
        dislikeGroupSuggestedClip(reason, clipSuggestion.id, clipSuggestion.id),
      );
    },
    [dispatch],
  );

  const handleSelectClip: HandleSelectClip = useCallback(
    async ({ region }): Promise<number | void> => {
      const suggestionId = await dispatch(
        createGroupClipSuggestion(
          Number(episodeId),
          subscriptionItemId,
          groupRequestId,
          region.startMillis,
          region.endMillis,
        ),
      );

      return suggestionId;
    },
    [dispatch, episodeId, groupRequestId, subscriptionItemId],
  );

  return (
    <ClipSelectPage
      {...{
        suggestedClips,
        dislikedSuggestionIds,
        clipsPageInfo,
        source,
      }}
      onDislikeClip={handleDislikeClip}
      onSelectClip={handleSelectClip}
      onSubmit={handleSubmit}
    />
  );
};

export default AutomationSuggestedClipsPage;
