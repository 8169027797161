import {
  FacebookSquare,
  InstagramSquare,
  LinkedInSquare,
  ThreadsSquare,
  TikTokSquare,
  XSquare,
} from '@sparemin/blockhead';

import React from 'react';
import { YoutubeOutput } from 'components/icons';
import { OutputOption } from 'redux/modules/edit-automation';
import { AudiogramDestination } from 'types';

export const ICON_DIMENSION = 45;

export const OUTPUT_OPTIONS_MAP: Partial<Record<
  AudiogramDestination,
  OutputOption
>> = {
  youtube: 'YOUTUBE',
  tiktok: 'TIKTOK',
  instagram: 'INSTAGRAM',
  linkedin: 'LINKEDIN',
  facebook: 'FACEBOOK',
  twitter: 'TWITTER',
  threads: 'THREADS',
};

export const ICON_BY_OUTPUT_OPTION_MAP: Partial<Record<
  AudiogramDestination,
  JSX.Element
>> = {
  youtube: <YoutubeOutput height={ICON_DIMENSION} width={ICON_DIMENSION} />,
  tiktok: <TikTokSquare height={ICON_DIMENSION} width={ICON_DIMENSION} />,
  instagram: <InstagramSquare height={ICON_DIMENSION} width={ICON_DIMENSION} />,
  linkedin: <LinkedInSquare height={ICON_DIMENSION} width={ICON_DIMENSION} />,
  facebook: <FacebookSquare height={ICON_DIMENSION} width={ICON_DIMENSION} />,
  twitter: <XSquare height={ICON_DIMENSION} width={ICON_DIMENSION} />,
  threads: <ThreadsSquare height={ICON_DIMENSION} width={ICON_DIMENSION} />,
};
