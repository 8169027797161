import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ModalFooterButton from 'components/Modal/ModalFooterButton';
import { Handle } from 'components/SocialAccountInfo';
import { Step } from 'components/SteppedModal';
import SwitchAccountMessage from 'components/SwitchAccountMessage';
import useInstagramAuthenticator from 'hooks/useInstagramAuthenticator';
import { showError } from 'redux/modules/notification';
import { instagramUserDataSelector } from 'redux/modules/social';
import { Dispatch } from 'redux/types';
import { StepProps } from '../types';
import { block } from '../utils';

export default function useSwitchAccountStep({ goToStep }: StepProps): Step {
  const dispatch = useDispatch<Dispatch>();
  const { username } = useSelector(instagramUserDataSelector);

  const { authenticating, withAuthentication } = useInstagramAuthenticator({
    onError: error => dispatch(showError({ message: error.message })),
    force: true,
  });

  const handleCancel = useCallback((): void => {
    goToStep('no-account-found');
  }, [goToStep]);

  const handleSwithSuccess = useCallback((): void => {
    goToStep('loader');
  }, [goToStep]);

  return {
    title: 'switch instagram accounts',
    component: (
      <SwitchAccountMessage
        className={block('switch-account-step')}
        username={<Handle username={username} />}
      />
    ),
    id: 'switch-account',
    renderFooterButtons: ({ cancel, submit }) => [
      <ModalFooterButton {...cancel} onClick={handleCancel} key="cancel">
        cancel
      </ModalFooterButton>,
      <ModalFooterButton
        {...submit}
        disabled={authenticating}
        key="submit"
        onClick={withAuthentication(handleSwithSuccess)}
        theme="submit"
      >
        switch accounts
      </ModalFooterButton>,
    ],
  };
}
