import { useToggle } from '@sparemin/blockhead';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import {
  AudioClipperComponent,
  RegionUpdateAction,
} from 'components/AudioClipper';
import VideoPlayer from 'components/VideoPlayer';
import { ClipSuggestionDislikeReason } from 'types';
import {
  DEFAULT_CLIP_END_MILLIS,
  DEFAULT_CLIP_START_MILLIS,
} from '../constants';
import {
  ClipSelectContextType,
  ClipSelectionType,
  ClipSelectMediaType,
  ClipsPageInfo,
  ClipSuggestion,
  FormValues,
  Region,
  VideoPlayerRefs,
} from '../types';
import { useClipSelectNavigation } from './ClipSelectNavigationContext/ClipSelectNavigationContext';
import useClipSelectVideoPlayer from './useClipSelectVideoPlayer';

const ClipSelectContext = React.createContext<ClipSelectContextType>(null);

interface Props {
  suggestedClips?: ClipSuggestion[];
  dislikedSuggestionIds?: number[];
  clipsPageInfo: ClipsPageInfo;
  mediaType: ClipSelectMediaType;
  onDislikeClip?: (
    reason: ClipSuggestionDislikeReason,
    clipSuggestion: ClipSuggestion,
  ) => void;
  onSubmit: (values: FormValues) => Promise<void>;
  onSelectClip: (values: FormValues) => Promise<number | void>;
}

const { useRef, useState } = React;

export const ClipSelectProvider: React.FC<Props> = props => {
  const {
    suggestedClips,
    dislikedSuggestionIds,
    clipsPageInfo,
    mediaType,
    onSubmit,
    onDislikeClip,
    onSelectClip,
    children,
  } = props;

  const suggestionIds = useMemo(
    (): number[] => suggestedClips.map(clip => clip.id),
    [suggestedClips],
  );

  const [removedSuggestionIds, setRemovedSuggestionIds] = useState<number[]>(
    [],
  );
  const visibleSuggestionIds = useMemo(
    () => suggestionIds?.filter(id => !removedSuggestionIds.includes(id)),
    [removedSuggestionIds, suggestionIds],
  );
  const [region, setRegion] = useState<Region>(null);
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>();
  const [selectedRegion, setSelectedRegion] = useState<Region>(null);
  const [playing, setPlaying] = useState(false);
  const [activeSuggestionId, setActiveSuggestionId] = useState<number>(
    undefined,
  );
  const lastSelectionTypeRef = useRef<ClipSelectionType>(
    ClipSelectionType.DEFAULT,
  );
  const [videoPlayerRefs, setVideoPlayerRefs] = useState<VideoPlayerRefs>();
  const [navState, send] = useClipSelectNavigation();
  const {
    toggleOn: playVideo,
    toggleOff: pauseVideo,
    value: isVideoPlaying,
  } = useToggle(false);

  const playerRef = videoPlayerRefs?.[activeSuggestionId];

  const audioClipperRef = useRef<AudioClipperComponent>();

  const hasClipSuggestions = !!suggestionIds?.length;

  const clipSuggestion = useMemo(
    (): ClipSuggestion =>
      suggestedClips.find(suggestion => suggestion.id === activeSuggestionId),
    [activeSuggestionId, suggestedClips],
  );

  const {
    play: playClip,
    pause: pauseClip,
    onSelectionChange,
  } = useClipSelectVideoPlayer(videoPlayerRefs, clipSuggestion);

  const onSuggestionSelect = (suggestionRegion: Region) => {
    lastSelectionTypeRef.current = ClipSelectionType.SUGGESTED_CLIP;

    onSelectionChange();
    setRegion(suggestionRegion);
    setSelectedRegion(suggestionRegion);
  };

  const onRegionChange = (value: Region, action?: RegionUpdateAction) => {
    if (action !== 'init') {
      lastSelectionTypeRef.current = ClipSelectionType.CUSTOM_CLIP;
      setRegion(value);
    }
  };

  const handleAdjust = useCallback(() => {
    onRegionChange(selectedRegion);
    videoPlayer?.seek(selectedRegion.startMillis);
    send({ type: 'ADJUST_CLICK' });
  }, [selectedRegion, send, videoPlayer]);

  const handlePausePlayers = useCallback(
    (play: boolean): void => {
      if (play) {
        audioClipperRef.current?.play();
        playVideo();
      } else {
        audioClipperRef.current?.pause();
        pauseVideo();
      }
    },
    [pauseVideo, playVideo],
  );

  const handlePausePlayer = useCallback((): void => {
    pauseClip();
    handlePausePlayers(false);
    setPlaying(false);
  }, [handlePausePlayers, pauseClip]);

  const handleSubmit = async () => {
    handlePausePlayer();

    onSubmit({
      region,
      lastClipSelectionType: lastSelectionTypeRef.current,
      isCaptionEnabled: true,
      clipSuggestion,
    });
  };

  const handleSelectClip = async (): Promise<number | void> => {
    handlePausePlayer();

    if (!suggestionIds.length) {
      send({ type: 'SUBMIT_SUCCESS' });
    }

    const suggestionId = await onSelectClip({
      region,
      lastClipSelectionType: lastSelectionTypeRef.current,
      isCaptionEnabled: true,
      clipSuggestion,
    });

    if (suggestionId) {
      addVideoPlayerRef(suggestionId);
    }

    return suggestionId;
  };

  const togglePlayback = useCallback(() => {
    if (playing) {
      pauseClip();
      setPlaying(false);
    } else {
      // business requirement that suggestions should always start playing from the beginning
      playerRef.current.currentTime = DEFAULT_CLIP_START_MILLIS;
      playClip();
      setPlaying(true);
    }
  }, [pauseClip, playClip, playerRef, playing]);

  const removeSuggestion = useCallback(
    (id: number) => {
      handlePausePlayer();
      setRemovedSuggestionIds(ids => ids.concat(id));
    },
    [handlePausePlayer],
  );

  const addVideoPlayerRef = useCallback(
    (suggestionId: number) => {
      const videoPlayerRefsCopy = {
        ...videoPlayerRefs,
      };

      videoPlayerRefsCopy[suggestionId] = React.createRef();

      setVideoPlayerRefs(videoPlayerRefsCopy);
    },
    [videoPlayerRefs],
  );

  useEffect(() => {
    if (suggestionIds && !activeSuggestionId) {
      setActiveSuggestionId(suggestionIds[0]);
    }
  }, [activeSuggestionId, suggestionIds]);

  useEffect(() => {
    if (!!visibleSuggestionIds.length && !videoPlayerRefs) {
      setVideoPlayerRefs(
        visibleSuggestionIds.reduce((res, id) => {
          res[id] = React.createRef();

          return res;
        }, {}),
      );
    }
  }, [setVideoPlayerRefs, videoPlayerRefs, visibleSuggestionIds]);

  useEffect(() => {
    send({
      type: 'DATA_LOAD_SUCCESS',
      payload: { suggestionCount: suggestionIds.length },
    });

    if (suggestionIds.length === 0) {
      setRegion({
        startMillis: DEFAULT_CLIP_START_MILLIS,
        endMillis: DEFAULT_CLIP_END_MILLIS,
      });
    }
  }, [send, suggestionIds]);

  useEffect(() => {
    if (visibleSuggestionIds?.length === 0) {
      setRegion({
        startMillis: DEFAULT_CLIP_START_MILLIS,
        endMillis: DEFAULT_CLIP_END_MILLIS,
      });
    }
  }, [visibleSuggestionIds]);

  useEffect(() => {
    if (!navState.matches('select')) {
      pauseClip();
      setPlaying(false);
    }
    if (!navState.matches('clip')) {
      handlePausePlayers(false);
    }
  }, [handlePausePlayers, navState, pauseClip]);

  return (
    <ClipSelectContext.Provider
      value={{
        activeSuggestionId,
        dislikedSuggestionIds,
        playerRef,
        videoPlayerRefs,
        audioClipperRef,
        videoPlayer,
        setVideoPlayer,
        onRegionChange,
        onSuggestionSelect,
        region,
        selectedRegion,
        suggestionIds,
        playing,
        removeSuggestion,
        removedSuggestionIds,
        setActiveSuggestionId,
        togglePlayback,
        visibleSuggestionIds,
        clipSuggestion,
        hasClipSuggestions,
        suggestedClips,
        clipsPageInfo,
        mediaType,
        addVideoPlayerRef,
        videoPlayerControls: {
          playing: isVideoPlaying,
          onPlay: playVideo,
          onPause: pauseVideo,
        },
        isClipSuggestionLoading: clipSuggestion?.status !== 'completed',
        onAdjust: handleAdjust,
        onSubmit: handleSubmit,
        onDislikeClip,
        onSelectClip: handleSelectClip,
      }}
    >
      {children}
    </ClipSelectContext.Provider>
  );
};

export function useClipPlayer() {
  const context = useContext(ClipSelectContext);

  if (context === null) {
    throw new Error('useClipPlayer must be used within a ClipSelectProvider');
  }

  const { playerRef } = context;

  return {
    playerRef,
  };
}

export function useClipSelect() {
  const context = useContext(ClipSelectContext);

  if (context === null) {
    throw new Error('useClipSelect must be used within a ClipSelectProvider');
  }

  const { playerRef, ...rest } = context;

  return rest;
}

export default ClipSelectContext;
