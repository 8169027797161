import * as React from 'react';
import { H1, H2 } from 'components/Heading';
import {
  FacebookButton,
  InstagramButton,
  LinkedInButton,
  ThreadsButton,
  TikTokButton,
  TwitterButton,
  YouTubeButton,
} from 'components/SocialButton';
import { DestinationPlatform } from './types';
import { block } from './utils';

export interface DestinationPlatformsProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  onPlatformSelection: (platform: DestinationPlatform) => void;
}

const DestinationPlatforms: React.FC<DestinationPlatformsProps> = ({
  title = 'Choose a destination',
  description = 'Where would you like to promote your podcast?',
  onPlatformSelection,
}) => {
  return (
    <>
      <div className={block('headers')}>
        <H1>{title}</H1>

        <H2>{description}</H2>
      </div>

      <div className={block('platforms-container')}>
        <YouTubeButton onClick={() => onPlatformSelection('youtube')} />

        <InstagramButton onClick={() => onPlatformSelection('instagram')} />

        <FacebookButton onClick={() => onPlatformSelection('facebook')} />

        <TikTokButton onClick={() => onPlatformSelection('tiktok')} />

        <LinkedInButton onClick={() => onPlatformSelection('linkedin')} />

        <ThreadsButton onClick={() => onPlatformSelection('threads')} />

        <TwitterButton onClick={() => onPlatformSelection('twitter')} />
      </div>
    </>
  );
};

export default DestinationPlatforms;
