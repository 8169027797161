import { LocationDescriptor } from 'history';
import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DESTINATION_PRESETS } from 'blocks/DestinationPlatforms';
import BemCssTransition from 'components/BemCssTransition';
import FontAwesome from 'components/FontAwesome';
import {
  onClickAudiogramProject,
  onClickFullEpisode,
} from 'redux/modules/mixpanel';
import { AudiogramDestination, AutogramVideoType } from 'types';
import { getAudiogramWizardPath } from 'utils/routes';
import { RoutingStepProps, StepId, WizardRouterOption } from '../types';
import { cardWizardBlock as block } from '../utils';
import DestinationStep from './DestinationStep';
import FeedCollectStep from './FeedCollectStep';
import FileStep from './FileStep';
import IconicSelectorStep from './IconicSelectorStep';
import PodcastAddedStep from './PodcastAddedStep';
import VideoTypeStep from './VideoTypeStep';

const { useCallback } = React;

type Step = {
  id: StepId;
  component: React.ReactNode;
};

const RoutingStep: React.FC<RoutingStepProps> = props => {
  const {
    className,
    destinationPlatform,
    stepId,
    onFileSelect,
    onSelectRoute,
    onCompleteFeedCollect,
    onSkipFeedCollect,
    setDestinationPlatform,
    onStepChange,
  } = props;
  const dispatch = useDispatch<Dispatch>();

  const handleSelectRoute = useCallback(
    (value: WizardRouterOption, e: React.MouseEvent<any>) => {
      onSelectRoute(value, e);

      if (value === 'other') {
        onStepChange('file-selection');
      }

      if (value === 'podcast') {
        onStepChange('feed-collect');
      }
    },
    [onSelectRoute, onStepChange],
  );

  const handlePodcastSelect = useCallback(() => {
    onStepChange('podcast-added');
  }, [onStepChange]);

  const handleGoToDestinationSelection = useCallback(() => {
    onStepChange('destination-selection');
  }, [onStepChange]);

  const getDestinationPreset = useCallback(
    (platform: AudiogramDestination): LocationDescriptor => {
      return getAudiogramWizardPath({
        defaultClipDurationMillis:
          DESTINATION_PRESETS.more[platform].durationMs,
        defaultAspectRatio: DESTINATION_PRESETS.more[platform].aspectRatio,
        defaultPresetKey: DESTINATION_PRESETS.more[platform].key,
      });
    },
    [],
  );

  const handleSelectedDestinationPlatform = useCallback(
    (
      newPlatform: AudiogramDestination,
      shouldSelectVideoTypes: boolean,
    ): void => {
      setDestinationPlatform(newPlatform);

      if (!shouldSelectVideoTypes) {
        onCompleteFeedCollect(getDestinationPreset(newPlatform));
        dispatch(onClickAudiogramProject());

        return;
      }

      onStepChange('video-type-selection');
    },
    [
      setDestinationPlatform,
      onStepChange,
      getDestinationPreset,
      onCompleteFeedCollect,
      dispatch,
    ],
  );

  const handleProjectTypeSelected = useCallback(
    (
      newProjectType: AutogramVideoType,
      destination: LocationDescriptor,
    ): void => {
      onCompleteFeedCollect(destination);

      dispatch(
        newProjectType === 'fullEpisode'
          ? onClickFullEpisode()
          : onClickAudiogramProject(),
      );
    },
    [dispatch, onCompleteFeedCollect],
  );

  const handleBack = () => {
    onStepChange('route-selection');
  };

  const steps: Step[] = [
    {
      id: 'route-selection',
      component: (
        <IconicSelectorStep<WizardRouterOption>
          className={className}
          onClick={handleSelectRoute}
          options={[
            {
              href: '/wizard?type=audiogram',
              icon: (
                <FontAwesome
                  icon="microphone"
                  style={{ height: 30, width: 20 }}
                />
              ),
              label: 'Podcast',
              value: 'podcast',
            },
            {
              href: '/wizard?type=audiogram',
              icon: (
                <FontAwesome icon="book" style={{ height: 30, width: 26 }} />
              ),
              label: 'Audio Book',
              value: 'audioBook',
            },
            {
              href: '/wizard?type=audiogram',
              icon: (
                <FontAwesome
                  icon="broadcast-tower"
                  style={{ height: 30, width: 38 }}
                />
              ),
              label: 'Radio Show',
              value: 'radioShow',
            },
            {
              href: '/wizard?type=audiogram',
              icon: (
                <FontAwesome icon="music" style={{ height: 30, width: 30 }} />
              ),
              label: 'Music',
              value: 'music',
            },
            {
              icon: (
                <FontAwesome
                  icon="ellipsis-h"
                  style={{ height: 30, width: 30 }}
                />
              ),
              label: 'Other',
              value: 'other',
              color: '#8ca0c3',
            },
          ]}
          title="What shall we make a video for?"
          subtitle="You can always choose another option later."
        />
      ),
    },
    {
      id: 'file-selection',
      component: <FileStep onSelect={onFileSelect} />,
    },
    {
      id: 'feed-collect',
      component: (
        <FeedCollectStep
          onPodcastSelect={handlePodcastSelect}
          onSkip={onSkipFeedCollect}
          onBack={handleBack}
        />
      ),
    },
    {
      id: 'podcast-added',
      component: (
        <PodcastAddedStep onComplete={handleGoToDestinationSelection} />
      ),
    },
    {
      id: 'destination-selection',
      component: (
        <DestinationStep
          onSelectDestinationPlatform={handleSelectedDestinationPlatform}
        />
      ),
    },
    {
      id: 'video-type-selection',
      component: (
        <VideoTypeStep
          platform={destinationPlatform}
          onProjectTypeSelected={handleProjectTypeSelected}
          onBackClick={handleGoToDestinationSelection}
        />
      ),
    },
  ];

  return (
    <>
      {steps.map(step => (
        <BemCssTransition
          key={step.id}
          mountOnEnter
          unmountOnExit
          in={step.id === stepId}
          timeout={{
            enter: 600,
            exit: 300,
          }}
          transitionClassName={block('step-transition')}
        >
          {step.component}
        </BemCssTransition>
      ))}
    </>
  );
};

export default RoutingStep;
