import { normalize, schema } from 'normalizr';
import { SuperAgentStatic } from 'superagent';
import { attachFields } from 'utils/request';
import { createRequest } from '../utils';
import {
  ACTION_KEY,
  CreateCreationClipSuggestionArgs,
  CreateCreationClipSuggestionFeedbackArgs,
  CreateCreationClipSuggestionResult,
  CreationArgs,
  CreationResult,
  EnableCreationClipSuggestionArgs,
  EnableCreationClipSuggestionResult,
  GetCreationArgs,
  GetCreationClipSuggestionArgs,
  GetCreationClipSuggestionResult,
  GetCreationClipSuggestionsArgs,
  GetCreationClipSuggestionsProgressResult,
  GetCreationClipSuggestionsResult,
  ServiceMethod,
} from './types';

const BASE_PATH = '/api/v1/creation';
const CREATION_REQUEST_PATH = `${BASE_PATH}/creation-request`;

const creationSchema = new schema.Entity('creations');

const creationClipSuggestionsSchema = new schema.Entity(
  'creationClipSuggestions',
  {},
  {
    idAttribute: () => 'clipGroup',
    processStrategy: entity => ({
      clipSuggestions: entity.suggestions,
    }),
  },
);

const creationSuggestionsProgressSchema = new schema.Entity(
  'creationSuggestionsProgress',
  {},
  {
    idAttribute: () => 'progress',
    processStrategy: entity => ({
      progress: entity.progress,
      status: entity.status,
    }),
  },
);

async function create(
  request: SuperAgentStatic,
  [creationReq]: CreationArgs,
): Promise<CreationResult> {
  const req = request.post(CREATION_REQUEST_PATH);
  attachFields(req, creationReq);

  const { body } = await req;
  return normalize(body, creationSchema);
}

async function getCreation(
  request: SuperAgentStatic,
  [creationId]: GetCreationArgs,
): Promise<CreationResult> {
  const req = request.get(`${CREATION_REQUEST_PATH}/${creationId}`);
  const { body } = await req;
  return normalize(body, creationSchema);
}

async function enableCreationClipSuggestion(
  request: SuperAgentStatic,
  [creationRequestId, suggestionId]: EnableCreationClipSuggestionArgs,
): Promise<EnableCreationClipSuggestionResult> {
  const res = await request.post(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}/enable`,
  );

  return res.body;
}

async function createCreationClipSuggestionFeedback(
  request: SuperAgentStatic,
  [
    feedbackType,
    creationRequestId,
    suggestionId,
  ]: CreateCreationClipSuggestionFeedbackArgs,
) {
  await request
    .post(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}/feedback`,
    )
    .send({
      feedbackType,
      creationRequestId,
      suggestionId,
    });
}

async function getCreationClipSuggestions(
  request: SuperAgentStatic,
  [creationRequestId]: GetCreationClipSuggestionsArgs,
): Promise<GetCreationClipSuggestionsResult> {
  const res = await request.get(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion`,
  );

  return normalize(res, [creationClipSuggestionsSchema]);
}

const getCreationClipSuggestion = (
  request: SuperAgentStatic,
  args: GetCreationClipSuggestionArgs,
): Promise<GetCreationClipSuggestionResult> => {
  const [creationRequestId, suggestionId] = args;

  return request
    .get(
      `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion/${suggestionId}`,
    )
    .then(res => res.body);
};

const getCreationSuggestionsProgress = async (
  request: SuperAgentStatic,
  args: GetCreationClipSuggestionArgs,
): Promise<GetCreationClipSuggestionsProgressResult> => {
  const [creationRequestId] = args;

  const res = await request.get(
    `${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion-progress`,
  );

  return normalize(res, [creationSuggestionsProgressSchema]);
};

async function createCreationClipSuggestion(
  request: SuperAgentStatic,
  [
    creationRequestId,
    newTrimStartMillis,
    newTrimEndMillis,
  ]: CreateCreationClipSuggestionArgs,
): Promise<CreateCreationClipSuggestionResult> {
  const res = await request
    .post(`${CREATION_REQUEST_PATH}/${creationRequestId}/suggestion`)
    .send({ newTrimStartMillis, newTrimEndMillis });

  return res.body;
}

export const handle = (method: any, args: any, token?: string): any => {
  const request = createRequest({
    token,
    baseUrl: spareminConfig.services.creation,
  });

  switch (method) {
    case ServiceMethod.CREATE:
      return create(request, args);

    case ServiceMethod.CREATION_GET:
      return getCreation(request, args);

    case ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS:
      return getCreationClipSuggestions(request, args);

    case ServiceMethod.GET_CREATION_CLIP_SUGGESTION:
      return getCreationClipSuggestion(request, args);

    case ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS_PROGRESS:
      return getCreationSuggestionsProgress(request, args);

    case ServiceMethod.ENABLE_CREATION_CLIP_SUGGESTION:
      return enableCreationClipSuggestion(request, args);

    case ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION_FEEDBACK:
      return createCreationClipSuggestionFeedback(request, args);

    case ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION:
      return createCreationClipSuggestion(request, args);

    default:
      throw new Error(`${ACTION_KEY} cannot handle method ${method}`);
  }
};
