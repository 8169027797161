import React, { useCallback } from 'react';
import ShareFormTextarea from 'components/ShareForm/ShareFormTextarea';
import { block } from '../utils';

type CustomIdInputProps = {
  customId?: string;
  disabled?: boolean;
  onChange?: (val: string) => void;
};

const CustomIdInput: React.FC<CustomIdInputProps> = ({
  customId,
  disabled = false,
  onChange,
}) => {
  const handleChange = useCallback(
    (val: string) => {
      onChange(val);
    },
    [onChange],
  );

  return (
    <ShareFormTextarea
      label="Custom ID"
      value={customId}
      type="input"
      placeholder="Input an ID"
      maxChars={200}
      hideProgress
      className={disabled ? block('disabled') : undefined}
      onChange={handleChange}
      autoFocus={false}
    />
  );
};
export default CustomIdInput;
