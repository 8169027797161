import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _, { omit } from 'underscore';

import {
  CaptionsConfig,
  validateCaptionsConfigIntegrity,
} from 'blocks/TextOverlayModal/v2';
import VideoTemplateEditor, {
  ActionUtils,
  EditorState,
  TemplateAction,
  TemplateEditorStateExport,
  VideoTemplateState,
} from 'components/VideoTemplateEditor';
import {
  useDynamicEpisodeImageIntegration,
  useDynamicPodcastTextIntegration,
  useIntroOutroUploadIntegration,
  useMediaUploadIntegration,
  useStaticTextIntegration,
} from 'components/VideoTemplateEditor/integrations';
import { getStateContent } from 'components/VideoTemplateEditor/state/state-utils';
import useTemplateCompatibilityCheck from 'components/VideoTemplateEditor/state/useTemplateCompatibilityCheck';
import useOnStyleChange from 'components/VideoTemplateEditor/useOnStyleChange';
import useTemplateValidationCheck from 'components/VideoTemplateEditor/useTemplateValidationCheck';
import { useProIndicator } from 'containers/ProIndicator';
import useCustomizeStepIntroOutroInit from 'containers/ProjectWizards/CustomizeStep/useCustomizeStepIntroOutroInit';
import { createDefaultSoundwave } from 'containers/ProjectWizards/utils';
import { TranscriptionFormValue } from 'containers/TranscriptionForm';
import useLastUsedStyleDispatch from 'hooks/useLastUsedStyleDispatch';
import useLastUsedStyleSelector from 'hooks/useLastUsedStyleSelector';
import usePodcastData from 'hooks/usePodcastData';
import { userIntegratorsSelector } from 'redux/modules/auth';
import {
  defaultLogoUrlSelector,
  defaultVideoExportSettingsSelectorByRatio,
  defaultWaveformSelector,
} from 'redux/modules/display-pref/selectors';
import { podcastWorkflowTemplatesSelector } from 'redux/modules/entities/selectors';
import { onBackToTemplateStyles, onClickAsset } from 'redux/modules/mixpanel';
import { showError } from 'redux/modules/notification';
import { Dispatch } from 'redux/types';
import { AutogramVideoType } from 'types';
import { getAspectRatioName } from 'utils/aspect-ratio';
import { DEFAULT_EPISODE_TITLE, UCS_EDITOR_DEFAULTS } from 'utils/constants';
import { withValue } from 'utils/control';
import { getDefaultTimer } from 'utils/embed/timer';
import { formatWatermarkSrcForConfig } from 'utils/embed/watermark';
import measurement from 'utils/measurement';
import { customizeStepBlock as block } from '../utils';
import { OnSubscriptionOptionsChange } from './AutomationWorkflowWizard/useAutomationWorkflowWizard/useAutomationWorkflowWizard';

const { useCallback, useState } = React;

const defaultWatermarkSelector = createSelector(defaultLogoUrlSelector, url => {
  if (!url) return undefined;
  const watermark = formatWatermarkSrcForConfig(url);
  return {
    original: url,
    src: url,
    position: {
      left: measurement(watermark.position.properties.left),
      top: measurement(watermark.position.properties.top),
    },
    size: {
      height: measurement(watermark.style.height),
      width: measurement(watermark.style.width),
    },
  };
});

export interface AutomationCustomizeStepProps {
  aspectRatio: number;
  ctaLabel?: string;
  disabled?: boolean;
  initialEditorState?: VideoTemplateState;
  initialTemplateIsDirty?: boolean;
  onSubmit?: (
    exportState: TemplateEditorStateExport,
    editorState: VideoTemplateState,
    isTemplateDirty: boolean,
  ) => void;
  onTemplatesClick?: () => void;
  onTranscriptionChange?: (language?: string, transcribe?: boolean) => void;
  onCaptionsChange?: (captions?: CaptionsConfig) => void;
  onSubscriptionOptionsChange?: OnSubscriptionOptionsChange;
  templateId: string;
  podcastId: string;
  videoType: AutogramVideoType;
  transcription?: TranscriptionFormValue;
  captions?: CaptionsConfig;
  language?: string;
}

const templateSelector = createSelector(
  podcastWorkflowTemplatesSelector,
  (__, templateId) => templateId,
  (templates, templateId) => templates?.get(templateId)?.toJS(),
);

const defaultSoundwaveSelector = createSelector(
  defaultWaveformSelector,
  waveform => createDefaultSoundwave(waveform),
);

const AutomationCustomizeStep: React.FC<AutomationCustomizeStepProps> = ({
  aspectRatio,
  ctaLabel = 'preview your automation',
  disabled,
  initialEditorState,
  initialTemplateIsDirty,
  onSubmit = _.noop,
  onTemplatesClick = _.noop,
  onTranscriptionChange = _.noop,
  onCaptionsChange = _.noop,
  onSubscriptionOptionsChange,
  templateId,
  podcastId,
  videoType,
  transcription,
  captions,
  language,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { setLastUsedStyle } = useLastUsedStyleDispatch('autogram');
  const { lastUsedStyle } = useLastUsedStyleSelector('autogram');
  const { isFree } = useProIndicator();
  const defaultWatermark = useSelector(defaultWatermarkSelector);
  const defaultSoundwave = useSelector(defaultSoundwaveSelector);
  const integrators = useSelector(userIntegratorsSelector);
  const allowCaptions = integrators.length ? false : !isFree;

  const { intro: introDefault, outro: outroDefault } = useSelector(
    defaultVideoExportSettingsSelectorByRatio(getAspectRatioName(aspectRatio)),
  );
  const { handleCheckIsValidTemplate } = useTemplateValidationCheck();

  // Gets default enterprise values for intro and outro
  const introDefaultVideoUrl = introDefault?.value;
  const outroDefaultVideoUrl = outroDefault?.value;

  const handleStyleChange = useOnStyleChange({
    onStyleChange: useCallback(
      (style, actionType) => {
        if (actionType !== 'IMAGE_FILE_SELECT') {
          setLastUsedStyle(omit(style, 'image', 'video'));
        }
      },
      [setLastUsedStyle],
    ),
  });

  const template = useSelector(state => templateSelector(state, templateId));

  const hasCaptions = validateCaptionsConfigIntegrity(
    template?.previewConfiguration?.captions,
  );
  const isCaptionEnabled = allowCaptions && hasCaptions;

  const { artworkUrl, episodeTitle, podcastTitle } = usePodcastData({
    defaultEpisodeTitle: DEFAULT_EPISODE_TITLE,
    podcastId,
  });

  const uploadMediaIntegration = useMediaUploadIntegration({ priority: 0 });
  const dynamicIntegration = useDynamicEpisodeImageIntegration({
    placeholderImageSrc: artworkUrl,
    priority: 1,
  });
  const staticTextIntegration = useStaticTextIntegration({
    placeholder: 'static placeholder',
    priority: 3,
  });
  const dynamicTextIntegration = useDynamicPodcastTextIntegration({
    episodeNamePlaceholder: episodeTitle,
    podcastNamePlaceholder: podcastTitle,
    priority: 4,
    replaceImageEffectDynamicTextWildcards: true,
  });
  const uploadVideoIntegration = useIntroOutroUploadIntegration({
    priority: 5,
  });

  const [editorState, setEditorState] = useState<VideoTemplateState>(() => {
    // If there is an initial saved state, this will mount it instead of generating a brand
    // new state using the template or initial state. The initial state allows re-hidrating
    // a state when unmounting and remounting while going to and back from preview step.
    if (initialEditorState) {
      return initialEditorState;
    }

    if (template) {
      return EditorState.createModifiedState(
        EditorState.createStateFromTemplate(template),
        content =>
          _.compose(
            _.partial(EditorState.setTranscription, _, {
              language,
              transcribe: isCaptionEnabled,
            }),
            _.partial(EditorState.setCaptions, _, captions),
            dynamicTextIntegration.replaceKeyAssets,
            template.templateType !== 'userGenerated' &&
              dynamicIntegration.replaceKeyImage
              ? dynamicIntegration.replaceKeyImage
              : _.identity,
            EditorState.createSoundwaveRestorePoint,
            _.partial(EditorState.setIntroOutroDefaultPrefs, _, {
              introDefaultVideoUrl,
              outroDefaultVideoUrl,
            }),
          )(content),
      );
    }

    return EditorState.createModifiedState(
      EditorState.createEmptyState(aspectRatio),
      content =>
        _.compose(
          dynamicIntegration.replaceKeyImage,
          !defaultWatermark
            ? _.identity
            : _.partial(EditorState.addWatermark, _, defaultWatermark),
          withValue(
            lastUsedStyle.get('timer'),
            timer =>
              _.partial(EditorState.setTimerOptions, _, {
                ...getDefaultTimer(aspectRatio, 'string'),
                ...timer,
              }),
            _.identity,
          ),
          _.partial(EditorState.setProgressAnimationOptions, _, {
            ...UCS_EDITOR_DEFAULTS.progress,
            ...lastUsedStyle.get('progress'),
          }),
          _.partial(
            EditorState.addTextOverlay,
            _,
            lastUsedStyle.get('textOverlay'),
            {
              integrationData: lastUsedStyle.getIn([
                'textOverlay',
                'integrationData',
              ]),
            },
          ),
          _.partial(
            EditorState.setSoundwave,
            _,
            lastUsedStyle.get('soundwave'),
          ),
          EditorState.createSoundwaveRestorePoint,
          _.partial(EditorState.setSoundwave, _, defaultSoundwave),
          _.partial(
            EditorState.setBackgroundColor,
            _,
            lastUsedStyle.get('backgroundColor'),
          ),
          _.partial(EditorState.setTranscription, _, transcription),
          _.partial(EditorState.setCaptions, _, captions),
          _.partial(EditorState.setIntroOutroDefaultPrefs, _, {
            introDefaultVideoUrl,
            outroDefaultVideoUrl,
          }),
        )(content),
    );
  });

  // Pre-uploads the current default state for intro/outro clips.
  useCustomizeStepIntroOutroInit({ editorState, setEditorState });

  const handleChange = useCallback(
    (
      stateUpdater: (prevState: VideoTemplateState) => VideoTemplateState,
      action: TemplateAction,
    ) => {
      // checkes if action is an Error one, if so, it shows a message showing what happened.
      // no further actions are dispatched in this case.
      if (ActionUtils.isErrorAction(action)) {
        dispatch(
          showError({
            message: action.payload?.message,
            dismissAfterSec: 5,
          }),
        );
        return;
      }

      setEditorState(prevState => {
        const state = stateUpdater(prevState);
        const content = EditorState.getContent(state);

        handleStyleChange(content, action);

        if (action.type === 'TRANSCRIPTION_CHANGE') {
          onTranscriptionChange(
            action.payload.transcription.language,
            action.payload.transcription.transcribe,
          );
        }

        if (action.type === 'CAPTIONS_CONFIG_UPDATE') {
          onCaptionsChange(action.payload.captions);
        }

        if (action.type === 'TEMPLATES_BUTTON_CLICK') {
          onTemplatesClick();
          dispatch(onBackToTemplateStyles(templateId));
        } else if (action.type === 'CHILD_VIEW_OPEN') {
          if (action.meta.source === 'preview') {
            dispatch(onClickAsset(action.payload));
          }
        }

        return state;
      });
    },
    [
      dispatch,
      handleStyleChange,
      onCaptionsChange,
      onTemplatesClick,
      onTranscriptionChange,
      templateId,
    ],
  );

  const handleCtaClick = useCallback(
    (isTemplateDirty?: boolean) => {
      const state = getStateContent(editorState);

      if (!handleCheckIsValidTemplate(state, videoType)) {
        return;
      }

      const exportState = EditorState.exportState(editorState, [
        dynamicIntegration.postProcessor,
        dynamicTextIntegration.postProcessor,
        dynamicTextIntegration.formatDynamicTexts,
      ]);

      onSubmit(exportState, editorState, isTemplateDirty);
    },
    [
      editorState,
      handleCheckIsValidTemplate,
      videoType,
      dynamicIntegration.postProcessor,
      dynamicTextIntegration.postProcessor,
      dynamicTextIntegration.formatDynamicTexts,
      onSubmit,
    ],
  );

  const { checkTemplateCompatibility } = useTemplateCompatibilityCheck({
    onChange: handleChange,
  });

  React.useEffect(() => {
    const { templateType } = template || {};

    checkTemplateCompatibility(templateId, templateType);

    onSubscriptionOptionsChange?.(
      {
        isCaptionEnabled,
      },
      'merge',
    );
  }, [
    checkTemplateCompatibility,
    isCaptionEnabled,
    onSubscriptionOptionsChange,
    template,
    templateId,
  ]);

  return (
    <div className={block()}>
      {!editorState ? null : (
        <VideoTemplateEditor
          className={block('editor')}
          ctaLabel={ctaLabel}
          disabled={disabled}
          features={{
            progress: videoType === 'fullEpisode' ? 'hidden' : undefined,
            waveform: {
              fidelity: 'hidden',
            },
            captions: integrators.length ? 'hidden' : true,
          }}
          integrations={[
            uploadMediaIntegration,
            dynamicIntegration,
            staticTextIntegration,
            dynamicTextIntegration,
            uploadVideoIntegration,
          ]}
          initialDirtyState={initialTemplateIsDirty}
          state={editorState}
          onChange={handleChange}
          onCtaClick={handleCtaClick}
          videoType={videoType}
        />
      )}
    </div>
  );
};

export default AutomationCustomizeStep;
