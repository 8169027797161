import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { YouTubeButton } from 'components/SocialButton';
import YoutubePostVideoModal from 'containers/YoutubePostVideoModal/YoutubePostVideoModal';
import useYouTubeAuthenticator from 'hooks/useYouTubeAuthenticator';
import { videoIdSelector } from 'redux/modules/download';
import {
  postYoutubeVideo,
  youtubeProviderUserIdSelector,
} from 'redux/modules/social';

// utils
import { createChainedFunction } from 'utils/functions';

// hooks
import { useSocialSection } from '../DownloadPageSocialSection/SocialSectionContext';
import useYoutubePostButtonDispatch from './useYoutubePostButtonDispatch';

export interface YoutubePostButtonProps {
  disabled?: boolean;
  show?: boolean;
}

const { useCallback, useState } = React;

const YoutubePostButton: React.FC<YoutubePostButtonProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const { onClick } = useYoutubePostButtonDispatch();
  const {
    activeModal,
    toggleModalVisibility,
    onUnlockAICaptions,
  } = useSocialSection();
  const providerUserId = useSelector(youtubeProviderUserIdSelector);

  const [error, setError] = useState<string>(undefined);

  const handleAuthSuccess = useCallback(() => {
    setError(undefined);
    toggleModalVisibility('youtube', true);
  }, [setError, toggleModalVisibility]);

  const handleModalHide = useCallback(() => {
    setError(undefined);
    toggleModalVisibility('youtube', false);
  }, [setError, toggleModalVisibility]);

  const handleError = useCallback(
    err => {
      setError(err);
      toggleModalVisibility('youtube', true);
    },
    [setError, toggleModalVisibility],
  );

  const handleUnlockAICaptions = useCallback(() => {
    toggleModalVisibility('youtube', false);
    onUnlockAICaptions();
  }, [onUnlockAICaptions, toggleModalVisibility]);

  const handleCancelUnlockAICaptions = useCallback(() => {
    toggleModalVisibility('youtube', true);
  }, [toggleModalVisibility]);

  const { authenticating, withAuthentication } = useYouTubeAuthenticator({
    force: false,
    onError: handleError,
  });

  const handleSubmit = useCallback(
    ({
      categoryId,
      defaultLanguage,
      description,
      madeForKids,
      playlists,
      tags,
      title,
      visibility,
    }) => {
      dispatch((_, getState) => {
        const videoId = videoIdSelector(getState());
        dispatch(
          postYoutubeVideo(videoId, {
            categoryId,
            defaultLanguage,
            description,
            madeForKids,
            playlists,
            privacyStatus: visibility,
            tags,
            title,
            providerUserId,
          }),
        );
      });
    },
    [dispatch, providerUserId],
  );

  return (
    <>
      <YouTubeButton
        disabled={disabled || authenticating}
        onClick={createChainedFunction(
          onClick,
          withAuthentication(handleAuthSuccess),
        )}
        params={withAuthentication}
        size="small"
      />
      <YoutubePostVideoModal
        show={activeModal === 'youtube'}
        onHide={handleModalHide}
        onSubmit={handleSubmit}
        error={error}
        source="DownloadPage"
        onUnlockAICaptions={handleUnlockAICaptions}
        onCancelUnlockAICaptions={handleCancelUnlockAICaptions}
      />
    </>
  );
};

export default YoutubePostButton;
