import React, { useMemo } from 'react';
import Select, { Option, SelectProps } from 'components/Select';
import { SWITCH_ACCOUNT_VALUE } from './constants';
import SwitchAccountIcon from './SwitchAccountIcon';

type AccountSelectProps = SelectProps;

const AccountSelect: React.FC<AccountSelectProps> = props => {
  const { options, ...rest } = props;

  const optionsWithSwitchAccount = useMemo(
    () => [
      ...options,
      {
        label: 'Switch Account',
        logo: <SwitchAccountIcon />,
        value: SWITCH_ACCOUNT_VALUE,
      } as Option,
    ],
    [options],
  );

  return <Select options={optionsWithSwitchAccount} {...rest} />;
};
export default AccountSelect;
