import { Thumbnail } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { H1 } from 'components/Heading';
import { HeadphonesHeady } from 'components/icons';

import { getAspectRatioValue } from 'utils/aspect-ratio';
import { introBlock as block } from '../utils';
import { useClipSelect } from './ClipSelectContext';
import { getIntroMessage } from './utils';

export interface ClipSelectIntroProps {
  className?: string;
}

const ClipSelectIntro: React.FC<ClipSelectIntroProps> = ({ className }) => {
  const {
    clipsPageInfo: { artwork, artworkAspectRatioName },
    suggestionIds,
  } = useClipSelect();

  return (
    <div className={cn(block(), className)}>
      <div className={block('callout')}>
        <div className={block('body')}>
          <div
            className={block('artwork-container', {
              [artworkAspectRatioName]: true,
            })}
          >
            {artwork && (
              <Thumbnail
                src={artwork}
                alt="Clip artwork"
                ratio={getAspectRatioValue(artworkAspectRatioName)}
                basis={
                  getAspectRatioValue(artworkAspectRatioName) < 1
                    ? 'height'
                    : 'width'
                }
              />
            )}
          </div>
          <H1 className={block('message')}>
            {getIntroMessage(suggestionIds?.length)}
          </H1>
        </div>
        <div className={block('arrow')} />
      </div>
      <HeadphonesHeady className={block('heady')} />
    </div>
  );
};

export default ClipSelectIntro;
