import { Heading } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { useState } from 'react';
import { useClipSelect } from 'blocks/ClipSelect/containers/ClipSelectContext';
import CirclePlayButton, {
  CirclePlayButtonProps,
} from 'components/CirclePlayButton';

import ProgressOverlay from 'components/ProgressOverlay';
import { range } from 'utils/numbers';
import AudioClipCardText from './AudioClipCardText';
import { ClipTagProps } from './ClipTag';
import { ClipTimecodesProps } from './ClipTimecodes';
import { block } from './utils';

type PickedPlayButtonProps = Pick<CirclePlayButtonProps, 'playing'>;

export interface AudioClipCardProps
  extends ClipTagProps,
    ClipTimecodesProps,
    PickedPlayButtonProps {
  className?: string;
  onClickControlButton?: () => void;
  playable?: boolean;
  isLoading?: boolean;
  text: string;
  videoUrl: string;
  id?: number;
}

const AudioClipCard: React.FC<AudioClipCardProps> = ({
  className,
  clipEndMillis,
  clipStartMillis,
  onClickControlButton,
  playable,
  playing,
  isLoading,
  text,
  videoUrl,
  id,
}) => {
  const [playbackMillis, setPlaybackMillis] = useState(0);

  const {
    videoPlayerRefs,
    clipsPageInfo: { aspectRatioName },
  } = useClipSelect();

  return (
    <div
      className={cn(block({ [aspectRatioName]: true, playable }), className)}
    >
      <div className={block('video-player')}>
        <div className={block('playback')}>
          {!isLoading && (
            <video
              ref={videoPlayerRefs?.[id]}
              src={videoUrl}
              className={cn(block('video', { [aspectRatioName]: true }))}
              loop
              onTimeUpdate={e => setPlaybackMillis(e.currentTarget.currentTime)}
            />
          )}

          <div className={block('center-content')}>
            {isLoading ? (
              <Heading level={1} className={block('loading-message')}>
                This clip will
                <br /> be ready soon
              </Heading>
            ) : (
              <CirclePlayButton
                {...{ playing }}
                className={block('play-button')}
                onClick={onClickControlButton}
                progress={range(
                  0,
                  videoPlayerRefs?.[id]?.current?.duration,
                  0,
                  1,
                  playbackMillis,
                )}
              />
            )}
          </div>

          {isLoading && (
            <div className={block('overlay-wrapper')}>
              <ProgressOverlay in />
            </div>
          )}
        </div>
      </div>

      <AudioClipCardText
        {...{ text, clipStartMillis, clipEndMillis, playable }}
        isDisabled={isLoading}
      />
    </div>
  );
};

export default AudioClipCard;
