import {
  IEmbedConfig,
  ProjectCreationMethod,
  Replace,
  SoundWaveGeneration,
  TemplateType,
} from 'types';
import { ClipSuggestionStatus, FeedbackType } from '../podcast-service';
import { IApiAction, IApiResponse } from '../types';

export const ACTION_KEY = 'CREATION_SERVICE';

export enum ServiceMethod {
  CREATE = 'CREATE',
  CREATION_GET = 'CREATION_GET',
  GET_CREATION_CLIP_SUGGESTIONS = 'GET_CREATION_CLIP_SUGGESTIONS',
  ENABLE_CREATION_CLIP_SUGGESTION = 'ENABLE_CREATION_CLIP_SUGGESTION',
  CREATE_CREATION_CLIP_SUGGESTION_FEEDBACK = 'CREATE_CREATION_CLIP_SUGGESTION_FEEDBACK',
  CREATE_CREATION_CLIP_SUGGESTION = 'CREATE_CREATION_CLIP_SUGGESTION',
  GET_CREATION_CLIP_SUGGESTION = 'GET_CREATION_CLIP_SUGGESTION',
  GET_CREATION_CLIP_SUGGESTIONS_PROGRESS = 'GET_CREATION_CLIP_SUGGESTIONS_PROGRESS',
}

export enum CreationStatus {
  SUBMITTED = 'submitted',
  PROCESSING_ASSETS = 'processingAssets',
  CREATING_CONFIG = 'creatingConfig',
  EXPORTING_VIDEO = 'exportingVideo',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum AssetStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  NOT_FOUND = 'notFound',
  PROCESSING = 'processing',
}

export interface CreationVideoInfo {
  alreadyProcessed: boolean;
  currentlyProcessing: boolean;
  videoId: number;
}

export interface CreationRequest {
  audioFile?: Blob;
  audioPodcastId?: string;
  audioRemoteEpisodeId?: string;
  audioUrl?: string;
  videoFile?: Blob;
  videoUrl?: string;
  isVideoTranscriptEnabled?: boolean;
  videoLanguage?: string;
  videoTrimStartMillis?: number;
  videoTrimEndMillis?: number;
  entireAudioInstanceId?: number;
  entireAudioTrimStartMillis?: number;
  entireAudioTrimEndMillis?: number;
  preferredAudioWavetype?: SoundWaveGeneration;
  isAudioTranscriptEnabled?: boolean;
  templateId?: string;
  templateType?: TemplateType;
  projectCreateMethod?: ProjectCreationMethod;
  projectName?: string;
  baseConfigJson?: string;
  entireAudioTranscriptUrl?: string;
  clippedAudioTranscriptUrl?: string;
  initiateExportVideo?: boolean;
  videoFrameHeight?: number;
  videoFrameWidth?: number;
  videoFrameQuality?: number;
  videoFrameRate?: number;
  aspectRatioWidth?: number;
  aspectRatioHeight?: number;
  customTraceId?: string;
  traceId?: string;
  isEddy?: boolean;
  audioLanguage?: string;
  uploadedVideoId?: number;
  videoInfo?: CreationVideoInfo;
  userId?: number;
}

export type CreationActionOptions = Replace<
  Omit<CreationRequest, 'audioUrl' | 'audioFile' | 'videoUrl' | 'videoFile'>,
  {
    audioSource?: string | File;
    videoSource?: string | File;
    baseConfigJson?: IEmbedConfig;
    projectUuid?: string;
  }
>;

export type CreationVideoExportOptions = Pick<
  CreationActionOptions,
  | 'videoFrameWidth'
  | 'videoFrameQuality'
  | 'videoFrameHeight'
  | 'videoFrameRate'
>;

export interface AssetStatuses {
  audioStatus: AssetStatus;
  waveformStatus: AssetStatus;
}

export interface CreationResponse {
  assetStatuses: AssetStatus;
  createdAt: number;
  id: number;
  initiateVideoExport: boolean;
  requestObj: CreationRequest;
  status: CreationStatus;
}

export type CreationArgs = [CreationRequest];
export type GetCreationArgs = [number];

export interface CreationResult {
  entities: {
    creations: {
      [key: number]: CreationResponse;
    };
  };
  result: number;
}

type ApiAction<M extends ServiceMethod, A> = IApiAction<
  typeof ACTION_KEY,
  M,
  A
>;

export type CreationAction = ApiAction<ServiceMethod.CREATE, CreationArgs>;
export type GetCreationAction = ApiAction<
  ServiceMethod.CREATION_GET,
  GetCreationArgs
>;

export type GetCreationClipSuggestionsArgs = [
  number, // creationRequestId
];

export type GetCreationClipSuggestionArgs = [
  number, // creationRequestId
  number, // suggestionId
];

export type GetCreationClipSuggestionsProgressArgs = [
  number, // creationRequestId
];

export type CreateCreationClipSuggestionArgs = [
  number, // creationRequestId
  number, // newTrimStartMillis
  number, // newTrimEndMillis
];

export type EnableCreationClipSuggestionArgs = [
  number, // creationRequestId
  number, // suggestionId
];

export type CreateCreationClipSuggestionFeedbackArgs = [
  FeedbackType, // feedbackType:
  number, // creationRequestId
  number, // suggestionId
];

export type EnableCreationClipSuggestionResult = null;

export interface CreationClipSuggestion {
  suggestionId: number;
  startMillis: number;
  endMillis: number;
  text?: string;
  widgetId?: string;
  videoUrl?: string;
  videoThumbnailUrl?: string;
  status?: ClipSuggestionStatus;
}

export interface NormalizeGroupClipSuggestions {
  entities: {
    creationClipSuggestions: CreationClipSuggestion;
  };
  result: CreationClipSuggestion;
}

export type GetCreationClipSuggestionsResult = NormalizeGroupClipSuggestions;

export type GetCreationClipSuggestionsAction = ApiAction<
  ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS,
  GetCreationClipSuggestionsArgs
>;

export type GetCreationClipSuggestionAction = ApiAction<
  ServiceMethod.GET_CREATION_CLIP_SUGGESTION,
  GetCreationClipSuggestionArgs
>;

export type GetCreationClipSuggestionsProgressAction = ApiAction<
  ServiceMethod.GET_CREATION_CLIP_SUGGESTIONS_PROGRESS,
  GetCreationClipSuggestionsProgressArgs
>;

export type GetCreationClipSuggestionResult = CreationClipSuggestion;

export type GetCreationClipSuggestionsProgressResult = any;

export type CreateCreationClipSuggestionAction = ApiAction<
  ServiceMethod.CREATE_CREATION_CLIP_SUGGESTION,
  CreateCreationClipSuggestionArgs
>;

export type CreateCreationClipSuggestionResult = CreationClipSuggestion;

export interface CreationServiceDispatch {
  (action: CreationAction): Promise<IApiResponse<CreationResult>>;
  (action: GetCreationAction): Promise<IApiResponse<CreationResult>>;
  (action: GetCreationClipSuggestionsAction): Promise<
    IApiResponse<GetCreationClipSuggestionsResult>
  >;
  (action: CreateCreationClipSuggestionAction): Promise<
    IApiResponse<CreateCreationClipSuggestionResult>
  >;
  (action: GetCreationClipSuggestionAction): Promise<
    IApiResponse<GetCreationClipSuggestionResult>
  >;
  (action: GetCreationClipSuggestionsProgressAction): Promise<
    IApiResponse<GetCreationClipSuggestionsProgressResult>
  >;
}
