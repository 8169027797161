import { getThemeColor, useTheme, VideoSingleStar } from '@sparemin/blockhead';
import cn from 'classnames';
import * as React from 'react';

import bem from 'utils/bem';
import ProjectButton from '../../containers/ProjectButton';
import { INewProjectButtonProps } from './types';

const block = bem('video-transcription-button');

const VideoTranscriptionButton: React.SFC<INewProjectButtonProps> = ({
  className,
  description = 'Turn video podcasts into shareable clips with stylish captions in just a few clicks',
  icon,
  title,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ProjectButton
      className={cn(block(), className)}
      icon={
        <VideoSingleStar
          width="80px"
          height="40px"
          color={getThemeColor('s5')({ theme })}
        />
      }
      projectType="video-transcript"
      title={
        <>
          Caption and clip
          <br />
          video podcasts
        </>
      }
      description={description}
      to={{
        pathname: '/wizard',
        query: {
          type: 'video-transcription',
        },
      }}
      {...rest}
    />
  );
};

export default VideoTranscriptionButton;
