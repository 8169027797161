import { Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import DestinationPlatforms, {
  checkIfDestinationPresetExists,
  DestinationPlatform,
} from 'blocks/DestinationPlatforms';

import { onOnboardingWizardNext } from 'redux/modules/mixpanel';
import { AudiogramDestination } from 'types';
import { onboardingDestinationStepBlock as block } from '../utils';
import CardWizardStep from './CardWizard/CardWizardStep';

interface DestinationStepProps {
  onSelectDestinationPlatform: (
    newPlatform: AudiogramDestination,
    shouldSelectVideoTypes: boolean,
  ) => void;
}

function DestinationStep(props: DestinationStepProps) {
  const { onSelectDestinationPlatform } = props;

  const dispatch = useDispatch<Dispatch>();

  const handlePlatformSelection = useCallback(
    (platform: DestinationPlatform): void => {
      onSelectDestinationPlatform(
        platform,
        checkIfDestinationPresetExists(platform),
      );

      dispatch(
        onOnboardingWizardNext('destination', { destination: platform }),
      );
    },
    [dispatch, onSelectDestinationPlatform],
  );

  return (
    <CardWizardStep className={block()}>
      <Spacer
        as="div"
        orientation="vertical"
        align="center"
        justify="center"
        className={block('destination-step-wrapper')}
      >
        <DestinationPlatforms onPlatformSelection={handlePlatformSelection} />
      </Spacer>
    </CardWizardStep>
  );
}

export default DestinationStep;
