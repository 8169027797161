import React from 'react';
import { Child } from 'components/icons';
import RadioOptions, { RadioOptionsProps } from 'components/RadioOptions';
import { block } from './utils';
import YoutubeSelectContainer from './YoutubeSelectContainer';

export interface YoutubeAudienceSelectorProps
  extends Pick<RadioOptionsProps, 'onChange' | 'value'> {}

const YoutubeAudienceSelector: React.FC<YoutubeAudienceSelectorProps> = ({
  onChange,
  value,
}) => (
  <YoutubeSelectContainer label="Audience">
    <Child className={block('made-for-kids-toggle-icon')} />

    <span className={block('made-for-kids-toggle-label')}>Made for kids?</span>

    <RadioOptions
      className={block('options')}
      indicatorClassName={block('option-indicator')}
      listClassName={block('options-list')}
      onChange={onChange}
      optionClassName={block('option')}
      options={[
        {
          id: 'no',
          label: <>No</>,
          value: false,
        },
        {
          id: 'yes',
          label: <>Yes</>,
          value: true,
        },
      ]}
      value={value}
    />
  </YoutubeSelectContainer>
);

export default YoutubeAudienceSelector;
