import React from 'react';
import YoutubeSelectContainer from '../YoutubeSelectContainer';
import { block } from './utils';

type AssetTitleProps = {
  disabled?: boolean;
};

const AssetTitle: React.FC<AssetTitleProps> = ({ disabled = false }) => (
  <YoutubeSelectContainer
    label="Asset Title"
    className={disabled ? block('disabled') : undefined}
  >
    <div>We'll use each video's title for this option</div>
  </YoutubeSelectContainer>
);

export default AssetTitle;
