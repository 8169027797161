import React, { useEffect, useState } from 'react';

import useDisco from 'hooks/useDisco';
import useStaticCallback from 'hooks/useStaticCallback';

interface DiscoWidgetInjectorProps {
  className?: string;
  onInitialize?: () => void;
  displayVariant: string;
}

const DiscoWidgetInjector: React.FunctionComponent<DiscoWidgetInjectorProps> = props => {
  const { className, onInitialize, displayVariant } = props;

  const [injectionPoint, setInjectionPoint] = useState<HTMLDivElement>();

  const { initializeDisco } = useDisco({
    defaultInitializeOnLoad: false,
    injectionPoint,
    displayVariant,
  });

  const staticInitializeDisco = useStaticCallback(initializeDisco);

  useEffect(() => {
    onInitialize();
    staticInitializeDisco();
  }, [onInitialize, staticInitializeDisco]);

  return <div className={className} ref={setInjectionPoint} />;
};

export default DiscoWidgetInjector;
